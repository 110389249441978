import deepmerge from 'deepmerge'
import {
  validMinMaxRange,
  setRangeOrMaxBoundary
} from '../../../../utils/rangeHelpers'

import {
  isRange,
  isProvider,
  isPurpose,
  isContract,
  transform,
  clearUpdates,
  arrayDeepMerge
} from './index'

import { closeMenu, disableMenu } from './menu'

export function applyFilters (state, { filters }) {
  let appliedFilters = {}

  filters.forEach((name) => {
    if (isRange(name)) {
      appliedFilters[name] = transform(state[name])(
        disableMenu, closeMenu, applyRangeOptions, clearUpdates
      )
    }

    if (isProvider(name)) {
      appliedFilters[name] = transform(state[name])(
        disableMenu, closeMenu, applyTypeaheadSelections, clearUpdates
      )
    }

    if (isPurpose(name)) {
      appliedFilters[name] = transform(state[name])(
        disableMenu, closeMenu, applyPurposeOptions, clearUpdates
      )
    }

    if (isContract(name)) {
      appliedFilters[name] = transform(state[name])(
        disableMenu, closeMenu, applyContractOptions, clearUpdates
      )
    }
  })

  appliedFilters.modal = { open: false }

  return appliedFilters
}

export function applyContractOptions (state) {
  if (!state.update) return state

  const { options } = state.update

  return arrayDeepMerge(state, {
    applied: options.toggled
  })
}

export function applyTypeaheadSelections (state) {
  if (!state.update) return state

  const { selected } = state.update

  return arrayDeepMerge(state, {
    applied: selected && selected.length > 0 ? selected.map(s => s.name) : false
  })
}

export function applyRangeOptions (state) {
  if (!state.update) {
    return state
  }

  if (rangeOptionsCleared(state.options)) {
    return deepmerge(state, { applied: false })
  }

  const min = state.options.min.value
  const max = state.options.max.value
  const range = setRangeOrMaxBoundary({ min, max }, state.options)
  const applied = validMinMaxRange(range) ? range : { min: range.min, max: range.min }

  return deepmerge(state, {
    applied,
    options: {
      min: { value: applied.min },
      max: { value: applied.max }
    }
  })
}

const clearedPurpose = (options) =>
  Object.values(options).every(value => !value)

export function applyPurposeOptions (state) {
  if (!state.update) {
    return state
  }

  if (clearedPurpose(state.options)) {
    return deepmerge(state, { applied: false })
  }

  return deepmerge(state, { applied: state.options })
}

function rangeOptionsCleared (options) {
  return options.max.value === options.max.boundary &&
    options.min.value === options.min.boundary
}
