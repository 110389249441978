import { fetchDistrictsNearby } from '../data'

export const REQUEST_DISTRICTS_NEARBY = 'REQUEST_DISTRICTS_NEARBY'
export function requestDistrictsNearby (props) {
  return {
    type: REQUEST_DISTRICTS_NEARBY,
    meta: props
  }
}

export const RECEIVE_DISTRICTS_NEARBY = 'RECEIVE_DISTRICTS_NEARBY'
export function receiveDistrictsNearby (props, data) {
  return {
    type: RECEIVE_DISTRICTS_NEARBY,
    payload: data,
    meta: props
  }
}

export const RECEIVE_DISTRICTS_NEARBY_ERROR = 'RECEIVE_DISTRICTS_NEARBY_ERROR'
export function receiveDistrictsNearbyError (props, error) {
  return {
    type: RECEIVE_DISTRICTS_NEARBY_ERROR,
    payload: {
      error: error.message,
      status: error.status
    },
    meta: props
  }
}

function milesToMeters (miles) {
  return miles / 0.000621371
}

function metersToMiles (meters) {
  return meters * 0.000621371
}

export function getDistrictsNearby ({ latitude, longitude, radius }, retries = 2) {
  const props = { latitude, longitude, radius: milesToMeters(radius) }
  const meta = { latitude, longitude, radius }

  return function (dispatch) {
    dispatch(requestDistrictsNearby(meta))

    return fetchDistrictsNearby(props)
      .then(data => data.map(row => {
        row.distance = metersToMiles(row.distance)
        return row
      }))
      .then(data => dispatch(receiveDistrictsNearby(meta, data)))
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveDistrictsNearbyError(meta, error))
        }
        return dispatch(getDistrictsNearby(meta, retries - 1))
      })
  }
}

export default {
  REQUEST_DISTRICTS_NEARBY,
  RECEIVE_DISTRICTS_NEARBY,
  RECEIVE_DISTRICTS_NEARBY_ERROR
}
