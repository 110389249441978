import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import '../shared.sass'
import { stateBandwidthDashboard } from '../../../routes'
import { buildFilterQueryString } from '../../../utils/urlHelpers'

export default function StateBandwidthLink ({ classNames = [''], stateCode, filters = [], children }) {
  if (!stateCode) { return <div /> }

  const location = {
    pathname: stateBandwidthDashboard.generatePath({ state_code: stateCode }),
    search: buildFilterQueryString(filters)
  }

  return (
    <Link className={classNames.join(' ')} to={location}>
      {children}
    </Link>
  )
}

StateBandwidthLink.propTypes = {
  stateCode: PropTypes.string,
  filters: PropTypes.array,
  classNames: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any
}
