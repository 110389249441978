import { combineReducers } from 'redux'
import districts from './districts'
import selected from './selected'
import recentSearches from './recentSearches'
import states from './states'

export const initialState = {
  selected: [],
  states: [],
  districts: [],
  recentSearches: []
}

export default combineReducers({
  districts,
  recentSearches,
  selected,
  states
})
