import React from 'react'
import nearbyServicesButtonIcon from '../../../assets/icon-16-icon-pin-white.svg'

const ExpandMapButton = ({ bannerVisible, onClick }) => {
  return (
    <button className='NearbyServicesMap__button' onClick={onClick}>
      <img src={nearbyServicesButtonIcon} alt='button pin icon' />
      {bannerVisible ? 'Larger Map' : 'Smaller Map'}
    </button>
  )
}

export default ExpandMapButton
