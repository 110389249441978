import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import recommendedIcon from '../../../assets/icon-24-icon-recommended.svg'
import SharedTooltip from '../../Tooltip/SharedTooltip'
import ConsortiumTooltip from '../../Tooltip/ConsortiumTooltip'
import {
  formatBandwidth,
  formatCost,
  formatPurpose,
  titleize,
  formatISODate
} from '../../../utils/stringHelpers'

export function ServiceDetails ({ selected, service }) {
  return (
    <div>
      <div className='ServicePopup__content-group'>
        <div className='ServicePopup__content-group--bandwidth'>
          <div className='ServicePopup__metric ServicePopup__metric--bandwidth'>
            {service.recommended &&
              <img src={recommendedIcon}
                alt='Recommended Service Icon'
                className='ServicePopup__recommended-icon'
              />
            }
            {formatBandwidth(service.download_speed_mbps)}
            <SharedTooltip enabled={selected && service.shared} id={service.line_item} className='ServicePopup__tooltip--shared' />
          </div>
          <div className='ServicePopup__label'>
            {formatPurpose(service.purpose)}
          </div>
        </div>
        <div className='ServicePopup__content-group--cost'>
          <div className='ServicePopup__metric ServicePopup__metric--cost'>
            {formatCost(service.average_monthly_cost, '0,0')}
            <ConsortiumTooltip className='ServicePopup__tooltip--consortium' enabled={service.applicant_type === 'Consortium'} id={service.line_item} />
          </div>
          <div className='ServicePopup__label'>
            Monthly Cost /
            <br />
            Connection
          </div>
        </div>
      </div>
      <div className='ServicePopup__content-group--service-provider'>
        <div className='ServicePopup__metric--service-provider'>
          {titleize(service.service_provider_name)}
        </div>
      </div>
      <div className='ServicePopup__content-group'>
        <div className='ServicePopup__content-group--contract-end'>
          <div className='ServicePopup__label--contract-end'>
            CONTRACT END
          </div>
          <div className='ServicePopup__metric--contract-end'>
            {formatISODate(service.contract_expiration_date)}
          </div>
        </div>
        <div className='ServicePopup__content-group--connections'>
          <div className='ServicePopup__label--connections'>
            CONNECTIONS
          </div>
          <div className='ServicePopup__metric--connections'>
            {service.district_num_lines}
          </div>
        </div>
      </div>
    </div>
  )
}

ServiceDetails.propTypes = {
  service: PropTypes.object.isRequired
}

function renderServices (selected, services) {
  return services.map((service) => {
    return (
      <div className='ServicePopup__body-content' key={service.service_id}>
        <ServiceDetails selected={selected} service={service} />
      </div>
    )
  })
}

function fadeOutBottom (numServices) {
  const baseClass = 'ServicePopup__body__fadeout'
  return (numServices > 2 ? baseClass : baseClass + '--hidden')
}

export default function PopupBody ({ selected, services, style }) {
  const className = classnames({
    'ServicePopup__body--multiple': services.length > 1
  })
  return (
    <div className={`ServicePopup__body ${className}`} style={style}>
      {renderServices(selected, services)}
      <div className={fadeOutBottom(services.length)} />
    </div>
  )
}

PopupBody.propTypes = {
  services: PropTypes.array.isRequired
}
