import costOverTime from './costOverTime'
import erateAppliedFunding from './erateAppliedFunding'
import percentMeetingGoal from './percentMeetingGoal'
import status from './status'
import comparisonStateCode from './comparisonStateCode'
import { combineReducers } from 'redux'

export default combineReducers({
  costOverTime,
  erateAppliedFunding,
  percentMeetingGoal,
  comparisonStateCode,
  status
})
