import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../actions/nearbyServicesFilters'
import { servicesByRecipientBen, addDefaultDistrictService } from './utils'

export default function (state = {}, action = {}) {
  const { type, payload } = action
  if (type === NEARBY_SERVICES_DATA_FILTERED) {
    const { districtServices, selectedDistrict } = Object.assign({}, payload)
    let selectedDistrictServices = addDefaultDistrictService(districtServices, selectedDistrict)
    return selectedDistrictServices.reduce(servicesByRecipientBen, {})
  }

  return state
}
