import React from 'react'
import Skeleton from '../../Skeleton'
import * as statuses from '../../../reducers/status'
import loadingSpinner from '../../../assets/loading-spinner-gray.svg'

const MapSkeleton = ({ map, status }) => {
  if (!map || status === statuses.LOADING) {
    return (
      <div className='NearbyServicesMap__skeleton'>
        <Skeleton height={'100%'} />
        <img className='NearbyServicesMap__loading-spinner' src={loadingSpinner} aria-hidden alt='loading spinner' />
      </div>
    )
  }
  return null
}

export default MapSkeleton
