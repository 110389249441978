import { LOCATION_CHANGE } from 'connected-react-router'
import {
  STATE_COMPARISON_CLICKED,
  STATE_COMPARISON_CLEARED
} from '../../../../actions/stateOverviewDashboard'

export default function comparisonStateCode (state = null, action = {}) {
  const { type } = action
  switch (type) {
    case STATE_COMPARISON_CLICKED:
      return action.payload
    case STATE_COMPARISON_CLEARED:
    case LOCATION_CHANGE:
      return null
    default:
      return state
  }
}
