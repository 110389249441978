import React, { useState, useEffect } from 'react'

export default function LastUpdated () {
  const [state, setState] = useState([])

  const updated = async () => {
    const url = `${process.env.REACT_APP_BODHI_URL}nation`
    fetch(url).then(
      response => response.json()
    ).then(
      data => setState(data[data.length - 1])
    )
  }

  useEffect(() => {
    updated()
  }, [])

  return (
    <div className='Footer-wrap__updated'>
    Last Updated: {state.timestamp}
    </div>
  )
}
