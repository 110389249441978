import { LOCATION_CHANGE } from 'connected-react-router'
import { getNation } from '../actions/nation'
import { nationalDashboard } from '../routes'

const nationalDashboardDataLoader = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { location: { pathname } } = payload
    const match = nationalDashboard.matchPath(pathname)
    if (match) {
      store.dispatch(getNation())
    }
  }
}

export default nationalDashboardDataLoader
