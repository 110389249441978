import * as stringHelpers from './stringHelpers'
import * as classNameUtils from './classNameUtils'
import * as urlHelpers from './urlHelpers'
import { LOADED } from '../reducers/status'
import { renderMobile, renderTabletMd } from './windowUtils'
import sortObjects, { ASC, DESC } from './sortHelpers'
import { convertDecimalToPercentValue } from './numberHelpers'

const isLoaded = (statuses) => {
  return [...new Set([...statuses])].every(s => s === LOADED)
}

function safeDup (obj) {
  if (typeof obj === 'undefined') {
    return undefined
  }
  return JSON.parse(JSON.stringify(obj))
}

const isIE11 = !!document.documentMode

export default {
  ...stringHelpers,
  ...classNameUtils,
  ...urlHelpers
}

export {
  isIE11,
  isLoaded,
  safeDup,
  sortObjects,
  ASC,
  DESC,
  renderMobile,
  renderTabletMd,
  convertDecimalToPercentValue
}
