import { getFilters } from '../selectors/filterSelectors'
import { getData, getSelectedSortOption } from '../selectors/dataSelectors'
import { updateDefaultSortOption } from './helpers'

export const FILTER_NEARBY_SERVICES = 'FILTER_NEARBY_SERVICES'
export function filterNearbyServices (filters) {
  return {
    type: FILTER_NEARBY_SERVICES,
    payload: filters
  }
}

export const FILTER_NEARBY_SERVICES_BY_PROVIDER = 'FILTER_NEARBY_SERVICES_BY_PROVIDER'
export function filterNearbyServicesByProvider (filters, nearby) {
  return {
    type: FILTER_NEARBY_SERVICES_BY_PROVIDER,
    payload: { filters, nearby }
  }
}

export const NEARBY_SERVICES_FILTER_UPDATED = 'NEARBY_SERVICES_FILTER_UPDATED'
export function nearbyServicesFilterUpdated (name, update) {
  return {
    type: NEARBY_SERVICES_FILTER_UPDATED,
    payload: {
      name,
      update
    }
  }
}

export const NEARBY_SERVICES_FILTERS_APPLIED = 'NEARBY_SERVICES_FILTERS_APPLIED'
export function nearbyServicesFiltersApplied (filters, { method } = { method: 'push' }) {
  return {
    type: NEARBY_SERVICES_FILTERS_APPLIED,
    payload: {
      filters
    },
    meta: {
      method
    }
  }
}

export const NEARBY_SERVICES_FILTER_MENU_TOGGLED = 'NEARBY_SERVICES_FILTER_MENU_TOGGLED'
export function nearbyServicesFilterMenuToggled (name, open) {
  return {
    type: NEARBY_SERVICES_FILTER_MENU_TOGGLED,
    payload: {
      name,
      open
    }
  }
}

export const NEARBY_SERVICES_FILTER_CLEARED = 'NEARBY_SERVICES_FILTER_CLEARED'
export function nearbyServicesFilterCleared (name) {
  return {
    type: NEARBY_SERVICES_FILTER_CLEARED,
    payload: {
      name
    }
  }
}

export const NEARBY_SERVICES_FILTERS_CLEARED = 'NEARBY_SERVICES_FILTERS_CLEARED'
export function nearbyServicesFiltersCleared () {
  return {
    type: NEARBY_SERVICES_FILTERS_CLEARED
  }
}

export const NEARBY_SERVICES_DATA_FILTERED = 'NEARBY_SERVICES_DATA_FILTERED'
export function nearbyServicesDataFiltered (payload = {}) {
  return {
    type: NEARBY_SERVICES_DATA_FILTERED,
    payload
  }
}

export const NEARBY_SERVICES_FILTERS_MODAL_TOGGLED = 'NEARBY_SERVICES_FILTERS_MODAL_TOGGLED'
export function nearbyServicesFiltersModalToggled (open = false) {
  return {
    type: NEARBY_SERVICES_FILTERS_MODAL_TOGGLED,
    payload: {
      open
    }
  }
}

export const applyFilters = async (dispatch, getState) => {
  const filters = getFilters(getState())
  await dispatch(filterNearbyServices(filters))
  await applyProviderFilter(dispatch, getState)
  return notifyDataFiltered(dispatch, getState)
}

const applyProviderFilter = async (dispatch, getState) => {
  const { nearbyServices: { data: { nearby }, filters } } = getState()
  return dispatch(filterNearbyServicesByProvider(filters, nearby))
}

const notifyDataFiltered = async (dispatch, getState) => {
  const state = getState()
  const { district: selectedDistrict } = state
  const { nearby, districtServices } = getData(state)
  const selectedSortOption = getSelectedSortOption(state)
  const defaultSortOption = updateDefaultSortOption(nearby, selectedSortOption)
  return dispatch(
    nearbyServicesDataFiltered({ defaultSortOption, nearby, districtServices, selectedDistrict: selectedDistrict.data })
  )
}
