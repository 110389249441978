import { REQUEST_NEARBY_SERVICES } from '../../../../actions/nearbyServices'

export default function (state = true, action = {}) {
  const { type, payload } = action

  if (type === REQUEST_NEARBY_SERVICES) {
    return payload.isFirstRequest
  }

  return state
}
