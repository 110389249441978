import { combineReducers } from 'redux'
import districts from './districts'
import table from './table'

const initialState = {
  districts: [],
  table: {}
}

export default combineReducers({
  initialState,
  districts,
  table
})
