import React from 'react'
import './Track.sass'

function Track ({ source, target, getTrackProps }) {
  const left = `${source.percent}%`
  const width = `${target.percent - source.percent}%`

  return (
    <div
      className='RangeFilter__Track'
      style={{ left, width }}
      {...getTrackProps()}
    />
  )
}

export default Track
