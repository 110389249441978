import React from 'react'
import PropTypes from 'prop-types'
import ChartBar from '../../ChartBar'
import FCCErateSource from '../../FCCErateSource'
import * as statuses from '../../../reducers/status'
import './index.sass'

export default function MeetingGoal ({ status }) {
  return (
    <div className='MeetingGoal'>
      <div className='NationalMetrics__title'>As median bandwidth per student in school districts has gone up, median cost per megabit has gone down!.</div>
      <div className='MeetingGoalCharts__center'>
        {status === statuses.LOADED &&
        <ChartBar />
        }
      </div>
      <div class='ChartLegend'>
        <div class='ChartLegend__series state'>
          <div class='ChartLegend__series__box' />
          <div class='ChartLegend__series__label'>Median Bandwidth per student (Mbps)</div>
        </div>
        <div class='ChartLegend__series national'>
          <div class='ChartLegend__series__box' />
          <div class='ChartLegend__series__label'>Median Cost per Mbps ($)</div>
        </div>
      </div>
      <FCCErateSource className='NationalMetrics__source' />
    </div>
  )
}

MeetingGoal.propTypes = {
  pctMeetingGoal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  status: PropTypes.string
}
