import React from 'react'
import PropTypes from 'prop-types'

import './Actions.sass'

function Action ({ label, onClick }) {
  let labelClass

  if (typeof label !== 'undefined') {
    labelClass = `Filter__action--${label}`
  }

  return (
    <li className={`Filter__action ${labelClass}`} onClick={onClick}>
      {label}
    </li>
  )
}

function Actions ({ applied, onClear, onApply }) {
  const clear = <Action label='clear' onClick={onClear} key='0' />
  const apply = <Action label='apply' onClick={onApply} key='1' />
  const empty = <Action key='3' />

  return (
    <ul className='Filter__actions'>
      {applied ? [clear, apply] : [empty, apply]}
    </ul>
  )
}

Actions.propTypes = {
  applied: PropTypes.bool,
  onClear: PropTypes.func,
  onApply: PropTypes.func
}

export default Actions
