import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Title from '../../Table/Title'
import NearbyServicesTableData from './NearbyServicesTableData'
import { titleize, formatNumber } from '../../../utils/stringHelpers'
import * as statuses from '../../../reducers/status'
import { anyFilterApplied } from '../../../reducers/shared/filters/buildFilterFunction'
import TableOptions from './TableOptions'
import { useToggleFooterOnScroll } from '../../../hooks'
import './index.sass'

const subtitle = ({ filtersApplied, district, services, status }) => {
  if (status !== statuses.PROCESSED || !district) return null
  const serviceCount = services.length
  return filtersApplied
    ? `${formatNumber(serviceCount, '0,0')} internet services within 100 miles of ${titleize(district.entity_name)} match your search`
    : `Showing all ${formatNumber(serviceCount, '0,0')} internet services within 100 miles of ${titleize(district.entity_name)}`
}

export default function NearbyServicesTable ({
  services,
  table,
  filters,
  district,
  status,
  onSort,
  onTableMouseEnter,
  onTableMouseLeave
}) {
  const { sort: tableSort, status: tableStatus } = table
  const filtersApplied = anyFilterApplied(filters, ...Object.keys(filters))
  const ref = useToggleFooterOnScroll()
  const tableOptionsRef = useRef()
  const tableOptionsHeight = tableOptionsRef.current && tableOptionsRef.current.clientHeight

  useEffect(() => {
    if (ref && ref.current && window.Element.prototype.hasOwnProperty('scrollTo')) {
      ref.current.scrollTo(0, 0)
    }
  }, [ref, district])

  return (
    <div className={classnames('NearbyServicesTable', `NearbyServicesTable--${status}`)} ref={ref}>
      <div className='NearbyServicesTableTitle__container'>
        <Title
          className='NearbyServices__header'
          title='Explore internet prices'
          subtitle={subtitle({ filtersApplied, district, services, status })} />
      </div>
      <div className='TableOptions' ref={tableOptionsRef}>
        <TableOptions
          onSort={onSort}
          services={services}
          district={district}
          tableSort={tableSort}
        />
      </div>
      <NearbyServicesTableData
        tableOptionsHeight={tableOptionsHeight}
        services={services}
        district={district}
        tableSort={tableSort}
        onSort={onSort}
        onTableMouseEnter={onTableMouseEnter}
        onTableMouseLeave={onTableMouseLeave}
        tableStatus={tableStatus}
      />
    </div>
  )
}

NearbyServicesTable.propTypes = {
  services: PropTypes.array.isRequired,
  district: PropTypes.object,
  table: PropTypes.shape({
    sort: PropTypes.shape({
      selected: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      options: PropTypes.array
    }),
    status: PropTypes.string
  }),
  onSort: PropTypes.func,
  status: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  onTableMouseEnter: PropTypes.func,
  onTableMouseLeave: PropTypes.func
}
