import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import closeButtonIcon from '../../../assets/icon-24-icon-close-black.svg'
import { NEARBY, STATE, CLOSED } from './utils'
import { stopPropagation } from '../../../utils/actionHelpers'
import { formatNumber } from '../../../utils/stringHelpers'

export default function MeetingGoalTabs ({ stateCode, tab, setTab, numDistrictsState, numDistrictsNearby }) {
  const tabClasses = (name) => {
    return classnames(
      'MeetingGoalTabs__tab', {
        'MeetingGoalTabs__tab--selected': tab === name
      }
    )
  }
  return (
    <div className='MeetingGoalTabs'>
      <div className={tabClasses(STATE)} onClick={() => setTab(STATE)}>
        {`${formatNumber(numDistrictsState)} school districts in ${stateCode}`}
      </div>
      <div className={tabClasses(NEARBY)} onClick={() => setTab(NEARBY)}>
        <div className='MeetingGoalTabs__text'>{`${numDistrictsNearby} school districts nearby`}</div>
        <img
          src={closeButtonIcon}
          role='button'
          alt='close icon'
          className='MeetingGoalTabs__close'
          onClick={stopPropagation(() => setTab(CLOSED))} />
      </div>
    </div>
  )
}

MeetingGoalTabs.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  stateCode: PropTypes.string,
  numDistrictsState: PropTypes.number,
  numDistrictsNearby: PropTypes.number
}
