export const INITIALIZED = 'INITIALIZED'
export const REQUESTED = 'REQUESTED'
export const UPDATED = 'UPDATED'
export const SORTING = 'SORTING'
export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const PROCESSED = 'PROCESSED'
export const ERROR = 'ERROR'

export const statuses = [INITIALIZED, REQUESTED, LOADED, UPDATED, PROCESSED, ERROR]

export default {
  INITIALIZED,
  REQUESTED,
  UPDATED,
  SORTING,
  LOADING,
  LOADED,
  PROCESSED,
  ERROR
}
