import React from 'react'
import {
  formatBandwidth,
  formatCost,
  formatProviders
} from '../../../utils/stringHelpers'
import PopupHeader from '../shared/PopupHeader'
import Base from './Base'
import Column from './Column'

const Header = ({ title, onClose }) => {
  return <PopupHeader
    title={title}
    subtitle="Below the FCC's internet bandwidth goal"
    onClose={onClose} />
}

const Body = ({ data }) => {
  return (
    <div className='DistrictPopup__body DistrictPopup__body--not-meeting'>
      <Column
        title='current'
        classId='current'
        metric={formatBandwidth(data.total_bandwidth_mbps)}
        subtitle={`${formatBandwidth(data.bandwidth_per_student_mbps)}/student`} />
      <Column
        title='total cost'
        classId='cost'
        metric={`${formatCost(data.total_monthly_cost, '0,0', { nan: '?' })}/month`} />
      <Column
        title='recommended'
        classId='recommended'
        metric={`${formatBandwidth(data.recommended_bw)}`}
        subtitle='1 Mbps/student' />
      <div className='PopupColumn--providers'>
        {formatProviders(data.service_providers)}
      </div>
    </div>
  )
}

const NotMeetingGoal = ({ data, onClose }) => {
  return <Base
    data={data}
    header={<Header title={data.entity_name} onClose={onClose} />}
    body={<Body data={data} />}
  />
}

export default NotMeetingGoal
