import React from 'react'
import './GovernorQuoteBox.sass'
import PropTypes from 'prop-types'
import bundleLoader from '../../utils/bundle-loader'
import classnames from 'classnames'

const images = bundleLoader.importGovernorImages()

export default function GovernorQuoteBox ({ committed, govName, stateCode, stateName, quote, expandWidth }) {
  if (!committed) { return null }

  return (
    <div className={classnames('GovernorQuoteBox', { 'GovernorQuoteBox__expand': expandWidth })}>
      <div className='GovernorQuoteBox__header' >
        <img className={'GovernorQuoteBox__photo'} src={images[stateCode + '.jpeg']} alt='Governor' />
        Gov. {govName} is committed to connecting {stateName} students
      </div>
      <blockquote className='GovernorQuoteBox__quote'>
        {quote}
      </blockquote>
    </div>)
}

GovernorQuoteBox.propTypes = {
  stateCode: PropTypes.string
}
