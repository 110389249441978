import { connect } from 'react-redux'
import { navPromptClosed } from '../../actions/navbar'
import NavPromptTooltip from '../../components/Navbar/NavPromptTooltip'

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.navbar.navPromptOpen,
    prompt: state.navbar.prompt,
    leftOffset: state.navbar.leftOffset,
    type: state.navbar.navPromptType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(navPromptClosed())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavPromptTooltip)
