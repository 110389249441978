import React from 'react'
import ErateStateFundingMetrics from './ErateStateFundingMetrics'
import ErateImpactMetrics from './ErateImpactMetrics'
import DistrictsFundingTable from './DistrictsFundingTable'
import states from '../../data/states'
import FCCErateSource from '../FCCErateSource'
import fundingYears from '../../data/fundingYears'
import './index.sass'

const Footnote = () => (
  <div className='StateErateDashboard__footnote'>
    Funding totals represent the estimated E-rate discount funding to be
    awarded based on the original FCC E-rate Form 471 (FY{fundingYears.current}).
  </div>
)

export default function StateErateDashboard ({ stateMetrics, districts, onSort }) {
  if (!stateMetrics || !stateMetrics.data) return null
  const { data: { state_code: stateCode } } = stateMetrics
  const stateName = states[stateCode] && states[stateCode].stateName

  return (
    <div className='StateErateDashboard'>
      <div className='StateErateDashboard__header'>
        <h1 className='StateErateDashboard__title'>
          The Federal E-rate Program is crucial to {stateName}'s public schools, driving access
          to broadband and digital education throughout the state.
        </h1>
        <FCCErateSource className='StateErateDashboard__source' />
      </div>
      <div className='StateErateDashboard__metrics'>
        <div className='StateErateDashboard__metrics--state'>
          <ErateStateFundingMetrics stateMetrics={stateMetrics} />
          <ErateImpactMetrics stateMetrics={stateMetrics} />
          <Footnote />
        </div>
        <div className='StateErateDashboard__metrics--district'>
          <DistrictsFundingTable
            districts={districts}
            onSort={onSort}
            stateCode={stateCode}
          />
        </div>
      </div>
    </div>
  )
}
