import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faCheckSquare, faSquare)

export default function Checkbox (props = {}) {
  const className = props.checked ? 'check-square' : 'square'
  const iconModifier = props.checked ? 'checked' : 'unchecked'
  const iconClass = `PurposeFilter__checkbox-icon--${iconModifier}`
  const onChange = event => props.onChange(event.target.checked)
  const attrs = { ...props, ...{ onChange } }

  return (
    <span className='PurposeFilter__checkbox'>
      <input type='checkbox' className='PurposeFilter__checkbox--input' {...attrs} />
      <Icon icon={className} className={`PurposeFilter__checkbox-icon ${iconClass}`} />
    </span>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired
}
