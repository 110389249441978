import React from 'react'
import { useLocation } from 'react-router-dom'
import { stateBandwidthDashboard } from '../../routes'
import './index.sass'

export default function NoResults () {
  const location = useLocation()
  const isStateBandwidthDashboard = (!!stateBandwidthDashboard.matchPath(location.pathname))

  const stateBandwidthText = "Uh oh! There aren't any school districts matching your search. Try removing filters to find more school districts."
  const nearbyServicesText = "Uh oh! There aren't any internet services matching your search. Try removing filters to find more services."
  const text = isStateBandwidthDashboard ? stateBandwidthText : nearbyServicesText
  return (
    <div className='NoResults'>
      <hr />
      <p className='NoResults__bold'>No Results</p>
      <p>{text}</p>
      <hr />
    </div>
  )
}
