import React from 'react'
import Tooltip from '.'
import classnames from 'classnames'
import infoIcon from '../../assets/icon-16-icon-info-secondary-3.svg'

export default function InfoTooltip ({ enabled = true, className, content, placement = 'bottom' }) {
  if (!enabled) return null

  return (
    <Tooltip placement={placement} overlay={content}>
      <img
        tabIndex={0}
        aria-hidden
        className={classnames('InfoTooltip__icon', className)}
        src={infoIcon}
        alt='info tooltip' />
    </Tooltip>
  )
}
