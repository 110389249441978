import { fetchRawErateDistricts } from '../data'
import { applyFilters } from './districtsFilters'
import { tableSortDispatcher, firstRequest } from './helpers'
import { getData } from '../selectors/dataSelectors'

export const REQUEST_DISTRICTS = 'REQUEST_DISTRICTS'
export function requestRawErateDistricts (stateCode, isFirstRequest) {
  return {
    type: REQUEST_DISTRICTS,
    payload: { stateCode, isFirstRequest }
  }
}

export const RECEIVE_DISTRICTS = 'RECEIVE_DISTRICTS'
export function receiveRawErateDistricts ({ stateCode, data, isFirstRequest }) {
  return {
    type: RECEIVE_DISTRICTS,
    payload: { stateCode, data, isFirstRequest }
  }
}

export const RECEIVE_DISTRICTS_ERROR = 'RECEIVE_DISTRICTS_ERROR'
export function receiveRawErateDistrictsError (stateCode, error) {
  return {
    type: RECEIVE_DISTRICTS_ERROR,
    payload: {
      stateCode,
      error: error.message,
      status: error.status
    }
  }
}

export const SORT_DISTRICTS_TABLE = 'SORT_DISTRICTS_TABLE'
export function sortRawErateDistrictsTable (options) {
  return {
    type: SORT_DISTRICTS_TABLE,
    payload: { ...options }
  }
}

export const DISTRICTS_TABLE_SORTED = 'DISTRICTS_TABLE_SORTED'
export function rawErateDistrictsTableSorted () {
  return {
    type: DISTRICTS_TABLE_SORTED
  }
}

export const DISTRICTS_TABLE_SORT_APPLIED = 'DISTRICTS_TABLE_SORT_APPLIED'
export function rawErateDistrictsTableSortApplied (option) {
  return {
    type: DISTRICTS_TABLE_SORT_APPLIED,
    payload: { ...option }
  }
}

export const applySort = async (dispatch, getState) => {
  await tableSortDispatcher(sortRawErateDistrictsTable)(dispatch, getState)
  await dispatch(rawErateDistrictsTableSorted())
}

export function getRawErateDistricts (stateCode, retries = 2) {
  const requestedStateCode = stateCode
  return function (dispatch, getState) {
    const state = getState()
    const { districts: currentData } = getData(state)
    const currentStateCode = state.rawErateDistricts && state.rawErateDistricts.stateCode
    const isFirstRequest = firstRequest(currentData, currentStateCode, requestedStateCode)

    dispatch(requestRawErateDistricts(stateCode, isFirstRequest))

    const dispatchSuccess = data => {
      let action = receiveRawErateDistricts({ stateCode, data, isFirstRequest })
      return dispatch(action)
    }

    const dispatchError = error => {
      console.error(error.message)
      if (retries === 0) {
        return dispatch(receiveRawErateDistrictsError(stateCode, error))
      }
      return dispatch(getRawErateDistricts(stateCode, retries - 1))
    }

    return fetchRawErateDistricts(stateCode)
      .then(dispatchSuccess, dispatchError)
  }
}

export const loadRawErateDistrictsData = stateCode => {
  return async function (dispatch, getState) {
    await getRawErateDistricts(stateCode)(dispatch, getState)
    await applyFilters(dispatch, getState)
    return applySort(dispatch, getState)
  }
}

export default {
  REQUEST_DISTRICTS,
  RECEIVE_DISTRICTS,
  RECEIVE_DISTRICTS_ERROR,
  SORT_DISTRICTS_TABLE,
  DISTRICTS_TABLE_SORTED,
  DISTRICTS_TABLE_SORT_APPLIED
}
