import {
  DISTRICT_NAME_MOUSEENTER,
  DISTRICT_NAME_MOUSELEAVE,
  SERVICE_PIN_MOUSEENTER,
  SERVICE_PIN_MOUSELEAVE
} from '../../../../actions/map'

const tooltipId = (state = null, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case DISTRICT_NAME_MOUSEENTER:
    case SERVICE_PIN_MOUSEENTER:
      return payload.tooltipId
    case SERVICE_PIN_MOUSELEAVE:
    case DISTRICT_NAME_MOUSELEAVE:
      return null
    default:
      return state
  }
}

export default tooltipId
