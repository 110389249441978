import { dataHasFitnessProperties } from './fitnessFilters'

const unfitForIATransform = row => (
  {
    ...row,
    ...{
      bandwidth_per_student_mbps: null,
      cost_per_mbps: null,
      service_providers: null
    }
  }
)

const unfitForCostTransform = row => (
  {
    ...row,
    ...{ cost_per_mbps: null }
  }
)

const serviceProviderTransform = row => {
  const { service_providers: serviceProviders } = row
  if (!serviceProviders || !Array.isArray(serviceProviders)) {
    return row
  }

  let transformed = serviceProviders.filter(name => name !== 'null')
  if (transformed.length === 0) {
    transformed = null
  }

  return {
    ...row,
    ...{ service_providers: transformed }
  }
}

const unfitTypeTransform = row => {
  const {
    service_providers: serviceProviders,
    bandwidth_per_student_mbps: bandwidthPerStudentMbps,
    cost_per_mbps: costPerMbps
  } = row

  let type = null

  if (!(serviceProviders && bandwidthPerStudentMbps)) {
    type = 'ia'
  } else if (!costPerMbps) {
    type = 'cost'
  }

  return {
    ...row,
    ...{ unfit_type: type }
  }
}

const invalidMetricsToNull = data => {
  data = [
    serviceProviderTransform,
    unfitTypeTransform
  ].reduce((data, transform) => data.map(transform), data)

  if (!dataHasFitnessProperties(data)) {
    return data
  }

  return data
    .map(row => {
      if (!row['fit_for_ia']) {
        return unfitForIATransform(row)
      }
      if (!row['fit_for_ia_cost']) {
        return unfitForCostTransform(row)
      }
      return row
    })
}

const applyTransforms = data => invalidMetricsToNull(data)
export default applyTransforms
