import React from 'react'
import classnames from 'classnames'

// export const PRELIM_METRICS_MSG = 'These metrics are preliminary and are subject to modification until October 2020.'
export const PRELIM_METRICS_MSG = null

export default function PreliminaryMetricsFootnote ({ className }) {
  if (!PRELIM_METRICS_MSG) {
    return null
  }

  return (
    <div className={classnames(className, 'PreliminaryMetricsFootnote')}>
      {PRELIM_METRICS_MSG}
    </div>
  )
}
