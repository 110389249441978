import emailjs from 'emailjs-com'
import { buildTemplateParams } from '../utils/emailHelpers'

export async function sendEmail (formData) {
  if (process.env.REACT_APP_SEND_EMAILJS === 'true') {
    const templateParams = buildTemplateParams(formData)

    return emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'contact_form', templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
      .then(response => { return response })
      .then(response => {
        if (response.status >= 400) {
          return Promise.reject(new Error(JSON.stringify(response)))
        } else return response
      })
  } else {
    return { status: 200, text: 'Email not sent in environment' }
  }
}
