import { CONTACT_FORM_SUBMITTED, sendContactFormEmail } from '../actions/contactUs'

const sendContactForm = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === CONTACT_FORM_SUBMITTED) {
    store.dispatch(sendContactFormEmail(payload))
  }
}

export default sendContactForm
