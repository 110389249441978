import actions from '../../../actions/stateMetrics'
import * as status from '../../status'

const initialState = {
  data: {},
  status: status.INITIALIZED
}

function stateMetrics (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_STATE_METRICS:
      return {
        data: state.data,
        status: status.REQUESTED
      }

    case actions.RECEIVE_STATE_METRICS:
      return {
        data: payload.data,
        status: status.LOADED
      }

    case actions.RECEIVE_STATE_METRICS_ERROR:
      return {
        data: state.data,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default stateMetrics
