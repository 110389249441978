import deepmerge from 'deepmerge'

export function enableFilter (state, name) {
  return {
    [name]: {
      ...state[name],
      ...{ enabled: true }
    }
  }
}

export function enableFilters (state) {
  return Object.keys(state).reduce((nextState, name) =>
    deepmerge(nextState, enableFilter(state, name))
  , {})
}
