import deepmerge from 'deepmerge'
import commonState from './commonState'
import providerLocationChangeHandler from '../../../shared/filters/providerLocationChangeHandler'
import { formatProviderOptions } from '../../../shared/utils'
import { LOCATION_CHANGE } from 'connected-react-router'
import { RECEIVE_NEARBY_SERVICES } from '../../../../actions/nearbyServices'
import { enableMenu } from '../../../shared/filters/transformations/menu'
import { FILTER_NEARBY_SERVICES_BY_PROVIDER } from '../../../../actions/nearbyServicesFilters'

export const initialState = deepmerge(commonState, { options: [] })

const updateOptions = (state, data) => {
  if (!Array.isArray(data) || (data.length === 0)) return state

  const providerNames = data.map((service) => service.service_provider_name)

  const options = formatProviderOptions(providerNames, state.applied)

  return { ...state, ...{ options } }
}

const locationChange = providerLocationChangeHandler(initialState)

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      return locationChange(state, action)
    case RECEIVE_NEARBY_SERVICES:
      return enableMenu(updateOptions(state, payload.data))
    case FILTER_NEARBY_SERVICES_BY_PROVIDER:
      return updateOptions(state, payload.nearby)
    default:
      return state
  }
}
