import React from 'react'
import './index.sass'

function linkText (href, text) {
  return <a href={href} target='_blank' rel='noopener noreferrer'>{text}</a>
}

export default function AboutUs () {
  const partnershipLink = 'https://connectednation.org/blog/2020/01/14/educationsuperhighway-partners-with-connected-nation-to-carry-forward-its-mission/'
  const newSchoolsLink = 'http://www.newschools.org/wp-content/uploads/2019/09/Gallup-Ed-Tech-Use-in-Schools-2.pdf'
  const findOutMoreLink = 'http://educationsuperhighway.org/every-classroom-every-day'
  return (
    <div className='AboutUsContainer'>
      <div className='AboutUs'>
        <div className='AboutUs__title'>
          About Connect K-12
        </div>
        <div className='AboutUs__text'>
          <i>Connect K-12</i> is a free, actionable internet speed and pricing information tool for America’s K-12 school districts.
          Using open E-rate data, the tool includes national, state, and school district level connectivity metrics to drive school network upgrades to 1 Mbps per student.
        </div>
        <div className='AboutUs__text'>
          <i>Connect K-12</i> was built to continue to provide transparency around the bandwidth school districts buy. Data transparency is critical for two reasons:
          <ol>
            <li>It creates informed buyers at the state and school district level who, as a result of transparency, can negotiate more effectively to increase bandwidth for their students.</li>
            <li>It helps track progress towards bandwidth targets that will enable all students to take advantage of digital learning.</li>
          </ol>
        </div>
        <div className='AboutUs__text'>
          <i>Connect K-12</i> has been designed as a resource for leaders in state government and school district administrators,
          including superintendents and technology directors, who are helping school districts upgrade their connectivity.
        </div>
        <div className='AboutUs__header'>
          Every classroom, every day
        </div>
        <div className='AboutUs__text'>
          85% of teachers want to use more digital learning in their classrooms ({linkText(newSchoolsLink, 'NewSchools/Gallup Survey')}),
          but only 38% of school districts meet the 1 Mbps per student benchmark - the FCC’s 2018 goal for student internet access.
        </div>
        <div className='AboutUs__text'>
          A broad coalition of partners understands the importance of meeting this goal, including 38 governors,
          nonprofit organizations, like SETDA, CoSN, and SHLB, and  service providers. Join the coalition of educators,
          policy-makers, and broadband advocates in supporting our school districts with the broadband needed to support
          digital learning in every classroom, every day.
        </div>
        <div className='AboutUs__text'>
          {linkText(findOutMoreLink, 'Find out more')}
        </div>
        <div className='AboutUs__header'>
          About the partnership
        </div>
        <div className='AboutUs__text'>
          Strong leadership, actionable data, and partnerships at the state and national level have been critical to increasing school broadband access.
          The tool was originally developed and designed by EducationSuperHighway.
        </div>
        <div className='AboutUs__text'>
          <strong>Connected Nation</strong> and <strong>Funds For Learning</strong> have partnered to launch and maintain <i>Connect K-12</i>.
          National nonprofit Connected Nation will work with state leaders to identify and support school districts with upgrading to the
          1 Mbps per student goal by 2024. Funds For Learning will manage the technology platform and ensure data in <i>Connect K-12</i> is
          accurate and updated annually.
        </div>
        <div className='AboutUs__text'>
          Connected Nation is a nonprofit dedicated to improving lives by providing innovative solutions that
          expand access, adoption, and use of high-speed internet and its related technology to all people.
          Funds For Learning® is a professional firm specializing in the federal E-rate funding program.
        </div>
        <div className='AboutUs__text'>
          From 2012-2020, EducationSuperHighway led the effort that closed the classroom connectivity gap. In November 2021, they launched a new mission to close the digital divide for the 18 million households that have access to the Internet but can't afford to connect.
        </div>
        <div className='AboutUs__text'>
          {linkText(partnershipLink, 'Learn more about the partnership')}
        </div>
      </div>
    </div>
  )
}
