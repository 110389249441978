import PropTypes from 'prop-types'

export const featureCollection = PropTypes.shape({
  type: PropTypes.oneOf(['FeatureCollection']),
  features: PropTypes.array
})

export default {
  featureCollection
}
