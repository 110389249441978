import React, { useLayoutEffect, useRef, useEffect } from 'react'
import { HorizontalBar, Bar } from 'react-chartjs-2'
import iconStar from '../../../assets/iconStar.svg'
import colors from '../../../styles/variables.scss'
import barChartOptions from './barChartOptions'
import { renderMobile, sortObjects, DESC, convertDecimalToPercentValue } from '../../../utils'
import * as hooks from '../../../hooks'
import stateData from '../../../data/states.js'
import PreliminaryMetricsFootnote from '../../PreliminaryMetricsFootnote'
import './index.sass'

const handleMousedown = (event) => {
  const stateRankingsTarget = event.target.matches ? event.target.matches('.chartjs-render-monitor') : event.target.msMatchesSelector('.chartjs-render-monitor')
  let tooltipEl = document.getElementById('StateRankings__tooltip')

  if (!stateRankingsTarget && tooltipEl) {
    tooltipEl.style.opacity = 0
  }

  const govQuote = document.getElementsByClassName('ChartTooltip__container--quote')[0]
  if (govQuote) govQuote.scrollTop = 0
}

export default function StateRankings ({ states }) {
  let type
  const ref = useRef()
  const { width } = hooks.useWindowDimensionsCtx()

  useLayoutEffect(() => {
    // Hack for fixing squished star in mobile
    // will open issue with chartjs
    if (type === 'horizontalBar' && ref.current) {
      setTimeout(() => {
        ref.current.chartInstance.update()
      }, 1000)
    }
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleMousedown)
    return () => {
      document.removeEventListener('mousedown', handleMousedown)
    }
  })

  const { data, status } = states

  if (status !== 'LOADED') return null

  function getBackgroundColor (metric) {
    if (metric === null) return colors.colorGray3
    if (metric <= 0.20) return colors.colorNationalMapBlue1
    if (metric <= 0.40) return colors.colorNationalMapBlue2
    if (metric <= 0.60) return colors.colorNationalMapBlue3
    if (metric <= 0.80) return colors.colorNationalMapBlue4
    if (metric <= 1) return colors.colorNationalMapBlue5
    return colors.black
  }

  const sortedData = sortObjects(data, 'percent_districts_meeting_goal', { direction: DESC }).filter(d => d.state_code !== 'DC')

  type = renderMobile(width) ? 'horizontalBar' : 'bar'
  const labels = sortedData.map(d => d.state_code)
  const values = sortedData.map(d => convertDecimalToPercentValue(d.percent_districts_meeting_goal))
  const rankings = {
    labels,
    datasets: [{
      data: values,
      backgroundColor: sortedData.map(d => getBackgroundColor(d.percent_districts_meeting_goal)),
      hoverBorderColor: colors.white,
      hoverBorderWidth: sortedData.map(d => stateData[d.state_code] && stateData[d.state_code].committed ? 2 : 0)
    }]
  }

  const stateCount = sortedData.filter(d => stateData[d.state_code].committed).length

  return (
    <div className='StateRankings'>
      <h2 className='StateRankings__title'>
        Nationwide, {stateCount} states have <strong>committed</strong>
        <img src={iconStar} alt='star icon' className='StateRankings__title--star' />
        to reach the 1 Mbps per student goal
      </h2>
      <h3 className='StateRankings__subtitle'>
        % of school districts meeting the FCC recommended bandwidth of 1 Mbps per student
      </h3>
      <div className='StateRankings__chart'>
        {
          type === 'bar'
            ? <Bar ref={ref} data={rankings} options={barChartOptions(type, width)} />
            : <HorizontalBar ref={ref} data={rankings} options={barChartOptions(type, width)} />
        }
      </div>
      <PreliminaryMetricsFootnote className='StateRankings__footnote' />
    </div>
  )
}
