import React from 'react'
import Bandwidth from '../../Filters/Bandwidth'
import * as Input from '../../Filters/Inputs'
import './BandwidthPerStudent.sass'
import { formatBandwidth, formatRangeWith } from '../../../utils/stringHelpers'

function BandwidthPerStudent ({ bandwidth, onChange, onApply, ...props }) {
  return (
    <Bandwidth
      label='Bandwidth/Student'
      step={0.1}
      bandwidth={bandwidth}
      enabled={bandwidth.enabled}
      onChange={onChange}
      appliedLabel={formatRangeWith(formatBandwidth)(bandwidth) + '/student'}
      onApply={onApply}
      minInput={props => <Input.BandwidthPerStudent name='min' {...props} />}
      maxInput={props => <Input.BandwidthPerStudent name='max' {...props} />}
      {...props} />
  )
}

export default BandwidthPerStudent
