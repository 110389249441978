import { useEffect } from 'react'

export default function useMapPositioning ({ map, fitBounds, center, offsetY = 0, resetZoom = true }) {
  useEffect(() => {
    if (map && fitBounds && resetZoom) {
      const mapHeight = map._container.clientHeight
      const padding = mapHeight > 300 ? 100 : 0
      const cameraOptions = map.cameraForBounds(fitBounds, { center, offset: [0, offsetY], padding })
      map.fitBounds(fitBounds, { duration: 0, ...cameraOptions, offset: [0, offsetY] })
      map.resize()
    }
  }, [map, fitBounds, center, offsetY, resetZoom])

  useEffect(() => {
    if (map && center) {
      map.easeTo({ center, offset: [0, offsetY] })
    }
  }, [map, center, offsetY])
}
