import React from 'react'
import './index.sass'

export default function ContactInformation () {
  return (
    <div>
      <h2 className='ContactInformation__section-title'>Contact Information</h2>
      <p>Connected Nation</p>
      <p>191 Professional Park Ct Suite B P.O. Box 3448</p>
      <p>Bowling Green, KY 42102</p>
      <p><a href='mailto:support@connectk12.org'>support@connectk12.org</a></p>
    </div>
  )
}
