import { fetchNationMetrics } from '../data'

export const REQUEST_NATION = 'REQUEST_NATION'
export function requestNation () {
  return {
    type: REQUEST_NATION
  }
}

export const RECEIVE_NATION = 'RECEIVE_NATION'
export function receiveNation (data) {
  return {
    type: RECEIVE_NATION,
    payload: data
  }
}

export const RECEIVE_NATION_ERROR = 'RECEIVE_NATION_ERROR'
export function receiveNationError (error) {
  return {
    type: RECEIVE_NATION_ERROR,
    payload: {
      error: error.message,
      status: error.status
    }
  }
}

export function getNation (retries = 2) {
  return function (dispatch) {
    dispatch(requestNation())

    return fetchNationMetrics()
      .then(data => { return dispatch(receiveNation(data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveNationError(error))
        }
        return dispatch(getNation(retries - 1))
      })
  }
}

export default {
  REQUEST_NATION,
  RECEIVE_NATION,
  RECEIVE_NATION_ERROR
}
