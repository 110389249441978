import { LOCATION_CHANGE } from 'connected-react-router'
import { OPEN_SELECTED_POPUP } from '../../../../actions/nearbyServices'
import actions from '../../../../actions/map'

const defaultState = {}

function buildPopup (services, totalServices, selected) {
  return {
    entity_name: services[0].recipient_district_name,
    entity_number: services[0].recipient_district_ben,
    latitude: services[0].latitude,
    longitude: services[0].longitude,
    selected: selected,
    totalServices: totalServices,
    services: [...services].sort((a, b) => (a.recommended > b.recommended) ? -1 : 1),
    districtFitForIA: services[0].recipient_district_fit_for_ia
  }
}

export default function (state = defaultState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case OPEN_SELECTED_POPUP: {
      const { services, selected } = payload
      return buildPopup(services, services.length, selected)
    }
    case actions.SERVICE_PIN_CLICKED: {
      const { services, selected, totalServices } = payload
      return buildPopup(services, totalServices, selected)
    }
    case actions.SERVICE_POPUP_CLOSED:
    case LOCATION_CHANGE:
      return {}
    default:
      return state
  }
}
