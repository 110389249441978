import React from 'react'
import PropTypes from 'prop-types'
import Filter, { Label, Options as OptionsContainer } from '../../../Filters/Filter'
import PurposeOptions from './PurposeOptions'
import InfoTooltip from '../../../Tooltip/InfoTooltip'
import { tooltipContent } from '../../tooltipContent'

import './PurposeFilter.sass'

/*
 *
 * @param {Object} purpose
 * @param {boolean} applied
 * @param {Function} onChange
 * @param {Function} onClick
 * @param {Function} onApply
 * @param {Function} onClear
 * @returns {*}
 * @constructor
 *
 */

export default function PurposeFilter ({ purpose, applied, onChange, onClick, onApply, onClear }) {
  const clearAllOptions = onClear()
  const { open, options, enabled } = purpose

  function PurposeTooltip ({ id, className }) {
    return <InfoTooltip className={className} content={tooltipContent('purpose', id)} />
  }

  const appliedLabel = `Purpose (${Object.values(applied).filter(applied => applied).length})`

  return (
    <Filter
      label='Purpose'
      className='Filter--purpose'
      open={open}
      applied={applied}
      enabled={enabled}>
      <Label open={open} onClick={onClick} name='Purpose'
        tooltip={() => <PurposeTooltip id='PurposeFilter__modal__tooltip' className='PurposeFilter__modal__tooltip' />}
        applied={!!applied}
        appliedLabel={appliedLabel} />

      <OptionsContainer
        visible={open}
        applied={!!applied}
        onApply={onApply}
        onClear={clearAllOptions}>
        <div className='Filter__options--header PurposeFilter__header'>
          <strong>Purpose</strong>
          <PurposeTooltip id='PurposeFilter__header__tooltip' className='PurposeFilter__header__tooltip' />
        </div>
        <PurposeOptions {...{ options, onChange }} />
      </OptionsContainer>
    </Filter>
  )
}

PurposeFilter.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}
