import React from 'react'
import classNames from 'classnames'
import './Toggle.sass'

export default function Toggle ({ className, toggled, onChange }) {
  const toggledClass = `Toggle__label--${toggled ? 'on' : 'off'}`

  return (
    <div>
      <input
        className='Toggle__checkbox'
        id='Toggle__id'
        type='checkbox'
        onChange={onChange}
        checked={toggled} />
      <label
        className={classNames('Toggle__label', toggledClass, className)}
        htmlFor='Toggle__id'>
        <span className='Toggle__button' />
      </label>
    </div>
  )
}
