import actions from '../../../actions/historicalStateMetrics'
import * as status from '../../status'
import { sortObjects } from '../../../utils'

const initialState = {
  data: {},
  status: status.INITIALIZED
}

const addMetrics = (metric, stateMetrics) => (databaseProperty, storeProperty) => {
  if (metric[databaseProperty] !== null) {
    stateMetrics[storeProperty].push({
      fundingYear: metric.funding_year,
      data: metric[databaseProperty]
    })
  }
}

function historicalStateMetrics (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_HISTORICAL_STATE_METRICS:
      return {
        data: initialState.data,
        stateCode: payload.stateCode,
        status: status.REQUESTED
      }

    case actions.RECEIVE_HISTORICAL_STATE_METRICS:
      const stateMetrics = {
        medianCostPerMbps: [],
        percentDistrictsMeetingGoal: [],
        percentStudentsMeetingGoal: [],
        erateAppliedFunding: [],
        contractsExpiring: null,
        numDistrictsNotMeetingGoal: null,
        percentProjectedMeetingGoalIncrease: null
      }

      const sortedData = sortObjects([...payload.data], 'funding_year')

      sortedData.reduce((stateMetrics, metric) => {
        const addMetric = addMetrics(metric, stateMetrics)
        addMetric('median_cost_per_mbps', 'medianCostPerMbps')
        addMetric('percent_districts_meeting_goal', 'percentDistrictsMeetingGoal')
        addMetric('percent_students_meeting_goal', 'percentStudentsMeetingGoal')
        addMetric('total_funding_requested', 'erateAppliedFunding')
        return stateMetrics
      }, stateMetrics)

      const latestMetrics = sortedData[sortedData.length - 1]

      stateMetrics.contractsExpiring = latestMetrics.num_districts_contract_expiring
      stateMetrics.numDistrictsNotMeetingGoal = latestMetrics.num_districts_not_meeting_goal
      stateMetrics.percentProjectedMeetingGoalIncrease = latestMetrics.percent_projected_meeting_goal_increase

      return {
        stateCode: payload.stateCode,
        data: stateMetrics,
        status: status.LOADED
      }

    case actions.RECEIVE_HISTORICAL_STATE_METRICS_ERROR:
      return {
        data: state.data,
        stateCode: payload.stateCode,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default historicalStateMetrics
