import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DistrictsTableTitle from './DistrictsTableTitle'
import DistrictsTableData from './DistrictsTableData'
import DownloadDistricts from './DownloadDistricts'
import SortDropdown, { dropdownOptions } from '../../SortDropdown'
import columnHeaders from './columnHeaders.json'
import descIcon from '../../../assets/iconSortDesc24.svg'
import ascIcon from '../../../assets/iconSortAsc24.svg'
import { useWindowDimensionsCtx } from '../../../hooks'
import { ASC, renderMobile } from '../../../utils'
import './index.sass'

const sortIcon = ({ direction }) => {
  return direction === ASC ? ascIcon : descIcon
}

export default function DistrictsTable ({ districts, stateMetrics, onSort, onTableMouseEnter, onTableMouseLeave }) {
  const {
    filters,
    data: {
      table,
      districts: districtsData
    },
    status,
    stateCode
  } = districts
  const options = dropdownOptions(columnHeaders)
  const { width } = useWindowDimensionsCtx()
  const isMobile = renderMobile(width)

  return (
    <div className={classnames('DistrictsTable', `DistrictsTable--${status}`)}>
      <div className='DistrictsTableTitle__container'>
        <DistrictsTableTitle
          status={status}
          filters={filters}
          filtered={districtsData.length}
          tableStatus={table.status}
          stateMetrics={stateMetrics}
          className='desktop' />
        <div className='DistrictsTable__options'>
          <SortDropdown
            options={options}
            value={table.sort}
            icon={sortIcon(table.sort)}
            onChange={onSort} />
          <DownloadDistricts data={districtsData} stateCode={stateCode} />
        </div>
      </div>
      <DistrictsTableData
        districts={districtsData}
        isMobile={isMobile}
        stateCode={stateCode}
        status={status}
        onTableMouseEnter={onTableMouseEnter}
        onTableMouseLeave={onTableMouseLeave}
        tableSortOption={table.sort}
        tableStatus={table.status}
      />
    </div>
  )
}

DistrictsTable.propTypes = {
  districts: PropTypes.shape({
    data: PropTypes.shape({
      districts: PropTypes.array.isRequired,
      table: PropTypes.object
    }),
    stateCode: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }),
  stateMetrics: PropTypes.shape({
    data: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired
  }),
  onSort: PropTypes.func
}
