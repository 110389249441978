import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../actions/nearbyServicesFilters'
import { servicesByRecipientBen } from './utils'

export default function (state = {}, action = {}) {
  const { type, payload } = action

  if (type === NEARBY_SERVICES_DATA_FILTERED) {
    const { nearby } = payload
    return nearby.reduce(servicesByRecipientBen, {})
  }

  return state
}
