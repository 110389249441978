import {
  anyFilterApplied,
  filterApplied
} from '../../../filters/buildFilterFunction'

export const dataHasFitnessProperties = ([firstRow] = []) =>
  !!firstRow && Object.hasOwnProperty.apply(firstRow, ['fit_for_ia'])

const fitnessFilters = (filters, data) => {
  if (filterApplied(filters, 'cost')) {
    data = data.filter(district => !!district['cost_per_mbps'])
  }

  if (filterApplied(filters, 'bandwidth')) {
    data = data.filter(district => district['bandwidth_per_student_mbps'] !== null)
  }

  if (!dataHasFitnessProperties(data)) {
    return data
  }

  if (filterApplied(filters, 'cost')) {
    data = data.filter(district => !!district['fit_for_ia_cost'])
  }

  if (anyFilterApplied(filters, 'bandwidth', 'cost', 'provider', 'contract')) {
    return data.filter(district => !!district['fit_for_ia'])
  }

  return data
}

export default fitnessFilters
