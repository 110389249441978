import React, { useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import Filter, {
  Label,
  Options as OptionsContainer
} from '../Filter'
import clearIcon from '../../../assets/icon-16-icon-close-black.svg'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './index.sass'

const Token = ({ option, onRemove }) => {
  return (
    <div className='TypeaheadFilter__token'>
      {option.name}
      <img
        className='TypeaheadFilter__clear-icon'
        src={clearIcon}
        alt='clear filter'
        onClick={() => onRemove(option)} />
    </div>
  )
}

const Input = ({ setInputRef, onRemove, selected, ...props }) => {
  return (
    <div>
      <input {...props} className='Typeahead__input form-control' ref={setInputRef} type='text' />
      <div className='Typeahead__tokens-container'>
        {selected.map((option, idx) => {
          return (
            <Token key={`${idx}-token`} option={option} onRemove={onRemove} />
          )
        })}
      </div>
    </div>
  )
}

const renderInput = ({ referenceElementRef, inputRef, inputClassName, ...inputProps }, { onRemove, selected }) => {
  const setInputRef = (input) => {
    referenceElementRef(input)
    inputRef(input)
  }

  return (
    <Input
      setInputRef={setInputRef}
      onRemove={onRemove}
      selected={selected}
      {...inputProps} />
  )
}

function TypeaheadFilter ({ open, label, enabled, applied, selected, options, onChange, onClick, onClear,
  onApply }) {
  const typeaheadRef = useRef(null)

  const handleOnClear = () => {
    if (typeaheadRef.current) typeaheadRef.current.clear()
    onClear()
  }

  const defaultSelected = (selected || applied || []).map(name => ({ name, label: name }))

  return (
    <Filter {...{ label, open, enabled, applied }}>
      <Label open={open} onClick={onClick} name={label} applied={!!applied} appliedLabel={`Provider (${applied.length})`} />
      <OptionsContainer
        visible={open}
        applied={!!applied}
        onApply={onApply}
        onClear={handleOnClear}>
        <div className='Filter__options--header Provider__header'>
          <strong>{label}</strong>
        </div>
        <Typeahead
          multiple
          highlightOnlyResult
          ref={typeaheadRef}
          id='TypeaheadFilter'
          className='TypeaheadFilter'
          onChange={onChange}
          options={options}
          inputProps={{ 'aria-label': 'Filter by Service Provider' }}
          maxHeight='184px'
          placeholder='Start typing service provider name...'
          defaultSelected={defaultSelected}
          renderInput={renderInput} />
      </OptionsContainer>
    </Filter>
  )
}

export default TypeaheadFilter
