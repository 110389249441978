import { getSelectedSortOption } from '../selectors/dataSelectors'
import { recommendedServices } from '../components/NearbyServices/helpers'
import { initialState as proximitySortOption } from '../reducers/districtDashboard/nearbyServices/table/sort/selected'

function firstRequest (currentData, currentId, requestedId) {
  return !currentData || currentId !== requestedId
}

function tableSortDispatcher (actionBuilder) {
  return async (dispatch, getState) => {
    const selectedSortOption = getSelectedSortOption(getState())
    selectedSortOption.label = selectedSortOption.label.replace('Sort by ', '')
    const action = actionBuilder(selectedSortOption)
    return dispatch(action)
  }
}

function updateDefaultSortOption (services, selectedSortOption) {
  const recommended = recommendedServices(services) && selectedSortOption.value === 'recommended'
  return (recommended || selectedSortOption.value !== 'recommended')
    ? selectedSortOption : proximitySortOption
}

export {
  tableSortDispatcher,
  updateDefaultSortOption,
  firstRequest
}
