import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Table.sass'

function Title ({ className, title, subtitle, subtitleClassName, children }) {
  return (
    <div className={classNames(className, 'Table__title--container')}>
      <div className='Table__title--text'>
        <h2 className='Table__title'>
          {title}
        </h2>
        {
          subtitle &&
          <div className={classNames('Table__title--subtitle', subtitleClassName)}>
            {subtitle}
          </div>
        }
      </div>
      {children}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any
}

export default Title
