import { combineReducers } from 'redux'
import districtServices from './districtServices'
import nearby from './nearby'
import isFirstRequest from './isFirstRequest'

const initialState = {
  districtServices: [],
  nearby: [],
  isFirstRequest: true
}

export default combineReducers({
  initialState,
  districtServices,
  nearby,
  isFirstRequest
})
