import React from 'react'
import PropTypes from 'prop-types'
import ChartHeaderPDF from './ChartHeaderPDF'
import ChartLegendPDF from './ChartLegendPDF'
import { View, Image, StyleSheet } from '@react-pdf/renderer'

export default function CostOverTimePDF ({ chartData, chartImage, stateCode, comparisonStateCode, hasStateQuote }) {
  const chartContainerStyle = StyleSheet.create({
    width: '100%',
    paddingBottom: hasStateQuote ? '5mm' : '0mm'
  })

  const imageWidth = hasStateQuote ? '75%' : '90%'
  const imageMarginLeft = hasStateQuote ? '20mm' : '5mm'
  const legendMarginLeft = hasStateQuote ? '25mm' : '10mm'

  return (
    <View style={chartContainerStyle}>
      <ChartHeaderPDF
        title={chartData.title}
        subtitle={chartData.subtitle}
        hasStateQuote={hasStateQuote} />
      <Image src={chartImage} style={{ width: imageWidth, marginLeft: imageMarginLeft }} />
      <View style={{ marginLeft: legendMarginLeft }}>
        <ChartLegendPDF stateCode={stateCode} comparisonStateCode={comparisonStateCode} />
      </View>
    </View>
  )
}

CostOverTimePDF.propTypes = {
  chartData: PropTypes.object,
  chartImage: PropTypes.string,
  stateCode: PropTypes.string,
  comparisonStateCode: PropTypes.string
}
