import { useState } from 'react'
import {
  SELECTED_ID,
  RECOMMENDED_ID,
  PINS_ID,
  setSelectedIconImages,
  setHoverIconImages,
  setDefaultIconImages
} from '../../Map/layerStyles'
import {
  containsLngLatPoint,
  getEventFeature,
  hidePointer,
  showPointer,
  addTagManagerData
} from '../../../utils/mapUtils'

export default function useMapUtils ({ mapboxMap, toggleDistrictBanner, nearby, selected, coordinates, onPinClick, onMouseLeave, onMouseEnter, onClosePopup }) {
  const [displayLabels, setDisplayLabels] = useState(false)
  const [containerHeight, setContainerHeight] = useState(null)

  function handleMapButtonClick () {
    toggleDistrictBanner()
    setContainerHeight(null)
  }

  function handleDisplayLabels (map) {
    if (map && map.getLayer(PINS_ID)) {
      const features = map.queryRenderedFeatures({ layers: [SELECTED_ID, PINS_ID, RECOMMENDED_ID] })
      setDisplayLabels(features.length <= 50)
    }
  }

  function handleClick (event) {
    handleMouseLeave(event)
    const feature = getEventFeature(event)
    addTagManagerData(feature)
    setSelectedIconImages(mapboxMap, feature.id)
    onPinClick(nearby[feature.id] || selected[feature.id])
  }

  function handleMouseLeave (event) {
    onMouseLeave()
    setDefaultIconImages(mapboxMap)
    hidePointer(event.target)
  }

  function handleMouseEnter (event) {
    const feature = getEventFeature(event)
    setHoverIconImages(mapboxMap, feature.id)
    onMouseEnter(feature.id)
    showPointer(event.target)
  }

  function handleClosePopup () {
    setDefaultIconImages(mapboxMap)
    onClosePopup()
  }

  function handleTooltipOpen () {
    if (!coordinates || !mapboxMap) return
    const bounds = mapboxMap.getBounds().toArray()
    if (containsLngLatPoint(bounds, coordinates)) return
    mapboxMap.panTo(coordinates)
  }

  return {
    displayLabels,
    containerHeight,
    setContainerHeight,
    eventHandlers: {
      handleMapButtonClick,
      handleDisplayLabels,
      handleClick,
      handleMouseLeave,
      handleMouseEnter,
      handleClosePopup,
      handleTooltipOpen
    }
  }
}
