import classNames from 'classnames'

export function sortedHeaderClass (column, selected) {
  return classNames('Table__header',
    { 'Table__header--selected': column === selected }
  )
}

export function liClass (modifiers = []) {
  const classes = modifiers.map((e) => {
    if (e) { return `Metrics--${e}` } else { return null }
  })
  return classNames(classes)
}

export function ulClass (color, top) {
  return classNames('Metrics', `Metrics--${color}`,
    { 'Metrics--top': top === 'top' }
  )
}
