import { CONTACT_FORM_SUBMITTED, CONTACT_US_MENU_BUTTON_CLICKED } from '../../../actions/contactUs'

export const initialState = false

function contactFormSubmitted (state = initialState, action = {}) {
  switch (action.type) {
    case CONTACT_FORM_SUBMITTED:
      return true
    case CONTACT_US_MENU_BUTTON_CLICKED:
      return initialState
    default:
      return state
  }
}

export default contactFormSubmitted
