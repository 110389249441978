import { RECEIVE_DISTRICTS_SEARCH, REQUEST_GLOBAL_SEARCH } from '../../../../actions/globalSearch'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = { data: [], searchTerm: '' }

export const MIN_SEARCH_LENGTH = 3

export default function districts (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      return initialState
    case REQUEST_GLOBAL_SEARCH:
      if (payload.searchTerm.length < MIN_SEARCH_LENGTH) return { data: [], searchTerm: payload.searchTerm }
      return { data: state.data, searchTerm: payload.searchTerm }
    case RECEIVE_DISTRICTS_SEARCH:
      const { data, searchTerm } = payload
      if (searchTerm !== state.searchTerm) return { data: [], searchTerm: state.searchTerm }
      const districts = data.map((district) => {
        district.label = `${district.entity_name} (${district.state_code})`
        return district
      })
      return { data: districts, searchTerm: state.searchTerm }
    default:
      return state
  }
}
