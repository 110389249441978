import { SORT_DISTRICTS_TABLE, DISTRICTS_TABLE_SORT_APPLIED } from '../../../../../actions/rawErateDistricts'
import { DESC } from '../../../../../utils/sortHelpers'

export const initialState = {
  label: 'Sort by E-rate $ portion (High-Low)',
  mobile: 'Sort by E-rate $ portion',
  value: 'total_funding_requested',
  direction: DESC
}

export default function sort (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SORT_DISTRICTS_TABLE: {
      const { label } = payload
      return {
        ...payload,
        label: `Sort by ${label}`
      }
    }
    case DISTRICTS_TABLE_SORT_APPLIED: {
      const { label } = payload
      return {
        ...payload,
        label: label.includes('Sort by') ? label : `Sort by ${label}`
      }
    }
    default:
      return state
  }
}
