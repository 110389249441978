import React from 'react'
import PropTypes from 'prop-types'
import { View, Text } from '@react-pdf/renderer'
import states from '../../../data/states'
import '../StateQuoteBox.sass'
import colors from '../../../styles/variables.scss'

const headerStyle = {
  backgroundColor: colors.colorSecondary310Percent,
  color: colors.black,
  fontSize: '1.75vw',
  padding: '2mm',
  display: 'inline-flex',
  flexDirection: 'row'
}

const quoteStyle = {
  backgroundColor: colors.colorLightGray1,
  color: colors.black,
  fontSize: '1.5vw',
  padding: '2mm'
}

export default function StateQuoteBoxPDF ({ stateCode, hasStateQuote }) {
  if (!states[stateCode].stateQuote) { return <View /> }

  return (
    <View style={{ paddingBottom: '0mm', width: '100%' }}>
      <View>
        <View style={headerStyle} >
          <Text style={{ width: '100%', marginLeft: '1mm' }}>
            More About Connectivity in {states[stateCode].stateName}
          </Text>
        </View>
        <Text style={quoteStyle}>
          {states[stateCode].stateQuote}
        </Text>
      </View>
    </View>
  )
}

StateQuoteBoxPDF.propTypes = {
  stateCode: PropTypes.string
}
