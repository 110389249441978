import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../../Skeleton'
import { ZoomControl } from 'react-mapbox-gl'
import './DistrictsMap.sass'
import { Map, mapProps } from '../../../utils/mapUtils'
import * as statuses from '../../../reducers/status'
import Popup, { DistrictPopup } from '../../Popup'
import { useMapboxContainer } from '../../../hooks'
import DistrictPins from './DistrictPins'
import loadingSpinner from '../../../assets/loading-spinner-gray.svg'
import useMapPositioning from '../../../hooks/useMapPositioning'

export default function DistrictsMap ({ districts, tooltipId, onMouseEnter, onMouseLeave, onPinClick, onOpenPopup, onClosePopup }) {
  const { data, status } = districts
  const { status: tableStatus } = data.table
  const { fitBounds, center, popup, pins, resetZoom } = data.map
  const [mapboxMap, setMapboxMap] = useState(null)
  const [initialFitBounds, setInitialFitBounds] = useState(true)
  const container = useMapboxContainer(mapboxMap)
  const onMoveEnd = () => { if (initialFitBounds) setInitialFitBounds(false) }
  const pinsProps = { map: mapboxMap, tooltipId, onMouseEnter, onMouseLeave, onClick: onPinClick, popup }

  useMapPositioning({ map: mapboxMap, fitBounds, center, resetZoom })

  if (status === statuses.ERROR) {
    return <div ref={container}>`Error loading map: ${districts.error}`</div>
  }

  return (
    <div className='DistrictsMap__container'>
      { (!mapboxMap || tableStatus === statuses.LOADING) &&
        <div className='DistrictsMap__skeleton'>
          <Skeleton height={'100%'} />
          <img className='DistrictsMap__loading-spinner' src={loadingSpinner} aria-hidden alt='loading spinner' />
        </div>
      }
      <div className='DistrictsMap' ref={container}>
        <Map
          onMoveEnd={onMoveEnd}
          onStyleLoad={setMapboxMap}
          {...mapProps()}>
          <DistrictPins sourceData={pins.data} {...pinsProps} />
          <Popup
            data={popup}
            onOpen={onOpenPopup}
            onClose={onClosePopup}
            map={mapboxMap}
            element={<DistrictPopup />}
          />
          <ZoomControl
            className='map__zoom-control'
            zoomDiff={1}
            style={{ top: '50px', right: '50px' }} />
        </Map>
      </div>
    </div>
  )
}

DistrictsMap.propTypes = {
  districts: PropTypes.shape({
    data: PropTypes.shape({
      districts: PropTypes.array,
      table: PropTypes.object.isRequired,
      map: PropTypes.shape({
        popup: PropTypes.object.isRequired,
        fitBounds: PropTypes.array.isRequired,
        center: PropTypes.array.isRequired
      })
    }),
    stateCode: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }),
  onPinClick: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired
}
