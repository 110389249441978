import React from 'react'
import PopupFooter from '../shared/PopupFooter'

const Base = ({ data, header, body }) =>
  (
    <div>
      {header}
      <hr />
      {body}
      <PopupFooter entityNumber={data.entity_number} />
    </div>
  )

export default Base
