import { matchPath, generatePath } from 'react-router'

const route = path => ({
  path,
  matchPath: pathname => matchPath(pathname, { path, exact: true }),
  generatePath: opts => generatePath(path, opts)
})

export const homepage = route('/')
export const nationalDashboard = route('/national')
export const districtDashboard = route('/districts/:entity_number')
export const stateBandwidthDashboard = route('/states/:state_code/districts')
export const stateErateDashboard = route('/states/:state_code/erate')
export const stateOverviewDashboard = route('/states/:state_code')
export const statePage = pathname => (
  stateBandwidthDashboard.matchPath(pathname) ||
  stateErateDashboard.matchPath(pathname) ||
  stateOverviewDashboard.matchPath(pathname)
)
export const aboutUsPage = route('/about')
export const contactUsPage = route('/contact')
export const faqPage = route('/faq')
export const privacyPolicyPage = route('/privacy')
export const termsOfUsePage = route('/terms')
export const onHomepage = pathname => !!homepage.matchPath(pathname)
export const onDistrictPage = pathname => !!districtDashboard.matchPath(pathname)
export const onNationalPage = pathname => !!nationalDashboard.matchPath(pathname)
export const onStatePage = pathname => (
  !!stateBandwidthDashboard.matchPath(pathname) ||
  !!stateErateDashboard.matchPath(pathname) ||
  !!stateOverviewDashboard.matchPath(pathname)
)
export const onStateEratePage = pathname => !!stateErateDashboard.matchPath(pathname)
export const onStateOverviewPage = pathname => !!stateOverviewDashboard.matchPath(pathname)
export const onStateBandwidthPage = pathname => !!stateBandwidthDashboard.matchPath(pathname)
export const onContactUsPage = pathname => !!contactUsPage.matchPath(pathname)
export const onFAQPage = pathname => !!faqPage.matchPath(pathname)
export const onPrivacyPolicyPage = pathname => !!privacyPolicyPage.matchPath(pathname)
export const onTermsOfUsePage = pathname => !!termsOfUsePage.matchPath(pathname)

export default {
  homepage,
  nationalDashboard,
  districtDashboard,
  stateBandwidthDashboard,
  stateOverviewDashboard,
  stateErateDashboard,
  aboutUsPage,
  contactUsPage,
  faqPage,
  statePage,
  privacyPolicyPage,
  termsOfUsePage,
  onDistrictPage,
  onHomepage,
  onNationalPage,
  onStatePage,
  onStateOverviewPage,
  onStateBandwidthPage,
  onContactUsPage,
  onStateEratePage
}
