import { HTTP_ERROR_RESPONSE } from '../../actions'

function requestErrorStatusCode (state = null, action = {}) {
  if (action.type === HTTP_ERROR_RESPONSE) {
    return action.payload.status || 500
  } else {
    return null
  }
}

export default requestErrorStatusCode
