import { CALCULATE_RAW_ERATE_STATE_BANDWIDTH_CHART_DATA } from '../../../../actions/rawErateHistoricalStateMetrics'
import { formatLargeCost } from '../../../../utils/stringHelpers'

const initialState = {}

export default function erateAppliedFunding (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    // NOTE: Use the RAW ERATE version of this instead. Its the only place it is used.
    case CALCULATE_RAW_ERATE_STATE_BANDWIDTH_CHART_DATA:
      const erateAppliedFunding = payload.data.erateAppliedFunding.slice(Math.max(payload.data.erateAppliedFunding.length - 5, 1))
      const stateCode = payload.stateCode
      const labels = erateAppliedFunding.map(data => data.fundingYear)
      const stateData = erateAppliedFunding.map(data => data.data)
      const latestAppliedFunding = formatLargeCost(stateData[stateData.length - 1])

      return {
        ...state,
        title: title(stateCode, latestAppliedFunding, labels[labels.length - 1]),
        subtitle: subtitle,
        labels: labels,
        stateData: stateData
      }

    default:
      return state
  }
}

export const title = (stateCode, latestAppliedFunding, lastYear) => {
  return `${stateCode} school districts filed for ${latestAppliedFunding} in E-rate funds in ${lastYear}`
}

export const subtitle = 'E-rate discount funding requested'
