import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { homepage } from '../../routes'
import { renderTabletMd } from '../../utils/windowUtils'
import * as hooks from '../../hooks'
import hamburgerMenuIcon from '../../assets/iconHamburgerMenu.svg'
import { MobileMenu, DesktopMenu } from '../../containers/Navbar/Menu'
import GlobalSearchTypeahead from '../../containers/GlobalSearchTypeahead'
import NavPromptTooltip from '../../containers/Navbar/NavPromptTooltip'
import './index.sass'
import logo from '../../assets/connect-k12-logo.svg'
import logoMobile from '../../assets/connect-k12-logomark.svg'

const HamburgerButton = ({ onClick }) => (
  <div className='Navbar__hamburger-button' onClick={onClick}>
    <img
      src={hamburgerMenuIcon}
      alt='mobile navigation menu button' />
  </div>
)

function getLogoSrc (mobileMenu, isOnHomepage, showNavbarTypeahead) {
  return mobileMenu && (!isOnHomepage || showNavbarTypeahead) ? logoMobile : logo
}

export default function Navbar ({ isOnHomepage, showNavbarTypeahead }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const renderMobileMenu = renderTabletMd(width)
  const handleMobileMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false)
  }

  const handleClickOutside = (event) => {
    if (event.target.className === 'MobileMenu__background') {
      setMobileMenuOpen()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div className='NavWrap'>
      <div className={classnames('Navbar', {
        'Navbar__homepage': isOnHomepage,
        'Navbar__homepage--typeahead': showNavbarTypeahead
      })}>
        <Link className='Navbar__logo' to={homepage.path}>
          <img className='Navbar__img' src={getLogoSrc(renderMobileMenu, isOnHomepage, showNavbarTypeahead)} alt='ConnectK12 logo link' />
        </Link>
        { showNavbarTypeahead && <GlobalSearchTypeahead /> }
        {
          renderMobileMenu
            ? <HamburgerButton onClick={handleMobileMenuClick} />
            : <DesktopMenu />
        }
        <MobileMenu open={mobileMenuOpen} onClose={handleMobileMenuClose} />
        <NavPromptTooltip />
      </div>
    </div>
  )
}
