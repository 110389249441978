import { getPathname } from './locationSelectors'
import {
  districtDashboard,
  stateBandwidthDashboard,
  stateErateDashboard
} from '../routes'

export const getStateMetrics = (state, stateCode) => {
  return state.states.data.find(s => s.state_code === stateCode)
}

export const getNearbyServicesData = state =>
  state.nearbyServices.data

export const getNearbyServicesMap = state =>
  state.nearbyServices.map

export const getSelectedDistrictServices = state => {
  const servicesByRecipientBen = getNearbyServicesMap(state).selected
  return Object.values(servicesByRecipientBen)[0]
}

export const getNearbyServicesTable = state => {
  return state.nearbyServices.table
}

export const getNearbyServicesFilters = state => {
  return state.nearbyServices.filters
}

const getNearbyServicesTableSort = state => {
  return state.nearbyServices.table.sort
}

export const getNearbyServicesTableStatus = state => {
  return state.nearbyServices.table.status
}

const getDistrictsData = state =>
  state.districts.data

const getRawErateDistrictsData = state =>
  state.rawErateDistricts.data

export const getDistrictsMap = state => {
  const districtsData = getDistrictsData(state)
  return districtsData.map
}

export const getData = state => {
  const pathname = getPathname(state)
  if (stateBandwidthDashboard.matchPath(pathname)) {
    return getDistrictsData(state)
  }
  if (stateErateDashboard.matchPath(pathname)) {
    return getRawErateDistrictsData(state)
  }
  if (districtDashboard.matchPath(pathname)) {
    return getNearbyServicesData(state)
  }
  return {}
}

export const getMap = state => {
  const pathname = getPathname(state)
  if (stateBandwidthDashboard.matchPath(pathname)) {
    return getDistrictsMap(state)
  }
  if (districtDashboard.matchPath(pathname)) {
    return getNearbyServicesMap(state)
  }
  return {}
}

export const getSelectedSortOption = state => {
  const pathname = getPathname(state)
  if (stateBandwidthDashboard.matchPath(pathname) ||
      stateErateDashboard.matchPath(pathname)) {
    const { table: { sort } } = getData(state)
    return sort
  }
  if (districtDashboard.matchPath(pathname)) {
    const { selected } = getNearbyServicesTableSort(state)
    if (selected) return selected
  }
  return {}
}

export const getTooltipId = state => {
  const map = getMap(state)
  if (map) return map.tooltipId
}
