import { RECEIVE_DISTRICT_SERVICES } from '../../../../actions/districtServices'

export default function (state = [], action = {}) {
  const { type, payload } = action

  if (type === RECEIVE_DISTRICT_SERVICES) {
    return payload.data
  }

  return state
}
