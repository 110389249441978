import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import options from './barChartOptions'
import ChartHeader from './ChartHeader'
import ChartLegend from './ChartLegend'
import ButtonLink from '../../links/StateBandwidthLink'
import ViewSlider from './ViewSlider'
import colors from '../../../styles/variables.scss'
import utils, { renderMobile } from '../../../utils'
import * as hooks from '../../../hooks'
import './PercentMeetingGoal.sass'
import classNames from 'classnames'

const availableFundingYearData = (years) => (data) => (years.includes(data.fundingYear))

function StateBandwidthLink ({ stateCode, numDistrictsNotMeetingGoal }) {
  if (utils.shouldAddBandwidthQuery(numDistrictsNotMeetingGoal)) {
    return (
      <ButtonLink
        stateCode={stateCode}
        filters={['bandwidth']}
        classNames={['StateButtonLink']}>
        View districts under 1 Mbps
      </ButtonLink>
    )
  } else {
    return (
      <ButtonLink
        stateCode={stateCode}
        filters={[]}
        classNames={['StateButtonLink']}>
        View districts
      </ButtonLink>
    )
  }
}

export default function PercentMeetingGoal ({
  chartData,
  stateCode,
  onChartRendered,
  onViewUpdated,
  comparisonStateCode,
  pdfImages,
  expandWidth
}) {
  const { width } = hooks.useWindowDimensionsCtx()
  const [chartRenderedStateCode, setChartRenderedStateCode] = useState(stateCode)
  const chartRef = React.createRef()
  const { title, subtitle, stateData, nationData, stateComparisonData, view, numDistrictsNotMeetingGoal } = chartData

  if (!nationData.length || !stateData.length) return null

  const labels = stateData.map(d => d.fundingYear)
  const imgStateCode = comparisonStateCode || stateCode

  const data = {
    labels,
    datasets: [
      {
        label: 'national:National',
        backgroundColor: colors.colorChartColor1,
        data: nationData.filter(availableFundingYearData(labels)).map(nation => nation.data),
        isComparison: false,
        barPercentage: 1.0,
        categoryPercentage: 0.5
      },
      {
        label: 'state:' + stateCode,
        backgroundColor: colors.colorChartColor3,
        data: stateData.map(state => state.data),
        isComparison: false,
        barPercentage: 1.0,
        categoryPercentage: 0.5
      }
    ]
  }
  if (comparisonStateCode && stateComparisonData) {
    data.datasets.push(
      {
        label: 'state-comparison:' + comparisonStateCode,
        backgroundColor: colors.colorChartColor2,
        data: stateComparisonData.filter(availableFundingYearData(labels)).map(d => d.data),
        isComparison: true,
        barPercentage: 1.0,
        categoryPercentage: 0.5
      }
    )
  }

  const animationOptions = {
    animation: {
      onComplete: () => {
        if ((chartRenderedStateCode !== imgStateCode) || !pdfImages[imgStateCode]) {
          const imgData = chartRef.current.chartInstance.toBase64Image()
          onChartRendered({
            name: 'percentMeetingGoal',
            imgData,
            stateCode: imgStateCode
          })
          setChartRenderedStateCode(imgStateCode)
        }
      }
    }
  }

  return (
    <div className={classNames('PercentMeetingGoal', { 'PercentMeetingGoal__expand': expandWidth })}>
      <ChartHeader
        title={title}
        subtitle={subtitle}
        chartName='percent-meeting-goal' />
      <ViewSlider view={view} onChange={onViewUpdated} />
      <div className='PercentMeetingGoal__content-container'>
        <div className='PercentMeetingGoal__chart'>
          <Bar
            ref={chartRef}
            data={data}
            options={{ ...options(renderMobile(width), data.datasets), ...animationOptions }} />
        </div>
        <ChartLegend stateCode={stateCode} comparisonState={comparisonStateCode} />
        <div className='PercentMeetingGoal__button'>
          <StateBandwidthLink stateCode={stateCode} numDistrictsNotMeetingGoal={numDistrictsNotMeetingGoal} />
        </div>
      </div>
    </div>
  )
}

PercentMeetingGoal.propTypes = {
  chartData: PropTypes.object,
  pdfImages: PropTypes.object,
  stateCode: PropTypes.string.isRequired,
  comparisonStateCode: PropTypes.string,
  onChartRendered: PropTypes.func.isRequired,
  onViewUpdated: PropTypes.func.isRequired,
  govCommitted: PropTypes.bool
}
