import { syncRecentSearches } from '../actions/globalSearch'

const actionsToPersist = {
  STATE_SELECTED: 'state',
  DISTRICT_SELECTED: 'district'
}

const sessionStorage = window.sessionStorage
const SESSION_DATA = 'recentSearches'

function distinctFiveSearches (recent, data) {
  const collection = [...recent]
  const labels = recent.map(c => c.label)
  const index = labels.indexOf(data.label)
  if (index >= 0) collection.splice(index, 1)
  collection.push(data)
  return collection.reverse().slice(0, 5).reverse()
}

const persistSessionStorage = (data) => {
  const recent = JSON.parse(sessionStorage.getItem(SESSION_DATA)) || []
  const recentSearches = distinctFiveSearches(recent, data)
  sessionStorage.setItem(SESSION_DATA, JSON.stringify(recentSearches))
  return recentSearches
}

const sessionStorageMiddleware = store => next => action => {
  const result = next(action)
  const shouldPersist = actionsToPersist[action.type]
  if (shouldPersist) {
    const data = action.payload[shouldPersist]
    const search = { ...data, recent: true }
    const recentSearches = persistSessionStorage(search)
    store.dispatch(syncRecentSearches(recentSearches))
  }
  return result
}

export default sessionStorageMiddleware
