import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import PopupHeader from '../shared/PopupHeader'
import './index.sass'

const DirtyServicesPopup = forwardRef(({ data, onClose }, ref) => {
  return (
    <div className='DirtyServicesPopup__popup' ref={ref}>
      <PopupHeader title={data.entity_name} onClose={onClose} />
      <div className='DirtyServicesPopup__content'>
        We have insufficient broadband services for this school district.
      </div>
    </div>
  )
})

export default DirtyServicesPopup

DirtyServicesPopup.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
}
