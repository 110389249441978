import actions from '../../../actions/statesMetrics'
import * as statuses from '../../status'

const initalState = {
  status: statuses.INITIALIZED,
  data: []
}

export default function states (state = initalState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case actions.REQUEST_STATES_METRICS:
      return { ...state, status: statuses.REQUESTED }
    case actions.RECEIVE_STATES_METRICS_ERROR:
      return { ...state, status: statuses.ERROR }
    case actions.RECEIVE_STATES_METRICS:
      return { status: statuses.LOADED, data: payload.data }
    default:
      return state
  }
}
