import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import './index.sass'

export function ChartTooltip ({ tooltip, className = null, tooltipHeader, tooltipBody }) {
  return (
    <div className={`ChartTooltip ${className}`}>
      <table className='ChartTooltip__table'>
        <thead>
          {tooltipHeader(tooltip.title[0], className)}
        </thead>
        <tbody className='ChartTooltip__body'>
          {tooltipBody({ className, tooltip })}
        </tbody>
      </table>
    </div>
  )
}

ChartTooltip.propTypes = {
  tooltip: PropTypes.object,
  className: PropTypes.string,
  tooltipHeader: PropTypes.func,
  tooltipBody: PropTypes.func
}

export default function generateChartTooltip ({ tooltipType, tooltipHeader, tooltipBody, renderTooltip = () => true }) {
  return function (tooltip) {
    if (!tooltip) return
    let tooltipEl = document.getElementById('ChartTooltip')

    const position = this._chart.canvas.getBoundingClientRect()

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'ChartTooltip'
      document.body.appendChild(tooltipEl)
    }

    if (tooltip.yAlign) {
      tooltipEl.classList.add(tooltip.yAlign)
    } else {
      tooltipEl.classList.add('no-transform')
    }

    let offset = tooltip.caretX
    const tooltipWidth = tooltipType === 'StateRankings__tooltip' ? 345 : 150
    const tooltipIsOffPage = tooltip.caretX > (this._chart.width - tooltipWidth)
    const tooltipEdgeOffset = this._chart.width - tooltipWidth + 10

    if (tooltipType === 'StateRankings__tooltip' && this._chart.width < 270) {
      offset = position.left - 20
    } else if (tooltipType === 'StateRankings__tooltip' && this._chart.width < 400) {
      offset = position.left
    } else if (tooltipType === 'StateRankings__tooltip') {
      offset = tooltipIsOffPage
        ? position.left + tooltipEdgeOffset
        : tooltip.caretX - 20
    } else if (offset < 20) {
      offset = tooltip.caretX + 20
    } else if (position.left >= 10) {
      offset = tooltipIsOffPage
        ? position.left + tooltipEdgeOffset
        : tooltip.caretX + position.left
    }

    // calculate tooltip caret Y
    if (tooltipType === 'StateRankings__tooltip' && this._chart.width < 400) {
      tooltip.caretY = tooltip.caretY - 120
    } else if (tooltipType === 'StateRankings__tooltip') {
      tooltip.caretY = 0
    }

    tooltipEl.style.opacity = 1
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.left = `${offset}px`

    tooltipEl.style.top = `${position.top + tooltip.caretY + window.pageYOffset - 100}px`
    tooltipEl.style.padding = `${tooltip.yPadding}px ${tooltip.xPadding}px`
    tooltipEl.style.pointerEvents = 'auto'

    if (tooltip.opacity === 0 || !renderTooltip(tooltip.title)) {
      tooltipEl.style.opacity = 0
      return
    }

    ReactDOM.render(
      <ChartTooltip
        className={tooltipType}
        tooltip={tooltip}
        tooltipHeader={tooltipHeader}
        tooltipBody={tooltipBody} />,
      document.getElementById('ChartTooltip')
    )
  }
}

export const cleanupChartTooltips = () => {
  const tooltip = document.getElementById('ChartTooltip')
  if (tooltip) ReactDOM.unmountComponentAtNode(tooltip)
}
