import React from 'react'

export default function CallToAction ({
  header,
  subheader,
  image
}) {
  return (
    <div className='CallToAction'>
      <div className='CallToAction__heading'>
        <div className='CallToAction__header'>{header} </div>
        <div className='CallToAction__subheader'>{subheader}</div>
      </div>
      <img src={image} alt='view dashboards' className='CallToAction__image' />
    </div>
  )
}
