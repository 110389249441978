import React from 'react'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'
import classnames from 'classnames'
import sharedIcon from '../../assets/shared.svg'
import { useWindowDimensionsCtx } from '../../hooks'
import { renderTabletMd } from '../../utils'

const content = ({ className }) => {
  return (
    <Popover content='true' className={classnames('SharedService__tooltip--content', className)}>
      This service is shared across multiple school districts.
    </Popover>
  )
}

export default function SharedTooltip ({ enabled = false, className, id }) {
  const { width } = useWindowDimensionsCtx()
  const placement = renderTabletMd(width) ? 'auto' : 'right'

  if (!enabled) return null

  return (
    <Tooltip key={id} placement={placement} overlay={content({ className })}>
      <img
        aria-hidden
        tabIndex={0}
        className='SharedService__icon'
        src={sharedIcon}
        alt='shared service tooltip' />
    </Tooltip>
  )
}
