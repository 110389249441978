import React from 'react'
import Skeleton from '../../Skeleton'
import DistrictServices from '../DistrictServices'
import InfoTooltip from '../../Tooltip/InfoTooltip'
import DirtyMetricTooltip from '../../Tooltip/DirtyMetricTooltip'
import tooltipContent from './tooltipContent'
import ServiceProvidersTooltip from './ServiceProvidersTooltip'
import { formatBandwidth, formatCost } from '../../../utils/stringHelpers'

const placeholderElements = (
  [
    ['bandwidth_per_student_mbps', <Skeleton width={'6vw'} />, 'per student'],
    ['total_bandwidth_mbps', <Skeleton width={'6vw'} />, 'total bandwidth'],
    ['total_monthly_cost', <Skeleton width={'6vw'} />, 'total monthly internet cost'],
    ['primary_provider', <Skeleton width={'6vw'} />, 'service provider']
  ]
)

const currentMetricsContent = (district, placeholder) => {
  if (placeholder) return placeholderElements
  const CostMetric = district.total_monthly_cost ? formatCost(district.total_monthly_cost, '0,0')
    : <DirtyMetricTooltip id={'total-cost'} message='We have insufficient data about the cost of broadband for this school district.' />
  if (district.bandwidth_per_student_mbps) {
    return [
      ['bandwidth_per_student_mbps', formatBandwidth(district.bandwidth_per_student_mbps), 'per student'],
      ['total_bandwidth_mbps', formatBandwidth(district.total_bandwidth_mbps), 'total bandwidth'],
      ['total_monthly_cost', CostMetric, 'total monthly', 'internet cost'],
      ['primary_provider', <ServiceProvidersTooltip district={district} />, 'service provider']
    ]
  } else {
    return [
      ['bandwidth_per_student_mbps', <DirtyMetricTooltip id={'bandwidth-per-student'} />, 'bandwidth per student'],
      ['total_bandwidth_mbps', <DirtyMetricTooltip id={'total-bandwidth'} />, 'total bandwidth'],
      ['total_monthly_cost', <DirtyMetricTooltip id={'total-cost'} />, 'total monthly', 'internet cost'],
      ['primary_provider', <DirtyMetricTooltip id={'service-provider'} />, 'service provider']
    ]
  }
}

export default function CurrentMetrics ({ placeholder, district, services }) {
  return (
    <div className='DistrictDetails__metrics--current'>
      <div className='DistrictDetails__metrics--title'>
        CURRENT INTERNET&nbsp;<InfoTooltip content={tooltipContent('current')} />
        <div className='DistrictDetails__separator' />
      </div>
      <DistrictServices district={district} services={services} />
      <div className='DistrictDetails__metric-container'>
        {
          currentMetricsContent(district, placeholder).map((metric, key) => {
            return (
              <div key={key} className='DistrictDetails__metric'>
                <div className={`DistrictDetails__metric--value DistrictDetails__metric--${metric[0]}`}>{metric[1]}</div>
                <div className='DistrictDetails__metric--description'>{metric[2]}</div>
                {metric[3] && <div className='DistrictDetails__metric--description'>{metric[3]}</div>}
              </div>
            )
          })
        }
      </div>
      <DistrictServices className='DistrictServices__mobile' district={district} services={services} />
    </div>
  )
}
