import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const Table = forwardRef(({ classNames = [], children, style, fadeout = false }, ref) => {
  return (
    <div className='Table__container' style={style} ref={ref}>
      <table className={`${[...classNames]} table table-striped`}>
        {children}
      </table>
      {fadeout && <div className='Table__container__fadeout' />}
    </div>
  )
})

export default Table

Table.propTypes = {
  classNames: PropTypes.array,
  children: PropTypes.any,
  fadeout: PropTypes.bool
}
