import React from 'react'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'
import infoIcon from '../../assets/icon-16-icon-info-secondary-3.svg'
import classnames from 'classnames'

const content = ({ id, className }) => (
  <Popover id={id} className={classnames('ConsortiumAppliedTooltip__content', className)} content='true'>
    This service was procured through a group purchasing arrangement, such as a consortium or state contract.
  </Popover>
)

const ConsortiumAppliedTooltip = ({ id, className, enabled = false }) => {
  const popperConfig = { modifiers: { preventOverflow: { boundariesElement: 'viewport', enabled: false } } }

  if (!enabled) return null

  return (
    <Tooltip popperConfig={popperConfig} overlay={content({ id, className })}>
      <div className='ConsortiumAppliedTooltip'>
        <img tabIndex={0} className='ConsortiumAppliedTooltip__icon' src={infoIcon} alt='Consortium applied service Icon' />
      </div>
    </Tooltip>
  )
}

export default ConsortiumAppliedTooltip
