import deepmerge from 'deepmerge'
const RANGE_FILTERS = ['bandwidth', 'size', 'cost']
const PROVIDER = 'provider'
const PURPOSE = 'purpose'
const CONTRACT = 'contract'

export function arrayDeepMerge (x, y) {
  return deepmerge(x, y, { arrayMerge: (_dest, source) => source })
}

export const isRange = (name) => RANGE_FILTERS.includes(name)
export const isProvider = (name) => name === PROVIDER
export const isPurpose = (name) => name === PURPOSE
export const isContract = (name) => name === CONTRACT

export function updateProviderOptions (state, providerOptions) {
  return {
    provider: arrayDeepMerge(state, {
      options: [ ...providerOptions ]
    })
  }
}

export function clearUpdates (state) {
  return { ...state, ...{ update: undefined } }
}

export const transform = state => (...fns) =>
  fns.reduce((output, fn) => fn(output), state)
