import React from 'react'
import MapPopup, { ServicePopup, DirtyServicesPopup } from '../../Popup'

function Popup ({ data, map, onOpen, onClose }) {
  const element = data.selected && !data.districtFitForIA
    ? <DirtyServicesPopup />
    : <ServicePopup />

  return (
    <MapPopup
      data={data}
      onOpen={onOpen}
      onClose={onClose}
      map={map}
      element={element}
    />
  )
}

export default Popup
