import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import ReactDOM from 'react-dom'
import State from '../shared/State'
import District from '../shared/District'
import Faq from '../shared/Faq'
import NationalLink from '../shared/NationalLink'
import ContactUs from '../shared/ContactUs'
import Header from './Header'
import './index.sass'

export default function MobileMenu ({ open, stateCode, onNavPromptOpened, onClose, stateMetrics }) {
  const { pathname } = useLocation()
  if (!open) return null

  const handleStateMenuClick = (prompt) => {
    onClose()
    if (!stateCode) onNavPromptOpened(prompt)
  }

  const handleDistrictMenuClick = (prompt) => {
    onClose()
    onNavPromptOpened(prompt)
  }

  return ReactDOM.createPortal(
    <aside className='MobileMenu__background'>
      <div className='MobileMenu'>
        <Header onClick={onClose} />
        <ul className='MobileMenu__list'>
          <li key='NationalLink'>
            <NationalLink
              pathname={pathname}
              onClick={onClose}
              mobile />
          </li>
          <li key='MobileStateMenu'>
            <State
              stateMetrics={stateMetrics}
              stateCode={stateCode}
              pathname={pathname}
              onClick={handleStateMenuClick}
              mobile />
          </li>
          <li key='DistrictLink'>
            <District
              pathname={pathname}
              onClick={handleDistrictMenuClick}
              mobile />
          </li>
          <li key='FaqLink'>
            <Faq
              pathname={pathname}
              onClick={onClose}
              mobile />
          </li>
          <li key='ContactUsLink'>
            <ContactUs
              pathname={pathname}
              onClick={onClose}
              mobile />
          </li>
        </ul>
      </div>
    </aside>,
    document.getElementById('app'))
}

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  stateCode: PropTypes.string,
  onNavPromptOpened: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
