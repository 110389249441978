import deepmerge from 'deepmerge'
import { LOCATION_CHANGE } from 'connected-react-router'
import { parseQuery, validURLParams } from '../../../../utils/urlHelpers'
import { setRangeOrMaxBoundary, validMinMaxRange } from '../../../../utils/rangeHelpers'

const rangeParams = (params) => {
  const { min, max } = params || {}
  return { min, max }
}

const setOptions = ({ min, max }, defaultOptions) => {
  return {
    min: {
      value: min,
      boundary: defaultOptions.min.boundary
    },
    max: {
      value: max,
      boundary: defaultOptions.max.boundary
    }
  }
}

export default name => initialState => (state = initialState, action = {}) => {
  const { payload, type } = action

  if (type !== LOCATION_CHANGE) {
    throw new Error(`applyRangeParams expects to be invoked with a LOCATION_CHANGE action. Received: "${type}"`)
  }

  const query = parseQuery(payload.location.search)
  const params = rangeParams(query[name])

  if (!params.min && !params.max) {
    return {
      ...state,
      ...{
        applied: false,
        options: initialState.options
      }
    }
  }

  if (validURLParams(params)) {
    const range = setRangeOrMaxBoundary(params, initialState.options)
    const applied = validMinMaxRange(range) ? range : {
      min: range.min,
      max: range.min
    }
    return deepmerge(state, {
      applied,
      options: setOptions(applied, initialState.options)
    })
  }
}
