import React from 'react'
import './index.sass'
import content from './content'
import Section from './Section'

export default function Faq () {
  let questionNumber = 0
  let previousSectionLength = 0
  return (
    <div className='Faq'>
      <h1 className='Faq__title'>
        Frequently Asked Questions
      </h1>
      {content.map((section, index) => {
        questionNumber = questionNumber + previousSectionLength
        previousSectionLength = section.faqs.length
        return (
          <Section sectionTitle={section.sectionTitle} faqs={section.faqs}
            questionNumber={questionNumber} key={'faqSection__' + index} />
        )
      })}
    </div>
  )
}
