/**
 * Checks if a single filter is applied
 *
 * @param filters {Object}
 * @param name {string}
 * @returns {boolean}
 */
export const filterApplied = (filters, name) =>
  !!filters[name] && !!filters[name].applied

/**
 * Checks if any of the given filters are applied
 * @param filters {Object}
 * @param names {string}
 */
export const anyFilterApplied = (filters, ...names) =>
  names
    .map(name => filterApplied(filters, name))
    .some(applied => !!applied)

/**
 * Accepts a collection of factory functions. It passes each factory function
 * the current state of it's corresponding filter from the store and then
 * applies the returned functions to the input data. The filterFactories
 * argument is expected to be an object with properties that map to the filters
 * in the store.
 *
 * @param {Object} filterFactories
 * @returns {function(Object, Array): Array} - A function that applies the
 * filters specified by the first argument, which is expected to match the shape
 * of the filters in the store, to the array given as the second argument.
 *
 * @example
 *
 *     // The key 'age' matches the key in 'filters'
 *     const filterFactories = {
 *       age: (options, applied) => row =>
 *         row.yearsOld >= applied.min && row.yearsOld <= applied.max
 *     }
 *
 *     // This would normally be returned by querying the store
 *     const filters = {
 *       age: {
 *         applied: { min: 18, max: 40 },
 *         options: { ... } // omitted
 *       }
 *     }
 *
 *     const data = [
 *       { name: 'Jack', yearsOld: 10 },
 *       { name: 'Jane', yearsOld: 20 },
 *       { name: 'Alice', yearsOld: 50 }
 *     ]
 *
 *     const filterFunction = buildFilterFunction(filterFactories)
 *     const filteredData = filterFunction(filters, data)
 *     // [{ name: 'Jane', yearsOld: 20 }]
 */
const buildFilterFunction = filterFactories => (filters, data) => {
  const appliedFilters = Object
    .entries(filterFactories)
    .filter(([name]) => filterApplied(filters, name))
    .map(([name, factory]) => {
      const { options, applied } = filters[name]
      return factory(options, applied)
    })

  return appliedFilters.reduce((output, fn) => output.filter(fn), [...data])
}

export default buildFilterFunction
