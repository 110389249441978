import React, { createRef } from 'react'
import './index.sass'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import '../../NearbyServices/NearbyServicesTable/index.sass'
import MeetingGoalTable from './MeetingGoalTable'
import MeetingGoalTabs from './MeetingGoalTabs'
import { isLoaded } from '../../../utils'
import '../../Table/Table.sass'
import { NEARBY, CLOSED } from './utils'

export default function MeetingGoalModal ({ tab, setTab, stateMetrics, meetingGoalDistricts }) {
  let { nearby, districts } = meetingGoalDistricts
  const ref = createRef()
  const { status: stateStatus, data: stateData } = stateMetrics

  function onCloseModal (event) {
    if (!ref.current || !ref.current.contains(event.target)) { setTab(CLOSED) }
  }

  if (!tab || tab === CLOSED) return null

  if (!isLoaded([nearby.status, districts.status, stateStatus])) {
    return ReactDOM.createPortal(
      <aside className='MeetingGoalModal__background' onClick={onCloseModal} />,
      document.getElementById('app'))
  }

  const tableData = tab === NEARBY ? nearby.data : districts.data

  return ReactDOM.createPortal(
    <aside className='MeetingGoalModal__background' onClick={onCloseModal}>
      <div className='MeetingGoalModal' ref={ref} >
        <MeetingGoalTabs
          tab={tab}
          setTab={setTab}
          stateCode={stateData.state_code}
          numDistrictsState={stateData.num_districts_meeting_goal}
          numDistrictsNearby={nearby.data.length} />
        <MeetingGoalTable tab={tab} data={tableData} stateCode={stateData.state_code} />
      </div>
    </aside>,
    document.getElementById('app')
  )
}

MeetingGoalModal.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  stateMetrics: PropTypes.object,
  meetingGoalDistricts: PropTypes.object
}
