import React, { useState } from 'react'
import classnames from 'classnames'
import './index.sass'
import Modal from './Modal'

export default function DistrictServices ({ district, services, className }) {
  const [open, setOpen] = useState(false)

  if (!services) return null

  return (
    <div className={classnames('DistrictServices', className)}>
      <div tabIndex={0} className='DistrictServices__link' onClick={() => setOpen(true)}>
        View school district's services
      </div>
      <Modal
        open={open}
        district={district}
        services={services}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}
