export const CHART_RENDERED = 'CHART_RENDERED'
export function chartRendered ({ name, imgData, stateCode }) {
  return {
    type: CHART_RENDERED,
    payload: {
      name,
      imgData,
      stateCode
    }
  }
}

export const STATE_COMPARISON_CLICKED = 'STATE_COMPARISON_CLICKED'
export function stateComparisonClicked (stateCode) {
  return {
    type: STATE_COMPARISON_CLICKED,
    payload: stateCode
  }
}

export const STATE_COMPARISON_CLEARED = 'STATE_COMPARISON_CLEARED'
export function stateComparisonCleared () {
  return {
    type: STATE_COMPARISON_CLEARED
  }
}
