import { getPathname } from './locationSelectors'
import { stateBandwidthDashboard, districtDashboard } from '../routes'

const getStateDashboardFilters = state =>
  state.districts.filters

const getDistrictDashboardFilters = state =>
  state.nearbyServices.filters

export const getFilters = state => {
  const pathname = getPathname(state)
  if (stateBandwidthDashboard.matchPath(pathname)) {
    return getStateDashboardFilters(state)
  }
  if (districtDashboard.matchPath(pathname)) {
    return getDistrictDashboardFilters(state)
  }
  return {}
}

export function getAppliedFilterParams (state) {
  const filters = getFilters(state)
  const params = {}
  for (const filterName in filters) {
    const applied = filters[filterName].applied
    if (applied && filterName !== 'modal') {
      params[filterName] = applied
    }
  }
  return params
}
