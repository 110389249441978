import { showFooter } from '../actions'
import { useDispatch } from 'react-redux'
import { useBottomScrollListener } from './useBottomScrollListener'

export function useToggleFooterOnScroll () {
  const dispatch = useDispatch()
  const onBottom = () => dispatch(showFooter(true))
  const offBottom = () => dispatch(showFooter(false))
  return useBottomScrollListener(onBottom, offBottom)
}
