import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { defaults } from 'react-chartjs-2'
import * as statuses from '../../reducers/status'
import { renderMobile } from '../../utils'
import * as hooks from '../../hooks'
import states from '../../data/states'
import PercentMeetingGoalChart from './charts/PercentMeetingGoal'
import CostOverTimeChart from './charts/CostOverTime'
import ContractsExpiring from './charts/ContractsExpiring'
import StateOverviewPDFButton from './pdfs/StateOverviewPDFButton'
import ErateAppliedFundingChart from './charts/ErateAppliedFunding'
import GovernorQuoteBox from './GovernorQuoteBox'
import StateQuoteBox from './StateQuoteBox'
import StateComparisonDropdown from '../StateComparisonDropdown'
import FCCErateSource from '../FCCErateSource'
import { PRELIM_METRICS_MSG } from '../PreliminaryMetricsFootnote'
import { cleanupChartTooltips } from '../ChartTooltip'
import './index.sass'
import NotFoundPage from '../NotFoundPage'

export const title = (stateCode) => {
  return `${states[stateCode].stateName} K-12 schools need high-speed internet access to take advantage of digital learning in every classroom, every day.`
}

export default function StateOverviewDashboard ({
  stateMetrics,
  nationMetrics,
  onChartRendered,
  stateOverviewCharts,
  onStateComparisonClicked,
  onStateComparisonCleared,
  onPercentMeetingGoalViewUpdated
}) {
  defaults.global.defaultFontFamily = 'Axiforma-Regular'
  const { width } = hooks.useWindowDimensionsCtx()

  useEffect(() => {
    return cleanupChartTooltips
  })

  if (!stateOverviewCharts || !stateMetrics || !nationMetrics) {
    return null
  }

  if (stateMetrics.status === statuses.ERROR) {
    return <NotFoundPage />
  }

  const { stateCode, data: stateData, status: stateStatus } = stateMetrics
  const { status: chartDataStatus } = stateOverviewCharts.chartData
  const { data: nationData, status: nationStatus } = nationMetrics

  if (stateStatus !== statuses.LOADED || chartDataStatus !== statuses.LOADED || nationStatus !== statuses.LOADED) {
    return <div className='StateOverviewDashboard__loading' />
  }

  const {
    percentMeetingGoal,
    erateAppliedFunding,
    costOverTime,
    comparisonStateCode
  } = stateOverviewCharts.chartData
  const {
    percentMeetingGoal: percentMeetingGoalImages,
    costOverTime: costOverTimeImages
  } = stateOverviewCharts.pdfImages
  const { contractsExpiring, percentProjectedMeetingGoalIncrease } = stateData
  const contractsExpiringHidden = contractsExpiring === 0 || !percentProjectedMeetingGoalIncrease
  const governorQuoteHidden = !states[stateCode].committed
  const stateQuoteBoxHidden = !states[stateCode].stateQuote

  return (
    <div className='StateOverviewDashboard'>
      <h1 className='StateOverviewDashboard__title'>
        {title(stateCode)}
      </h1>
      <div className='StateOverviewDashboard__menu'>
        <span className='StateOverviewDashboard__comparison-text'>Compare to: </span>
        <StateComparisonDropdown
          stateCode={stateCode}
          onStateComparisonClicked={onStateComparisonClicked}
          onStateComparisonCleared={onStateComparisonCleared}
        />
        <FCCErateSource className='StateOverviewDashboard__source-text' preliminaryMetrics={PRELIM_METRICS_MSG ? true : null} />
        { !renderMobile(width) &&
        <StateOverviewPDFButton
          stateMetrics={stateData}
          stateOverviewCharts={stateOverviewCharts}
          selectedStateCode={stateCode} />
        }
      </div>
      <div className='StateOverviewDashboard__charts'>
        <PercentMeetingGoalChart
          expandWidth={contractsExpiringHidden || governorQuoteHidden}
          chartData={percentMeetingGoal}
          pdfImages={percentMeetingGoalImages}
          stateCode={stateCode}
          comparisonStateCode={comparisonStateCode}
          onChartRendered={onChartRendered}
          onViewUpdated={onPercentMeetingGoalViewUpdated} />
        {
          !contractsExpiringHidden &&
          <ContractsExpiring
            expandWidth={governorQuoteHidden}
            contractsExpiring={contractsExpiring}
            percentProjectedMeetingGoalIncrease={percentProjectedMeetingGoalIncrease}
            stateCode={stateCode}
          />
        }
        <GovernorQuoteBox
          expandWidth={contractsExpiringHidden}
          stateCode={stateCode}
          committed={states[stateCode].committed}
          govName={states[stateCode].govName}
          stateName={states[stateCode].stateName}
          quote={states[stateCode].govQuote} />
        <ErateAppliedFundingChart
          expandWidth={contractsExpiringHidden && governorQuoteHidden}
          stateCode={stateCode}
          chartData={erateAppliedFunding}
          onChartRendered={onChartRendered} />
        <CostOverTimeChart
          expandWidth
          stateCode={stateCode}
          chartData={costOverTime}
          pdfImages={costOverTimeImages}
          comparisonStateCode={comparisonStateCode}
          nationMedianCosts={nationData.medianCostPerMbps}
          onChartRendered={onChartRendered} />
        {
          !stateQuoteBoxHidden &&
          <StateQuoteBox expandWidth quote={states[stateCode].stateQuote} stateName={states[stateCode].stateName} />
        }
      </div>
    </div>
  )
}

StateOverviewDashboard.propTypes = {
  stateMetrics: PropTypes.shape({
    stateCode: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.shape({
      percentProjectedMeetingGoalIncrease: PropTypes.number,
      contractsExpiring: PropTypes.number
    })
  }),
  nationMetrics: PropTypes.shape({
    data: PropTypes.object,
    status: PropTypes.string
  }),
  onChartRendered: PropTypes.func,
  stateOverviewCharts: PropTypes.shape({
    chartData: PropTypes.object,
    pdfImages: PropTypes.object
  }),
  onPercentMeetingGoalViewUpdated: PropTypes.func
}
