import React from 'react'
import PropTypes from 'prop-types'

import './Label.sass'

import arrowDown from '../../../assets/icon-16-icon-arrow-d-black.svg'
import arrowUp from '../../../assets/icon-16-icon-arrow-u-white.svg'

function Label ({ open, name, onClick, tooltip: Tooltip, applied, appliedLabel, children }) {
  const toggleState = open || applied ? 'open' : 'closed'
  const arrowIcon = open ? arrowUp : arrowDown

  const labelClass = `Filter__label--${toggleState}`
  const toggleClass = `Filter__toggle--${toggleState}`
  const nameClass = `Filter__name--${toggleState}`
  const altText = `${open ? 'Close' : 'Open'} ${name} filter options menu`

  const label = applied ? appliedLabel : name

  return (
    <div className={`Filter__label ${labelClass}`}>
      <div className={`Filter__name ${nameClass}`} onClick={onClick}>
        <div className='Filter__label-text'>{label}</div>
        { Tooltip && <Tooltip /> }
        { (!applied || open) && <img className={`Filter__toggle ${toggleClass}`}
          src={arrowIcon}
          role='button'
          alt={altText} />}
      </div>
      {children}
    </div>
  )
}

Label.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func
}

export default Label
