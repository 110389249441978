import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import contactUsSelected from '../../../assets/icon-24-icon-email-secondary-3.svg'
import contactUsIcon from '../../../assets/icon-24-icon-email-gray-1.svg'
import { contactUsPage, onContactUsPage } from '../../../routes'
import MenuItem from './MenuItem'

export default function ContactUs ({ pathname, onClick, mobile = false }) {
  const icon = onContactUsPage(pathname) ? contactUsSelected : contactUsIcon
  return (
    <div className='Navbar__link Navbar__link--contact-us' onClick={onClick}>
      <Link to={contactUsPage.path}>
        <MenuItem
          icon={icon}
          selected={onContactUsPage(pathname)}
          mobile={mobile}>
          Contact Us
        </MenuItem>
      </Link>
    </div>
  )
}

ContactUs.propTypes = {
  pathname: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  onClick: PropTypes.func
}
