import React from 'react'
import { formatCost, formatCostPerMbps } from '../../../utils/stringHelpers'

function CostRange ({ range }) {
  let str

  if (range.length > 1) {
    str = `${formatCost(range[0])} - ${formatCostPerMbps(range[1])}`
  } else {
    str = `${formatCostPerMbps(range[0])}`
  }

  return (
    <div className='NearbyServicesMap__tooltip-content--cost'>
      {str}
    </div>
  )
}

function TooltipContent ({ name, costRange }) {
  if (!name) return null
  return (
    <div className='NearbyServicesMap__tooltip-content'>
      <div className='NearbyServicesMap__tooltip-recipient-name'>{name}</div>
      {costRange && <CostRange range={costRange} />}
    </div>
  )
}

export default TooltipContent
