import { ASC, DESC } from '../../utils/sortHelpers'

export default function dropdownOptions (columnHeaders) {
  return columnHeaders.reduce((options, option) => {
    if (option.ascLabel) {
      options.push(
        { value: option.column,
          label: option.ascLabel,
          mobile: option.mobileLabel,
          direction: ASC,
          secondary: option.secondary
        }
      )
    }
    if (option.descLabel) {
      options.push(
        {
          value: option.column,
          label: option.descLabel,
          mobile: option.mobileLabel,
          direction: DESC,
          secondary: option.secondary
        }
      )
    }
    return options
  }, [])
}
