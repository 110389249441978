import React, { useRef } from 'react'
import NoResults from '../../NoResults'
import Skeleton from '../../Skeleton'
import TableRow from './TableRow'
import Table from '../../Table'
import Header from '../../Table/Header'
import TableCell from '../../Table/Cell'
import utils from '../../../utils'
import InfoTooltip from '../../Tooltip/InfoTooltip'
import { tooltipContent } from '../tooltipContent'
import columnHeaders from './columnHeaders.json'
import RecommendedTableRow from './RecommendedTableRow'
import * as statuses from '../../../reducers/status'

export default function NearbyServicesTableData ({
  services,
  district,
  tableSort,
  tableOptionsHeight,
  onSort,
  onTableMouseEnter,
  onTableMouseLeave,
  tableStatus
}) {
  const tableHeaderRef = useRef(null)
  if (services.length === 0 && tableStatus === statuses.LOADED) { return <NoResults /> }
  const tableHeaderHeight = tableHeaderRef.current && tableHeaderRef.current.clientHeight
  const { selected: selectedSortOption } = tableSort

  function includesRecommended (services) {
    return services.some(s => s.recommended)
  }

  return (
    <Table classNames={['NearbyServicesTable__table']}>
      <thead>
        <tr className='NearbyServicesTable__header-row' ref={tableHeaderRef}>
          {
            columnHeaders.map((header) => {
              return (
                <Header
                  style={{ top: `${tableOptionsHeight}px` }}
                  header={header.header}
                  column={header.column}
                  key={header.column}
                  classNames={[utils.sortedHeaderClass(header.column, selectedSortOption.value)]}>
                  <InfoTooltip
                    enabled={!!header.tooltip}
                    className='NearbyServicesTable__header__tooltip'
                    placement={header.column === 'purpose' ? 'left' : 'bottom'}
                    content={tooltipContent(header.column, `NearbyServicesTable__header__tooltip--${header.column}`, district)}
                  />
                </Header>
              )
            })
          }
        </tr>
        {
          (tableStatus === statuses.LOADED && includesRecommended(services)) &&
          <RecommendedTableRow
            style={{ top: `${tableHeaderHeight + tableOptionsHeight}px` }}
          />
        }
      </thead>
      <tbody>
        { tableStatus !== statuses.LOADED
          ? [...Array(20)].map((_, row) => {
            return (
              <tr className='NearbyServicesTable__row' key={`SkeletonRow-${row}`}>
                {[...Array(7)].map((_, col) => {
                  return (
                    <TableCell classNamePrefix='NearbyServicesTable__cell' key={`SkeletonTableCell-${row}-${col}`}>
                      <Skeleton />
                    </TableCell>
                  )
                })}
              </tr>
            )
          })
          : services.map((service, idx) => {
            return (
              <TableRow
                key={`TableRow--${idx}`}
                service={service}
                district={district}
                onTableMouseEnter={onTableMouseEnter}
                onTableMouseLeave={onTableMouseLeave}
              />
            )
          })
        }
      </tbody>
    </Table>
  )
}
