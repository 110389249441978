import React from 'react'
import PropTypes from 'prop-types'
import './MenuButton.sass'
import classNames from 'classnames'
import { CONTACT_US_OPTIONS } from '../CONTACT_US_OPTIONS'

export default function MenuButton ({ choice, onMenuButtonClick, selectedContactForm }) {
  const config = CONTACT_US_OPTIONS[choice].menuButton

  const selected = selectedContactForm === choice

  const handleClick = () => {
    onMenuButtonClick(choice)
  }

  return (
    <button onClick={handleClick} id={choice}
      className={classNames('MenuButton', { 'MenuButton--selected': selected })}>
      <img className='MenuButton__img' src={config.src} alt={config.alt} />
      <div className='MenuButton__text'>
        {config.titleTop}
        <br />
        {config.titleBottom}
      </div>
    </button>
  )
}

MenuButton.propTypes = {
  choice: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  selectedContactForm: PropTypes.string
}
