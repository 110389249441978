import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * Wraps a reducer so that it only executes on URLs that match the given pattern
 * @param matcher {Function}
 * @param reducer {Function}
 * @returns {Function}
 */
export default (matcher, reducer) => (state, action = {}) => {
  if (action.type !== LOCATION_CHANGE) { return state }
  const { payload: { location: { pathname } } } = action

  if (!matcher(pathname)) { return state }

  return reducer(state, action)
}
