import { combineReducers } from 'redux'
import filters from './filters'
import data from './data'
import map from './map'
import table from './table'
import error from './error'
import recipientDistrictBen from './recipientDistrictBen'
import status from './status'

const initialState = {
  data: {},
  recipientDistrictBen: null,
  status: null,
  filters: {}
}

export default combineReducers({
  initialState,
  filters,
  data,
  map,
  table,
  recipientDistrictBen,
  error,
  status
})
