import React from 'react'
import styles from '../../../styles/variables.scss'
import 'chartjs-plugin-datalabels'
import states from '../../../data/states.js'
import iconStar from '../../../assets/iconStar.svg'
import generateChartTooltip from '../../ChartTooltip'
import bundleLoader from '../../../utils/bundle-loader'

const tooltipType = 'StateRankings__tooltip'

export const tooltipHeader = (mobile) => (stateCode) => {
  const header = mobile ? `${stateCode} Gov. ${states[stateCode].govName}` : `${states[stateCode].stateName} - Governor ${states[stateCode].govName}`
  return (
    <tr>
      <th className={`ChartTooltip__header`}>
        {states[stateCode].committed && <img src={iconStar} alt='star icon' className='ChartTooltip__header--star' />}
        {header}
      </th>
    </tr>
  )
}

export const tooltipBody = ({ tooltip }) => {
  const stateCode = tooltip.title[0]

  const images = bundleLoader.importGovernorImages()

  return (
    <tr>
      <td className='ChartTooltip__container'>
        <img className={`ChartTooltip__container--photo`} src={images[stateCode + '.jpeg']} alt='governor' />
        <div className='ChartTooltip__container--quote'>
          {states[stateCode].govQuote}
          <br />
        </div>
      </td>
    </tr>
  )
}

export const renderTooltip = (stateCode) => {
  return states[stateCode].committed
}

const formatChartValue = (value, chart) => {
  const noDataMetric = chart.dataset.backgroundColor[chart.dataIndex] === styles.colorGray3
  return noDataMetric ? '-' : value
}

const labeledAxesOption = (width) => {
  const isBelowDesktopSm = width <= 1440
  return [
    {
      ticks: {
        fontSize: 10,
        fontColor: styles.black,
        fontFamily: styles.fontRegular,
        padding: 10,
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0
      },
      gridLines: false,
      barThickness: isBelowDesktopSm ? 13 : 16
    },
    {
      ticks: {
        fontSize: isBelowDesktopSm ? 15 : 18,
        fontColor: styles.colorPrimary,
        fontFamily: styles.fontAwesome,
        fontWeight: 900,
        autoSkip: false,
        padding: 0,
        offset: true,
        maxRotation: 0,
        minRotation: 0,
        callback: (label) => {
          if (states[label] && states[label].committed) return starIconUnicode
        }
      },
      offset: true,
      gridLines: false,
      barThickness: isBelowDesktopSm ? 12 : 16
    }
  ]
}

const hiddenAxesOption = [ { display: false } ]

function _setDynamicOptions (type, width) {
  const dynamicOptions = {
    bar: {
      scales: {
        xAxes: labeledAxesOption(width),
        yAxes: hiddenAxesOption
      },
      layout: {
        padding: {
          top: 20
        }
      },
      plugins: {
        datalabels: {
          align: 'end',
          anchor: 'end',
          font: {
            size: 11,
            family: styles.fontRegular
          },
          color: styles.black,
          formatter: formatChartValue
        }
      }
    },
    horizontalBar: {
      scales: {
        xAxes: hiddenAxesOption,
        yAxes: labeledAxesOption(width)
      },
      layout: {
        padding: {
          right: 50,
          left: 20
        }
      },
      plugins: {
        datalabels: {
          formatter: formatChartValue,
          labels: {
            value: {
              align: 'right',
              anchor: 'end',
              color: styles.black,
              font: {
                size: 12,
                family: styles.fontRegular
              },
              offset: 10
            },
            icon: {
              align: 'start',
              anchor: 'start',
              color: styles.colorPrimary,
              font: {
                size: 18,
                family: styles.fontAwesome
              },
              formatter: (value, chart) => {
                const stateCode = chart.chart.config.data.labels[chart.dataIndex]
                return states[stateCode] && states[stateCode].committed ? starIconUnicode : ''
              },
              offset: 30
            }
          }
        }
      }
    }
  }

  return dynamicOptions[type]
}

const starIconUnicode = '\uf005'

export default function (type, width) {
  const dynamicOptions = _setDynamicOptions(type, width)

  return (
    {
      legend: {
        display: false
      },
      layout: dynamicOptions.layout,
      scales: dynamicOptions.scales,
      plugins: dynamicOptions.plugins,
      maintainAspectRatio: false,
      events: ['mousemove'],
      tooltips: {
        enabled: false,
        mode: 'index',
        intersect: false,
        axis: type === 'horizontalBar' ? 'y' : 'x',
        custom: generateChartTooltip({ tooltipType, tooltipHeader: tooltipHeader(type === 'horizontalBar'), tooltipBody, renderTooltip })
      }
    }
  )
}
