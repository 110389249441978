import { connect } from 'react-redux'
import Filters from '../../components/NearbyServices/Filters'
import {
  nearbyServicesFilterMenuToggled,
  nearbyServicesFiltersApplied,
  nearbyServicesFiltersModalToggled,
  nearbyServicesFilterCleared,
  nearbyServicesFiltersCleared,
  nearbyServicesFilterUpdated
} from '../../actions/nearbyServicesFilters'

const mapStateToProps = ({ nearbyServices: { filters, status } }) => {
  return { filters, status }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (filter, update) => {
      dispatch(nearbyServicesFilterUpdated(filter, update))
    },
    onToggle: (name, open) => {
      dispatch(nearbyServicesFilterMenuToggled(name, open))
    },
    onApply: (...names) => {
      dispatch(nearbyServicesFiltersApplied(names))
    },
    onModalToggle: (open) => {
      dispatch(nearbyServicesFiltersModalToggled(open))
    },
    onClear: (name) => {
      dispatch(nearbyServicesFilterCleared(name))
    },
    onClearAll: () => {
      dispatch(nearbyServicesFiltersCleared())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
