import React from 'react'
import './index.sass'
import TableTitle from '../../Table/Title'
import TableData from './TableData'
import SortDropdown, { dropdownOptions } from '../../SortDropdown'
import DownloadDistricts from './DownloadDistricts'
import columnHeaders from './columnHeaders.json'
import descIcon from '../../../assets/iconSortDesc24.svg'
import ascIcon from '../../../assets/iconSortAsc24.svg'
import fundingYears from '../../../data/fundingYears'

import { ASC } from '../../../utils'

const sortIcon = ({ direction }) => {
  return direction === ASC ? ascIcon : descIcon
}

const ErateDistrictFundingTable = ({ districts, onSort, stateCode }) => {
  const {
    data: {
      table,
      districts: districtsData
    },
    status
  } = districts
  const options = dropdownOptions(columnHeaders)

  return (
    <div className='DistrictsFundingTable'>
      <TableTitle
        className='DistrictsFundingTable__title'
        title='E-rate Funding Requested by School District'
        subtitle={`E-rate Funding Year ${fundingYears.current}`}
      />
      <div className='DistrictsTable__options'>
        <SortDropdown
          options={options}
          value={table.sort}
          icon={sortIcon(table.sort)}
          onChange={onSort} />
        <DownloadDistricts data={districtsData} stateCode={stateCode} />
      </div>
      <TableData
        districts={districtsData}
        status={status}
        tableSortOption={table.sort}
        tableStatus={table.status}
      />
    </div>
  )
}

export default ErateDistrictFundingTable
