import actions from '../../../actions/rawErateHistoricalStateMetrics'
import * as status from '../../status'
import { sortObjects } from '../../../utils'

const initialState = {
  data: {},
  status: status.INITIALIZED
}

const addMetrics = (metric, stateMetrics) => (databaseProperty, storeProperty) => {
  if (metric[databaseProperty] !== null) {
    stateMetrics[storeProperty].push({
      fundingYear: metric.funding_year,
      data: metric[databaseProperty]
    })
  }
}

function rawErateHistoricalStateMetrics (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_RAW_ERATE_HISTORICAL_STATE_METRICS:
      return {
        data: initialState.data,
        stateCode: payload.stateCode,
        status: status.REQUESTED
      }

    case actions.RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS:
      const stateMetrics = {
        erateAppliedFunding: []
      }

      const sortedData = sortObjects([...payload.data], 'funding_year')

      sortedData.reduce((stateMetrics, metric) => {
        const addMetric = addMetrics(metric, stateMetrics)
        addMetric('total_funding_requested', 'erateAppliedFunding')
        return stateMetrics
      }, stateMetrics)

      return {
        stateCode: payload.stateCode,
        data: stateMetrics,
        status: status.LOADED
      }

    case actions.RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS_ERROR:
      return {
        data: state.data,
        stateCode: payload.stateCode,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default rawErateHistoricalStateMetrics
