import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import ChartHeader from './ChartHeader'
import ChartLegend from './ChartLegend'
import colors from '../../../styles/variables.scss'
import options from './lineChartOptions'
import classnames from 'classnames'
import { renderMobile } from '../../../utils'
import * as hooks from '../../../hooks'
import './CostOverTime.sass'

const availableFundingYearData = (years) => (data) => {
  return years.includes(data.fundingYear)
}

export default function CostOverTime ({ comparisonStateCode, pdfImages, stateCode, chartData, nationMedianCosts, expandWidth, onChartRendered }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const [chartRenderedStateCode, setChartRenderedStateCode] = useState(null)
  const chartRef = React.createRef()
  const { title, subtitle, stateData, stateComparisonData } = chartData

  if (!stateData.length) return null

  const labels = stateData.map(d => d.fundingYear)
  const imgStateCode = comparisonStateCode || stateCode

  const data = {
    labels,
    datasets: [
      {
        label: 'state:' + stateCode,
        backgroundColor: colors.colorChartColor3,
        borderColor: colors.colorChartColor3,
        fill: false,
        lineTension: 0,
        pointStyle: 'circle',
        pointBorderWidth: 2,
        pointBorderColor: colors.white,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: colors.colorChartColor3,
        pointHoverBackgroundColor: colors.white,
        pointBackgroundColor: colors.colorChartColor3,
        data: stateData.map(d => d.data)
      },
      {
        label: 'national:National',
        backgroundColor: colors.colorChartColor1,
        borderColor: colors.colorChartColor1,
        fill: false,
        lineTension: 0,
        pointStyle: 'circle',
        pointBorderWidth: 2,
        pointBorderColor: colors.white,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: colors.colorChartColor1,
        pointHoverBackgroundColor: colors.white,
        pointBackgroundColor: colors.colorChartColor1,
        data: nationMedianCosts.filter(availableFundingYearData(labels)).map(n => n.data)
      }
    ]
  }

  if (comparisonStateCode && stateComparisonData) {
    data.datasets.push(
      {
        label: 'state-comparison:' + comparisonStateCode,
        backgroundColor: colors.colorChartColor2,
        borderColor: colors.colorChartColor2,
        fill: false,
        lineTension: 0,
        pointStyle: 'circle',
        pointBorderWidth: 2,
        pointBorderColor: colors.white,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: colors.colorChartColor2,
        pointHoverBackgroundColor: colors.white,
        pointBackgroundColor: colors.colorChartColor2,
        data: stateComparisonData.filter(availableFundingYearData(labels)).map(n => n.data)
      }
    )
  }

  const animationOptions = {
    animation: {
      onComplete: () => {
        if ((chartRenderedStateCode !== imgStateCode) || !pdfImages[imgStateCode]) {
          const imgData = chartRef.current.chartInstance.toBase64Image()
          onChartRendered({
            name: 'costOverTime',
            imgData,
            stateCode: imgStateCode
          })
          setChartRenderedStateCode(imgStateCode)
        }
      }
    }
  }

  return (
    <div className={classnames('CostOverTime', { 'CostOverTime__expand': expandWidth })}>
      <ChartHeader
        title={title}
        subtitle={subtitle}
        chartName='cost-over-time' />
      <div className='CostOverTime__chart'>
        <Line
          ref={chartRef}
          data={data}
          options={{ ...options(renderMobile(width)), ...animationOptions }} />
      </div>
      <ChartLegend stateCode={stateCode} comparisonState={comparisonStateCode} />
      <p className='CostOverTime__footnote'>
      States procure internet in different ways, which can impact their cost metrics. {' '}
        <a
          href='https://esh-jam-pdfs.s3-us-west-1.amazonaws.com/learn-more-about-broadband-cost.pdf'
          target='_blank' rel='noopener noreferrer' className='CostOverTime__footnote__pdf-link'>
          Learn more.
        </a>
      </p>
    </div>
  )
}

CostOverTime.propTypes = {
  stateCode: PropTypes.string,
  chartData: PropTypes.object,
  nationMedianCosts: PropTypes.array.isRequired,
  onChartRendered: PropTypes.func.isRequired
}
