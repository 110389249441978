import { LOCATION_CHANGE } from 'connected-react-router'
import {
  getDistrict,
  getNation,
  getStateMetrics,
  getDistrictsNearby,
  getDistrictServices,
  getDistricts,
  searchDistricts
} from '../actions'
import { RECEIVE_DISTRICT } from '../actions/district'
import { REQUEST_GLOBAL_SEARCH } from '../actions/globalSearch'
import { MAX_NEIGHBOURING_RADIUS } from './utils'
import { loadNearbyServicesData } from '../actions/nearbyServices'
import { districtDashboard } from '../routes'
import { MIN_SEARCH_LENGTH } from '../reducers/shared/globalSearch/data/districts'

const districtDashboardDataLoader = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { location: { pathname } } = payload
    const match = districtDashboard.matchPath(pathname)

    if (match) {
      store.dispatch(getDistrict(match.params.entity_number))
      store.dispatch(getDistrictServices(match.params.entity_number))
      store.dispatch(getNation())
    }
  }

  if (action.type === RECEIVE_DISTRICT) {
    const { state_code: stateCode, latitude, longitude } = action.payload.data
    store.dispatch(loadNearbyServicesData(action.payload.data))
    store.dispatch(getStateMetrics(stateCode))
    store.dispatch(getDistrictsNearby({ latitude, longitude, radius: MAX_NEIGHBOURING_RADIUS }))
    store.dispatch(getDistricts(stateCode))
  }

  if (action.type === REQUEST_GLOBAL_SEARCH) {
    const { searchTerm } = action.payload
    if (searchTerm.length >= MIN_SEARCH_LENGTH) {
      store.dispatch(searchDistricts(searchTerm))
    }
  }
}

export default districtDashboardDataLoader
