import React, { useRef, useEffect, useCallback, useState } from 'react'
import GlobalSearchTypeahead from '../../containers/GlobalSearchTypeahead'
import CallToAction from './CallToAction'
import Testimonial from './Testimonial'
import NewsletterSignupConfirmation from './NewsletterSignupConfirmation'
import homepageDistrict from '../../assets/homepage-district.svg'
import homepageNational from '../../assets/homepage-national.svg'
import homepageStateOverview from '../../assets/homepage-state-overview.svg'
import testimonialSarah from '../../assets/testimonial-sarah.png'
import testimonialMindy from '../../assets/testimonial-mindy.png'
import testimonialDennis from '../../assets/testimonial-dennis.png'
import testimonialMcCall from '../../assets/testimonial-mccall.png'
import { NB_HYPHEN } from '../../utils/stringHelpers'
import { navbarHeight } from '../../styles/variables.scss'
import './index.sass'

export default function Homepage ({ showNavbarTypeahead, setShowNavbarTypeahead }) {
  const typeaheadRef = useRef(null)

  const handleScroll = useCallback((event) => {
    if (typeaheadRef.current) {
      const navbar = Number(navbarHeight.replace(/\D+/, ''))
      const { bottom } = typeaheadRef.current.getBoundingClientRect()
      const isTypeaheadHidden = bottom < navbar
      if (showNavbarTypeahead !== isTypeaheadHidden) {
        setShowNavbarTypeahead(isTypeaheadHidden)
      }
    }
  }, [showNavbarTypeahead, typeaheadRef, setShowNavbarTypeahead])

  const [newsletter, setNewsletter] = useState({ name: null, email: null })

  const handleNewsletterChange = () => {
    const name = document.getElementById('fieldName').value
    const email = document.getElementById('fieldEmail').value

    setNewsletter({ name: name, email: email })
  }

  const handleNewsletterSubmit = (event) => {
    event.preventDefault()

    setNewsletter({ name: event.target.name.value, email: event.target.email.value })
  }

  const handleNewsletterCancel = () => {
    setNewsletter({ name: null, email: null })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <div className='Homepage'>
      <div className='Homepage__body'>
        <div className='Homepage__title'>
          Internet speeds and pricing for America’s public schools at your fingertips.
        </div>
        <div className='Homepage__subtitle'>
          Connect K{NB_HYPHEN}12 is a free tool that aggregates,
          analyzes, and visualizes E{NB_HYPHEN}rate data, so you don’t have to.
        </div>

        <div ref={typeaheadRef} className='Homepage__typeahead-container'>
          <GlobalSearchTypeahead />
        </div>
        <div className='Homepage__cta-container'>
          <CallToAction
            image={homepageStateOverview}
            header='Explore statewide trends'
            subheader={`in K${NB_HYPHEN}12 broadband through interactive dashboards.`}
          />
          <CallToAction
            image={homepageDistrict}
            header='Find your school district'
            subheader='to explore local competitive pricing for internet access.'
          />
          <CallToAction
            image={homepageNational}
            header='Discover national momentum'
            subheader='and compare state progress toward connectivity goals.'
          />
        </div>
      </div>
      <div className='Homepage__testimonials'>
        <div className='Homepage__testimonials--heading'>
          Learn how education leaders are using Connect K{NB_HYPHEN}12 to drive upgrades and make data-driven purchasing decisions:
        </div>
        <div className='Homepage__testimonials--container'>
          <Testimonial
            quote={`With Connect K${NB_HYPHEN}12, if the governor’s office asks me how much school districts are paying for internet access, I can easily find out. I also can use it to track school districts with upcoming internet contract expirations to remind them to apply for E${NB_HYPHEN}rate`}
            name='Sarah Palubinski'
            image={testimonialSarah}
            title='Kansas State E-rate coordinator'
          />
          <Testimonial
            quote={`Viewing my state’s broadband data was simple! Using Connect K${NB_HYPHEN}12 to access and download E${NB_HYPHEN}rate data is a lot easier than navigating the USAC data platform.`}
            name='Mindy Fiscus'
            image={testimonialMindy}
            title='Digital Access Coordinator for the Learning Technology Center of Illinois'
          />
          <Testimonial
            quote={`Connect K${NB_HYPHEN}12 helps me understand what neighboring school districts are getting for internet access. I can use this to start conversations with my service provider about my contract renewal so that I can make sure we use every cent possible for student learning.`}
            name='Dennis Elledge'
            image={testimonialDennis}
            title='Derby USD 260, KS'
          />
          <Testimonial
            quote={`This tool will help our governor and state legislators better understand school district connectivity across the state so that we can assess funding needs and really maximize our efforts and impacts of broadband in Montana.`}
            name='McCall Flynn'
            image={testimonialMcCall}
            title='Education Policy Advisor, Montana'
          />
        </div>
      </div>
      <div className='Homepage__newsletter'>
        <form className='Homepage__newsletter--form' onSubmit={handleNewsletterSubmit}>
          <div className='Homepage__newsletter--container'>
            <div className='Homepage__newsletter--heading'>Sign up for important news and updates related to improving classroom connectivity</div>
            <input className='rbt-input-main form-control rbt-input' type='text' name='name' placeholder='Name' />
            <input className='rbt-input-main form-control rbt-input' type='email' name='email' placeholder='Email' />
            <button>Sign Up for Email Updates</button>
          </div>
        </form>
        {
          // Once set set the newsletter in our state, show the sign-up page
          newsletter.name && newsletter.email && <NewsletterSignupConfirmation name={newsletter.name} email={newsletter.email} onChange={handleNewsletterChange} onCancel={handleNewsletterCancel} />
        }
      </div>
    </div>
  )
}
