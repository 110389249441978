import React from 'react'
import MapTooltip from '../../MapTooltip'
import GeoJSONDataLayer from '../../Map/GeoJSONDataLayer'
import {
  getFeature,
  shouldOpenTooltip,
  containsLngLatPoint,
  addTagManagerData
} from '../../../utils/mapUtils'

const SOURCE_ID = 'district-pins'

function DistrictPins ({ sourceData, popup, map, tooltipId, onClick, onMouseEnter, onMouseLeave }) {
  const feature = getFeature(tooltipId, sourceData.features)
  const { id: featureId, properties: featureProps, geometry } = { ...feature }
  const { coordinates } = { ...geometry }

  function onTooltipOpen () {
    if (!coordinates || !map) return
    const bounds = map.getBounds().toArray()
    if (containsLngLatPoint(bounds, coordinates)) return
    map.panTo(coordinates)
  }

  const handleOnClick = (feature) => {
    addTagManagerData(feature)
    onMouseLeave(null)
    onClick(feature)
  }

  return (
    <div>
      <GeoJSONDataLayer
        highlightId={tooltipId}
        selectedId={popup.entity_number}
        sourceId={SOURCE_ID}
        sourceData={sourceData}
        onClick={handleOnClick}
        onMouseEnter={feature => onMouseEnter(feature.id)}
        onMouseLeave={() => onMouseLeave(null)}
        map={map}
      />
      <MapTooltip
        onOpen={onTooltipOpen}
        coordinates={coordinates}
        enabled={shouldOpenTooltip(tooltipId, featureId)}>
        <div>{featureProps && featureProps.name}</div>
      </MapTooltip>
    </div>
  )
}

export default DistrictPins
