import React from 'react'

export default function Testimonial ({
  image,
  quote,
  name,
  title
}) {
  return (
    <div className='Testimonial'>
      <img src={image} alt='testimonial headshot' className='Testimonial__image' />
      <div className='Testimonial__text-container'>
        <div className='Testimonial__quote'>"{quote}"</div>
        <br />
        <div className='Testimonial__name'>{name}</div>
        <div className='Testimonial__title'>{title}</div>
      </div>
    </div>
  )
}
