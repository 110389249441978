import React from 'react'
import RangeFilter from './RangeFilter'

const Header = ({ label }) => {
  return (
    <div className='Filter__options--header'>
      <strong>{label}</strong>
    </div>
  )
}

function Bandwidth ({
  bandwidth,
  step,
  label,
  maxInput: MaxInput,
  minInput: MinInput,
  appliedLabel,
  ...props
}) {
  return (
    <RangeFilter
      range={bandwidth}
      step={step}
      label={label}
      appliedLabel={appliedLabel}
      header={() => <Header label={label} />}
      minInput={MinInput}
      maxInput={MaxInput}
      {...props}
    />
  )
}

export default Bandwidth
