import React from 'react'
import {
  privacyPolicyLink,
  ConnectedNationLink,
  fccWebsiteLink,
  fundsforlearningLink,
  usacOpenDataLink,
  usacToolsAndResLink,
  ncesDataDefinitionLink,
  erateModOrderLink,
  contactUsLink,
  educationSuperHighwayLink
} from './links'
import fundingYears from '../../data/fundingYears'

export default[
  {
    sectionTitle: 'General',
    faqs: [
      {
        question: 'Why was Connect K-12 created? What is the purpose of the product?',
        answer: (<div>
          <p>
            Connect K-12 is a free, actionable internet speed and pricing information tool for America’s K-12 school districts. Connect K-12 was built to provide transparency around the bandwidth school districts buy. Data transparency is critical for two reasons:
          </p>
          <ol>
            <li key='faq-q-why-connectk12-0'>
              It creates informed buyers at the state and school district level who, as a result of transparency, can negotiate more effectively to increase bandwidth for their students.
            </li>
            <li key='faq-q-why-connectk12-1'>
              It helps track progress towards bandwidth targets that will enable all students to take advantage of digital learning.
            </li>
          </ol>
          <p>
            Connect K-12 has been designed as a resource for leaders in state government and for school district administrators, including superintendents and technology directors, who are helping school districts upgrade their connectivity.
          </p>
        </div>)
      }, {
        question: 'Who created Connect K-12?',
        answer: (<div>
          <p>
            Connect K-12 was originally developed and designed by {educationSuperHighwayLink}. EducationSuperHighway is a national non-profit with the mission to close the digital divide for the 18 million households that have access to the Internet but can't afford to connect. They focus on America's most unconnected communities, where more than 25% of people don't have Internet.
          </p>
          <p>
            From 2012-2020 EducationSuperHighway led the effort that closed the classroom connectivity gap. In 2013, only 10% of students had access to digital learning in their classrooms. Today, thanks to an unprecedented bi-partisan effort by federal, state, and school district leaders, supported by K-12 advocacy organizations, the classroom connectivity gap is closed - 47 million students are connected, and 99.3% of America's schools have a high-speed broadband connection.
          </p>
          <p>
            {ConnectedNationLink}
            and {fundsforlearningLink}
            were selected as partners to launch and maintain Connect K-12 through 2024.
          </p>
        </div>)
      }, {
        question: 'Who are EducationSuperHighway, Connected Nation, and Funds for Learning?',
        answer: (<div>
          <p>
            From 2012-2020 {educationSuperHighwayLink} led the effort that closed the classroom connectivity gap. In November 2021, they launched a new mission to close the digital divide for the 18 million households that have access to the Internet but can't afford to connect.
          </p>
          <p>
            Connected Nation and Funds For Learning have partnered to launch and maintain Connect K-12. {ConnectedNationLink}
            is a nonprofit dedicated to improving lives by providing innovative solutions that expand access, adoption, and use of high-speed internet and its related technology to all people. Connected Nation will work with state leaders to identify and support school districts with upgrading to the 1 Mbps per student goal by 2024.
          </p>
          <p>
            {fundsforlearningLink}
            is a professional firm specializing in the federal E-rate funding program. They will manage the technology platform and ensure data in Connect K-12 is accurate and updated annually.
          </p>
        </div>)
      }, {
        question: 'Is Connect K-12 free or is there a cost for use?',
        answer: (<div>
          <p>
            Connect K-12 is, and always will be, completely free.
          </p>
        </div>)
      }, {
        question: 'What is Connected Nation and Funds For Learning doing with this school district broadband data?',
        answer: (<div>
          <p>
            The data shown in the tool is publicly available through the {usacOpenDataLink}, as required by the Federal Communications Commission’s E-rate Modernization Order. This data is not sold or used by EducationSuperHighway, Connected Nation, or Funds For Learning for commercial benefit in any way.
          </p>
          <p>
            For more information, please visit our {privacyPolicyLink}.
          </p>
        </div>)
      }
    ]
  }, {
    sectionTitle: 'Data and Metrics',
    faqs: [
      {
        question: 'What are the data sources for the broadband information shown in Connect K-12?',
        answer: (<div>
          <p>
        Connect K-12 utilizes data from the Federal Communications Commission’s (FCC) School and Libraries Program (commonly known as E-rate). School districts apply for federal E-rate reimbursement on an annual basis through the Form 471 application by itemizing the details for the broadband services they are purchasing. This data is made available publicly through the E-rate administrator {usacToolsAndResLink} and the {usacOpenDataLink}. Currently, the product is displaying data from the Form 471 application from Funding {fundingYears.current}.          </p>
          <p>
            Due to filing errors and inconsistencies, Funds for Learning analysts also review and edit information received, when necessary, to better represent services procured. Analysts also add additional broadband services that are not included in the E-rate data when additional information is provided by states or school districts.
          </p>
          <p>
            To edit or clarify any information within Connect K-12, please {contactUsLink}.
          </p>
        </div>)
      }, {
        question: 'What is the source of demographic data, including the number of students at each school district?',
        answer: (<div>
          <p>
            The number of students represents the total full-time number of students for traditional schools at the school district, based on demographic data from the National Center for Education Statistics (NCES) for the 2016-2017 school year when available. When NCES data is not available, student data from the FY2020 USAC Entity Reports is used. Occasionally, school districts or state education leaders will contact us to update these numbers directly.
          </p>
          <p>
            To edit or clarify any information within Connect K-12, please {contactUsLink}.
          </p>
        </div>)
      }, {
        question: 'Which school districts are included in Connect K-12?',
        answer: (<div>
          <p>
            Connect K-12 includes traditional school districts only. Generally, Connect K-12 does not include data for charter schools, private schools, libraries, non-instructional facilities, tribal schools, vocational schools, or alternative schools. For more information on how traditional school districts are categorized, visit the NCES {ncesDataDefinitionLink}.
          </p>
          <p>
            School districts are only included if they have historically participated in the E-rate program (either as an applicant or as a recipient on a third-party’s application) and have a billed entity number (BEN) associated with them or their schools.
          </p>
          <p>
            If school districts are missing from Connect K-12 that appear to meet the inclusion criteria, please {contactUsLink}.
          </p>
        </div>)
      }, {
        question: 'Why can’t I find my school district?',
        answer: (<div>
          <p>
            Traditional school districts are searchable in Connect K-12 by the school district name we have on record. School district names are provided to us by the National Center for Education Statistics (NCES) or by the USAC Entity Reports. If you can’t find your traditional school district or it appears we have the wrong school district name on file, please {contactUsLink}.
          </p>
          <p>
            Keep in mind that
            <strong>not</strong>
            all school districts are included in Connect K-12. Connect K-12 does
            <strong>not</strong>
            include data for charter schools, private schools, libraries, non-instructional facilities, tribal schools, vocational schools, or alternative schools. Additionally, school districts are only included if they have a USAC billed entity number (BEN) associated with them or with one of their schools.
          </p>
        </div>)
      }, {
        question: 'Why do I not see all the broadband services for my school districts?',
        answer: (<div>
          <p>
            Connect K-12 only includes information on internet services. WAN services (i.e. transport within the school district’s network from site to site) are not represented.
          </p>
          <p>
            If you have information for internet services that appear to be missing, please {contactUsLink}.
          </p>
        </div>)
      }, {
        question: 'My school district’s bandwidth looks incorrect. How is the total bandwidth, bandwidth per student, and recommended bandwidth of a school district calculated?',
        answer: (<div>
          <p>
            The total bandwidth represents the total amount of internet for all schools in the school district. In cases where districts are billed for ISP separately from the data connection to the ISP, the lesser bandwidth is taken as the connection capacity.
          </p>
          <p>
            The bandwidth per student represents the total internet bandwidth divided by the total number of full-time students. As school district size increases, a smaller percentage of users are likely to be on the network at the same time during peak demand periods. To account for this, bandwidth per student is adjusted on a sliding scale as district size increases to more accurately represent the per student bandwidth capacity.
          </p>
        </div>)
      }, {
        question: 'My school district’s cost looks incorrect. How is the total monthly internet cost for a school district calculated?',
        answer: (<div>
          <p>
            The total monthly internet cost represents the sum of the monthly costs for all internet connections going to the schools. One-time costs are allocated on a monthly basis across the months of service when zero monthly cost is reported.
          </p>
          <p>
            The internet cost does not necessarily reflect what the school district actually pays each month for internet access. Sometimes third-parties, such as the state network, are responsible for the cost each school district incurs. In addition, all costs shown are pre-discount, so the E-rate Program often will also pay for a portion of the cost each school district incurs.
          </p>
          <p>
            When internet bandwidth is shared across multiple school districts, the cost of the bandwidth is allocated proportionally across school districts based on the number of students.
          </p>
        </div>)
      }, {
        question: 'Are the cost numbers shown in Connect K-12 pre- or post-discount in terms of E-rate funding?',
        answer: (<div>
          <p>
            Unless otherwise specified, the internet costs shown in Connect K-12 are all pre-discount. This means the subsidy amount that the E-rate Program provides for each service has
            <em>not</em>
            been deducted from the cost.
          </p>
        </div>)
      }, {
        question: 'What does the “purpose” of an internet service refer to?',
        answer: (<div>
          <p>
            The purpose of an internet service is a term included on the E-rate Form 471 Application that specifies exactly what is included as part of the service:
          </p>
          <ul>
            <li>
              Internet: Internet access service that includes a connection from any service from any applicant site directly to the internet service provider (ISP).
            </li>
            <li>
              ISP only (no circuit): Internet access with no circuit (data circuit to ISP state/regional network is billed separately).
            </li>
            <li>
              Transport to the ISP: Data connection(s) for an applicant’s hub site to an internet service provider and/or state/regional network where internet access service is billed separately.
            </li>
          </ul>
        </div>)
      }, {
        question: 'Why are some services displayed as “recommended”?',
        answer: (<div>
          <p>
            Sometimes internet services will display as “recommended” at the top of a school district’s dashboard. These services have been identified through an algorithm based on the availability of the service provider (i.e. must have internet circuits close by) and because the service is offered at a competitive price.
          </p>
          <p>
            Since school districts consider many factors when choosing an internet service and provider, custom filters and sorting allow school districts to find other relevant internet services.
          </p>
        </div>)
      }
    ]
  }, {
    sectionTitle: 'E-Rate',
    faqs: [
      {
        question: 'What is E-rate?',
        answer: (<div>
          <p>
            The Federal Communications Commission’s (FCC) E-rate program, also known as the Schools and Libraries Program, is a federal program that provides discounts for telecommunications, internet access, and internal connections to eligible schools and libraries.
          </p>
          <p>
            Find out more about E-rate on the {fccWebsiteLink}.
          </p>
        </div>)
      }
    ]
  }, {
    sectionTitle: '1 Mbps per Student',
    faqs: [
      {
        question: 'Where does the 1 Megabit per second (Mbps) per student goal come from?',
        answer: (<div>
          <p>
            The 1 Mbps per student goal was adopted by the Federal Communications Commission in the {erateModOrderLink}. Since its adoption, industry leaders have supported the movement toward 1 Mbps per student for the future of digital learning, including State Education Technology Directors Association (SETDA), The Consortium for School Networking (CoSN), Connected Nation, Funds For Learning, Future Ready, The School Superintendent Organization (AASA), and School Health Libraries (SHLB).
          </p>
        </div>)
      }, {
        question: 'What does it mean to be “ready for every classroom, every day”?',
        answer: (<div>
          <p>
            As technology adoption grows in a school, with more student devices being deployed and more teachers incorporating technology into their curriculum, bandwidth demand grows alongside adoption.
          </p>
          <p>
            21st century classrooms, where teachers use media-rich technology to enhance learning require 1 Megabit per second per student (Mbps/student) of internet access. This target was first proposed by SETDA and adopted by the FCC as a longer term goal.
          </p>
          <p>
            We believe that supporting school districts in attaining the 1 Mbps per student goal will help to ensure equitable access to the internet needed to take advantage of digital learning in every classroom, every day.
          </p>
        </div>)
      }, {
        question: 'What does it mean for a state to be “committed” to 1 Mbps per student?',
        answer: (<div>
          <p>
            In 2014, 50 governors across the United States stated the importance of working toward the 100 kilobits per second (kbps) per student goal. Today, more than 99% of schools nationally meet that goal.
          </p>
          <p>
            The time is now for the same coalition of state and national leaders to set their sights on 21st century learning in every classroom, every day. To enable this, school broadband connections need to be capable of delivering 1 Mbps/student. Governors across the nation who are “committed to 1 Mbps per student” have already made a public statement of commitment to set the vision and make this goal a reality.
          </p>
        </div>)
      }
    ]
  }, {
    sectionTitle: 'Report a Problem',
    faqs: [
      {
        question: 'How do I give feedback or report an issue?',
        answer: (<div>
          <p>
            We appreciate your feedback and want to hear from you! Please {contactUsLink} with your product feedback or to report an issue.
          </p>
        </div>)
      }
    ]
  }
]
