import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import arrowRight from '../../../assets/icon-24-icon-arrow-r-black.svg'
import './PopupFooter.sass'
import { districtDashboard } from '../../../routes'
import { Link } from 'react-router-dom'

const PopupFooter = forwardRef(({ entityNumber, enabled = true }, ref) => {
  if (!enabled) return null

  return (
    <div ref={ref} className='PopupFooter'>
      <img
        className='PopupFooter__icon'
        src={arrowRight}
        role='button'
        alt='right arrow' />
      <Link className={'PopupFooter__link'} to={districtDashboard.generatePath({ entity_number: entityNumber })}>
        View District Details
      </Link>
    </div>
  )
})

export default PopupFooter

PopupFooter.propTypes = {
  entityNumber: PropTypes.number
}
