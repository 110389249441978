import combine from '../combine'
import historicalStateMetrics from './historicalStateMetrics'
import rawErateHistoricalStateMetrics from './rawErateHistoricalStateMetrics'
import historicalNationMetrics from './historicalNationMetrics'
import stateOverviewCharts from './stateOverviewCharts'

export default combine({
  historicalNationMetrics,
  rawErateHistoricalStateMetrics,
  historicalStateMetrics,
  stateOverviewCharts
})
