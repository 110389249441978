import React from 'react'

const Column = ({ title, subtitle, icon, metric, classId }) => {
  return (
    <div className={`PopupColumn PopupColumn--${classId}`}>
      {title && <div className='PopupColumn__title'>{title}</div>}
      <div className='PopupColumn__metric'>{icon}{metric}</div>
      {subtitle && <div className='PopupColumn__subtitle'>{subtitle}</div>}
    </div>
  )
}

export default Column
