import React from 'react'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'

function districtAndSchoolBens (districtBens, schoolBens) {
  return (
    <Popover id='DistrictBensTooltip__content' content='true'>
      <div>
        <span className='DistrictBensTooltip__bens'>
          <strong>School District BENs:</strong>
        </span>
        {districtBens}
      </div>
      <div>
        <span className='DistrictBensTooltip__bens'>
          <strong>School BENs: </strong>
        </span>
        {schoolBens ? `${schoolBens.sort().join(', ')}` : ''}
      </div>
    </Popover>
  )
}

const DistrictBensTooltip = ({ districtBen, schoolBens }) => {
  return (
    <Tooltip trigger={['click']} placement='auto' overlay={districtAndSchoolBens(districtBen, schoolBens)}>
      <div tabIndex={0} className='DistrictDetails__link'>USAC Entity Numbers (BEN)</div>
    </Tooltip>
  )
}

export default DistrictBensTooltip
