import chatIcon from '../../assets/icon-48-icon-chat-black.svg'
import bugIcon from '../../assets/icon-48-icon-bug-black.svg'
import dataIcon from '../../assets/icon-48-icon-data-black.svg'
import questionMark from '../../assets/icon-48-icon-question-black.svg'

export const CONTACT_US_OPTIONS = {
  productFeedback: {
    menuButton: {
      titleTop: 'Product',
      titleBottom: 'Feedback',
      src: chatIcon,
      alt: 'chat box icon'
    },
    formOptions: {
      title: 'Product Feedback',
      messageLabel: 'We welcome your feedback, let us know what you like and where we can get better.',
      placeholder: 'Please share your feedback with us.',
      buttonText: 'Submit Product Feedback'
    },
    topic: 'Product Feedback'
  },
  bugReport: {
    menuButton: {
      titleTop: 'Report',
      titleBottom: 'a Bug',
      src: bugIcon,
      alt: 'bug icon'
    },
    formOptions: {
      title: 'Report a Bug',
      messageLabel: 'What happened? Sharing the steps you took leading up to the problem can help.',
      placeholder: 'Please describe the problem you encountered.',
      buttonText: 'Submit Bug Report'
    },
    topic: 'Bug Report'
  },
  inaccurateData: {
    menuButton: {
      titleTop: 'Inaccurate',
      titleBottom: 'Data',
      src: dataIcon,
      alt: 'data graph icon'
    },
    formOptions: {
      title: 'Inaccurate Data',
      messageLabel: 'Please tell us more, sharing information on the page you were on and the type of data that appears inaccurate can help.',
      placeholder: 'Please describe where and what data appears inaccurate.',
      buttonText: 'Submit Data Inaccuracy'
    },
    topic: 'Inaccurate Data'
  },
  somethingElse: {
    menuButton: {
      titleTop: 'Something',
      titleBottom: 'Else',
      src: questionMark,
      alt: 'question mark icon'
    },
    formOptions: {
      title: 'Something Else',
      messageLabel: 'Please tell us more about your feedback or issue.',
      placeholder: 'Please tell us more about your feedback or issue.',
      buttonText: 'Submit'
    },
    topic: 'Something Else'
  }
}
