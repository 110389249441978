import combine from '../combine'
import { TOGGLE_DISTRICT_BANNER } from '../../actions/'
import nation from '../shared/nation'
import stateMetrics from '../shared/stateMetrics'
import meetingGoalDistricts from '../shared/meetingGoalDistricts'
import district from './district'
import districtServices from './districtServices'
import districtsNearby from './districtsNearby'
import nearbyServices from './nearbyServices'

function bannerVisible (state = true, action = {}) {
  if (action.type !== TOGGLE_DISTRICT_BANNER) return state
  return !state
}

export default combine({
  district,
  districtServices,
  districtsNearby,
  meetingGoalDistricts,
  nation,
  nearbyServices,
  stateMetrics,
  bannerVisible
})
