// import stateMetrics from '../shared/stateMetrics'
// import districts from './districts'
import rawErateStateMetrics from '../shared/rawErateStateMetrics'
import rawErateDistricts from '../shared/rawErateDistricts'
import combine from '../combine'

export default combine({
  // stateMetrics,
  // districts
  rawErateStateMetrics,
  rawErateDistricts
})
