import React from 'react'
import { InputWithUnits, Input } from '.'
import classNames from 'classnames'

export function Size ({ name, value, className, onChange, onApply, maxBoundary }) {
  return (
    <InputWithUnits
      className={classNames('SizeFilter__input', className)}
      name={name}
      value={value}
      input={props => <Input maxBoundary={maxBoundary} {...props} />}
      onChange={onChange}
      onApply={onApply}
    />
  )
}
