import React from 'react'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'
import iconRecommended from '../../assets/icon-24-icon-recommended.svg'

const content = (id) => (
  <Popover id={id} content='true'>
    The provider serving this school district has internet connections available closest to your school district.
  </Popover>
)

const RecommendedServiceTooltip = ({ id, className, children }) => {
  return (
    <Tooltip placement='auto' overlay={content(id)}>
      <div className='RecommendedService__tooltip'>
        <img tabIndex={0} id='RecommendedService__icon' src={iconRecommended} alt='Recommended Service Icon' className={className} />
        {children}
      </div>
    </Tooltip>
  )
}

export default RecommendedServiceTooltip
