import React from 'react'
import './StateQuoteBox.sass'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function StateQuoteBox ({ quote, stateName, expandWidth }) {
  if (!quote) {
    return null
  }

  return (
    <div className={classnames('StateQuoteBox', { 'StateQuoteBox__expand': expandWidth })}>
      <div className='StateQuoteBox__header' >
        More About Connectivity in {stateName}
      </div>
      <blockquote className='StateQuoteBox__quote'>
        {quote}
      </blockquote>
    </div>)
}

StateQuoteBox.propTypes = {
  quote: PropTypes.string
}
