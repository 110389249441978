import React from 'react'
import RangeFilter from './RangeFilter'
import * as Input from './Inputs'

export default function Cost ({ cost, step, appliedLabel, label, ...props }) {
  return (
    <RangeFilter
      range={cost}
      step={step}
      label={label}
      appliedLabel={appliedLabel}
      minInput={props => <Input.Cost name='min' step={step} {...props} />}
      maxInput={props => <Input.Cost name='max' step={step} {...props} />}
      {...props} />
  )
}
