import districtsActions from '../../../actions/districts'
import { sortObjects } from '../../../utils'
import * as statuses from '../../status'
import { ASC } from '../../../utils/sortHelpers'

const initialState = {
  data: [],
  status: statuses.INITIALIZED
}

function districts (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case districtsActions.REQUEST_DISTRICTS:
      return { data: [], status: statuses.REQUESTED }

    case districtsActions.RECEIVE_DISTRICTS:
      let districts = payload.data.filter((dist) => { return dist.meeting_goal })
      districts = sortObjects(districts, 'entity_name', { direction: ASC })
      return { data: districts, status: statuses.LOADED }

    case districtsActions.RECEIVE_DISTRICTS_ERROR:
      return { data: [], status: statuses.ERROR }

    default:
      return state
  }
}

export default districts
