import { MIN_NEIGHBOURING_RADIUS, MAX_NEIGHBOURING_RADIUS } from '../../../middleware/utils'

export const getNeighbouring = (districts) => {
  let result = {}
  const neighborsWithin50Miles = districts.filter(district =>
    district.distance <= MIN_NEIGHBOURING_RADIUS)
  if (neighborsWithin50Miles.length >= 5) {
    result.districts = neighborsWithin50Miles
    result.radius = MIN_NEIGHBOURING_RADIUS
  } else {
    result.districts = districts
    result.radius = MAX_NEIGHBOURING_RADIUS
  }
  return result
}
