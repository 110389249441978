import React from 'react'
import classnames from 'classnames'
import './index.sass'
import { Link } from 'react-router-dom'
import { nationalDashboard } from '../../routes'

export default function ErrorPage ({ status }) {
  const notFound = status >= 400 && status < 500
  const serverError = status >= 500

  const pageType = classnames({
    'NotFound': notFound,
    'ServerError': serverError
  })

  const content = {
    ServerError: {
      title: 'Uh oh!',
      error: 'Error code: 500',
      subtitle: 'Something went wrong on our end. We are working to resolve the issue. Please try again later.'
    },
    NotFound: {
      title: '404',
      subtitle: 'Uh oh, page not found, that’s almost as sad as students without internet access.'
    }
  }

  return (
    <div className={`ErrorPage ${pageType}`}>
      <h1 className={`ErrorPage__title ${pageType}__title`}>
        {content[pageType].title}
      </h1>
      <h3 className={`ErrorPage__error ${pageType}__error`}>
        {content[pageType].error}
      </h3>
      <h2 className={`ErrorPage__subtitle ${pageType}__subtitle`}>
        {content[pageType].subtitle}
      </h2>
      <Link to={nationalDashboard.path}>
        <button className={`ErrorPage__button ${pageType}__button`}>Return to home</button>
      </Link>
    </div>
  )
}
