import { types } from '../../reducers/shared/districts/data/map/pins'
import colors from '../../styles/variables.scss'
import LABEL_URL from '../../assets/map-label-background.png'

/** Layer and image IDs **/
export const SELECTED_ID = 'map-map-pin-selected-district'
export const SELECTED_HOVER_ID = 'map-map-pin-selected-district-hover'
export const RECOMMENDED_ID = 'map-map-pin-default-recommended'
export const RECOMMENDED_HOVER_ID = 'map-map-pin-hover-recommended'
export const RECOMMENDED_SELECTED_ID = 'map-map-pin-selected-recommended'
export const PINS_ID = 'nearby-services-pins'
export const PINS_LABEL_ID = 'pins-label'
export const NEARBY_SERVICES_SOURCE_ID = 'nearby-services'

/**
 * @module Map/layerStyles
 * The appearance of pins, tooltips, and text labels is specified by a MapBox style document.
 *
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/
 * @see https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md
 */
export const LABEL_IMAGE_ID = 'label-background'

export const LABEL_OPTIONS = {
  stretchX: [[8, 15]],
  stretchY: [[8, 15]],
  content: [5, 5, 15, 15]
}
export {
  LABEL_URL
}

/**
 * Returns a MapBox layout style configuration option for text labels
 * and uses boolean property displayLabels to determine visibility
 *
 * @param displayLabels Boolean
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#symbol
 */
export function textLayoutOptions (displayLabels) {
  return {
    'text-field': '{label}',
    'text-offset': [0, 1.6],
    'text-anchor': 'top',
    'text-size': 12,
    'text-font': ['Roboto Bold', 'Open Sans Semibold'],
    'text-justify': 'center',
    'text-allow-overlap': false,
    'icon-text-fit': 'both',
    'icon-image': LABEL_IMAGE_ID,
    'icon-size': 1,
    'icon-allow-overlap': true,
    'visibility': displayLabels ? 'visible' : 'none'
  }
}

/**
 * Paint styles for map pins
 *
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/#paint-property
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions
 */
export const pin = {
  'circle-radius': 6,
  'circle-stroke-width': 2,
  'circle-color': [
    'case',
    ['==', ['get', 'goalStatus'], types.MISSING_DATA], colors.colorGray2, // background color for MISSING_DATA
    ['==', ['get', 'selected'], true], colors.transparent, // transparent background for selected pins
    ['==', ['get', 'recommended'], true], colors.transparent, // transparent background for recommended pins
    colors.colorSecondary3 // default background color
  ],
  'circle-stroke-color': [
    'case',
    ['==', ['get', 'goalStatus'], types.MISSING_DATA], colors.colorGray1, // stroke color for MISSING_DATA
    ['==', ['get', 'selected'], true], colors.transparent, // transparent for selected pins
    ['==', ['get', 'recommended'], true], colors.transparent, // transparent  for selected pins
    colors.colorTertiary1 // default stroke color
  ]
}

/**
 * Returns a MapBox paint style configuration option for map pins which includes
 * the data dependent style definition for 'circle-stroke-color', which is what
 * makes the circle outline white on mouse over.
 *
 * @param highlightId
 * @param staticStyles
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions
 */
export function circlePaintOptions (highlightId = null, selectedId = null, staticStyles = pin) {
  return {
    ...staticStyles,
    ...{
      'circle-color': [
        'case',
        ['==', ['get', 'selected'], true], colors.transparent,
        ['==', ['get', 'id'], selectedId], colors.colorTertiary1, // Background color for when popup is open
        pin['circle-color']
      ]
    },
    ...{
      'circle-stroke-color': [
        'case',
        ['==', ['get', 'selected'], true], colors.transparent, // Transparent for selected pins
        ['==', ['get', 'id'], selectedId], colors.white, // Stroke color for when popup is open
        ['==', ['get', 'id'], highlightId], colors.white, // Stroke color for when pin is hovered
        pin['circle-stroke-color']
      ]
    }
  }
}

export function setDefaultIconImages (map) {
  if (map) {
    map.setLayoutProperty(SELECTED_ID, 'icon-image', SELECTED_ID)
    map.setLayoutProperty(RECOMMENDED_ID, 'icon-image', RECOMMENDED_ID)
  }
}

export function setHoverIconImages (map, featureId) {
  if (map && featureId) {
    map.setLayoutProperty(RECOMMENDED_ID, 'icon-image', [
      'match',
      ['get', 'id'],
      featureId,
      RECOMMENDED_HOVER_ID,
      RECOMMENDED_ID
    ])
    map.setLayoutProperty(SELECTED_ID, 'icon-image', [
      'match',
      ['get', 'id'],
      featureId,
      SELECTED_HOVER_ID,
      SELECTED_ID
    ])
  }
}

export function setSelectedIconImages (map, featureId) {
  if (map) {
    map.setLayoutProperty(RECOMMENDED_ID, 'icon-image', [
      'match',
      ['get', 'id'],
      featureId,
      RECOMMENDED_SELECTED_ID,
      RECOMMENDED_ID
    ])
  }
}

export function addLabelImage (map) {
  if (map && !map.hasImage(LABEL_IMAGE_ID)) {
    let image = new window.Image(24, 24)
    image.src = LABEL_URL
    image.onload = () => {
      if (!map.hasImage(LABEL_IMAGE_ID)) {
        map.addImage(LABEL_IMAGE_ID, image, LABEL_OPTIONS)
      }
    }
  }
}

export const sharedLayoutOptions = (iconImage) => ({
  'icon-allow-overlap': true,
  'icon-image': iconImage,
  'icon-size': 1,
  'visibility': 'visible'
})
