import React from 'react'
import PropTypes from 'prop-types'
import DistrictDetails from '../DistrictDetails'
import BandwidthStatus from '../BandwidthStatus'
import StateMetrics from '../StateMetrics'
import './index.sass'

export default function DistrictBanner ({
  enabled,
  district,
  districtServices,
  stateMetrics,
  meetingGoalDistricts
}) {
  if (!enabled || !district) return null

  const { data: districtData } = district
  const { data: stateData } = stateMetrics
  const placeholder = !districtData.entity_number

  return (
    <div className='DistrictBanner'>
      <DistrictDetails placeholder={placeholder} district={districtData} services={districtServices} />
      <BandwidthStatus placeholder={placeholder} bandwidth={districtData.adj_bandwidth_per_student_mbps} />
      <StateMetrics placeholder={!stateData.state_code} stateMetrics={stateMetrics} meetingGoalDistricts={meetingGoalDistricts} />
    </div>
  )
}

DistrictBanner.propTypes = {
  enabled: PropTypes.bool,
  district: PropTypes.shape({
    data: PropTypes.object,
    status: PropTypes.string
  }),
  districtServices: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.string
  }),
  stateMetrics: PropTypes.shape({
    data: PropTypes.object,
    status: PropTypes.string
  }),
  meetingGoalDistricts: PropTypes.shape({
    nearby: PropTypes.object,
    districts: PropTypes.object
  })
}
