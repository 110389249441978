import { sendEmail } from '../data/sendEmail'

export const CONTACT_US_MENU_BUTTON_CLICKED = 'CONTACT_US_MENU_BUTTON_CLICKED'
export const contactUsMenuButtonClicked = (button) => {
  return {
    type: CONTACT_US_MENU_BUTTON_CLICKED,
    payload: { button }
  }
}

export const CONTACT_FORM_SUBMITTED = 'CONTACT_FORM_SUBMITTED'
export const contactFormSubmitted = (formData) => {
  return {
    type: CONTACT_FORM_SUBMITTED,
    payload: formData
  }
}

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST'
export function sendEmailRequest () {
  return {
    type: SEND_EMAIL_REQUEST,
    payload: 'Requesting'
  }
}

export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export function sendEmailSuccess (response) {
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: response
  }
}

export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR'
export function sendEmailError (error) {
  return {
    type: SEND_EMAIL_ERROR,
    payload: error
  }
}

export function sendContactFormEmail (formData) {
  return function (dispatch) {
    dispatch(sendEmailRequest())

    return sendEmail(formData)
      .then(result => { return dispatch(sendEmailSuccess(result)) })
      .catch(error => { return dispatch(sendEmailError(error)) })
  }
}
