import numeral from 'numeral'
import { getUnitConverter, getUnits, UNITS } from './bandwidthHelpers'

const ACRONYMS = ['LLC', 'AT&T']

export const NBSP = '\u00A0'
export const NB_HYPHEN = '\u2011'
export function formatCSVString (string) {
  return string ? string.replace(/"/g, '""') : ''
}

export function formatPurpose (shortPurpose) {
  const purposeLookup = {
    'isp': 'ISP (no circuit)',
    'upstream': 'Transport (to ISP)',
    'internet': 'Internet'
  }
  return purposeLookup[shortPurpose] || 'Unknown'
}

export function capitalize (string) {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function titleize (string, acronyms) {
  if (!Array.isArray(acronyms)) {
    acronyms = ACRONYMS
  }

  const separators = /(\s|-)/

  return string.split(separators).reduce((output, group) => {
    if (acronyms.indexOf(group) !== -1) {
      return output + group
    }
    return output + capitalize(group.toLowerCase())
  }, '')
}

export function formatProviders (providers = []) {
  if (!Array.isArray(providers)) {
    return '-'
  }
  return providers.map(titleize).join(', ')
}

export function toClassCase (string) {
  return string.toLowerCase()
  // remove special characters except dash and space
    .replace(/[^a-zA-Z\- ]/g, '')
  // replace spaces with dash
    .replace(/\s/g, '-')
}

export function formatNumber (number, format = '0,0') {
  return number && numeral(number).format(format)
}

export function formatCost (cost, format = '0,0[.]00', { nan } = { nan: '-' }) {
  if (Number.isNaN(parseFloat(cost))) { return nan }
  return `$${numeral(cost).format(format)}`
}

export function formatLargeCost (cost) {
  return formatCost(cost, '0[.]0a').toUpperCase()
}

export function formatLargeNumber (number) {
  return number && formatNumber(number, '0,0a').toUpperCase()
}

export function formatCostPerMbps (cost, { count } = { count: 1 }, { nan } = { nan: '-' }) {
  if (Number.isNaN(parseFloat(cost))) { return nan }
  return `${formatCost(cost)}/Mbps${count > 1 ? '+' : ''}`
}

export function formatBandwidth (bandwidth) {
  if (Number.isNaN(parseFloat(bandwidth))) { return '-' }

  const bw = parseFloat(bandwidth)
  const units = getUnits(bw)
  const converter = getUnitConverter(units)
  const format = '0[.]0'
  const formatted = (bw >= 0.9995 && bw <= 0.9999) ? 999 : formatNumber(converter(bw), format)
  return units === UNITS.none
    ? formatted
    : `${formatted} ${units}`
}

export function formatPercent (decimal) {
  if (decimal || decimal === 0) {
    if (decimal > 0.99) return Math.floor(decimal * 100) + '%'
    if (decimal < 0.1) return numeral(decimal).format('0.[00]%')
    return Math.round(decimal * 100) + '%'
  }
}

export function formatISODate (timestamp, unknownMessage = 'Unknown') {
  if (timestamp === null || timestamp === '') { return unknownMessage }
  const date = new Date(timestamp)
  return `${date.getMonth() + 1}/${date.getUTCDate()}/${date.getFullYear()}`
}

export function formatISODateYearShort (timestamp, unknownMessage = 'Unknown') {
  if (timestamp === null || timestamp === '') { return unknownMessage }
  const date = new Date(timestamp)
  const yearLastTwoDigits = `${date.getFullYear()}`.slice(-2)
  return `${date.getMonth() + 1}/${date.getUTCDate()}/${yearLastTwoDigits}`
}

export function formatMiles (distanceInMiles) {
  distanceInMiles = formatNumber(distanceInMiles, '0,0')
  return distanceInMiles && `${distanceInMiles} Miles`
}

export function formatDistance (distanceInMeters) {
  if (!distanceInMeters) { return '0 Miles' }
  const distanceInMiles = formatNumber(distanceInMeters / 1609.344, '0,0')
  return distanceInMeters && `${distanceInMiles} Miles`
}

const addPlus = (number, max) => {
  return number === max ? '+' : ''
}

const getAppliedValues = (filter) => {
  return filter.applied ? [filter.applied.min, filter.applied.max] : [0, 0]
}

export const formatRangeWith = formatter => (filter) => {
  const maxBounday = filter.options.max.boundary
  const [minApplied, maxApplied] = getAppliedValues(filter)
  if (maxApplied === maxBounday) {
    return `${formatter(minApplied)}+`
  } else if (minApplied !== maxApplied) {
    return `${formatter(minApplied)}-${formatter(maxApplied) + addPlus(maxApplied, maxBounday)}`
  } else {
    return `${formatter(maxApplied)}`
  }
}

export function formatAddress (district, addressFields, zipcodeFields = ['physical_zipcode', 'physical_zipcode_ext']) {
  let result = ''

  addressFields.forEach((field) => {
    if (district[field]) {
      result += district[field] + ', '
    }
  })
  // deleting last comma
  result = result.substring(0, result.length - 2)

  if (district[zipcodeFields[0]]) {
    result += ' ' + district[zipcodeFields[0]]
  }
  if (district[zipcodeFields[1]]) {
    result += '-' + district[zipcodeFields[1]]
  }
  return result.replace(/"/g, '""')
}

export function formatDistrictURL (district) {
  if (district.entity_number) {
    return window.location.origin + '/districts/' + district.entity_number
  } else {
    return window.location.origin + '/districts/'
  }
}
