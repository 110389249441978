import React, { useCallback, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Popup as MapboxPopup } from 'react-mapbox-gl'
import { getCoordinates } from '../../utils/mapUtils'
import DistrictPopup from './DistrictPopup'
import DirtyServicesPopup from './DirtyServicesPopup'
import ServicePopup from './ServicePopup'
import './shared/Popup.sass'

// relative to the pin
const POPUP_OFFSET = {
  x: 0,
  y: -25
}

const POPUP_MARGIN = {
  x: 0,
  y: 25
}

export {
  DistrictPopup,
  ServicePopup,
  DirtyServicesPopup,
  POPUP_OFFSET,
  POPUP_MARGIN
}

const Popup = ({ data, onOpen, onClose, map, element }) => {
  const ref = useCallback(node => {
    if (node && map && Object.keys(data).length > 0) {
      onOpen(node, map, getCoordinates(data))
    }
  }, [data, map, onOpen])

  if (Object.keys(data).length === 0) return <div ref={ref} />

  const offset = data.selected ? [0, -35] : [POPUP_OFFSET.x, POPUP_OFFSET.y]

  return (
    <MapboxPopup
      coordinates={getCoordinates(data)}
      anchor='bottom'
      offset={offset}
      style={{ zIndex: 4 }}>
      {cloneElement(element, { map, data, onClose, ref })}
    </MapboxPopup>
  )
}

export default Popup

Popup.propTypes = {
  data: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
  map: PropTypes.object,
  element: PropTypes.element.isRequired
}
