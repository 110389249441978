import React from 'react'
import states from '../../../data/states'
import studentIcon from '../../../assets/studentOutline.png'
import dollarIcon from '../../../assets/icon-24-icon-state-erate-gray-1.svg'
import districtIcon from '../../../assets/icon-24-icon-district-gray-1.svg'
import { formatLargeNumber, formatCost } from '../../../utils/stringHelpers'
import './index.sass'

const ImpactMetric = ({ header, subheader, icon, metric }) => {
  return (
    <div className='ErateImpactMetrics__metric-container'>
      <div className='ErateImpactMetrics__icon--container'>
        <div className='ErateImpactMetrics__icon-circle'>
          <img className={`ErateImpactMetrics__icon ErateImpactMetrics__icon--${metric}`} src={icon} alt='state metric icon' />
        </div>
      </div>
      <div className='ErateImpactMetrics__content'>
        <div className={`ErateImpactMetrics__header ErateImpactMetrics__header--${metric}`}>{header}</div>
        <div className='ErateImpactMetrics__subheader'>{subheader}</div>
      </div>
    </div>
  )
}

export default function ErateImpactMetrics ({ stateMetrics }) {
  if (!stateMetrics || !stateMetrics.data) return null
  const {
    data: {
      state_code: stateCode,
      num_districts: numDistrictsFunded,
      num_students: numStudentsFunded,
      erate_funding_per_student: fundingPerStudent
    }
  } = stateMetrics
  const stateName = states[stateCode] && states[stateCode].stateName

  return (
    <div className='ErateImpactMetrics'>
      <div className='ErateImpactMetrics__container'>
        <ImpactMetric
          metric='numDistrictsFunded'
          header={`${formatLargeNumber(numDistrictsFunded)} school districts in ${stateName}`}
          subheader='access critical internet connectivity funded through the E-rate Program'
          icon={districtIcon}
        />
      </div>
      <div className='ErateImpactMetrics__container'>
        <ImpactMetric
          metric='numStudentsFunded'
          header={`${formatLargeNumber(numStudentsFunded, '0,0A')} students`}
          subheader={`in ${stateName} benefit from E-rate funding each year`}
          icon={studentIcon}
        />
        <ImpactMetric
          metric='fundingPerStudent'
          header={`${formatCost(fundingPerStudent)} per student`}
          subheader={`in E-rate funds accessed for Category 1 and 2 services in ${stateName}`}
          icon={dollarIcon}
        />
      </div>
    </div>
  )
}
