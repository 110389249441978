import { LOCATION_CHANGE } from 'connected-react-router'
import { parseQuery } from '../../../utils/urlHelpers'
import { arrayDeepMerge } from './transformations'

export default initialState => (state = initialState, action = {}) => {
  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { provider } = parseQuery(payload.location.search)
    if (!provider) {
      return {
        ...state,
        ...{
          applied: false,
          enabled: false,
          options: initialState.options
        }
      }
    }

    return arrayDeepMerge(state, {
      applied: provider,
      selected: provider
    })
  }
}
