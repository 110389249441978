import { fetchHistoricalStateMetrics } from '../data'
import status from '../reducers/status'
import { calculateNationChartData } from './historicalNationMetrics'

export const REQUEST_HISTORICAL_STATE_METRICS = 'REQUEST_HISTORICAL_STATE_METRICS'
export function requestHistoricalStateMetrics (stateCode) {
  return {
    type: REQUEST_HISTORICAL_STATE_METRICS,
    payload: { stateCode }
  }
}

export const REQUEST_COMPARISON_HISTORICAL_STATE_METRICS = 'REQUEST_COMPARISON_HISTORICAL_STATE_METRICS'
export function requestComparisonHistoricalStateMetrics (stateCode) {
  return {
    type: REQUEST_COMPARISON_HISTORICAL_STATE_METRICS,
    payload: { stateCode }
  }
}

export const RECEIVE_HISTORICAL_STATE_METRICS = 'RECEIVE_HISTORICAL_STATE_METRICS'
export function receiveHistoricalStateMetrics (stateCode, data) {
  return {
    type: RECEIVE_HISTORICAL_STATE_METRICS,
    payload: { stateCode, data }
  }
}

export const RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS = 'RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS'
export function receiveComparisonHistoricalStateMetrics (stateCode, data) {
  return {
    type: RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS,
    payload: { stateCode, data }
  }
}

export const RECEIVE_HISTORICAL_STATE_METRICS_ERROR = 'RECEIVE_HISTORICAL_STATE_METRICS_ERROR'
export function receiveHistoricalStateMetricsError (stateCode, error) {
  return {
    type: RECEIVE_HISTORICAL_STATE_METRICS_ERROR,
    payload: {
      data: {
        state_code: stateCode
      },
      error: error.message,
      status: error.status
    }
  }
}

export const RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR = 'RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR'
export function receiveComparisonHistoricalStateMetricsError (stateCode, error) {
  return {
    type: RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR,
    payload: {
      data: {
        state_code: stateCode
      },
      error: error.message,
      status: error.status
    }
  }
}

export const CALCULATE_STATE_BANDWIDTH_CHART_DATA = 'CALCULATE_STATE_BANDWIDTH_CHART_DATA'
export function calculateStateBandwidthChartData (data) {
  return {
    type: CALCULATE_STATE_BANDWIDTH_CHART_DATA,
    payload: data.historicalStateMetrics
  }
}

export const CALCULATE_COMPARISON_HISTORICAL_STATE_METRICS = 'CALCULATE_COMPARISON_HISTORICAL_STATE_METRICS'
export function calculateComparisonHistoricalStateMetrics () {
  return {
    type: CALCULATE_COMPARISON_HISTORICAL_STATE_METRICS
  }
}

export const PERCENT_MEETING_GOAL_VIEW_UPDATED = 'PERCENT_MEETING_GOAL_VIEW_UPDATED'
export function percentMeetingGoalViewUpdated (view) {
  return {
    type: PERCENT_MEETING_GOAL_VIEW_UPDATED,
    payload: {
      view
    }
  }
}

export const updatePercentMeetingGoalView = (view) => {
  return async function (dispatch, getState) {
    await dispatch(percentMeetingGoalViewUpdated(view))
    await generateStateMetricCharts(dispatch, getState)
    await dispatch(calculateNationChartData(getState()))
    await dispatch(calculateComparisonHistoricalStateMetrics())
  }
}

export const generateStateMetricCharts = async (dispatch, getState) => {
  const state = getState()
  if (state.historicalStateMetrics.status !== status.ERROR) {
    return dispatch(calculateStateBandwidthChartData(state))
  }
}

export function loadHistoricalStateMetricsData (stateCode) {
  return async function (dispatch, getState) {
    await getHistoricalStateMetrics(stateCode)(dispatch)
    await generateStateMetricCharts(dispatch, getState)
  }
}

export function getHistoricalStateMetrics (stateCode, retries = 2) {
  return function (dispatch) {
    dispatch(requestHistoricalStateMetrics(stateCode))

    return fetchHistoricalStateMetrics(stateCode)
      .then(data => { return dispatch(receiveHistoricalStateMetrics(stateCode, data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveHistoricalStateMetricsError(stateCode, error))
        }
        return dispatch(getHistoricalStateMetrics(stateCode, retries - 1))
      })
  }
}

export function loadComparisonHistoricalStateMetricsData (stateCode) {
  return async function (dispatch) {
    await getComparisonHistoricalStateMetrics(stateCode)(dispatch)
    await dispatch(calculateComparisonHistoricalStateMetrics())
  }
}

export function getComparisonHistoricalStateMetrics (stateCode, retries = 2) {
  return function (dispatch) {
    dispatch(requestComparisonHistoricalStateMetrics(stateCode))

    return fetchHistoricalStateMetrics(stateCode)
      .then(data => { return dispatch(receiveComparisonHistoricalStateMetrics(stateCode, data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveComparisonHistoricalStateMetricsError(stateCode, error))
        }
        return dispatch(getComparisonHistoricalStateMetrics(stateCode, retries - 1))
      })
  }
}

export default {
  REQUEST_HISTORICAL_STATE_METRICS,
  RECEIVE_HISTORICAL_STATE_METRICS,
  RECEIVE_HISTORICAL_STATE_METRICS_ERROR
}
