import React from 'react'
import questionMarkIcon from '../../assets/questionMarkGray.svg'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'

const content = ({ id, className, message }) => {
  return (
    <Popover id={`${id}-tooltip`} content='true' className={`${className}__tooltip--content`} >
      {message}
    </Popover>
  )
}
const DirtyMetricTooltip = ({ enabled = true, id, className = 'DirtyMetric', message = 'We have insufficient data about broadband services for this school district.' }) => {
  if (!enabled) return null
  return (
    <Tooltip id={id} placement='auto' overlay={content({ id, className, message })}>
      <img
        tabIndex={0}
        aria-hidden
        className={`${className}Tooltip__icon`}
        src={questionMarkIcon}
        alt='dirty metric tooltip' />
    </Tooltip>
  )
}

export default DirtyMetricTooltip
