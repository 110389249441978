import {
  RECEIVE_DISTRICTS,
  SORT_DISTRICTS_TABLE
} from '../../../../../actions/rawErateDistricts'
import { FILTER_DISTRICTS, FILTER_DISTRICTS_BY_PROVIDER } from '../../../../../actions/districtsFilters'
import { standardFilters, providerFilters } from './userControlledFilters'
import fitnessFilters from './fitnessFilters'
import fitnessTransforms from './fitnessTransforms'
import sortObjects from '../../../../../utils/sortHelpers'

export default function (state = [], action = {}) {
  const { type, payload } = action

  switch (type) {
    case RECEIVE_DISTRICTS:
      return fitnessTransforms(payload.data)
    case FILTER_DISTRICTS:
      return standardFilters(payload, fitnessFilters(payload, state))
    case FILTER_DISTRICTS_BY_PROVIDER:
      return providerFilters(payload.filters, fitnessFilters(payload.filters, state))
    case SORT_DISTRICTS_TABLE:
      return sortObjects(state, payload.value, payload)
    default:
      return state
  }
}
