import { combineReducers } from 'redux'
import data from './data'
import stateCode from './stateCode'
import status from './status'
import filters from './filters'

export const initialState = {
  data: {},
  stateCode: null,
  status: null,
  isFirstRequest: null
}

export default combineReducers({
  initialState,
  filters,
  stateCode,
  data,
  status
})
