import GlobalSearchTypeahead from '../../components/GlobalSearchTypeahead'
import { connect } from 'react-redux'
import {
  requestGlobalSearch,
  districtSelected,
  stateSelected
} from '../../actions/globalSearch'

const mapStateToProps = (state) => {
  return {
    globalSearch: state.globalSearch
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSearch: (searchTerm) => {
      dispatch(requestGlobalSearch(searchTerm))
    },
    onChange: (option) => {
      if (option.type === 'districts') dispatch(districtSelected(option))
      if (option.type === 'states') dispatch(stateSelected(option))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchTypeahead)
