import React from 'react'
import { Link } from 'react-router-dom'
import LastUpdated from './LastUpdated'
import {
  aboutUsPage,
  contactUsPage,
  privacyPolicyPage,
  termsOfUsePage
} from '../../routes'
import fundingYears from '../../data/fundingYears'

import './index.sass'

export default function Footer () {
  return (
    <div className='Footer-wrap'>
      <div className='Footer'>
        <div className='Footer__logo'>&#9400; {fundingYears.current} Connect K-12</div>
        <div className='Footer__item'>
          <a className='Footer__link--middle' href='https://twitter.com/connect_k12' target='_blank' rel='noopener noreferrer'>
            Twitter
          </a>
        </div>
        <div className='Footer__item'>
          <a className='Footer__link--middle' href='https://www.linkedin.com/company/connect-k-12' target='_blank' rel='noopener noreferrer'>
            LinkedIn
          </a>
        </div>
        <div className='Footer__item'>
          <a className='Footer__link--middle' href='https://www.facebook.com/GetConnectK12' target='_blank' rel='noopener noreferrer'>
            Facebook
          </a>
        </div>
        <div className='Footer__item'>
          <Link className='Footer__link--middle' to={privacyPolicyPage.path}>
            Privacy Policy
          </Link>
        </div>
        <div className='Footer__item'>
          <Link className='Footer__link--middle' to={termsOfUsePage.path}>
            Terms of Use
          </Link>
        </div>
        <div className='Footer__item'>
          <Link className='Footer__link--middle' to={contactUsPage.path}>
            Contact Us
          </Link>
        </div>
        <div className='Footer__item'>
          <Link className='Footer__link--last' to={aboutUsPage.path}>
            About Us
          </Link>
        </div>
      </div>
      <LastUpdated />
    </div>
  )
}
