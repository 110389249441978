import { CONTACT_US_MENU_BUTTON_CLICKED, CONTACT_FORM_SUBMITTED } from '../../../actions/contactUs'

export const initialState = ''

export default function selectedContactForm (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case CONTACT_US_MENU_BUTTON_CLICKED:
      return payload.button
    case CONTACT_FORM_SUBMITTED:
      return initialState
    default:
      return state
  }
}
