import qs from 'qs'

export function buildFilterQueryString (filters) {
  return filters.reduce(function (queryString, filter) {
    if (filter === 'bandwidth') {
      return queryString + 'bandwidth[max]=0.9999&'
    } else if (filter === 'contract') {
      return queryString + 'contract=true&'
    }
    return queryString
  }, '?').slice(0, -1)
}

export function shouldAddBandwidthQuery (numDistrictsNotMeetingGoal) {
  return numDistrictsNotMeetingGoal !== null && numDistrictsNotMeetingGoal !== 0
}

export function parseQuery (query) {
  let queryParams = qs.parse(query, { ignoreQueryPrefix: true })
  return parseObject(queryParams)
}

export function validURLParams (params, validOptions = ['max', 'min'], options = { isArray: false }) {
  if (options.isArray) {
    return Array.isArray(params) && params.length > 0 &&
      params.every((value) => validOptions.includes(value))
  } else {
    return isObject(params) && keysIncludeAnyOf(params, ...validOptions)
  }
}

export const keysIncludeAnyOf = (object, ...keys) =>
  Object.keys(object).some(key => keys.includes(key))

// inspired from https://github.com/xpepermint/query-types/blob/master/index.js
function parseObject (params) {
  if (isObject(params)) {
    for (let key in params) {
      params[key] = parseValue(params[key])
    }
  }
  return params
}

export function isObject (value) {
  return value && value.constructor === Object
}

function isNumeric (value) {
  return !isNaN(parseInt(value))
}

function isBoolean (value) {
  return value === 'true' || value === 'false'
}

function parseValue (value) {
  if (typeof value === 'undefined' || value === '') {
    return null
  } else if (isObject(value)) {
    return parseObject(value)
  } else if (isNumeric(value)) {
    return Number(value)
  } else if (isBoolean(value)) {
    return value === 'true'
  } else {
    return value
  }
}
