import React, { createRef, useState } from 'react'
import classNames from 'classnames'

/**
 * A string input field
 *
 * @param {string} className - CSS class name
 * @param {string} name - The name of the input
 * @param {number} value - The value of the field
 * @param {boolean} maxBoundary - The maximium value displayable for the input field
 * @param {number} step - The step value of the input
 * @param {Function} onBlur - Triggered when the input loses focus, removes style on input container
 * @param {Function} onApply - Triggered when user presses 'Enter' key
 * @param {Function} onFocus - Triggered when input is focused, adds style on input container
 * @param {Function} onChange - Triggered when the user changes the value in the
 *  input field - debounced by 500 ms
 * @returns {*}
 * @constructor
 */

export function Input ({ className, name, value, step, onBlur, onApply, onFocus, onChange, maxBoundary }) {
  const ref = createRef()
  const [inputValue, setInputValue] = useState(value >= maxBoundary ? maxBoundary : value)
  const [plusSign, setPlusSign] = useState(value >= maxBoundary)

  const handleChange = (event) => {
    setPlusSign(false)
    let value = event.target.value
    let valueIsInvalid = value.match('[^0-9+.]')
    if (valueIsInvalid) {
      value = value.replace(/[^0-9+.]/, '')
    }
    setInputValue(value)
  }

  const handleBlur = (event) => {
    let val = parseFloat(event.target.value)
    val = val >= maxBoundary ? maxBoundary : val
    if (!isNaN(val)) {
      val >= maxBoundary ? setPlusSign(true) : setPlusSign(false)
      setInputValue(val)
      if (val !== value) {
        onChange(val)
      }
    }
    onBlur()
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      let val = parseFloat(event.target.value)
      if (val !== value && !isNaN(val)) onChange(val)
      onApply()
    }
  }

  return (
    <input
      ref={ref}
      type='text'
      name={name}
      step={step || undefined}
      className={classNames('Input', className)}
      onChange={handleChange}
      value={inputValue + `${plusSign ? '+' : ''}`}
      onBlur={handleBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      autoComplete='off'
    />
  )
}

Input.defaultProps = {
  onChange: () => undefined,
  onBlur: () => undefined
}
