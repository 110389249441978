import {
  DISTRICT_PIN_CLICKED,
  DISTRICT_POPUP_OPENED
} from '../../../../../actions/map'
import { DISTRICTS_DATA_FILTERED } from '../../../../../actions/districtsFilters'
import {
  adjustCenterForPopup,
  calculateOffsetY,
  calculateCenter
} from '../../../../../utils/mapUtils'

const initialState = [-98.5795, 39.8283]

export default function (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case DISTRICTS_DATA_FILTERED:
      return calculateCenter(payload.fitBounds)
    case DISTRICT_PIN_CLICKED:
      return [...payload.coordinates]
    case DISTRICT_POPUP_OPENED:
      const { node, map } = payload
      const boundingBox = node.getBoundingClientRect()
      const mapBoundingBox = map._container.getBoundingClientRect()
      const offsetY = calculateOffsetY(mapBoundingBox, boundingBox) * -1
      const center = adjustCenterForPopup(map, offsetY, state)
      return center.toArray()
    default:
      return state
  }
}
