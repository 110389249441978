import Honeybadger from 'honeybadger-js'
import { RECEIVE_DISTRICT_ERROR } from '../actions/district'
import { RECEIVE_DISTRICTS_ERROR } from '../actions/districts'
import { RECEIVE_DISTRICT_SERVICES_ERROR } from '../actions/districtServices'
import { RECEIVE_DISTRICTS_NEARBY_ERROR } from '../actions/districtsNearby'
import { RECEIVE_HISTORICAL_NATION_METRICS_ERROR } from '../actions/historicalNationMetrics'
import {
  RECEIVE_HISTORICAL_STATE_METRICS_ERROR,
  RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR
} from '../actions/historicalStateMetrics'
import { RECEIVE_NATION_ERROR } from '../actions/nation'
import { RECEIVE_NEARBY_SERVICES_ERROR } from '../actions/nearbyServices'
import { RECEIVE_STATE_METRICS_ERROR } from '../actions/stateMetrics'
import { RECEIVE_STATES_METRICS_ERROR } from '../actions/statesMetrics'

import { httpErrorResponse } from '../actions'

const API_REQUEST_ERRORS = [
  RECEIVE_DISTRICT_ERROR,
  RECEIVE_DISTRICTS_ERROR,
  RECEIVE_DISTRICT_SERVICES_ERROR,
  RECEIVE_DISTRICTS_NEARBY_ERROR,
  RECEIVE_HISTORICAL_NATION_METRICS_ERROR,
  RECEIVE_HISTORICAL_STATE_METRICS_ERROR,
  RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR,
  RECEIVE_NATION_ERROR,
  RECEIVE_NEARBY_SERVICES_ERROR,
  RECEIVE_STATE_METRICS_ERROR,
  RECEIVE_STATES_METRICS_ERROR
]

const httpError = store => next => action => {
  const { type, payload } = action

  next(action)

  if (API_REQUEST_ERRORS.includes(type)) {
    Honeybadger.notify(type, {
      context: {
        api: process.env.REACT_APP_BODHI_URL,
        error: payload.error,
        status: payload.status
      }
    })

    store.dispatch(httpErrorResponse(payload))
  }
}

export default httpError
