import React from 'react'
import PropTypes from 'prop-types'
import PercentMeetingGoalPDF from './PercentMeetingGoalPDF'
import ContractsExpiringPDF from './ContractsExpiringPDF'
import ErateAppliedFundingPDF from './ErateAppliedFundingPDF'
import GovernorQuoteBoxPDF from './GovernorQuoteBoxPDF'
import CostOverTimePDF from './CostOverTimePDF'
import StateQuoteBoxPDF from './StateQuoteBoxPDF'
import states from '../../../data/states'
import logoSrc from '../../../assets/connect-k12-logo.png'
import { title } from '..'
import colors from '../../../styles/variables.scss'
import { PRELIM_METRICS_MSG } from '../../PreliminaryMetricsFootnote'

import { StyleSheet, Document, Page, View, Text, Font, Image } from '@react-pdf/renderer'
import fontFamily from '../../../assets/fonts/Muli.ttf'

export default function StateOverviewDashboardPDF ({
  stateMetrics,
  comparisonStateCode,
  selectedStateCode,
  chartData,
  pdfImages
}) {
  const imgStateCode = comparisonStateCode || selectedStateCode

  const hasStateQuote = !!states[selectedStateCode].stateQuote

  // We are using Muli font here because react-pdf currently only supports .ttf
  // file types and we do not have that available for Axiforma.
  Font.register({ family: 'Muli', src: fontFamily })
  Font.registerHyphenationCallback(word => [word])

  const containerStyle = StyleSheet.create({
    margin: '10mm 10mm 0 10mm',
    fontFamily: 'Muli'
  })

  const headerStyle = StyleSheet.create({
    fontSize: '2.5vw',
    fontWeight: 'bold',
    lineHeight: '1.25',
    marginBottom: hasStateQuote ? '2mm' : '5mm'
  })

  const inlineChartContainerStyle = StyleSheet.create({
    display: 'inline-flex',
    flexDirection: 'row'
  })

  const footerStyle = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0mm'
    },
    line: {
      margin: '4vw 0 2vw 0',
      borderBottomColor: colors.colorLightGray1,
      borderBottomWidth: StyleSheet.hairlineWidth
    },
    sourceLogoContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    sourceText: {
      fontSize: '1.5vw',
      lineHeight: '1.5',
      color: colors.colorGray1
    }
  })

  return (
    <Document>
      <Page size='A4'>
        <View style={containerStyle}>
          <Text style={headerStyle}>
            {title(selectedStateCode)}
          </Text>
          <View>
            <View style={inlineChartContainerStyle}>
              <PercentMeetingGoalPDF
                chartData={chartData.percentMeetingGoal}
                chartImage={pdfImages.percentMeetingGoal[imgStateCode]}
                stateCode={selectedStateCode}
                comparisonStateCode={comparisonStateCode}
                hasStateQuote={hasStateQuote}
              />
              <View style={{ marginRight: '5mm' }} />
              <GovernorQuoteBoxPDF stateCode={selectedStateCode} />
            </View>
            <View style={inlineChartContainerStyle}>
              <ContractsExpiringPDF
                stateCode={selectedStateCode}
                stateMetrics={stateMetrics}
                hasStateQuote={hasStateQuote}
              />
              <ErateAppliedFundingPDF
                chartData={chartData.erateAppliedFunding}
                chartImage={pdfImages.erateAppliedFunding[selectedStateCode]}
                hasStateQuote={hasStateQuote}
              />
            </View>
            <CostOverTimePDF
              chartData={chartData.costOverTime}
              chartImage={pdfImages.costOverTime[imgStateCode]}
              stateCode={selectedStateCode}
              comparisonStateCode={comparisonStateCode}
              hasStateQuote={hasStateQuote}
            />
            {hasStateQuote && <StateQuoteBoxPDF stateCode={selectedStateCode} hasStateQuote={hasStateQuote} /> }
          </View>
          <View style={footerStyle.container}>
            <View style={footerStyle.line} />
            <View style={footerStyle.sourceLogoContainer}>
              <Text style={footerStyle.sourceText}>
                Source: USAC Form 471 E-rate applications. 1 Mbps = 1,000 kbps.
              </Text>
              <Image src={logoSrc} />
            </View>
            <Text style={footerStyle.sourceText}>
                www.connectk12.org {PRELIM_METRICS_MSG}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

StateOverviewDashboardPDF.propTypes = {
  stateMetrics: PropTypes.shape({
    percentProjectedMeetingGoalIncrease: PropTypes.number,
    contractsExpiring: PropTypes.number
  }),
  comparisonStateCode: PropTypes.string,
  selectedStateCode: PropTypes.string.isRequired,
  chartData: PropTypes.shape({
    percentMeetingGoal: PropTypes.object,
    contractsExpiring: PropTypes.object,
    costOverTime: PropTypes.object,
    erateAppliedFunding: PropTypes.object
  }).isRequired,
  pdfImages: PropTypes.shape({
    percentMeetingGoal: PropTypes.object,
    contractsExpiring: PropTypes.object,
    costOverTime: PropTypes.object,
    erateAppliedFunding: PropTypes.object
  }).isRequired
}
