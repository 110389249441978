import React from 'react'
import PropTypes from 'prop-types'

function Header ({ header, column, classNames = ['Table__header'], style = {}, children }) {
  return (
    <th scope='col' id={column} className={[...classNames]} style={style}>
      <div className='Header'>
        <span>
          {header}
          {children}
        </span>
      </div>
    </th>
  )
}

Header.propTypes = {
  header: PropTypes.string,
  column: PropTypes.string,
  classNames: PropTypes.array
}

export default Header
