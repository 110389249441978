import React from 'react'
import PropTypes from 'prop-types'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { circlePaintOptions } from './layerStyles'
import { geoJSON } from '../../propTypes'
import { getEventFeature, hidePointer, showPointer } from '../../utils/mapUtils'

function GeoJSONDataLayer ({
  highlightId,
  selectedId,
  sourceId,
  sourceData,
  onClick,
  onMouseEnter,
  onMouseLeave,
  map,
  ...props
}) {
  if (!map) return null

  function handleClick (event) {
    handleMouseLeave(event)
    onClick(getEventFeature(event))
  }

  function handleMouseLeave (event) {
    onMouseLeave()
    hidePointer(event.target)
  }

  function handleMouseEnter (event) {
    const feature = getEventFeature(event)
    onMouseEnter(feature)
    showPointer(event.target)
  }

  return (
    <GeoJSONLayer
      id={sourceId}
      data={sourceData}
      circlePaint={circlePaintOptions(highlightId, selectedId)}
      circleOnMouseEnter={handleMouseEnter}
      circleOnMouseLeave={handleMouseLeave}
      circleOnClick={handleClick}
      symbolOnMouseEnter={handleMouseEnter}
      symbolOnMouseLeave={handleMouseLeave}
      symbolOnClick={handleClick}
      {...props}
    />
  )
}

GeoJSONDataLayer.propTypes = {
  sourceId: PropTypes.string.isRequired,
  sourceData: geoJSON.featureCollection.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

export default GeoJSONDataLayer
