import { LOCATION_CHANGE } from 'connected-react-router'
import { loadDistrictsData } from '../actions/districts'
import { getStateMetrics } from '../actions/stateMetrics'
import { stateBandwidthDashboard } from '../routes'

const stateDashboardDataLoader = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { location: { pathname } } = payload
    const match = stateBandwidthDashboard.matchPath(pathname)

    if (match) {
      store.dispatch(loadDistrictsData(match.params.state_code))
      store.dispatch(getStateMetrics(match.params.state_code))
    }
  }
}

export default stateDashboardDataLoader
