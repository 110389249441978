import { RECEIVE_NEARBY_SERVICES } from '../../../../actions/nearbyServices'
import { totalServicesByRecipientBen } from './utils'

export default function (state = {}, action = {}) {
  const { type, payload } = action

  if (type === RECEIVE_NEARBY_SERVICES) {
    const { data } = payload
    return data.reduce(totalServicesByRecipientBen, {})
  }

  return state
}
