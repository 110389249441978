import React from 'react'
import colors from '../../../styles/variables.scss'
import generateChartTooltip from '../../ChartTooltip'
import { formatCost } from '../../../utils/stringHelpers'

const tooltipType = 'CostOverTime__tooltip'

function titleFormatter (title) {
  return `${title} cost/Mbps`
}

export const tooltipHeader = (title, className) => {
  return (
    <tr>
      <th className={`${className}__header ChartTooltip__header`}>
        {titleFormatter(title)}
      </th>
    </tr>
  )
}

function splitTooltipBody (body) {
  const metric = body[0].split(':')
  return {
    type: metric[0],
    key: metric[1],
    value: metric[2]
  }
}

export const tooltipBody = ({ tooltip, className }) => {
  return (
    tooltip.body.map((row, idx) => {
      const metric = splitTooltipBody(row.lines)
      return (
        <tr key={`${metric.value}-${idx}`}>
          <td>
            <span className={`${className}__key--icon ChartTooltip__icon--${metric.type} ChartTooltip__icon`} />
            <div className={`${className}__key ChartTooltip__key`}>{metric.key}</div>
            <div className={`${className}__value ChartTooltip__value`}>{formatCost(metric.value)}</div>
          </td>
        </tr>
      )
    })
  )
}

function _setDynamicOptions (renderMobile) {
  return {
    point: {
      radius: renderMobile ? 8 : 5
    },
    xAxes: {
      fontSize: renderMobile ? 12 : 16
    },
    yAxes: {
      fontSize: renderMobile ? 12 : 14
    }
  }
}

export default function (renderMobile) {
  const dynamicOptions = _setDynamicOptions(renderMobile)
  return (
    {
      elements: {
        point: {
          radius: dynamicOptions.point.radius,
          hitRadius: 10
        }
      },
      tooltips: {
        enabled: false,
        mode: 'x',
        position: 'nearest',
        custom: generateChartTooltip({ tooltipType, tooltipHeader: tooltipHeader, tooltipBody: tooltipBody })
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: dynamicOptions.xAxes.fontSize,
              fontColor: colors.black,
              padding: 10
            },
            gridLines: false
          }
        ],
        yAxes: [
          {
            ticks: {
              fontSize: dynamicOptions.yAxes.fontSize,
              fontColor: colors.colorGray1,
              min: 0,
              padding: 10,
              callback: label => `$${label}`
            },
            position: 'right',
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              lineWidth: 0.5,
              zeroLineColor: colors.colorLightGray1,
              zeroLineWidth: 0.5,
              color: colors.colorLightGray1
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      maintainAspectRatio: false
    }
  )
}
