import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import USAMap from './USAMap'
import StatePopup from './StatePopup'
import './index.sass'
import MapLegend from './legend'
import FCCErateSource from '../../FCCErateSource'
import PreliminaryMetricsFootnote from '../../PreliminaryMetricsFootnote'

const getMapColor = (percentage) => {
  if (percentage > 0.80) return '#000f16'
  if (percentage > 0.60) return '#02557e'
  if (percentage > 0.40) return '#039ce5'
  if (percentage > 0.20) return '#76c8f0'
  if (percentage === null) return '#dfdfdf'
  return '#d1ecfa'
}

const popupTarget = (target) => {
  const popupTarget = target.matches('.StatePopup__link > a')
  return (typeof target.className === 'string') && (target.className.includes('StatePopup') || popupTarget)
}

export default function NationalHeatMap ({ states }) {
  const { data, status } = states
  const [popup, setPopup] = useState({ open: false })

  const handleClickOutside = (event) => {
    const heatmapTarget = event.target.matches('.NationalHeatMap path.state')
    if (!heatmapTarget && !popupTarget(event.target)) {
      setPopup({ open: false })
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  if (status !== 'LOADED') return null

  const handleMouseOver = (state) => (event) => {
    const x = event.clientX + window.pageXOffset
    const y = event.clientY + window.pageYOffset
    setPopup({
      stateCode: state.state_code,
      location: { x, y },
      metric: state.percent_districts_meeting_goal,
      open: popup.stateCode !== state.state_code || !popup.open
    })
  }

  const stateOptions = data.reduce((stateOptions, state) => {
    stateOptions[state.state_code] = {
      fill: getMapColor(state.percent_districts_meeting_goal),
      onMouseOver: handleMouseOver(state)
    }
    return stateOptions
  }, {})

  return (
    <div className='NationalHeatMap'>
      <div className='NationalHeatMap__title'>
        75% of school districts nationwide are meeting the FCC recommended bandwidth of 1 Mbps per student
      </div>
      <div className='NationalHeatMap__subtitle'>
        % of school districts meeting 1 Mbps/student by state
      </div>
      <StatePopup popup={popup} />
      <div className='NationalHeatMap__chart'>
        <USAMap stateOptions={stateOptions} />
      </div>
      <MapLegend />
      <FCCErateSource className='NationalHeatMap__source' />
      <PreliminaryMetricsFootnote className='NationalHeatMap__footnote' />
    </div>)
}

NationalHeatMap.propTypes = {
  states: PropTypes.object
}
