import React from 'react'
import classNames from 'classnames'
import { InputWithUnits, Input } from '.'
import {
  UNITS,
  getUnitConverter,
  getUnitInverter
} from '../../../utils/bandwidthHelpers'
import './CircuitSize.sass'

export function CircuitSize ({ name, value, className, onChange, onApply, maxBoundary }) {
  const convert = getUnitConverter(UNITS.Gbps)
  const invert = getUnitInverter(UNITS.Gbps)
  const converted = convert(value)

  const Units = () =>
    <div className='CircuitSize__units'>
      <div className='CircuitSize__unit-container'>
        <div className='CircuitSize__unit'>
          {UNITS.Gbps}
        </div>
      </div>
    </div>

  const CircuitSizeInput = ({ value, ...args }) =>
    <Input name={name} value={converted} maxBoundary={(maxBoundary / 1000)} {...args} />

  return (
    <InputWithUnits
      className={classNames('CircuitSize__input', className)}
      name={name}
      value={converted}
      input={CircuitSizeInput}
      units={Units}
      unitPosition='right'
      onChange={val => onChange(invert(val))}
      onApply={onApply}
    />
  )
}
