import * as routes from '../../../routes'
import { LOCATION_CHANGE } from 'connected-react-router'
import { RECEIVE_DISTRICT } from '../../../actions/district'
import { NAV_PROMPT_OPENED, NAV_PROMPT_CLOSED } from '../../../actions/navbar'
import { DOCUMENT_CLICKED } from '../../../actions'

export const initialState = {
  stateCode: null,
  navPromptOpen: false,
  navPromptType: null,
  prompt: null
}

function navbar (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      const { location: { pathname } } = payload
      const route = routes.statePage(pathname)
      return {
        ...state,
        stateCode: (route && route.params.state_code) || null,
        navPromptOpen: false,
        prompt: null
      }
    case RECEIVE_DISTRICT:
      const stateCode = payload.data.state_code
      return { ...state, stateCode }
    case NAV_PROMPT_OPENED:
      return {
        ...state,
        navPromptType: payload.type,
        navPromptOpen: true,
        prompt: payload.prompt,
        leftOffset: payload.leftOffset
      }
    case NAV_PROMPT_CLOSED:
    case DOCUMENT_CLICKED:
      return {
        ...state,
        navPromptOpen: false,
        prompt: null
      }
    default:
      return state
  }
}

export default navbar
