import { useEffect } from 'react'
import debounce from 'lodash.debounce'

// see: https://lodash.com/docs#debounce
export function useWindowResize (callback, wait = 200, debounceOpts = {}) {
  useEffect(() => {
    const debounced = debounce(callback, wait, debounceOpts)
    window.addEventListener('resize', debounced)

    return function cleanup () {
      window.removeEventListener('resize', debounced)
    }
  })
}
