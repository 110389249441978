import { sortObjects, ASC } from '../../../../utils'

export function servicesByRecipientBen (byBen, service) {
  const ben = service.recipient_district_ben
  byBen[ben] = byBen[ben] || []
  byBen[ben].push(service)
  return byBen
}

export function totalServicesByRecipientBen (byBen, service) {
  const ben = service.recipient_district_ben
  byBen[ben] = byBen[ben] || { totalServices: 0 }
  byBen[ben].totalServices += 1
  return byBen
}

export function nearestTenServices (services, district = {}) {
  function jsonCoords ({ latitude, longitude }) {
    return JSON.stringify({ latitude, longitude })
  }

  const sorted = sortObjects(services, 'distance', { direction: ASC })
  let nearest = [...new Set(sorted.map(jsonCoords))].slice(0, 10).map(JSON.parse)

  // include districtService for the case when there are no nearbyServices
  if (district) {
    nearest.push({
      latitude: district.latitude,
      longitude: district.longitude
    })
  }

  return nearest
}

export function addDefaultDistrictService (districtServices, selectedDistrict) {
  if (districtServices.length === 0) {
    return [{
      latitude: selectedDistrict.latitude,
      longitude: selectedDistrict.longitude,
      recipient_district_name: selectedDistrict.entity_name,
      recipient_district_ben: selectedDistrict.entity_number,
      recipient_district_fit_for_ia: false,
      service_fit_for_cost: false,
      selected: true
    }]
  }
  return districtServices
}
