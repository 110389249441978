import { LOCATION_CHANGE } from 'connected-react-router'
import deepmerge from 'deepmerge'
import commonState from './commonState'
import applyRangeParams from '../../filters/transformations/applyRangeParams'
import URLGuard from '../../filters/URLGuard'
import routes from '../../../../routes'

export const initialState = deepmerge(commonState, {
  options: {
    min: {
      boundary: 0,
      value: 0
    },
    max: {
      boundary: 3,
      value: 3
    }
  }
})

const applyURLParams = URLGuard(
  routes.stateBandwidthDashboard.matchPath,
  applyRangeParams('bandwidth')(initialState)
)

export default (state = initialState, action = {}) => {
  const { type } = action

  if (type === LOCATION_CHANGE) {
    return applyURLParams(state, action)
  }
  return state
}
