import districts from '../shared/districts'
import stateMetrics from '../shared/stateMetrics'
import meetingGoalDistricts from '../shared/meetingGoalDistricts'
import combine from '../combine'

export default combine({
  districts,
  meetingGoalDistricts,
  stateMetrics
})
