import {
  DISTRICT_PIN_CLICKED,
  DISTRICT_POPUP_CLOSED
} from '../../../../../actions/map'

const initialState = {}

export default function (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case DISTRICT_PIN_CLICKED:
      return {
        type: payload.goalStatus,
        ...JSON.parse(payload.district)
      }
    case DISTRICT_POPUP_CLOSED:
      return payload
    default:
      return state
  }
}
