import React, { createRef } from 'react'
import { defaults, Doughnut } from 'react-chartjs-2'

export default function ChartDoughnut ({ data, options = () => { return {} }, afterDrawPlugin = () => {} }) {
  defaults.global.defaultFontFamily = 'Axiforma-Regular'
  const chartRef = createRef()
  const plugins = [{ afterDraw: afterDrawPlugin }]

  return (
    <Doughnut
      ref={chartRef}
      data={data}
      plugins={plugins}
      options={{
        cutoutPercentage: 75,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        hover: { mode: null },
        ...options(chartRef)
      }} />
  )
}
