import bandwidth from './bandwidth'
import contract from './contract'
import cost from './cost'
import provider from './provider'
import size from './size'
import modal from './modal'
import filters from '../../filters'
import initialState, { filterId } from './initialState'
import { combineReducers } from 'redux'

const reducers = combineReducers({
  bandwidth,
  contract,
  cost,
  provider,
  size,
  modal
})

const sharedActions = filters(initialState, filterId)

export default (state = initialState, action = {}) => {
  return sharedActions(reducers(state, action), action)
}
