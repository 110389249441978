import { combineReducers } from 'redux'
import selected from './selected'
import options from './options'

const initialState = {
  selected: {},
  options: []
}

export default combineReducers({
  initialState,
  selected,
  options
})
