import { combineReducers } from 'redux'
import popup from './popup'
import fitBounds from './fitBounds'
import center from './center'
import pins from './pins'
import tooltipId from './tooltipId'
import resetZoom from './resetZoom'

const initialState = {
  popup: {},
  fitBounds: [],
  center: [],
  pins: {},
  tooltipId: null,
  resetZoom: true
}

export default combineReducers({
  initialState,
  resetZoom,
  popup,
  fitBounds,
  center,
  pins,
  tooltipId
})
