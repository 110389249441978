import commonState from './commonState'
import { FILTER_NEARBY_SERVICES } from '../../../../actions/nearbyServicesFilters'

export const initialState = { ...commonState, open: false, numApplied: 0 }

const countFiltersApplied = {
  purpose: (appliedFilter) => Object.values(appliedFilter).filter(filter => !!filter).length,
  provider: (appliedFilter) => appliedFilter.length
}

function calculateTotalFiltersApplied (filters) {
  let numApplied = 0
  for (const filter in filters) {
    if (!filters[filter].applied) continue
    if (countFiltersApplied[filter]) {
      numApplied += countFiltersApplied[filter](filters[filter].applied)
    } else {
      numApplied += 1
    }
  }
  return numApplied
}

export default (state = initialState, action) => {
  if (action.type === FILTER_NEARBY_SERVICES) {
    return {
      ...state,
      numApplied: calculateTotalFiltersApplied(action.payload)
    }
  }
  return state
}
