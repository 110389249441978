import React from 'react'
import RangeFilter from './RangeFilter'
import * as Input from './Inputs'
import { formatNumber, formatRangeWith } from '../../utils/stringHelpers'
import './Size.sass'

function Header () {
  return (
    <div className='Filter__options--header SizeFilter__header'>
      <strong>District Size</strong>
      <p>The number of students in a school district</p>
    </div>
  )
}

function Size ({ size, ...props }) {
  const step = 100

  return (
    <RangeFilter
      range={size}
      step={step}
      label='District Size'
      appliedLabel={formatRangeWith(formatNumber)(size) + ' students'}
      header={Header}
      minInput={props => <Input.Size name='min' {...props} />}
      maxInput={props => <Input.Size name='max' {...props} />}
      {...props} />
  )
}

export default Size
