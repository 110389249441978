import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import State from '../shared/State'
import District from '../shared/District'
import NationalLink from '../shared/NationalLink'
import ContactUs from '../shared/ContactUs'
import Faq from '../shared/Faq'
import './index.sass'

export default function DesktopMenu ({ stateCode, onNavPromptOpened, stateMetrics }) {
  const { pathname } = useLocation()
  const handleStateMenuClick = (props) => {
    if (!stateCode) onNavPromptOpened(props)
  }
  return (
    <div className='Navbar__desktop'>
      <NationalLink pathname={pathname} />
      <State stateMetrics={stateMetrics} stateCode={stateCode} pathname={pathname} onClick={handleStateMenuClick} />
      <District pathname={pathname} onClick={onNavPromptOpened} />
      <Faq pathname={pathname} />
      <ContactUs pathname={pathname} />
    </div>
  )
}

DesktopMenu.propTypes = {
  stateCode: PropTypes.string,
  onNavPromptOpened: PropTypes.func.isRequired
}
