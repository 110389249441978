import { LOCATION_CHANGE } from 'connected-react-router'
import {
  RECEIVE_DISTRICTS_SEARCH,
  REQUEST_GLOBAL_SEARCH
} from '../../../actions/globalSearch'
import * as statuses from '../../status'
import { MIN_SEARCH_LENGTH } from './data/districts'

const initialState = { status: statuses.INITIALIZED, locationOnRequest: null }

export default function status (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_GLOBAL_SEARCH:
      if (payload.searchTerm.length < MIN_SEARCH_LENGTH) return state
      return { status: statuses.REQUESTED, locationOnRequest: window.location.pathname }
    case RECEIVE_DISTRICTS_SEARCH:
      if (state.locationOnRequest !== window.location.pathname) return initialState
      return { status: statuses.LOADED }
    case LOCATION_CHANGE:
      return initialState
    default:
      return state
  }
}
