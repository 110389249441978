import actions from '../../../actions/districtsNearby'
import * as status from '../../status'

const initialState = {
  data: [],
  latitude: '',
  longitude: '',
  radius: '',
  status: status.INITIALIZED
}

function districtsNearby (state = initialState, action = {}) {
  const { type, payload, meta } = action

  switch (type) {
    case actions.REQUEST_DISTRICTS_NEARBY:
      return { ...{ data: state.data, status: status.REQUESTED }, ...meta }

    case actions.RECEIVE_DISTRICTS_NEARBY:
      return { ...{ data: payload, status: status.LOADED }, ...meta }

    case actions.RECEIVE_DISTRICTS_NEARBY_ERROR:
      return {
        ...{
          data: [],
          error: payload.message || 'Error requesting nearby districts',
          status: status.ERROR
        },
        ...meta
      }

    default:
      return state
  }
}

export default districtsNearby
