import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../actions/nearbyServicesFilters'
import { getCoordinates, getCostPerMbpsRange, geoJSONDoc } from '../../../../utils/mapUtils'
import { servicesByRecipientBen, addDefaultDistrictService } from './utils'
import { formatCostPerMbps } from '../../../../utils/stringHelpers'

function districtServicesFeature (services) {
  const isRecommended = service => !!service.recommended
  const selectedDistrictNotFitForIA = services => {
    return services[0].selected && !services[0].recipient_district_fit_for_ia
  }
  const costRange = selectedDistrictNotFitForIA(services) ? [] : getCostPerMbpsRange(services)
  const label = (costRange.length === 0) || selectedDistrictNotFitForIA(services)
    ? null
    : formatCostPerMbps(costRange[0], { count: costRange.length })
  const service = services[0]
  const coordinates = getCoordinates(service)

  if (coordinates.length === 0) { return }

  return {
    type: 'Feature',
    id: service.recipient_district_ben,
    geometry: {
      type: 'Point',
      coordinates
    },
    properties: {
      id: service.recipient_district_ben,
      name: service.recipient_district_name,
      recommended: services.some(isRecommended),
      selected: service.selected || false,
      label,
      costRange
    }
  }
}

export function makeFeatures (servicesByDistrict) {
  return Object
    .values(servicesByDistrict)
    .map(districtServicesFeature)
    .filter(x => !!x)
}

const addSelectedProperty = (services = []) => {
  return services.map(service => {
    return { ...service, selected: true }
  })
}

export default function (state = geoJSONDoc(), action = {}) {
  const { type, payload } = action

  if (type !== NEARBY_SERVICES_DATA_FILTERED) {
    return state
  }
  const { nearby, districtServices, selectedDistrict } = payload
  const adjustedDistrictServices = addDefaultDistrictService(districtServices, selectedDistrict)
  const services = [...new Set([...addSelectedProperty(adjustedDistrictServices), ...nearby])]

  return geoJSONDoc({
    features: makeFeatures(services.reduce(servicesByRecipientBen, {}))
  })
}
