import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from './Checkbox'
import './PurposeFilter.sass'
import { formatPurpose } from '../../../../utils/stringHelpers'

function Option ({ filter, option, checked, groupId, onChange }) {
  const inputId = `mf-${option}`
  const labelId = `mf-label-${option}`

  return (
    <label className='PurposeFilter__label' htmlFor={inputId}>
      <Checkbox name={filter} value={option} onChange={onChange} checked={checked}
        aria-labelledby={`${labelId} ${groupId}`} id={inputId} />
      <span className='PurposeFilter__label--text' id={labelId}>{formatPurpose(option)}</span>
    </label>
  )
}

Option.propTypes = {
  filter: PropTypes.string,
  option: PropTypes.string,
  checked: PropTypes.bool,
  groupId: PropTypes.string,
  onChange: PropTypes.func
}

export default Option
