import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import './index.sass'
import MeetingGoal from './MeetingGoal'
import MissingData from './MissingData'
import NotMeetingGoal from './NotMeetingGoal'
import { types } from '../../../reducers/shared/districts/data/map/pins'

const DistrictPopup = forwardRef((props, ref) => {
  const { data: { type } } = props

  return (
    <div className='DistrictPopup__popup' ref={ref}>
      {type === types.MEETING_GOAL && <MeetingGoal {...props} />}
      {type === types.NOT_MEETING_GOAL && <NotMeetingGoal {...props} />}
      {type === types.MISSING_DATA && <MissingData {...props} />}
    </div>
  )
})

export default DistrictPopup

DistrictPopup.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
}
