import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../../Skeleton'
import states from '../../../data/states'
import MeetingGoalModal from '../MeetingGoalModal'
import * as statuses from '../../../reducers/status'
import { NEARBY, STATE } from '../MeetingGoalModal/utils'
import { formatNumber, NBSP } from '../../../utils/stringHelpers'
import './index.sass'

const stateMetricsContent = (placeholder, nearby, stateMetrics) => {
  const { data, status: stateStatus } = stateMetrics
  const { state_code: stateCode = '' } = data
  const stateName = (stateCode && states[stateCode].stateName) || ''
  const dataLoaded = (stateStatus === statuses.LOADED) && (nearby.status === statuses.LOADED)
  const nearbyMetric = (placeholder || !dataLoaded) ? <Skeleton /> : `${nearby.data.length} nearby`
  const stateMetric = (placeholder || !dataLoaded) ? <Skeleton /> : `${formatNumber(data.num_districts_meeting_goal)}`

  return [
    [NEARBY, nearbyMetric, `school districts within${NBSP}${nearby.radius} miles`, 'See who nearby'],
    [STATE, stateMetric, `school districts in${NBSP}${stateName}`, `See who in ${stateCode}`]
  ]
}

export default function StateMetrics ({ placeholder, stateMetrics, meetingGoalDistricts }) {
  const [tab, setTab] = useState(null)
  const { nearby } = meetingGoalDistricts

  return (
    <div className='StateMetrics'>
      <h2 className='StateMetrics__title'>
        School districts meeting the 1 Mbps per student&nbsp;goal
      </h2>
      <div className='StateMetrics__metrics'>
        {
          stateMetricsContent(placeholder, nearby, stateMetrics).map((metric, key) => {
            return (
              <div className='StateMetrics__metric' key={`StateMetrics__${key}}`}>
                <div className='StateMetrics__metric--value'>{metric[1]}</div>
                <div className='StateMetrics__metric--description'>{metric[2]}</div>
                <div tabIndex={0} className='StateMetrics__metric--link' onClick={() => { setTab(metric[0]) }}>{metric[3]}</div>
              </div>
            )
          })
        }
      </div>
      <MeetingGoalModal
        tab={tab}
        setTab={setTab}
        stateMetrics={stateMetrics}
        meetingGoalDistricts={meetingGoalDistricts}
      />
    </div>
  )
}

StateMetrics.propTypes = {
  placeholder: PropTypes.bool,
  state: PropTypes.shape({
    data: PropTypes.object,
    status: PropTypes.oneOf(Object.values(statuses))
  }),
  nearby: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.oneOf(Object.values(statuses)),
    radius: PropTypes.number
  })
}
