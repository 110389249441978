const allUsageProperties = {
  shopping: 'Price Shopping',
  reporting: 'Reporting/Analysis',
  press: 'Press',
  advocacy: 'Education Advocacy',
  other: 'Other'
}

function buildHtmlForUsage (usageProperties, otherReason = '') {
  let innerHtml = ''
  usageProperties.forEach((property) => {
    if (property === 'other') {
      innerHtml = innerHtml.concat(`<li>${allUsageProperties[property]}: ${otherReason}</li>`)
    } else {
      innerHtml = innerHtml.concat(`<li>${allUsageProperties[property]}</li>`)
    }
  })
  return '<div><h2>I am using Connect K-12 for:</h2><ul>'.concat(innerHtml).concat('</ul></div>')
}

function getUsageProperties (formData) {
  let usageProperties = []
  Object.keys(allUsageProperties).forEach((property) => {
    if (formData[property]) { usageProperties.push(property) }
  })
  return usageProperties
}

function buildEmailBody (formData) {
  const title = '<h1>Contact Form Submission</h1>'
  const userDl = `<dl><dt>Name:</dt><dd>${formData.name}</dd><dt>Email:</dt><dd>${formData.email}</dd><dt>Topic:</dt><dd>${formData.topic}</dd></dl>`
  const userInfo = `<div><h2>User Information:</h2>${userDl}</div>`
  const msg = `<div><h2>Message:</h2><p>${formData.message}</p></div>`
  const usageProperties = getUsageProperties(formData)
  const usageHtml = buildHtmlForUsage(usageProperties, formData.otherReason)
  return title.concat(userInfo).concat(msg).concat(usageHtml)
}

function buildSubject (topic, email) {
  return `${topic} Contact Form Submission from ${email}`
}

export function buildTemplateParams (formData) {
  let params = {
    toEmail: process.env.REACT_APP_EMAILJS_TO_EMAIL,
    subject: buildSubject(formData.topic, formData.email),
    body: buildEmailBody(formData),
    environment: 'JAM Pupline'
  }
  return params
}
