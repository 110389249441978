import { createContext, useContext } from 'react'

const WindowDimensionsCtx = createContext(null)

const useWindowDimensionsCtx = () => useContext(WindowDimensionsCtx)

export {
  WindowDimensionsCtx,
  useWindowDimensionsCtx
}
