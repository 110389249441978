import {
  SYNC_RECENT_SEARCHES
} from '../../../../actions/globalSearch'
import { LOCATION_CHANGE } from 'connected-react-router'

const recentSearches = (state = [], action = {}) => {
  const { type } = action
  switch (type) {
    case SYNC_RECENT_SEARCHES:
    case LOCATION_CHANGE:
      return JSON.parse(window.sessionStorage.getItem('recentSearches')) || []
    default:
      return state
  }
}

export default recentSearches
