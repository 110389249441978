import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { onDistrictPage } from '../../../routes'
import districtIcon from '../../../assets/icon-24-icon-district-gray-1.svg'
import districtSelected from '../../../assets/icon-24-icon-district-secondary-3.svg'
import MenuItem from './MenuItem'
import { useMobileLeftOffset, calculateDesktopLeftOffset } from './useMobileLeftOffset'

export const PROMPT = 'Select a school district to view its connectivity dashboard'

export default function District ({ pathname, onClick, mobile = false }) {
  const ref = createRef()
  const icon = onDistrictPage(pathname) ? districtSelected : districtIcon
  const mobileLeftOffset = useMobileLeftOffset()

  const handleClick = districtRef => e => {
    e.stopPropagation()
    const menuItem = districtRef.current.getBoundingClientRect()
    const offset = !mobile
      ? calculateDesktopLeftOffset(menuItem)
      : mobileLeftOffset
    onClick({ type: 'DISTRICT', prompt: PROMPT, leftOffset: offset })
  }

  return (
    <div className='Navbar__link Navbar__link--district' onClick={handleClick(ref)} ref={ref}>
      <MenuItem
        icon={icon}
        selected={onDistrictPage(pathname)}
        mobile={mobile}>
        School District
      </MenuItem>
    </div>
  )
}

District.propTypes = {
  pathname: PropTypes.string,
  onClick: PropTypes.func,
  mobile: PropTypes.bool
}
