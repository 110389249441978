import {
  RECEIVE_NEARBY_SERVICES,
  SORT_NEARBY_SERVICES
} from '../../../../../actions/nearbyServices'
import { FILTER_NEARBY_SERVICES, FILTER_NEARBY_SERVICES_BY_PROVIDER } from '../../../../../actions/nearbyServicesFilters'
import { standardFilters, providerFilters } from './userControlledFilters'
import sortObjects from '../../../../../utils/sortHelpers'

export default function (state = [], action = {}) {
  const { type, payload } = action

  switch (type) {
    case RECEIVE_NEARBY_SERVICES:
      return payload.data
    case FILTER_NEARBY_SERVICES:
      return standardFilters(payload, state)
    case FILTER_NEARBY_SERVICES_BY_PROVIDER:
      return providerFilters(payload.filters, state)
    case SORT_NEARBY_SERVICES:
      return sortObjects([...state], payload.value, payload)
    default:
      return state
  }
}
