import { initialState as cost } from './cost'
import { initialState as bandwidth } from './bandwidth'
import { initialState as provider } from './provider'
import { initialState as purpose } from './purpose'
import { initialState as size } from './size'
import { initialState as modal } from './modal'

export const filterId = 'NEARBY_SERVICES'

export default {
  cost,
  provider,
  purpose,
  bandwidth,
  size,
  modal
}
