import { LOCATION_CHANGE } from 'connected-react-router'
import {
  CALCULATE_STATE_BANDWIDTH_CHART_DATA,
  RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS,
  PERCENT_MEETING_GOAL_VIEW_UPDATED,
  CALCULATE_COMPARISON_HISTORICAL_STATE_METRICS
} from '../../../../actions/historicalStateMetrics'
import { CALCULATE_NATION_CHART_DATA } from '../../../../actions/historicalNationMetrics'
import { STATE_COMPARISON_CLEARED } from '../../../../actions/stateOverviewDashboard'
import { formatPercent } from '../../../../utils/stringHelpers'
import fundingYears from '../../../../data/fundingYears'
import { formatStateMetric } from '../../../shared/utils'

export const initialState = {
  view: 'districts',
  title: null,
  subtitle: null,
  stateData: [],
  nationData: [],
  stateComparisonData: null,
  numDistrictsNotMeetingGoal: null,
  historicalStateComparisonData: {}
}

export default function percentMeetingGoal (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case CALCULATE_STATE_BANDWIDTH_CHART_DATA:
      const { numDistrictsNotMeetingGoal } = payload.data
      const stateData = state.view === 'districts'
        ? payload.data.percentDistrictsMeetingGoal.slice(Math.max(payload.data.percentDistrictsMeetingGoal.length - 5, 1))
        : payload.data.percentStudentsMeetingGoal.slice(Math.max(payload.data.percentStudentsMeetingGoal.length - 5, 1))
      const stateCode = payload.stateCode

      return {
        ...state,
        title: title(stateData, stateCode, state.view),
        subtitle,
        stateData,
        numDistrictsNotMeetingGoal
      }

    case CALCULATE_NATION_CHART_DATA: {
      const nationData = state.view === 'districts'
        ? payload.data.percentDistrictsMeetingGoal
        : payload.data.percentStudentsMeetingGoal

      return {
        ...state,
        nationData
      }
    }

    case CALCULATE_COMPARISON_HISTORICAL_STATE_METRICS:
      if (!state.historicalStateComparisonData) return { ...state }
      const stateComparisonData = state.view === 'districts'
        ? state.historicalStateComparisonData.percentDistrictsMeetingGoal
        : state.historicalStateComparisonData.percentStudentsMeetingGoal

      return {
        ...state,
        stateComparisonData
      }

    case RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS:
      const historicalStateComparisonData = {
        percentDistrictsMeetingGoal: [...payload.data].map(formatStateMetric('percent_districts_meeting_goal')),
        percentStudentsMeetingGoal: [...payload.data].map(formatStateMetric('percent_students_meeting_goal'))
      }
      return {
        ...state,
        historicalStateComparisonData
      }

    case STATE_COMPARISON_CLEARED:
    case LOCATION_CHANGE:
      return {
        ...state,
        stateComparisonData: null
      }

    case PERCENT_MEETING_GOAL_VIEW_UPDATED:
      return {
        ...state,
        view: payload.view
      }

    default:
      return state
  }
}

export const title = (percentMeetingGoal, stateCode, view) => {
  const currentData = percentMeetingGoal[percentMeetingGoal.length - 1]
  const prefix = fundingYears.current > currentData.fundingYear ? `In ${currentData.fundingYear}, ` : ''
  const verb = prefix ? 'were' : 'are'
  const fmtPercentMeetingGoal = formatPercent(currentData.data)
  const viewText = view === 'districts' ? 'school districts' : 'students'
  return `${prefix}${fmtPercentMeetingGoal} of ${stateCode} ${viewText} ${verb} at 1 Mbps/student`
}

export const subtitle = 'Progress toward the FCC recommended bandwidth goal'
