import { connect } from 'react-redux'
import { navPromptOpened, navPromptClosed } from '../../actions/navbar'
import { getStateMetrics } from '../../selectors/dataSelectors'
import Desktop from '../../components/Navbar/Desktop'
import Mobile from '../../components/Navbar/Mobile'

const mapStateToProps = (state, ownProps) => {
  return {
    stateCode: state.navbar.stateCode,
    navPromptOpen: state.navbar.navPromptOpen,
    stateMetrics: getStateMetrics(state, state.navbar.stateCode)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onNavPromptOpened: ({ type, prompt, leftOffset }) => {
      dispatch(navPromptOpened({ type, prompt, leftOffset }))
    },
    onNavPromptClosed: () => {
      dispatch(navPromptClosed())
    }
  }
}

export const DesktopMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Desktop)

export const MobileMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Mobile)

export default {
  DesktopMenu,
  MobileMenu
}
