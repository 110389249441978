import React from 'react'
import PropTypes from 'prop-types'
import { View, Image, StyleSheet, Text } from '@react-pdf/renderer'
import upArrowIcon from '../../../assets/up-arrow.png'
import { formatPercent } from '../../../utils/stringHelpers'
import ChartHeaderPDF from './ChartHeaderPDF'
import colors from '../../../styles/variables.scss'
import { contractsExpiringTitle } from '../charts/ContractsExpiring'

export default function ContractsExpiringPDF ({ stateMetrics, stateCode, hasStateQuote }) {
  const { contractsExpiring, percentProjectedMeetingGoalIncrease } = stateMetrics

  const containerStyle = StyleSheet.create({
    width: '40%',
    paddingRight: '3mm',
    paddingBottom: hasStateQuote ? '0mm' : '10mm'
  })

  const metricContainerStyle = StyleSheet.create({
    display: 'flex',
    flexDirection: 'row'
  })

  const circleContainerStyle = StyleSheet.create({
    border: `2 solid ${colors.colorChartColor3}`,
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: hasStateQuote ? 65 : 75,
    width: hasStateQuote ? 65 : 75
  })

  const metricStyle = StyleSheet.create({
    alignSelf: 'center',
    fontSize: '2.5vw',
    lineHeight: '1.5'
  })

  const metricTextStyle = StyleSheet.create({
    alignSelf: 'center',
    fontSize: '1.5vw',
    lineHeight: '1.5'
  })

  const imageStyle = StyleSheet.create({
    alignSelf: 'center',
    height: hasStateQuote ? '20%' : '30%',
    textAlign: 'center',
    width: hasStateQuote ? '20%' : '30%'
  })

  const subtitleStyle = StyleSheet.create({
    fontSize: '1.5vw',
    margin: '0 2mm',
    maxWidth: '45mm'
  })

  if (contractsExpiring === 0 || !percentProjectedMeetingGoalIncrease) return null

  return (
    <View style={containerStyle}>
      <ChartHeaderPDF title={contractsExpiringTitle(contractsExpiring)} hasStateQuote={hasStateQuote} />
      <View style={metricContainerStyle}>
        <View style={circleContainerStyle}>
          <Image src={upArrowIcon} style={imageStyle} />
          <Text style={metricStyle}>
            {formatPercent(percentProjectedMeetingGoalIncrease)}
          </Text>
          <Text style={metricTextStyle}>more</Text>
        </View>
        <Text style={subtitleStyle}>
          {stateCode} school districts would meet the FCC’s 1 Mbps per student goal if these school districts upgraded this year.
        </Text>
      </View>
    </View>
  )
}

ContractsExpiringPDF.propTypes = {
  chartData: PropTypes.object,
  chartImage: PropTypes.string
}
