import React from 'react'
import classnames from 'classnames'
import fundingYears from '../../data/fundingYears'
import { PRELIM_METRICS_MSG } from '../PreliminaryMetricsFootnote'

export default function FCCErateSource ({ className, preliminaryMetrics = false }) {
  const postfix = preliminaryMetrics ? (PRELIM_METRICS_MSG ? `. ${PRELIM_METRICS_MSG}` : null) : `(FY${fundingYears.current})`
  return (
    <div className={classnames(className, 'FCCErateSource')}>
      Source: FCC E-rate Form 471 {postfix}
    </div>
  )
}
