import { DISTRICTS_DATA_FILTERED } from '../../../../../actions/districtsFilters'
import { getCoordinates } from '../../../../../utils/mapUtils'

export const types = {
  MEETING_GOAL: 'meeting-goal',
  NOT_MEETING_GOAL: 'not-meeting-goal',
  MISSING_DATA: 'missing-data'
}

const getType = district => {
  if (district.bandwidth_per_student_mbps === null) {
    return types.MISSING_DATA
  }
  return district.meeting_goal
    ? types.MEETING_GOAL
    : types.NOT_MEETING_GOAL
}

const makeDistrictPin = district => {
  const coordinates = getCoordinates(district)
  if (coordinates.length === 0) { return }

  return {
    type: 'Feature',
    id: district.entity_number,
    geometry: {
      type: 'Point',
      coordinates
    },
    properties: {
      district,
      id: district.entity_number,
      name: district.entity_name,
      goalStatus: getType(district)
    }
  }
}

function geoJSONDoc ({ features } = { features: [] }) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

function makeFeatures (districts) {
  return districts
    .map(makeDistrictPin)
    .filter(pin => !!pin)
}

export default function (state = geoJSONDoc(), action = {}) {
  const { type, payload } = action

  if (type !== DISTRICTS_DATA_FILTERED) {
    return state
  }

  return geoJSONDoc({ features: makeFeatures(payload.filtered) })
}
