import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import './FilterModal.sass'
import '../FilterPane/FilterPane.sass'
import closeButtonIcon from '../../assets/icon-24-icon-close-black.svg'

function FilterModal ({ numApplied, open, onToggle, onApply, children, onClearAll }) {
  if (open) {
    return (
      <div className='FilterModal'>
        <div className='FilterModal__header'>
          <div>
            <img src={closeButtonIcon}
              className='FilterModal__close'
              role='button'
              alt='Close filters options'
              onClick={() => onToggle(false)} />
          </div>
          <h1 className='FilterModal__title'>Filters</h1>
          <div className='FilterModal__clear-all' onClick={() => onClearAll()}> Clear All </div>
        </div>
        <div className='FilterModal__body'>
          {children}
        </div>
        <div className='FilterModal__footer'>
          <div role='button'
            className='FilterModal__Button FilterModal__Button--save'
            onClick={onApply}>
            <span>Save Filters</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Button numApplied={numApplied} onClick={() => onToggle(true)} />
  )
}

FilterModal.propTypes = {
  numApplied: PropTypes.number,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  children: PropTypes.any,
  onClearAll: PropTypes.func
}

export default FilterModal
