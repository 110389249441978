import React from 'react'
import utils from '../../../utils'
import './index.sass'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { tableContent } from './utils'
import { districtDashboard } from '../../../routes'

function DistrictNameLink ({ service, isOutOfState }) {
  return (
    <Link className='MeetingGoalTable__link' to={districtDashboard.generatePath(service)}>
      {utils.titleize(service.entity_name)} {isOutOfState && `(${service.state_code})`}
    </Link>
  )
}

function renderBody (stateCode, data, columns) {
  return (
    data.map((service, key) => {
      return (
        <tr className='MeetingGoalTable__row' key={key}>
          {columns.map((column) => {
            if (column.field === 'entity_name') {
              const isOutOfState = stateCode !== service.state_code
              return (
                <td className={`MeetingGoalTable__column--${column.field}`} key={column.field}>
                  <DistrictNameLink service={service} isOutOfState={isOutOfState} />
                </td>
              )
            } else {
              return (
                <td key={column.field} className={`MeetingGoalTable__column--${column.field}`}>
                  {column.formatFunction(service[column.field])}
                </td>
              )
            }
          })}
        </tr>
      )
    })
  )
}

export default function MeetingGoalTable ({ tab, data, stateCode }) {
  const content = tableContent[tab]({ numDistricts: data.length, stateCode })
  const tableClassName = `MeetingGoalTable__${tab}`

  return (
    <div className='MeetingGoalTable'>
      <div className='MeetingGoalTable__title'>{content.title}</div>
      <table className={`table-striped table ${tableClassName}`}>
        <thead className={`MeetingGoalTable__headers ${tableClassName}__headers`}>
          <tr>
            {
              content.columns.map((header) => {
                return (
                  <th
                    key={header.field}
                    scope='col'
                    className={`MeetingGoalTable__header ${tableClassName}__header--${header.field}`}>
                    {header.header}
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {renderBody(stateCode, data, content.columns)}
        </tbody>
      </table>
    </div>
  )
}

MeetingGoalTable.propTypes = {
  tab: PropTypes.string,
  data: PropTypes.array,
  stateCode: PropTypes.string
}
