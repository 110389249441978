import DistrictsMap from '../../components/StateBandwidthDashboard/DistrictsMap'
import { connect } from 'react-redux'
import { getTooltipId } from '../../selectors/dataSelectors'
import {
  districtPinMouseEnter,
  districtPinMouseLeave
} from '../../actions/map'

const mapStateToProps = (state) => {
  return {
    tooltipId: getTooltipId(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMouseEnter: (tooltipId) => {
      dispatch(districtPinMouseEnter(tooltipId && Number(tooltipId)))
    },
    onMouseLeave: () => {
      dispatch(districtPinMouseLeave())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistrictsMap)
