export const snapUpTo = step => number => Math.ceil(number / step) * step

export function convertDecimalToPercentValue (value) {
  if (value) {
    if (value > 0.99) return Math.floor(value * 100)
    return Math.round(value * 100)
  } else {
    return 0
  }
}
