import { useCallback } from 'react'
import { useWindowResize } from './useWindowResize'

const redraw = (map, containerHeight, setContainerHeight) => node => {
  if (node && node.clientHeight > 0) {
    if (map) { map.resize() }
    setContainerHeight(node.clientHeight)
  }
}

export function useMapboxContainer (map, containerHeight = null, setContainerHeight = () => null) {
  useWindowResize(() => map && map.resize())
  return useCallback(redraw(map, containerHeight, setContainerHeight), [map, containerHeight, setContainerHeight])
}
