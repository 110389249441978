import React from 'react'
import ContactInformation from './ContactInformation'
import './index.sass'

export default function TermsOfUse () {
  return (
    <div className='TermsOfUseContainer'>
      <div className='TermsOfUse'>
        <h1 className='TermsOfUse__title'>Terms of Use</h1>
        <p>Effective date: August 13, 2020</p>
        <h2 className='TermsOfUse__section-title'><i>IMPORTANT – PLEASE READ THIS AGREEMENT!</i></h2>
        <ol type='I'>
          <li className='TermsOfUse__section-title'>Introduction and Eligibility</li>
          <p>Please read these Terms of Use ("Terms") carefully before using any products or services provided by Connected Nation, Inc (hereinafter also referred to as "CN"). These Terms include the Privacy Policy, which is incorporated by reference into these Terms.</p>
          <p><strong>Binding Agreement.</strong> These Terms constitute a binding agreement between you and CN and its affiliates and subsidiaries (hereinafter referred to as "we" or "us"). "You" and "users" shall mean all end users of CN products and services. You accept these Terms each time you access any CN product or service. If you do not accept these Terms, you must not use our products or services.</p>
          <p><strong>Revisions to Terms.</strong> We may revise these Terms at any time by posting an updated version to this web page. You should visit this page periodically to review the most current Terms, because you are bound by them. Your continued use of CN products or services after a change to these Terms constitutes your binding acceptance of these Terms.</p>
          <p><strong>Children.</strong> Our products and services will not knowingly collect PAI (as defined in our Privacy Policy) or personal information (as defined by the Children’s Online Privacy Protection Act, 15 U.S.C. §§ 6501–06, and its regulations) from children under the age of 13. If we learn that we have collected any PAI from children under 13, we will promptly take steps to delete such information.</p>
          <p>No part of CN products and services are directed to persons under the age of 13. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS the SITE AT ANY TIME OR IN ANY MANNER.</p>
          <p><strong>Agent of a Company, Entity, or Organization.</strong> If you are using CN products and services on behalf of a company, entity, or organization (hereinafter referred to as "Organization"), then you represent and warrant that you:</p>
          <ul>
            <li>are an authorized representative of the Organization;</li>
            <li>have the authority to bind that Organization to these Terms; and</li>
            <li>agree to be bound by these Terms on behalf of that Organization.</li>
          </ul>
          <p>The terms "post" and "posting" as used in these Terms shall mean the act of submitting, uploading, publishing, displaying, or similar action on CN products and services.</p>

          <li className='TermsOfUse__section-title'>CN Products and Services</li>
          <p>The "CN Product and Services" include Connect K12 and any related products, mobile applications or Internet services under CN’s control, whether partial or otherwise, in connection with providing such services. Connect K12 provides reporting based on E-rate form 471 data. Connect K12 provides an online platform to test broadband networks in public schools in the United States.  Connect K12 allows users to visualize homes of students that lack connectivity and potential broadband solutions for those homes.</p>

          <li className='TermsOfUse__section-title'>Communications</li>
          <p>You agree to receive email from us at the email address you provided to us for service-related purposes and to communicate with you about reports, analysis results, and related information.</p>
          <p><strong>Electronic Notices.</strong> By using CN products and services, reporting portal, or providing PAI to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of CN products and services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on CN products or services you are using or by sending an email to you. You may have a legal right to receive this notice in writing.</p>

          <li className='TermsOfUse__section-title'>CN’s Content Ownership and Use</li>
          <p>The contents of the CN Products and Services include: designs, text, graphics, images, video, information, logos, button icons, software, audio files, computer code, and other CN content (collectively, "CN Content"). All CN Content and the compilation (meaning the collection, arrangement, and assembly) of all CN Content are the property of CN or its licensors and are protected under copyright, trademark, and other laws.</p>
          <p><strong>License to You.</strong> We authorize you, subject to these Terms, to access and use CN products and services and CN Content solely for the use of CN’s services, at our discretion. Any other use is expressly prohibited. This license is revocable at any time without notice and with or without cause. Unauthorized use of the CN content may violate copyright, trademark, and applicable communications regulations and statutes and is strictly prohibited. You must preserve all copyright, trademarks, service marks, and other proprietary notices contained in the original CN Content on any copy you make of the CN Content.</p>
          <p><strong>CN Marks.</strong> "Connect K12," and any other CN logos and product and service names are or may be trademarks of CN (the "CN Marks"). Without our prior written permission, and except as solely enabled by any link as provided by us, you agree not to display or use in any manner the CN Marks.</p>

          <li className='TermsOfUse__section-title'>Suggestions and Submissions</li>
          <p>We appreciate hearing from our users and welcome your comments regarding CN products and services. Please be advised, however, that if you send us creative ideas, suggestions, inventions, or materials ("creative ideas"), we shall:</p>
          <ol type='1'>
            <li>own, exclusively, all now known or later discovered rights to the creative ideas;</li>
            <li>not be subject to any obligation of confidentiality and shall not be liable for any use or disclosure of any creative ideas; and</li>
            <li>be entitled to unrestricted use of the creative ideas for any purpose whatsoever, commercial or otherwise, without compensation to you or any other person.</li>
          </ol>
          <p>Unless otherwise addressed by this Agreement or federal or state law, by posting, uploading, inputting, providing or submitting information to CN (hereinafter referred to as "Submission"), you are granting CN, its affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their businesses including, without limitation, the rights to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission and to publicize your name in connection with your Submission.  No compensation will be paid with respect to the use of your Submission.  CN is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in CN’s sole discretion.  By posting, uploading, inputting providing or submitting your Submission, you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>

          <li className='TermsOfUse__section-title'>Disclaimers, Limitations, and Prohibitions</li>
          <p>You agree to use CN products and services only for its intended purpose. You must use the CN products and services in compliance with all privacy, data protection, intellectual property, and other applicable laws. The following uses of the CN products and services are prohibited. You may not:</p>
          <ol type='1'>
            <li>remove, disable, damage, circumvent, or otherwise interfere with the security of CN products and services;</li>
            <li>interfere or attempt to interfere with the proper working of the CN products and services;</li>
            <li>gain unauthorized access to the CN products and services or computers linked to the CN products and services;</li>
            <li>attempt to or submit a virus to the CN products and services;</li>
            <li>take any action imposing an unreasonable or disproportionately large load on CN’s infrastructure;</li>
            <li>attempt to or obtain unauthorized access to other users’ accounts;</li>
            <li>use CN products and services in a manner that results in or may result in complaints, disputes, claims, fines, penalties, and other liability to CN or others;</li>
            <li>submit incomplete, false, or misleading information;</li>
            <li>disclose personal information about another person; or</li>
            <li>use the CN products and services in an illegal way or to commit an illegal act.</li>
          </ol>

          <li className='TermsOfUse__section-title'>Consequences of Violating These Terms</li>
          <p>We reserve the right to prevent access to CN products and services for any reason, at our discretion. We reserve the right to refuse to provide the CN products and services to you in the future. Perpetual licenses granted by you to CN remain in full force and effect.</p>
          <p>We may review and remove data we collect from the CN products and services at any time for any reason, including activity which, in our sole judgment: violates these Terms; violates applicable laws, rules, or regulations; is abusive, disruptive, offensive or illegal; or violates the rights of, or harms or threatens the safety of, users of CN products and services.</p>
          <p>You are responsible for any claims, fees, fines, penalties, and other liability incurred by us or others caused by or arising out of your breach of these Terms and your use of CN products and services.</p>

          <li className='TermsOfUse__section-title'>CN’s Liability</li>
          <p>Changes to CN products and services. We may change, suspend, or discontinue any aspect of CN products and services at any time, including hours of operation or availability of the CN products and services or any feature, without notice or liability.</p>
          <p><strong>User Disputes.</strong> We are not responsible for any disputes or disagreements between you and any third party you interact with using CN products and services. You assume all risk associated with dealing with third parties. You agree to resolve disputes directly with the other party. You release CN of all claims, demands, and damages in disputes among users of CN products and services. You also agree not to involve us in such disputes. Use caution and common sense when using CN products and services.</p>
          <p><strong>Content Accuracy.</strong> We make no representations about accuracy, reliability, completeness, or timeliness of any contents of CN products and services. Similarly, we make no representations about accuracy, reliability, completeness, or timeliness of any data from a third-party service provider or the quality or nature of third-party products or services obtained through CN products and services. Use the CN products and services at your own risk.</p>
          <p><strong>Third-Party Websites.</strong> CN products and services may include links to third party websites and applications. You are responsible for evaluating whether you want to access or use them. We are not responsible for and do not endorse any features, content, advertising, products, or other materials on other websites or applications. You assume all risk and we disclaim all liability arising from your use of them.</p>
          <p>We make no promises and disclaim all liability of specific results from the use of CN products and services.</p>
          <p><strong>Released Parties Defined.</strong> "Released Parties" include CN and its affiliates, officers, employees, agents, partners, and licensors.</p>

          <li className='TermsOfUse__section-title'>Disclaimer of Warranties</li>
          <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF CN PRODUCTS AND SERVICES IS AT YOUR SOLE RISK, AND CN PRODUCTS AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND THE RELEASED PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON CN PRODUCTS AND SERVICES, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i) CN PRODUCTS AND SERVICES WILL MEET YOUR REQUIREMENTS, (ii) CN PRODUCTS AND SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF CN PRODUCTS AND SERVICES WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY GOODS OR SERVICE AVAILABLE ON CN PRODUCTS AND SERVICES WILL MEET YOUR EXPECTATIONS AND, AND (v) ANY ERRORS IN THE SERVICE WILL BE CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF CN PRODUCTS AND SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.</p>

          <li className='TermsOfUse__section-title'>Limitation of Liability and Indemnification</li>
          <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF CN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE CN PRODUCTS AND SERVICES; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH, FROM, OR AS A RESULT OF CN PRODUCTS AND SERVICES; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON CN PRODUCTS AND SERVICES; (v) YOUR RELIANCE ON CONTENT MADE AVAILABLE BY US; OR (vi) ANY OTHER MATTER RELATING TO CN PRODUCTS AND SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.</p>
          <p>TO THE FULLEST EXTENT POSSIBLE BY LAW, OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH CN PRODUCTS AND SERVICES OR YOUR USE OF CN CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED $100.</p>
          <p>You agree to defend, indemnify, and hold harmless the Released Parties from and against any claims, actions, or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from (i) your use of or reliance on any third-party content, (ii) your use of or reliance on any CN Content, or (iii) your breach of these Terms. We shall provide notice to you promptly of any such claim, suit, or proceeding.</p>
          <li className='TermsOfUse__section-title'>General Terms</li>
          <p>These Terms constitute the entire agreement between you and CN concerning your use of CN products and services. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p>

          <li className='TermsOfUse__section-title'>Arbitration, Class Waiver, and Waiver of Jury Trial</li>
          <p>These Terms and the relationship between you and CN shall be governed by the laws of the Commonwealth of Kentucky without regard to its conflict of law provisions. You and CN agree to submit to the personal and exclusive arbitration of any disputes relating to your use of CN products and services under the rules of the American Arbitration Association. Any such =97arbitration, to the extent necessary, shall be conducted in Kentucky. You covenant not to sue CN in any other forum.</p>
          <p>You also acknowledge and understand that, with respect to any dispute with the Released Parties arising out of or relating to your use of CN products and services or this Agreement:</p>
          <ul>
            <li>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY;</li>
            <li>YOU MUST FILE ANY CLAIM WITHIN ONE (1) YEAR AFTER SUCH CLAIM AROSE OR IT IS FOREVER BARRED.</li>
          </ul>
        </ol>

        <ContactInformation />
      </div>
    </div>
  )
}
