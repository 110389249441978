import React, { useEffect } from 'react'
import './NewsletterSignupConfirmation.sass'
import logo from '../../assets/connect-k12-logo.svg'

export default function NewsletterSignupConfirmation ({ name = '', email = '', onChange = null, onCancel = null }) {
  // useEffect is how you include third party scripts in React
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className='NewsletterSignupConfirmation'>
      <div className='NewsletterSignupConfirmation__container'>
        <div className='NewsletterSignupConfirmation__logo-container'>
          <img className='NewsletterSignupConfirmation__logo' src={logo} alt='ConnectK12 Logo' />
        </div>
        <div className='NewsletterSignupConfirmation__form-container'>
          <p>We'll send you important news and updates related to improving classroom connectivity throughout your district. </p>
          <form id='subForm' className='js-cm-form' action='https://www.createsend.com/t/subscribeerror?description=' method='post' data-id='A61C50BEC994754B1D79C5819EC1255C0AD79E1E45957F5A8C71FA481D12DEA65D71AC0E81130F83F599EEE1842050196ABD2FE2161FD7D36F2AA468D42E655F'>
            <div className='form-section'>
              <div className='textbox-wrapper'>
                <label className='textbox-label' for='fieldName'>Name </label>
                <input aria-label='Name' id='fieldName' maxLength='200' name='cm-name' className='textbox' value={name} onChange={onChange} />
              </div>
              <div className='textbox-wrapper'>
                <label className='textbox-label' for='fieldEmail'>Email</label>
                <input autoComplete='Email' aria-label='Email' id='fieldEmail' maxLength='200' name='cm-jjhkhhr-jjhkhhr' required type='email' className='js-cm-email-input qa-input-email textbox' value={email} onChange={onChange} />
              </div>
              <div>
                <label className='textbox-label'>Opt in to another list</label>
                <div className='checkbox-wrapper'>
                  <input id='yhther' name='cm-ol-yhther' type='checkbox' value='yhther' className='qa-checkbox-cm-ol-yhther checkbox' />
                  <label for='yhther' className='checkbox-label'>Connected Nation Monthly Enewsletter</label>
                </div>
              </div>
            </div>
            <div className='form-section top-divider'>
              <div className='checkbox-wrapper'>
                <input id='cm-privacy-consent' name='cm-privacy-consent' required type='checkbox' className='qa-checkbox-cm-privacy-consent checkbox' />
                <label for='cm-privacy-consent' className='checkbox-label'>I agree to be emailed</label>
              </div>
              <input id='cm-privacy-consent-hidden' name='cm-privacy-consent-hidden' type='hidden' value='true' />
            </div>
            <button type='submit' className='js-cm-submit-button newsletterbutton'>Subscribe</button>
            <button type='button' className='newsletterbutton cancel' onClick={onCancel}>Cancel</button>
          </form>
        </div>
      </div>
    </div>
  )
}
