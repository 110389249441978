import utils from '../../../utils'

export const NEARBY = 'NEARBY'
export const STATE = 'STATE'
export const CLOSED = 'CLOSED'

const nearbyTab = ({ numDistricts }) => (
  {
    title: `${numDistricts} school districts nearby meet the 1 Mbps per student goal`,
    columns: [{
      header: 'School District',
      field: 'entity_name',
      formatFunction: utils.titleize
    }, {
      header: 'Proximity',
      field: 'distance',
      formatFunction: utils.formatMiles
    }, {
      header: 'Bandwidth/ Student',
      field: 'bandwidth_per_student_mbps',
      formatFunction: utils.formatBandwidth
    }, {
      header: 'Total Internet Bandwidth',
      field: 'total_bandwidth_mbps',
      formatFunction: utils.formatBandwidth
    }, {
      header: 'Students',
      field: 'num_students',
      formatFunction: utils.formatNumber
    }]
  }
)

const stateTab = ({ numDistricts, stateCode }) => (
  {
    title: `${numDistricts} school districts in ${stateCode} meet the 1 Mbps per student goal`,
    columns: [{
      header: 'School District',
      field: 'entity_name',
      formatFunction: utils.titleize
    }, {
      header: 'Bandwidth / student',
      field: 'bandwidth_per_student_mbps',
      formatFunction: utils.formatBandwidth
    }, {
      header: 'Total Internet Bandwidth',
      field: 'total_bandwidth_mbps',
      formatFunction: utils.formatBandwidth
    }, {
      header: 'Students',
      field: 'num_students',
      formatFunction: utils.formatNumber
    }]
  }
)

export const tableContent = {
  NEARBY: nearbyTab,
  STATE: stateTab
}
