import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Skeleton from '../../Skeleton'
import NoResults from '../../NoResults'
import Table from '../../Table'
import Header from '../../Table/Header'
import TableCell from '../../Table/Cell'
import columnHeaders from './columnHeaders.json'
import { districtDashboard } from '../../../routes'
import * as statuses from '../../../reducers/status'
import utils from '../../../utils'
import DirtyDistrictTooltip from '../../Tooltip/DirtyDistrictTooltip'

function TableRow ({ district }) {
  // Raw E-rate data on the State E-rate Dashboard is NEVER dirty now!
  const dirty = false

  return (
    <tr className='DistrictsFundingTable__row'>
      <TableCell classNamePrefix='DistrictsFundingTable__cell' name='name'>
        <Link to={districtDashboard.generatePath({ entity_number: district.entity_number })} className='DistrictsFundingTable__cell--district-name'>
          <div
            id={district.entity_number}
            className={`DistrictsFundingTable__cell--district-name--${district.entity_number}`}>
            {utils.titleize(district.entity_name)}
          </div>
        </Link>
        {dirty && <DirtyDistrictTooltip unfitType={'erate'} />}
      </TableCell>
      <TableCell classNamePrefix='DistrictsFundingTable__cell' name='funding-applied-for'>
        {dirty ? '-' : utils.formatCost(district.total_funding_requested, '0,0.00')}
      </TableCell>
      <TableCell classNamePrefix='DistrictsFundingTable__cell' name='c1-discount'>
        {utils.formatPercent(district.c1_discount_rate / 100) || 'Unknown'}
      </TableCell>
      <TableCell classNamePrefix='DistrictsFundingTable__cell' name='c2-discount'>
        {utils.formatPercent(district.c2_discount_rate / 100) || 'Unknown'}
      </TableCell>
    </tr>
  )
}

export default function TableData ({ districts, status, tableSortOption, tableStatus }) {
  if (districts.length === 0 && status === statuses.PROCESSED) { return <NoResults /> }

  return (
    <Table classNames={['DistrictsFundingTable__table']} fadeout>
      <thead>
        <tr className='DistrictsFundingTable__header-row'>
          {
            columnHeaders.map((header) => {
              return (
                <Header
                  key={header.column}
                  header={header.header}
                  column={header.column}
                  classNames={[utils.sortedHeaderClass(header.column, tableSortOption.value)]}
                />
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        { tableStatus !== statuses.LOADED
          ? [...Array(20)].map((_, idx) => {
            return (
              <tr key={`Skeleton__row-${idx}`} className='DistrictsFundingTable__row'>
                {[...Array(5)].map((_, idx) => {
                  return (
                    <TableCell key={`Skeleton__cell-${idx}`} classNamePrefix='DistrictsFundingTable__cell' name={'i' + idx}>
                      <Skeleton />
                    </TableCell>
                  )
                })}
              </tr>
            )
          })
          : districts.map((district, idx) => {
            return (
              <TableRow
                key={`TableRow-${idx}`}
                district={district}
              />
            )
          })}
      </tbody>
    </Table>
  )
}

TableData.propTypes = {
  districts: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  tableSortOption: PropTypes.shape({
    value: PropTypes.string
  }),
  tableStatus: PropTypes.string
}
