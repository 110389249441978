import React from 'react'
import PropTypes from 'prop-types'

export default function USAState ({ state, stateData, fill, onMouseOver }) {
  const className = `${state} state`
  return (
    <path
      d={stateData.dimensions}
      fill={fill}
      data-name={state}
      className={className}
      onMouseOver={onMouseOver}>
      <title>{stateData.name}</title>
    </path>
  )
}

USAState.propTypes = {
  state: PropTypes.string,
  stateData: PropTypes.object,
  fill: PropTypes.string,
  onMouseOver: PropTypes.func.isRequired
}
