import {
  REQUEST_NEARBY_SERVICES,
  RECEIVE_NEARBY_SERVICES,
  RECEIVE_NEARBY_SERVICES_ERROR
} from '../../../actions/nearbyServices'
import {
  NEARBY_SERVICES_DATA_FILTERED,
  NEARBY_SERVICES_FILTERS_APPLIED
} from '../../../actions/nearbyServicesFilters'
import { REQUEST_DISTRICT } from '../../../actions/district'
import * as statuses from '../../status'

export default function (state = statuses.INITIALIZED, action = {}) {
  const { type } = action

  switch (type) {
    case REQUEST_NEARBY_SERVICES:
      return statuses.REQUESTED
    case RECEIVE_NEARBY_SERVICES:
      return statuses.LOADED
    case RECEIVE_NEARBY_SERVICES_ERROR:
      return statuses.ERROR
    case REQUEST_DISTRICT:
      return statuses.INITIALIZED
    case NEARBY_SERVICES_FILTERS_APPLIED:
      return statuses.UPDATED
    case NEARBY_SERVICES_DATA_FILTERED:
      return statuses.PROCESSED
    default:
      return state
  }
}
