import React, { useState } from 'react'
import classNames from 'classnames'
import './InputWithUnits.sass'

/**
 * A wrapper for an Input component and Unit component which manages their
 * related interactions and styling.
 *
 * @param {string} [className] - CSS class name
 * @param {string|function} [input='input'] - A React component for the input field
 * @param {string} [name] - input HTML element name property
 * @param {function} onApply - Triggered when user hits "Enter"
 * @param {function} onChange - Passed to the input component
 * @param {string} [unitPosition="left"] - Where units should be displayed in relation to
 *  the input component, one of: "left", "right"
 * @param {string|function} [units='span'] - A React component for the units field
 * @param {value} [value] - Value for the HTML input
 * @returns {*}
 * @constructor
 * @see https://americanexpress.io/faccs-are-an-antipattern/
 */
export const InputWithUnits = ({
  className,
  input: Input,
  name,
  onApply,
  onChange,
  unitPosition,
  units: Units,
  value
}) => {
  const [active, setActive] = useState(false)
  const baseClass = 'InputWithUnits'
  const containerClasses = classNames(baseClass, className, `${baseClass}--${name}`, {
    [`${baseClass}--active`]: active,
    [`${baseClass}--units-${unitPosition}`]: !!unitPosition
  })

  const handleBlur = () => {
    setActive(false)
  }

  const handleFocus = () => {
    setActive(true)
  }

  return (
    <div className={containerClasses}>
      <Units className='InputWithUnits__units' />
      <Input
        name={name}
        value={value}
        className={`${baseClass}__input ${baseClass}__input--${name}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        onApply={onApply}
      />
    </div>
  )
}

InputWithUnits.defaultProps = {
  unitPosition: 'left',
  units: 'span',
  input: 'input'
}
