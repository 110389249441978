import { LOCATION_CHANGE } from 'connected-react-router'
import routes from '../routes'
import contactUsPage from './contactUsPage'
import districtDashboard from './districtDashboard'
import homepage from './homepage'
import nationalDashboard from './nationalDashboard'
import stateErateDashboard from './stateErateDashboard'
import stateOverviewDashboard from './stateOverviewDashboard'
import stateBandwidthDashboard from './stateBandwidthDashboard'

export function getRouteReducer (pathname) {
  if (routes.onHomepage(pathname)) {
    return homepage
  }
  if (routes.onNationalPage(pathname)) {
    return nationalDashboard
  }
  if (routes.onStateEratePage(pathname)) {
    return stateErateDashboard
  }
  if (routes.onStateOverviewPage(pathname)) {
    return stateOverviewDashboard
  }
  if (routes.onStateBandwidthPage(pathname)) {
    return stateBandwidthDashboard
  }
  if (routes.onDistrictPage(pathname)) {
    return districtDashboard
  }
  if (routes.onContactUsPage(pathname)) {
    return contactUsPage
  }
}

export function routeReducer (state = {}, action = {}) {
  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    return getRouteReducer(payload.location.pathname)
  }
}

export default routeReducer
