import {
  SERVICE_PIN_CLICKED,
  SERVICE_POPUP_OPENED
} from '../../../../actions/map'
import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../actions/nearbyServicesFilters'
import { APPLY_NEARBY_SERVICES_FIT_BOUNDS } from '../../../../actions/nearbyServices'
import {
  calculateOffsetY,
  calculateFitBounds,
  calculateCenter
} from '../../../../utils/mapUtils'
import { nearestTenServices } from './utils'

export const defaultState = {
  fitBounds: [[0, 0], [0, 0]],
  center: [-98.5795, 39.8283],
  offsetY: 0,
  resetZoom: false
}

export default function (state = defaultState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case SERVICE_PIN_CLICKED:
      let service = payload.services[0]
      let { longitude, latitude } = service
      return {
        ...state,
        center: [longitude, latitude],
        resetZoom: false
      }
    case SERVICE_POPUP_OPENED:
      const { node, map, lngLat, selected } = payload
      const boundingBox = node.getBoundingClientRect()
      const mapBoundingBox = map._container.getBoundingClientRect()
      const offsetY = calculateOffsetY(mapBoundingBox, boundingBox, selected)
      return {
        ...state,
        center: lngLat,
        offsetY: offsetY
      }
    case NEARBY_SERVICES_DATA_FILTERED:
      const { nearestTen } = payload
      const fitBounds = calculateFitBounds(nearestTen)
      if (fitBounds) {
        return {
          ...state,
          fitBounds: fitBounds,
          center: calculateCenter(fitBounds),
          resetZoom: false
        }
      }
      return state
    case APPLY_NEARBY_SERVICES_FIT_BOUNDS: {
      const { nearby, districtServices, isFirstRequest } = payload
      if (nearby.length === 0 || !isFirstRequest) return { ...state, resetZoom: false }
      const nearestTen = nearestTenServices(nearby, districtServices[0])
      return {
        ...state,
        fitBounds: calculateFitBounds(nearestTen) || state.fitBounds,
        center: state.center,
        resetZoom: true
      }
    }
    default:
      return state
  }
}
