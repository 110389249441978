import React from 'react'
import './RangeInputGroup.sass'

export default function RangeInputGroup ({ minInput: Min, maxInput: Max, values, onChange, onApply, maxBoundary }) {
  const [min, max] = values

  return (
    <div className='RangeInputGroup'>
      <Min
        className='RangeInputGroup__min'
        value={min}
        onChange={(val) => onChange([val, max])}
        onApply={onApply}
      />
      <div className='RangeInputGroup__separator'>-</div>
      <Max
        className='RangeInputGroup__max'
        value={max}
        onChange={(val) => onChange([min, val])}
        onApply={onApply}
        maxBoundary={maxBoundary}
      />
    </div>
  )
}
