import {
  DISTRICT_PIN_CLICKED,
  DISTRICT_POPUP_OPENED
} from '../../../../../actions/map'
import { DISTRICTS_DATA_FILTERED } from '../../../../../actions/districtsFilters'

export default function (state = true, action = {}) {
  const { type } = action

  switch (type) {
    case DISTRICTS_DATA_FILTERED:
      return true
    case DISTRICT_PIN_CLICKED:
    case DISTRICT_POPUP_OPENED:
      return false
    default:
      return state
  }
}
