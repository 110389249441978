import React from 'react'
import Base from './Base'
import Column from './Column'
import {
  formatBandwidth,
  formatCost,
  formatProviders
} from '../../../utils/stringHelpers'
import meetingGoalIcon from '../../../assets/icon-24-icon-meeting-goal.svg'
import PopupHeader from '../shared/PopupHeader'

const Header = ({ title, onClose }) => {
  return <PopupHeader
    title={title}
    subtitle="Meeting the FCC's internet bandwidth goal"
    onClose={onClose} />
}

const Body = ({ data }) => {
  return (
    <div className='DistrictPopup__body DistrictPopup__body--meeting'>
      <Column
        classId='meeting'
        metric={`${formatBandwidth(data.total_bandwidth_mbps)}`}
        icon={<img src={meetingGoalIcon} alt='meeting goal icon' className='PopupColumn__icon' />}
        subtitle={`${formatBandwidth(data.bandwidth_per_student_mbps)}/student`} />
      <Column
        title='total cost'
        classId='cost'
        metric={`${formatCost(data.total_monthly_cost, '0,0', { nan: '?' })}/month`} />
      <div className='PopupColumn--providers'>
        {formatProviders(data.service_providers)}
      </div>
    </div>
  )
}

const MeetingGoal = ({ data, onClose }) => {
  return <Base
    data={data}
    header={<Header title={data.entity_name} onClose={onClose} />}
    body={<Body data={data} />}
  />
}

export default MeetingGoal
