import React from 'react'
import PropTypes from 'prop-types'
import './ChartHeader.sass'

export default function ChartHeader ({ title, subtitle, chartName }) {
  return (
    <div className='ChartHeader'>
      <div className={`ChartHeader__title ChartHeader__title--${chartName}`}>
        {title}
      </div>
      <div className='ChartHeader__subtitle'>
        {subtitle}
      </div>
    </div>
  )
}

ChartHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  chartName: PropTypes.string
}
