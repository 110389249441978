import { fetchStatesMetrics } from '../data'

export const REQUEST_STATES_METRICS = 'REQUEST_STATES_METRICS'
export function requestStatesMetrics () {
  return {
    type: REQUEST_STATES_METRICS
  }
}

export const RECEIVE_STATES_METRICS = 'RECEIVE_STATES_METRICS'
export function receiveStatesMetrics (data) {
  return {
    type: RECEIVE_STATES_METRICS,
    payload: { data }
  }
}

export const RECEIVE_STATES_METRICS_ERROR = 'RECEIVE_STATES_METRICS_ERROR'
export function receiveStatesMetricsError (error) {
  return {
    type: RECEIVE_STATES_METRICS_ERROR,
    payload: {
      error: error.message,
      status: error.status
    }
  }
}

export function getStatesMetrics (retries = 2) {
  return function (dispatch) {
    dispatch(requestStatesMetrics())

    return fetchStatesMetrics()
      .then(data => {
        const statesMetrics = data.filter(d => d.state_code !== 'DC')
        return dispatch(receiveStatesMetrics(statesMetrics))
      })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveStatesMetricsError(error))
        }
        return dispatch(getStatesMetrics(retries - 1))
      })
  }
}

export default {
  REQUEST_STATES_METRICS,
  RECEIVE_STATES_METRICS,
  RECEIVE_STATES_METRICS_ERROR
}
