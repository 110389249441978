import React from 'react'
import Popover from 'react-bootstrap/Popover'

export default function tooltipContent (key) {
  const current = (
    <Popover id='DistrictDetails__metrics__tooltiip' content='true'>
      <div>
        <p><b>Total Bandwidth:</b> The total amount of internet bandwidth serving all schools in the school district. In some cases where districts are billed for internet service separately from the data connection(s) to the ISP or state/regional network, the lesser bandwidth is taken as the connection capacity.</p>
        <p><b>Total Monthly Cost:</b> The total monthly cost of all internet access connections. When internet bandwidth is shared across multiple school districts, the cost of the bandwidth is allocated proportionally across school districts based on the number of students. Annual non-recurring costs are allocated across the total contract months to take into account the total cost of the service in cases where zero monthly cost was reported.</p>
        <p><b>Bandwidth/Student:</b> The total amount of internet bandwidth serving all schools in the school district divided by the total number of full-time students, based on USAC E-rate data. As district size increases, a smaller percentage of users are likely to be on the network concurrently during peak demand periods. To account for this, we reduce the recommended bandwidth amount proportionally to more accurately represent the per student capacity of the school district’s bandwidth.</p>
      </div>
    </Popover>
  )

  const fcc = (
    <Popover id='DistrictDetails__recommended-tooltip' content='true'>
      <div>
        <b>Recommended Bandwidth:</b> The total bandwidth for the school district to reach the FCC’s recommended 1 Mbps per student target, rounded up the nearest standard circuit size that is available in the market. As district size increases, a smaller percentage of users are likely to be on the network concurrently during peak demand periods. To account for this, we reduce the recommended bandwidth amount proportionally to more accurately represent the per student capacity of the school district’s bandwidth.
      </div>
    </Popover>
  )
  return { current, fcc }[key]
}
