import ReactMapboxGl from 'react-mapbox-gl'
import TagManager from 'react-gtm-module'
import { recommendedServices } from '../components/NearbyServices/helpers'

import { POPUP_OFFSET, POPUP_MARGIN } from '../components/Popup'

export const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  scrollZoom: false
})

export function geoJSONDoc ({ features } = { features: [] }) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export function addTagManagerData (feature) {
  const dataLayer = {
    event: 'map-pin-click',
    district: {
      entityNumber: feature.id,
      name: feature.properties.name
    }
  }
  return TagManager.dataLayer({ dataLayer })
}

export function getCoordinates (district) {
  if (!(district && district.longitude && district.latitude)) return []
  return [district.longitude, district.latitude]
}

export function getCostPerMbpsRange (services) {
  services = services.filter(service => service.monthly_cost_per_mbps)
  if (services.length === 0) return []
  services = services.sort((a, b) => a.monthly_cost_per_mbps - b.monthly_cost_per_mbps)
  if (services.length === 1) return [services[0].monthly_cost_per_mbps]
  return [...new Set([services[0].monthly_cost_per_mbps, services[services.length - 1].monthly_cost_per_mbps])]
}

export function containsLngLatPoint (bounds, lngLatPoint) {
  const [lng, lat] = lngLatPoint
  const [[swLng, swLat], [neLng, neLat]] = bounds
  const containsLatitude = swLat <= lat && lat <= neLat
  const containsLongitude = swLng > neLng
    ? swLng >= lng && lng >= neLng
    : swLng <= lng && lng <= neLng
  return containsLatitude && containsLongitude
}

export function mapProps () {
  return {
    style: 'mapbox://styles/esh-admin/ck7xt758q04e61ilm161gwfno',
    className: 'mapbox-map',
    movingMethod: 'easeTo',
    containerStyle: {
      height: '100%',
      width: '100%'
    }
  }
}

export function boundsFromLatsAndLongs (latValues, lngValues) {
  const maxLat = Math.max(...latValues)
  const minLat = Math.min(...latValues)
  const maxLng = Math.max(...lngValues)
  const minLng = Math.min(...lngValues)
  return [[minLng, minLat], [maxLng, maxLat]]
}

export function calculateFitBounds (data) {
  if (!Array.isArray(data) || data.length === 0) { return }
  const latValues = data.map(d => d.latitude).filter(lat => lat != null)
  const lngValues = data.map(d => d.longitude).filter(lng => lng != null)
  return boundsFromLatsAndLongs(latValues, lngValues)
}

export function calculateCenter (fitBounds) {
  const centerLong = (fitBounds[0][0] + fitBounds[1][0]) / 2
  const centerLat = (fitBounds[0][1] + fitBounds[1][1]) / 2
  return [centerLong, centerLat]
}

export function adjustCenterForPopup (map, offsetY, center) {
  let centerInPixels = map.project(center)
  let centerWithOffset = {
    x: centerInPixels.x,
    y: centerInPixels.y + offsetY
  }
  return map.unproject(centerWithOffset)
}

export function calculateOffsetY (mapBoundingBox, popupBoundingBox) {
  const mapHeight = mapBoundingBox.height
  const popupOffset = mapHeight / 2 -
    popupBoundingBox.height +
    POPUP_OFFSET.y -
    POPUP_MARGIN.y
  return Math.abs(Math.min(0, popupOffset))
}

export function showPointer (map) {
  map.getCanvas().style.cursor = 'pointer'
}

export function hidePointer (map) {
  map.getCanvas().style.cursor = ''
}

export function moveLayerToFront (map, layerId) {
  if (map.getLayer(layerId)) map.moveLayer(layerId)
}

export function getFeature (id, features) {
  if (id && Array.isArray(features)) {
    return features.find(feature => feature.id === id)
  }
}

export function shouldOpenTooltip (tooltipId, pinId) {
  return tooltipId !== undefined && pinId !== undefined && tooltipId === pinId
}

export function getEventFeature (event) {
  return event.features[0]
}

export function applyPaddingForRecommended (services = []) {
  const allServices = Object.values(services).reduce((acc, s) => acc.concat(s), [])
  return recommendedServices(allServices)
    ? { top: 150, bottom: 150, left: 150, right: 150 }
    : { top: 50, bottom: 50, left: 50, right: 50 }
}

export function addLayerSource (map, sourceId, data) {
  if (map && !map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: 'geojson',
      data
    })
  }
}
