import { connect } from 'react-redux'
import Filters from '../../components/StateBandwidthDashboard/Filters'
import {
  districtsFilterMenuToggled,
  districtsFiltersApplied,
  districtsFiltersModalToggled,
  districtsFilterCleared,
  districtsFiltersCleared,
  districtsFilterUpdated
} from '../../actions/districtsFilters'

const mapStateToProps = ({ districts: { filters, status } }) => {
  return { filters, status }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (filter, update) => {
      dispatch(districtsFilterUpdated(filter, update))
    },
    onToggle: (name, open) => {
      dispatch(districtsFilterMenuToggled(name, open))
    },
    onApply: (...names) => {
      dispatch(districtsFiltersApplied(names))
    },
    onModalToggle: (open) => {
      dispatch(districtsFiltersModalToggled(open))
    },
    onClear: (name) => {
      dispatch(districtsFilterCleared(name))
    },
    onClearAll: () => {
      dispatch(districtsFiltersCleared())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
