export default {
  all: [
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023
  ],
  current: process.env.REACT_APP_FUNDING_YEAR || 2023
}
