import actions from '../../../actions/district'
import * as status from '../../status'

export const initialState = {
  data: {},
  entityNumber: null,
  status: status.INITIALIZED
}

function district (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_DISTRICT:
      return {
        data: state.entityNumber === payload.entityNumber ? state.data : {},
        entityNumber: payload.entityNumber,
        status: status.REQUESTED
      }

    case actions.RECEIVE_DISTRICT:
      return {
        data: payload.data,
        entityNumber: payload.entityNumber,
        status: status.LOADED
      }

    case actions.RECEIVE_DISTRICT_ERROR:
      return {
        data: payload.entityNumber,
        error: payload.error.message || `Error requesting district ${payload.entityNumber}`,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default district
