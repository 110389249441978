import { CALCULATE_STATE_BANDWIDTH_CHART_DATA,
  REQUEST_HISTORICAL_STATE_METRICS } from '../../../../actions/historicalStateMetrics'
import * as statuses from '../../../status'

const initialState = statuses.INITIALIZED

export default function status (state = initialState, action = {}) {
  const { type } = action
  switch (type) {
    case REQUEST_HISTORICAL_STATE_METRICS:
      return statuses.REQUESTED
    case CALCULATE_STATE_BANDWIDTH_CHART_DATA:
      return statuses.LOADED
    default:
      return state
  }
}
