import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Filter.sass'

function Filter ({ label, children, open, enabled, applied, className }) {
  const stateClass = open ? 'Filter--open' : 'Filter--closed'
  const hiddenClass = !applied && 'Filter__hidden'
  const labelClass = `Filter--${label.replace(/[\s\\/]/, '')}`

  const classes = classnames('Filter', stateClass, hiddenClass, labelClass, className,
    { 'Filter--enabled': enabled }
  )

  return (
    <form className={classes}>
      {children}
    </form>
  )
}

Filter.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  open: PropTypes.bool,
  enabled: PropTypes.bool,
  className: PropTypes.string
}

export default Filter
