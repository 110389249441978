import React from 'react'
import PropTypes from 'prop-types'
import ChartHeader from './ChartHeader'
import StateBandwidthLink from '../../links/StateBandwidthLink'
import { formatPercent, NBSP } from '../../../utils/stringHelpers'
import upArrowIcon from '../../../assets/up-arrow.png'
import './ContractsExpiring.sass'
import classNames from 'classnames'

export function contractsExpiringTitle (contractsExpiring) {
  return `${contractsExpiring} school districts under 1${NBSP}Mbps/student have expiring internet contracts`
}

export default function ContractsExpiring ({ contractsExpiring, percentProjectedMeetingGoalIncrease, stateCode, expandWidth }) {
  return (
    <div className={'ContractsExpiring ' + classNames({ 'ContractsExpiring__expand': expandWidth })}>
      <ChartHeader
        title={contractsExpiringTitle(contractsExpiring)}
        chartName='contracts-expiring'
      />
      <div className='ContractsExpiring__content-container'>
        <div className='ContractsExpiring__metric-container'>
          <div className='ContractsExpiring__chart ContractsExpiring__circle'>
            <img className='ContractsExpiring__icon' src={upArrowIcon} alt='percentage increase' />
            <div className='ContractsExpiring__metric'>
              {formatPercent(percentProjectedMeetingGoalIncrease)}
              <span> more</span>
            </div>
          </div>
          <div className='ContractsExpiring__text'>
            {stateCode} school districts would meet the FCC’s 1 Mbps per student goal if these school districts upgraded this year.
          </div>
        </div>
        <div className='ContractsExpiring__button'>
          <StateBandwidthLink
            stateCode={stateCode}
            filters={['contract', 'bandwidth']}
            classNames={['StateButtonLink']}>
            View districts with expiring contracts
          </StateBandwidthLink>
        </div>
      </div>
    </div>
  )
}

ContractsExpiring.propTypes = {
  contractsExpiring: PropTypes.object,
  percentProjectedMeetingGoalIncrease: PropTypes.number,
  stateCode: PropTypes.string,
  expandWidth: PropTypes.bool
}
