import { fetchNearbyServices } from '../data'
import { applyFilters } from './nearbyServicesFilters'
import { tableSortDispatcher, firstRequest } from './helpers'
import { getData, getSelectedDistrictServices } from '../selectors/dataSelectors'

export const REQUEST_NEARBY_SERVICES = 'REQUEST_NEARBY_SERVICES'
export function requestNearbyServices (recipientDistrictBen, isFirstRequest) {
  return {
    type: REQUEST_NEARBY_SERVICES,
    payload: { recipientDistrictBen, isFirstRequest }
  }
}

export const RECEIVE_NEARBY_SERVICES = 'RECEIVE_NEARBY_SERVICES'
export function receiveNearbyServices (recipientDistrictBen, data, isFirstRequest) {
  return {
    type: RECEIVE_NEARBY_SERVICES,
    payload: { recipientDistrictBen, data, isFirstRequest }
  }
}

export const RECEIVE_NEARBY_SERVICES_ERROR = 'RECEIVE_NEARBY_SERVICES_ERROR'
export function receiveNearbyServicesError (recipientDistrictBen, error) {
  return {
    type: RECEIVE_NEARBY_SERVICES_ERROR,
    payload: {
      recipientDistrictBen,
      error: error.message,
      status: error.status
    }
  }
}

export const SORT_NEARBY_SERVICES = 'SORT_NEARBY_SERVICES'
export function sortNearbyServices (option) {
  return {
    type: SORT_NEARBY_SERVICES,
    payload: { ...option }
  }
}

export const NEARBY_SERVICES_SORTED = 'NEARBY_SERVICES_SORTED'
export function nearbyServicesSorted () {
  return {
    type: NEARBY_SERVICES_SORTED
  }
}

export const APPLY_NEARBY_SERVICES_FIT_BOUNDS = 'APPLY_NEARBY_SERVICES_FIT_BOUNDS'
export function applyNearbyServicesFitBounds ({ nearby, districtServices, isFirstRequest }) {
  return {
    type: APPLY_NEARBY_SERVICES_FIT_BOUNDS,
    payload: { nearby, districtServices, isFirstRequest }
  }
}

export const OPEN_SELECTED_POPUP = 'OPEN_SELECTED_POPUP'
const openSelectedPopup = (dispatch, getState) => {
  const state = getState()
  const { isFirstRequest } = getData(state)
  const districtServices = getSelectedDistrictServices(state)
  if (isFirstRequest) {
    return dispatch({
      type: OPEN_SELECTED_POPUP,
      payload: {
        services: districtServices,
        selected: true
      }
    })
  }
}

export const NEARBY_SERVICES_SORT_APPLIED = 'NEARBY_SERVICES_SORT_APPLIED'
export function nearbyServicesSortApplied (option) {
  return {
    type: NEARBY_SERVICES_SORT_APPLIED,
    payload: { ...option }
  }
}

export function getNearbyServices (recipientDistrict, retries = 2) {
  const { entity_number: requestedId } = recipientDistrict
  return function (dispatch, getState) {
    const state = getState()
    const { districtServices: currentData } = getData(state)
    const currentId = state.nearbyServices.recipientDistrictBen
    const isFirstRequest = firstRequest(currentData, currentId, requestedId)

    dispatch(requestNearbyServices(requestedId, isFirstRequest))

    const dispatchSuccess = data => {
      let action = receiveNearbyServices(requestedId, data, isFirstRequest)
      return dispatch(action)
    }

    const dispatchError = error => {
      console.error(error.message)
      if (retries === 0) {
        return dispatch(receiveNearbyServicesError(requestedId, error))
      }
      return dispatch(getNearbyServices(recipientDistrict, retries - 1))
    }

    return fetchNearbyServices(recipientDistrict)
      .then(dispatchSuccess, dispatchError)
  }
}

export const applySort = async (dispatch, getState) => {
  await tableSortDispatcher(sortNearbyServices)(dispatch, getState)
  await dispatch(nearbyServicesSorted())
}

const applyFitBounds = (dispatch, getState) => {
  const data = getData(getState())
  return dispatch(applyNearbyServicesFitBounds(data))
}

export function loadNearbyServicesData (recipientDistrict) {
  return async function (dispatch, getState) {
    await getNearbyServices(recipientDistrict)(dispatch, getState)
    await applyFilters(dispatch, getState)
    await applyFitBounds(dispatch, getState)
    await applySort(dispatch, getState)
    await openSelectedPopup(dispatch, getState)
  }
}

export default {
  REQUEST_NEARBY_SERVICES,
  RECEIVE_NEARBY_SERVICES,
  RECEIVE_NEARBY_SERVICES_ERROR,
  APPLY_NEARBY_SERVICES_FIT_BOUNDS,
  SORT_NEARBY_SERVICES,
  NEARBY_SERVICES_SORT_APPLIED,
  NEARBY_SERVICES_SORTED
}
