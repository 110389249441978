export function toggleMenu (state) {
  return { ...state, ...{ open: !state.open } }
}

export function closeMenu (state) {
  return { ...state, ...{ open: false } }
}

export function openMenu (state) {
  return { ...state, ...{ open: true } }
}

export function enableMenu (state) {
  return { ...state, ...{ enabled: true } }
}

export function disableMenu (state) {
  return { ...state, ...{ enabled: false } }
}

export function toggleMenus (state, name) {
  for (let filter in state) {
    if (filter === name) {
      state[filter] = toggleMenu(state[filter])
    } else {
      state[filter] = closeMenu(state[filter])
    }
  }
  return state
}

export function closeAllMenus (state) {
  Object.keys(state).forEach((name) => {
    state[name] = closeMenu(state[name])
  })
  return state
}

export function toggleAllMenus (state, open) {
  Object.keys(state).forEach((name) => {
    state[name] = { ...state[name], ...{ open: open } }
  })
  return state
}
