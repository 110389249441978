import React from 'react'
import { contactUsPage } from '../../routes'

export const privacyPolicyLink = <a target='_blank' rel='noopener noreferrer' href='https://www.educationsuperhighway.org/privacy/?_ga=2.170893920.172246164.1578350116-2127853833.1526676508'>Privacy Policy</a>
export const ConnectedNationLink = <a target='_blank' rel='noopener noreferrer' href='https://connectednation.org/'>Connected Nation</a>
export const fundsforlearningLink = <a target='_blank' rel='noopener noreferrer' href='https://www.fundsforlearning.com/'>Funds For Learning®</a>
export const usacOpenDataLink = <a target='_blank' rel='noopener noreferrer' href='https://opendata.usac.org/'>USAC Open Data Platform</a>
export const usacToolsAndResLink = <a target='_blank' rel='noopener noreferrer' href='https://www.usac.org/e-rate/resources/tools/'>USAC&apos;s tools and resources</a>
export const ncesDataDefinitionLink = <a target='_blank' rel='noopener noreferrer' href='http://nces.ed.gov/ccd/Data/txt/pau121alay.txt'>data definition</a>
export const erateModOrderLink = <a href='https://www.fcc.gov/general/summary-e-rate-modernization-order' target='_blank' rel='noopener noreferrer'>E-rate Modernization Order</a>
export const fccWebsiteLink = <a target='_blank' rel='noopener noreferrer' href='https://www.fcc.gov/consumers/guides/universal-service-program-schools-and-libraries-e-rate'>FCC website</a>
export const contactUsLink = <a href={contactUsPage.path}>contact us</a>
export const educationSuperHighwayLink = <a target='_blank' rel='noopener noreferrer' href='https://www.educationsuperhighway.org'>EducationSuperHighway</a>
