import { connect } from 'react-redux'
import { getPathname } from '../../selectors/locationSelectors'
import routes from '../../routes'

import Navbar from '../../components/Navbar'

const mapStateToProps = (state, ownProps) => {
  return {
    showNavbarTypeahead: routes.onHomepage(getPathname(state)) ? state.showNavbarTypeahead : true,
    isOnHomepage: routes.onHomepage(getPathname(state))
  }
}

export default connect(
  mapStateToProps,
  () => { return {} }
)(Navbar)
