import { getFilters } from '../selectors/filterSelectors'
import { getPathname } from '../selectors/locationSelectors'
import { stateBandwidthDashboard, districtDashboard } from '../routes'
import { nearbyServicesFiltersApplied } from './nearbyServicesFilters'
import { districtsFiltersApplied } from './districtsFilters'

export function applyFiltersOnClickAway () {
  return (dispatch, getState) => {
    const state = getState()
    let filterNames = []
    for (let [filterName, filter] of Object.entries(getFilters(state))) {
      if (filter.update) filterNames.push(filterName)
    }
    const pathname = getPathname(state)
    if (filterNames.length === 0) return null
    if (districtDashboard.matchPath(pathname)) {
      return dispatch(nearbyServicesFiltersApplied(filterNames))
    }
    if (stateBandwidthDashboard.matchPath(pathname)) {
      return dispatch(districtsFiltersApplied(filterNames))
    }
  }
}
