import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from './Checkbox'

const checkboxes = [
  { name: 'shopping',
    labelText: 'Finding internet service providers and/or price information for school district internet access' },
  { name: 'reporting',
    labelText: 'State broadband reporting and/or data analysis' },
  { name: 'press',
    labelText: 'Press' },
  { name: 'advocacy',
    labelText: 'Education Advocacy' },
  { name: 'other',
    labelText: 'Other' }
]

export default function UsageForm ({ register }) {
  return (
    <div className='ContactForm__usage'>
      <h3>
        I am using Connect K-12 for:
      </h3>
      { checkboxes.map((box, key) => {
        return (
          <Checkbox
            key={key}
            name={box.name}
            labelText={box.labelText}
            className='ContactForm__checkbox'
            register={register} />
        )
      }) }
    </div>
  )
}

UsageForm.propTypes = {
  register: PropTypes.func.isRequired
}
