import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './index.sass'
import MenuButton from './MenuButton'
import { CONTACT_US_OPTIONS } from './CONTACT_US_OPTIONS'
import ContactForm from './ContactForm'
import ThankYou from './ThankYou'

export default function ContactUs (props = {}) {
  const buttonChoices = Object.keys(CONTACT_US_OPTIONS)
  const { selectedContactForm, onMenuButtonClick,
    onContactFormSubmit, contactFormSubmitted } = props

  const parentClass = classnames('ContactUs', { 'ContactUs--empty': selectedContactForm === '' || contactFormSubmitted })

  return (
    <div className={parentClass}>
      <div className='ContactUs__container' >

        <ThankYou contactFormSubmitted={contactFormSubmitted} />

        <h1 className='ContactUs__title'>Contact Us</h1>
        <h3 className='ContactUs__subtitle'>What is this about?</h3>
        <p>
          Giving us a bit of info now helps us respond in a timely manner.
        </p>
        <div className='ContactUs__menu'>
          {
            buttonChoices.map((choice, key) => {
              return (
                <MenuButton
                  key={key}
                  choice={choice}
                  onMenuButtonClick={onMenuButtonClick}
                  selectedContactForm={selectedContactForm}
                />
              )
            })
          }
        </div>
        <ContactForm
          selectedContactForm={selectedContactForm}
          onContactFormSubmit={onContactFormSubmit}
        />
      </div>
    </div>
  )
}

ContactUs.propTypes = {
  selectedContactForm: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  onContactFormSubmit: PropTypes.func.isRequired,
  contactFormSubmitted: PropTypes.bool.isRequired
}
