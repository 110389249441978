import React, { createRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import GlobalSearchMenu from './GlobalSearchMenu'
import searchIcon from '../../assets/iconSearchWhite24.svg'
import { REQUESTED, INITIALIZED } from '../../reducers/status'
import './index.sass'

function GlobalSearchTypeahead ({ globalSearch, onSearch, onChange }) {
  const ref = createRef()
  const { data, status: { status } } = globalSearch
  const { states, districts, recentSearches } = data
  const [isLoading, setIsLoading] = useState(status === REQUESTED)
  const [selected, setSelected] = useState([])
  const minLength = recentSearches.length > 0 ? 0 : 2

  useEffect(() => {
    if (status === REQUESTED) setIsLoading(true)
    return () => setIsLoading(false)
  }, [status, setIsLoading])

  useEffect(() => {
    setSelected(data.selected[0] ? data.selected : [])
  }, [data.selected, setSelected])

  function handleSearch (term) {
    onSearch(term)
  }

  function customFilterBy (option, props) {
    if (props.text.length === 0) {
      return option.recent
    } else {
      return !option.recent
    }
  }

  function handleChange (selections) {
    setSelected(selections)
    if (selections[0]) onChange(selections[0])
  }

  const iconContainerClass = classNames('GlobalSearchTypeahead__icon-container', {
    'GlobalSearchTypeahead__icon-container--disabled': status !== INITIALIZED
  })

  return (
    <div className='GlobalSearchTypeahead__container'>
      <AsyncTypeahead
        ref={ref}
        highlightOnlyResult
        clearButton
        bsSize='lg'
        id='GlobalSearchTypeahead'
        className='GlobalSearchTypeahead'
        isLoading={isLoading}
        minLength={minLength}
        filterBy={customFilterBy}
        renderMenu={GlobalSearchMenu}
        selected={selected}
        onChange={handleChange}
        onSearch={handleSearch}
        useCache={false}
        options={[...states, ...districts.data, ...recentSearches]}
        paginate={false}
        placeholder='Search by state or school district'
        inputProps={{ autoComplete: 'GlobalSearchTypeahead' }}
      />
      <div className={iconContainerClass}>
        <img src={searchIcon} alt='search-icon' className='GlobalSearchTypeahead__icon--search' />
      </div>
    </div>
  )
}

export default GlobalSearchTypeahead
