import React from 'react'
import connectedNationLogo from '../../../assets/connected-nation-logo.png'
import fundsForLearningLogo from '../../../assets/ffl-logo.png'
import cosnLogo from '../../../assets/cosn-logo.png'
import sedtaLogo from '../../../assets/sedta-logo.png'
import futureReadyLogo from '../../../assets/future-ready-logo.png'
import fccLogo from '../../../assets/fcc-logo.png'
import shlbLogo from '../../../assets/shlb-logo.png'
import aasaLogo from '../../../assets/aasa-logo.png'
import eshLogo from '../../../assets/esh-logo.png'
import './Partners.sass'

function externalLink (href, imgAlt, imgSrc) {
  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      <img className='Partners--logo'
        alt={imgAlt}
        src={imgSrc} />
    </a>
  )
}

export default function Partners () {
  return (
    <div className='Partners'>
      <div className='Partners__text'>
        These organizations support 1 Mbps per student for the future of digital learning:
      </div>
      <div className='Partners--logos'>
        {externalLink('https://connectednation.org/', 'Connected Nation organization logo', connectedNationLogo)}
        {externalLink('https://www.fundsforlearning.com/', 'Funds For Learning company logo', fundsForLearningLogo)}
        {externalLink('https://www.cosn.org/', 'CoSN organization logo', cosnLogo)}
        {externalLink('https://www.setda.org/', 'State Educational Technology Directors Association logo', sedtaLogo)}
        {externalLink('https://futureready.org/', 'Future Ready Schools organization logo', futureReadyLogo)}
        {externalLink('https://www.fcc.gov/general/summary-e-rate-modernization-order', 'Federal Communications Commission logo', fccLogo)}
        {externalLink('https://www.shlb.org/', 'Schools, Health & Libraries Broadband Coalition logo', shlbLogo)}
        {externalLink('https://www.aasa.org/home/', "The School Superintendent's Association logo", aasaLogo)}
        {externalLink('https://www.educationsuperhighway.org/', 'Education Super Highway logo', eshLogo)}
      </div>
    </div>
  )
}
