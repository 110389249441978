import { connect } from 'react-redux'
import NationalDashboard from '../../components/NationalDashboard'

const mapStateToProps = (state) => {
  return {
    nation: state.nation,
    states: state.states
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const NationalDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NationalDashboard)

export default NationalDashboardContainer
