import React from 'react'
import COLUMNS from './columns'
import Header from '../../Table/Header'
import SharedTooltip from '../../Tooltip/SharedTooltip'
import ConsortiumTooltip from '../../Tooltip/ConsortiumTooltip'
import DirtyMetricTooltip from '../../Tooltip/DirtyMetricTooltip'
import unfitIcon from '../../../assets/not-found-icon.svg'
import exclamationPoint from '../../../assets/icon-24-icon-exclamation-secondary-3.svg'
import { useWindowDimensionsCtx } from '../../../hooks'
import { renderTabletMd } from '../../../utils/windowUtils'
import { contactUsPage } from '../../../routes'

export function ServiceHeaders () {
  return (
    <thead>
      <tr>
        {COLUMNS.map(({ header, column }) =>
          <Header header={header} column={column} key={column} />
        )}
      </tr>
    </thead>
  )
}

export function ServiceRows ({ services }) {
  if (!services) return null

  return (
    <tbody>
      {
        [...services]
          .sort((a, b) =>
            b.download_speed_mbps - a.download_speed_mbps
          )
          .map((service, rowIdx) => {
            if (!service.service_fit_for_cost) service.average_monthly_cost = null
            return (
              <tr key={`row_${rowIdx}`}>
                {COLUMNS.map(({ column, formatFn }, colIdx) => (
                  <td key={`column_${colIdx}`}>
                    {formatFn(service[column])}
                    <DirtyMetricTooltip
                      id={service.line_item}
                      enabled={!service.service_fit_for_cost && column === 'average_monthly_cost'}
                      className='DirtyServicesMetric'
                      message='We have insufficient data about the cost of broadband for this service.' />
                    <SharedTooltip
                      id={service.line_item}
                      enabled={service.shared && column === 'download_speed_mbps'}
                      className='DistrictServicesModal__tooltip--content' />
                    <ConsortiumTooltip
                      id={service.line_item}
                      enabled={service.applicant_type === 'Consortium' && column === 'average_monthly_cost'}
                      className='DistrictServicesModal__tooltip--content' />
                  </td>
                )
                )}
              </tr>
            )
          })
      }
    </tbody>
  )
}

export function UnfitMessage ({ enabled, services }) {
  const { width } = useWindowDimensionsCtx()
  const mobile = renderTabletMd(width)
  if (!enabled) return null

  if (services.length === 0) {
    return (
      <table>
        <ServiceHeaders />
        <tbody>
          <tr>
            <td
              colSpan={COLUMNS.length}
              style={mobile ? { paddingLeft: '14%' } : {}}
              className='UnfitMessage'>
              <img
                aria-hidden
                className='UnfitMessage__icon--no-services'
                src={unfitIcon}
                alt='clarify district icon' />
              <h1 className='UnfitMessage__text--no-services'>
                  We have insufficient broadband information for this school district. <br />
                  Please <a href={contactUsPage.path}>contact us</a> to clarify.
              </h1>
            </td>
          </tr>
        </tbody>
      </table>
    )
  } else {
    return (
      <table>
        <tbody>
          <tr>
            <td colSpan={COLUMNS.length} className='UnfitMessage'>
              <div className='UnfitMessage UnfitMessage--dirty-services'>
                <img aria-hidden
                  className='UnfitMessage__icon--dirty-services'
                  src={exclamationPoint}
                  alt='clarify district icon' />
                <div className='UnfitMessage__text--dirty-services'>
                   Our records suggest the broadband information for this school district may be incorrect.
                   Please <a href={contactUsPage.path}>contact us</a> to clarify.
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
