import { LOCATION_CHANGE } from 'connected-react-router'
import {
  CHART_RENDERED,
  STATE_COMPARISON_CLICKED
} from '../../../../actions/stateOverviewDashboard'
import { PERCENT_MEETING_GOAL_VIEW_UPDATED } from '../../../../actions/historicalStateMetrics'

export const initialState = {
  costOverTime: {},
  erateAppliedFunding: {},
  percentMeetingGoal: {}
}

export default function pdfImages (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      return initialState
    case CHART_RENDERED:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          [payload.stateCode]: payload.imgData
        }
      }
    case PERCENT_MEETING_GOAL_VIEW_UPDATED:
      return {
        ...state,
        percentMeetingGoal: {}
      }
    case STATE_COMPARISON_CLICKED:
      return {
        ...state,
        percentMeetingGoal: {
          ...state.percentMeetingGoal,
          [payload]: null
        },
        costOverTime: {
          ...state.costOverTime,
          [payload]: null
        }
      }
    default:
      return state
  }
}
