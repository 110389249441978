import React from 'react'
import Popover from 'react-bootstrap/Popover'
import Tooltip from '../../Tooltip'
import pluralize from 'pluralize'

function tooltipContent (serviceProviders) {
  return (
    <Popover id='ServiceProvidersTooltip' content='true'>
      <div>{serviceProviders.join(', ')}</div>
    </Popover>
  )
}

export default function ServiceProvidersTooltip ({ district }) {
  const otherProviders = district.service_providers.filter(provider => provider !== district.primary_provider)
  const numProviders = otherProviders.length
  return (
    <>
      {district.primary_provider}
      {
        numProviders >= 1 &&
        <Tooltip placement={'auto'} overlay={tooltipContent(otherProviders)}>
          <span> &
            <span className='ServiceProvidersTooltip DistrictDetails__link'> {numProviders} {pluralize('other', numProviders)}</span>
          </span>
        </Tooltip>
      }
    </>
  )
}
