import React from 'react'
import Popover from 'react-bootstrap/Popover'

export function tooltipContent (header) {
  const contentMap = {
    cost_per_mbps: (
      <Popover id={`DistrictsTable__cost-popover`} content='true'>
        <p><b>Total Monthly Cost/Mbps:</b>&nbsp;
          The total monthly cost of all internet access connections divided by the total internet bandwidth. When internet bandwidth is shared across multiple school districts, the cost of the bandwidth is allocated proportionally across school districts based on the number of students. Annual non-recurring costs are allocated across the total contract months to take into account the total cost of the service in cases where zero monthly cost was reported.
        </p>
      </Popover>
    ),
    bandwidth_per_student_mbps: (
      <Popover id={`NearbyServicesTable__bandwidth-popover`} content='true'>
        <p><b>Bandwidth/Student:</b>&nbsp;
          The total amount of internet bandwidth serving all schools in the school district divided by the total number of full-time students, based on USAC E-rate data. As district size increases, a smaller percentage of users are likely to be on the network at the same time during peak demand periods. To account for this, we also adjust the bandwidth per student on a sliding scale as district size increases to more accurately represent the per student bandwidth capacity.
        </p>
      </Popover>
    )
  }

  return (contentMap[header])
}
