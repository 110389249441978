import React from 'react'
import PropTypes from 'prop-types'

import StateBandwidthLink from '../../links/StateBandwidthLink'
import StateOverviewLink from '../../links/StateOverviewLink'
import StateErateLink from '../../links/StateErateLink'
import stateBandwidthIcon from '../../../assets/icon-24-icon-state-details-gray-1.svg'
import stateBandwidthSelected from '../../../assets/icon-24-icon-state-details-secondary-3.svg'
import stateOverviewIcon from '../../../assets/icon-24-icon-state-gray-1.svg'
import stateOverviewSelected from '../../../assets/icon-24-icon-state-secondary-3.svg'
import stateErateIcon from '../../../assets/icon-24-icon-state-erate-gray-1.svg'
import stateErateSelected from '../../../assets/icon-24-icon-state-erate-secondary-3.svg'
import * as routes from '../../../routes'
import { shouldAddBandwidthQuery } from '../../../utils/urlHelpers'
import MenuItem from './MenuItem'

export default function StateMenuDropdown ({ stateMetrics, pathname, stateCode, mobile, onClick }) {
  if (!stateCode) return null
  const bandwidthFilterQuery = stateMetrics && shouldAddBandwidthQuery(stateMetrics.num_districts_not_meeting_goal) ? ['bandwidth'] : []
  const menuIcons = {
    bandwidth: routes.onStateBandwidthPage(pathname) ? stateBandwidthSelected : stateBandwidthIcon,
    erate: routes.onStateEratePage(pathname) ? stateErateSelected : stateErateIcon,
    overview: routes.onStateOverviewPage(pathname) ? stateOverviewSelected : stateOverviewIcon
  }

  const dropdownClassname = mobile ? 'StateMenuDropdown--mobile' : 'StateMenuDropdown'

  return (
    <div className={dropdownClassname} onClick={onClick}>
      <StateOverviewLink stateCode={stateCode}>
        <MenuItem
          icon={menuIcons.overview}
          selected={routes.onStateOverviewPage(pathname)}
          mobile>
          State Overview
        </MenuItem>
      </StateOverviewLink>
      <StateBandwidthLink stateCode={stateCode} filters={bandwidthFilterQuery}>
        <MenuItem
          icon={menuIcons.bandwidth}
          selected={routes.onStateBandwidthPage(pathname)}
          mobile>
          Bandwidth Details
        </MenuItem>
      </StateBandwidthLink>
      <StateErateLink stateCode={stateCode}>
        <MenuItem
          icon={menuIcons.erate}
          selected={routes.onStateEratePage(pathname)}
          mobile>
          E-rate Details
        </MenuItem>
      </StateErateLink>
    </div>
  )
}

StateMenuDropdown.propTypes = {
  stateMetrics: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  stateCode: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
