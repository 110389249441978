import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'
import './ContactForm.sass'
import { CONTACT_US_OPTIONS } from '../CONTACT_US_OPTIONS'
import UsageForm from './UsageForm'

export default function ContactForm ({ selectedContactForm, onContactFormSubmit }) {
  const { register, handleSubmit, errors } = useForm()

  if (!selectedContactForm) { return (null) }

  const form = CONTACT_US_OPTIONS[selectedContactForm].formOptions
  const emailPattern = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$')

  const onSubmit = formData => {
    formData.topic = CONTACT_US_OPTIONS[selectedContactForm].topic
    onContactFormSubmit(formData)
  }

  const inputClassName = (baseClass, fieldErrors) => {
    return classnames(baseClass, { 'ContactForm--input': !fieldErrors }, { 'ContactForm--input-error': fieldErrors })
  }

  const errorMessage = (fieldErrors) => {
    if (!fieldErrors) { return null }
    return <p className='ContactForm__error-msg'>{fieldErrors.message}</p>
  }

  return (
    <div className='ContactForm'>
      <h3 className='ContactForm__title'>
        {form.title}:
      </h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='ContactForm__message'>
          <label className='ContactForm__message-label' htmlFor='message'>
            {form.messageLabel}
          </label>
          <textarea className={inputClassName('ContactForm__message--input', errors.message)}
            id='message'
            name='message'
            placeholder={form.placeholder}
            ref={register({ required: 'This field is required' })} />
          {errorMessage(errors.message)}
        </div>

        <div className='ContactForm__contact-info--container'>
          <div className='ContactForm__contact-info--name'>
            <label className='ContactForm__contact-info--label' htmlFor='name'>
              Your name
            </label>
            <input className={inputClassName('ContactForm__contact-info--input', errors.name)}
              id='name'
              name='name'
              placeholder='Enter your name'
              ref={register({ required: 'This field is required' })} />
            {errorMessage(errors.name)}
          </div>

          <div className='ContactForm__contact-info--email'>
            <label className='ContactForm__contact-info--label' htmlFor='email'>
              Your email
            </label>
            <input className={inputClassName('ContactForm__contact-info--input', errors.email)}
              id='email'
              name='email'
              placeholder='Enter your email address'
              ref={register({ required: 'This field is required', pattern: { value: emailPattern, message: 'Please enter a valid email address' } })} />
            {errorMessage(errors.email)}
          </div>
        </div>

        <UsageForm register={register} />

        <input className='ContactForm__submit' type='submit' value={form.buttonText} />
      </form>
    </div>
  )
}

ContactForm.propTypes = {
  selectedContactForm: PropTypes.string.isRequired,
  onContactFormSubmit: PropTypes.func.isRequired
}
