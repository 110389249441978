import commonState from './commonState'
import { FILTER_DISTRICTS } from '../../../../actions/districtsFilters'

export const initialState = { ...commonState, open: false, numApplied: 0 }

function calculateTotalFiltersApplied (filters) {
  let numApplied = 0
  for (const filter in filters) {
    if (!filters[filter].applied) continue
    if (filter === 'provider') {
      numApplied += filters[filter].applied.length
    } else {
      numApplied += 1
    }
  }
  return numApplied
}

export default (state = initialState, action) => {
  if (action.type === FILTER_DISTRICTS) {
    return {
      ...state,
      numApplied: calculateTotalFiltersApplied(action.payload)
    }
  }
  return state
}
