import React from 'react'
import PropTypes from 'prop-types'
import states from '../../../data/states'
import './ChartLegend.sass'

const series = (label, className) => {
  if (!label) return null
  return (
    <div className={`ChartLegend__series  ${className}`}>
      <div className={`ChartLegend__series__box`} />
      <div className='ChartLegend__series__label'>{label}</div>
    </div>
  )
}

export default function ChartLegend ({ stateCode, comparisonState }) {
  return (
    <div className='ChartLegend'>
      {series('National', 'national')}
      {series(states[stateCode].stateName, 'state')}
      {comparisonState && series(states[comparisonState].stateName, 'state-comparison')}
    </div>
  )
}

ChartLegend.propTypes = {
  stateCode: PropTypes.string.isRequired
}
