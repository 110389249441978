import React from 'react'
import PropTypes from 'prop-types'
import DownloadData from '../DownloadData'
import utils from '../../utils'

export default function DownloadNearbyServices ({ data, districtName }) {
  if (data.length === 0) return <div />

  const csvData = data.map((service) => {
    return {
      recipient_district_name: utils.formatCSVString(service.recipient_district_name),
      recipient_district_state_code: utils.formatCSVString(service.recipient_district_state_code),
      purpose: utils.formatPurpose(service.purpose),
      download_speed_mbps: utils.formatBandwidth(service.download_speed_mbps),
      average_monthly_cost: utils.formatCost(service.average_monthly_cost),
      service_provider_name: utils.titleize(service.service_provider_name),
      contract_expiration_date: utils.formatISODate(service.contract_expiration_date, 'Unknown'),
      distance: utils.formatDistance(service.distance),
      monthly_cost_per_mbps: utils.formatCost(service.monthly_cost_per_mbps),
      num_students: utils.formatNumber(service.recipient_district_num_students)
    }
  })

  const csvHeaders = [
    { label: 'School District', key: 'recipient_district_name' },
    { label: 'State Code', key: 'recipient_district_state_code' },
    { label: 'Purpose', key: 'purpose' },
    { label: 'Circuit Size', key: 'download_speed_mbps' },
    { label: 'Avg Monthly Cost', key: 'average_monthly_cost' },
    { label: 'Service Provider', key: 'service_provider_name' },
    { label: 'Contract End Date', key: 'contract_expiration_date' },
    { label: 'Proximity', key: 'distance' },
    { label: 'Monthly Cost per Mbps', key: 'monthly_cost_per_mbps' },
    { label: '# Students', key: 'num_students' }
  ]

  return (
    <DownloadData data={csvData} headers={csvHeaders} filename={'nearby internet services for ' + districtName + '.csv'} />
  )
}

DownloadNearbyServices.propTypes = {
  data: PropTypes.array.isRequired,
  districtName: PropTypes.string
}
