export const DISTRICT_PIN_CLICKED = 'DISTRICT_PIN_CLICKED'
export const districtPinClicked = (goalStatus, district, coordinates) => {
  return {
    type: DISTRICT_PIN_CLICKED,
    payload: {
      goalStatus,
      district,
      coordinates
    }
  }
}

export const DISTRICT_POPUP_OPENED = 'DISTRICT_POPUP_OPENED'
export const districtPopupOpened = (node, map) => {
  return {
    type: DISTRICT_POPUP_OPENED,
    payload: {
      node,
      map
    }
  }
}

export const DISTRICT_POPUP_CLOSED = 'DISTRICT_POPUP_CLOSED'
export const districtPopupClosed = () => {
  return {
    type: DISTRICT_POPUP_CLOSED,
    payload: {}
  }
}

export const SERVICE_PIN_CLICKED = 'SERVICE_PIN_CLICKED'
export const servicePinClicked = (services) => {
  return (dispatch, getState) => {
    const state = getState()
    const ben = services[0].recipient_district_ben
    const totalServices = state.nearbyServices.map.totalServices[ben] &&
      state.nearbyServices.map.totalServices[ben].totalServices
    const selected = state.nearbyServices.map.selected
    const selectedBen = Number.parseInt(Object.keys(selected)[0])
    const isSelected = ben === selectedBen

    dispatch({
      type: SERVICE_PIN_CLICKED,
      payload: {
        totalServices,
        selected: isSelected,
        services
      }
    })
  }
}

export const SERVICE_POPUP_OPENED = 'SERVICE_POPUP_OPENED'
export const servicePopupOpened = (node, map, lngLat) => {
  return {
    type: SERVICE_POPUP_OPENED,
    payload: {
      node,
      map,
      lngLat
    }
  }
}

export const SERVICE_POPUP_CLOSED = 'SERVICE_POPUP_CLOSED'
export const servicePopupClosed = () => {
  return {
    type: SERVICE_POPUP_CLOSED,
    payload: []
  }
}

export const SERVICE_PIN_MOUSEENTER = 'SERVICE_PIN_MOUSEENTER'
export const servicePinMouseEnter = (tooltipId) => {
  return {
    type: SERVICE_PIN_MOUSEENTER,
    payload: { tooltipId }
  }
}

export const SERVICE_PIN_MOUSELEAVE = 'SERVICE_PIN_MOUSELEAVE'
export const servicePinMouseLeave = () => {
  return {
    type: SERVICE_PIN_MOUSELEAVE
  }
}

export const DISTRICT_PIN_MOUSEENTER = 'DISTRICT_PIN_MOUSEENTER'
export const districtPinMouseEnter = (tooltipId) => {
  return {
    type: DISTRICT_PIN_MOUSEENTER,
    payload: { tooltipId }
  }
}

export const DISTRICT_PIN_MOUSELEAVE = 'DISTRICT_PIN_MOUSELEAVE'
export const districtPinMouseLeave = () => {
  return {
    type: DISTRICT_PIN_MOUSELEAVE
  }
}

export const DISTRICT_NAME_MOUSEENTER = 'DISTRICT_NAME_MOUSEENTER'
export function districtNameMouseEnter (tooltipId) {
  return {
    type: DISTRICT_NAME_MOUSEENTER,
    payload: { tooltipId }
  }
}

export const DISTRICT_NAME_MOUSELEAVE = 'DISTRICT_NAME_MOUSELEAVE'
export function districtNameMouseLeave () {
  return {
    type: DISTRICT_NAME_MOUSELEAVE
  }
}

export default {
  DISTRICT_PIN_CLICKED,
  DISTRICT_POPUP_CLOSED,
  SERVICE_PIN_CLICKED,
  SERVICE_POPUP_CLOSED,
  SERVICE_POPUP_OPENED,
  SERVICE_PIN_MOUSELEAVE,
  SERVICE_PIN_MOUSEENTER,
  DISTRICT_PIN_MOUSEENTER,
  DISTRICT_PIN_MOUSELEAVE,
  DISTRICT_NAME_MOUSEENTER,
  DISTRICT_NAME_MOUSELEAVE
}
