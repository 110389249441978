import deepmerge from 'deepmerge'
import commonState from '../commonState'
import { LOCATION_CHANGE } from 'connected-react-router'
import { RECEIVE_NEARBY_SERVICES } from '../../../../../actions/nearbyServices'
import { enableMenu } from '../../../../shared/filters/transformations/menu'
import applyURLParams from './applyURLParams'

export const initialState = deepmerge(commonState, {
  options: {
    internet: false,
    upstream: false,
    isp: false
  }
})

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      return applyURLParams(state, payload)
    case RECEIVE_NEARBY_SERVICES:
      return enableMenu(state)
    default:
      return state
  }
}
