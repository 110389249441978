import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import closeButtonIcon from '../../../assets/icon-24-icon-close-black.svg'
import selectDistrictImg from '../../../assets/select-location-district-dashboard.svg'
import selectStateImg from '../../../assets/select-location-state-overview.svg'

import GlobalSearchTypeahead from '../../../containers/GlobalSearchTypeahead'
import './index.sass'

export default function NavPromptTooltip ({ type, onClose, prompt, open, leftOffset = 0 }) {
  if (!open) { return null }
  return (
    ReactDOM.createPortal(
      <aside className='LowLightBackground'>
        <div className='NavPromptTooltip'
          style={{ left: `${leftOffset}px` }}
          onClick={(e) => e.stopPropagation()}>
          <img
            src={closeButtonIcon}
            role='button'
            alt='close icon'
            className='NavPromptTooltip__close-icon'
            onClick={onClose} />
          <div className='NavPromptTooltip__body'>
            <img
              src={type === 'STATE' ? selectStateImg : selectDistrictImg}
              alt='district and state dashboard page icon'
              className='NavPromptTooltip__body--image' />
            <p className='NavPromptTooltip__body--prompt'>
              {prompt}
            </p>
          </div>
          <div className='NavPromptTooltip__global-search-container'>
            <GlobalSearchTypeahead />
          </div>
        </div>
      </aside>
      , document.getElementById('app')
    )
  )
}

NavPromptTooltip.propTypes = {
  onClose: PropTypes.func,
  prompt: PropTypes.string,
  open: PropTypes.bool,
  leftOffset: PropTypes.number
}
