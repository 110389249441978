import React from 'react'
import './index.sass'
import NearbyServices from '../NearbyServices'
import * as status from '../../reducers/status'
import DistrictBanner from './DistrictBanner'
import NotFoundPage from '../NotFoundPage'

export default function DistrictDashboard ({
  district,
  districtServices,
  stateMetrics,
  meetingGoalDistricts,
  bannerVisible
}) {
  if (!district || !districtServices || !stateMetrics || !meetingGoalDistricts) return null
  if (district && district.status === status.ERROR) return <NotFoundPage />

  return (
    <div className='DistrictDashboard'>
      <DistrictBanner
        enabled={bannerVisible}
        district={district}
        districtServices={districtServices}
        stateMetrics={stateMetrics}
        meetingGoalDistricts={meetingGoalDistricts} />
      <NearbyServices />
    </div>
  )
}
