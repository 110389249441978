import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useWindowDimensionsCtx } from '../../hooks'
import { renderTabletMd } from '../../utils'

import './index.sass'

export default function Tooltip ({ trigger, placement = 'auto', overlay, children, ...props }) {
  const { width } = useWindowDimensionsCtx()
  const { mobileOnly } = props
  const mobileTrigger = ['focus', 'click']
  const hoverOnly = ['focus', 'hover']
  const defaultTrigger = renderTabletMd(width) ? mobileTrigger : mobileOnly ? null : hoverOnly

  return (
    <OverlayTrigger
      {...props}
      trigger={trigger || defaultTrigger}
      placement={placement}
      overlay={overlay}
      rootClose>
      {children}
    </OverlayTrigger>
  )
}
