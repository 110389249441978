import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { stateOverviewDashboard } from '../../../routes'

export default function StateOverviewLink ({ classNames = [''], stateCode, children }) {
  if (!stateCode) { return <div /> }

  return (
    <div className={classNames.join(' ')}>
      <Link to={stateOverviewDashboard.generatePath({ state_code: stateCode })}>
        {children}
      </Link>
    </div>
  )
}

StateOverviewLink.propTypes = {
  stateCode: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any
}
