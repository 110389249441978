import React from 'react'
import PropTypes from 'prop-types'
import DownloadData from '../../DownloadData'
import { formatAddress, formatDistrictURL } from '../../../utils/stringHelpers'

export default function DownloadDistricts ({ data, stateCode }) {
  const addressFields = ['physical_address', 'physical_address_2', 'physical_city', 'state_code']

  const csvData = data.map((district) => {
    return {
      ...district,
      entity_name: district.entity_name ? district.entity_name.replace(/"/g, '""') : '',
      csv_address: formatAddress(district, addressFields),
      csv_service_providers: district.service_providers ? `${district.service_providers.join('; ').replace(',', '\u002C').replace(/"/g, '""')}` : '',
      csv_link_to_district_dashboard_page: formatDistrictURL(district)
    }
  })

  const csvHeaders = [
    { label: 'District name', key: 'entity_name' },
    { label: 'Address', key: 'csv_address' },
    { label: 'Number of students', key: 'num_students' },
    { label: 'BEN(s)', key: 'entity_number' },
    { label: 'Bw per student in Mbps', key: 'bandwidth_per_student_mbps' },
    { label: 'Cost per Mbps', key: 'cost_per_mbps' },
    { label: 'Total IA Bandwidth in Mbps', key: 'total_bandwidth_mbps' },
    { label: 'IA Service Providers', key: 'csv_service_providers' },
    { label: 'Total monthly cost', key: 'total_monthly_cost' },
    { label: 'Contract expiring', key: 'contract_expiring' },
    { label: 'Latitude', key: 'latitude' },
    { label: 'Longitude', key: 'longitude' },
    { label: 'Link to district dashboard page', key: 'csv_link_to_district_dashboard_page' }
  ]

  return (
    <DownloadData data={csvData} headers={csvHeaders} filename={'districts-' + stateCode + '.csv'} />
  )
}

DownloadDistricts.propTypes = {
  data: PropTypes.array.isRequired,
  stateCode: PropTypes.string.isRequired
}
