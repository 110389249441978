import React from 'react'
import ChartDoughnut from '../../ChartDoughnut'
import colors from '../../../styles/variables.scss'
import { formatLargeCost } from '../../../utils/stringHelpers'

const afterDrawPlugin = (chart, _easing) => {
  if (chart.config.type === 'doughnut') {
    const { ctx, width, height } = chart.chart
    const fontSize = (height / 150).toFixed(2)
    ctx.font = `${fontSize}em "Axiforma-Bold"`
    ctx.fillStyle = colors.black
    ctx.textBaseline = 'middle'
    const text = formatLargeCost(chart.config.data.text)
    const textX = Math.round((width - ctx.measureText(text).width) / 2)
    const textY = height / 2
    ctx.fillText(text, textX, textY)
    ctx.save()
  }
}

export default function ErateFundingChart ({ totalFunding, totalC1Funding, totalC2Funding }) {
  if (!totalFunding || !totalC1Funding || !totalC2Funding) return null

  const data = {
    datasets: [{
      data: [totalC1Funding, totalC2Funding],
      backgroundColor: [colors.colorChartColor3, colors.colorChartColor1]
    }],
    text: totalFunding
  }

  return (
    <div className='ErateFundingChart__chart'>
      <ChartDoughnut
        data={data}
        afterDrawPlugin={afterDrawPlugin}
      />
    </div>
  )
}
