import React from 'react'
import { formatLargeCost } from '../../../utils/stringHelpers'
import { useWindowDimensionsCtx } from '../../../hooks'
import { renderMobileSm } from '../../../utils/windowUtils'
import Popover from 'react-bootstrap/Popover'
import InfoTooltip from '../../Tooltip/InfoTooltip'

function tooltipContent (category, text) {
  return (
    <Popover id={`ErateFundingChart__tooltip--category-${category}`} content='true'>
      {text}
    </Popover>
  )
}
export default function ErateFundingChartLegend ({ category, amount, text }) {
  const { width } = useWindowDimensionsCtx()
  const label = `Category ${category}`
  const fundingAmount = formatLargeCost(amount)
  const isMobile = renderMobileSm(width)

  return (
    <div className='ErateFundingChartLegend'>
      <h3 className='ErateFundingChartLegend__amount'>{fundingAmount}</h3>
      <div className='ErateFundingChartLegend__legend'>
        <span className={`ErateFundingChartLegend__legend-circle ErateFundingChartLegend__legend-circle--category${category}`} />
        {label}
        <InfoTooltip
          enabled={isMobile}
          className='ErateFundingChartLegend__tooltip'
          content={tooltipContent(category, text)}
          placement='auto'
        />
      </div>
      {!isMobile && <div className='ErateFundingChartLegend__text'>{text}</div>}
    </div>
  )
}
