import React from 'react'
import PropTypes from 'prop-types'
import DistrictsFilters from '../../containers/StateBandwidthDashboard/DistrictsFilters'
import DistrictsTableTitle from './DistrictsTable/DistrictsTableTitle'
import DistrictsTable from './DistrictsTable'
import DistrictsMap from '../../containers/StateBandwidthDashboard/DistrictsMap'
import { renderTabletMd } from '../../utils'
import * as hooks from '../../hooks'
import './index.sass'
import NotFoundPage from '../NotFoundPage'
import status from '../../reducers/status'

export default function StateBandwidthDashboard ({ districts, stateMetrics, onPinClick,
  onOpenPopup, onClosePopup, onSort, onTableMouseEnter, onTableMouseLeave }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const mobileOrTablet = renderTabletMd(width)

  if (stateMetrics && stateMetrics.status === status.ERROR) {
    return <NotFoundPage />
  }

  if (!districts || !stateMetrics) { return null }

  const { districts: districtsData, table } = districts.data

  return (
    <div className='StateBandwidthDashboard'>
      <DistrictsTableTitle
        status={districts.status}
        filters={districts.filters}
        filtered={districtsData.length}
        tableStatus={table.status}
        stateMetrics={stateMetrics}
        className='mobile' />
      <DistrictsFilters />
      <div className='StateBandwidthDashboard__content'>
        <DistrictsTable
          districts={districts}
          stateMetrics={stateMetrics}
          onSort={onSort}
          onTableMouseEnter={onTableMouseEnter}
          onTableMouseLeave={onTableMouseLeave}
        />
        {!mobileOrTablet &&
          <DistrictsMap
            districts={districts}
            onPinClick={onPinClick}
            onOpenPopup={onOpenPopup}
            onClosePopup={onClosePopup}
          />
        }
      </div>
    </div>
  )
}

StateBandwidthDashboard.propTypes = {
  districts: PropTypes.object,
  stateMetrics: PropTypes.object,
  onPinClick: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  onTableMouseEnter: PropTypes.func,
  onTableMouseLeave: PropTypes.func
}
