import { LOCATION_CHANGE } from 'connected-react-router'
import { RECEIVE_DISTRICT } from '../../../../actions/district'
import { REQUEST_GLOBAL_SEARCH } from '../../../../actions/globalSearch'
import { STATE_OVERVIEW, STATE_BANDWIDTH, STATE_ERATE, formatStateOption } from './states'
import * as routes from '../../../../routes'

function getStatePageLabel (pathname) {
  return routes.onStateBandwidthPage(pathname)
    ? STATE_BANDWIDTH
    : routes.onStateEratePage(pathname)
      ? STATE_ERATE
      : routes.onStateOverviewPage(pathname)
        ? STATE_OVERVIEW
        : null
}

const selected = (state = [], action = {}) => {
  const { type, payload } = action

  switch (type) {
    case RECEIVE_DISTRICT:
      const { data } = payload
      const district = {
        ...data,
        id: data.entity_number,
        label: `${data.entity_name} (${data.state_code})`,
        type: 'districts'
      }
      return [district]

    case REQUEST_GLOBAL_SEARCH:
      return []

    case LOCATION_CHANGE:
      const { location: { pathname } } = payload
      const noneSelected = routes.onNationalPage(pathname) ||
        routes.onContactUsPage(pathname) ||
        routes.onFAQPage(pathname) ||
        routes.onHomepage(pathname)
      if (noneSelected) return []
      if (!routes.onStatePage(pathname)) return state

      const statePage = routes.statePage(pathname)
      const { state_code: stateCode } = statePage.params

      return [formatStateOption(getStatePageLabel(pathname), stateCode)]

    default:
      return state
  }
}

export default selected
