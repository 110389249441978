import deepmerge from 'deepmerge'
import { isProvider } from './index'

export function updateFilter (state, name, update) {
  let filterWithUpdate = recordFilterUpdate(update)(state)
  if (!isProvider(name)) {
    filterWithUpdate = deepmerge(filterWithUpdate, update)
  }
  return {
    [name]: filterWithUpdate
  }
}

export const recordFilterUpdate = update => state => {
  return { ...state, ...{ update } }
}
