import { useWindowDimensionsCtx } from '../../../hooks'
import { renderMobileSm } from '../../../utils/windowUtils'

export const useMobileLeftOffset = () => {
  const { width } = useWindowDimensionsCtx()
  const mobileSm = renderMobileSm(width)
  return mobileSm ? 0 : (width / 4)
}

const NAV_PROMPT_WIDTH = 384

export const calculateDesktopLeftOffset = ({ left, width }) => {
  return left - (NAV_PROMPT_WIDTH / 2) + (width / 2)
}
