import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '../../Tooltip'
import TableCell from '../../Table/Cell'
import { districtDashboard } from '../../../routes'
import { tooltipContent } from '../tooltipContent'
import RecommendedServiceTooltip from '../../Tooltip/RecommendedServiceTooltip'
import ConsortiumTooltip from '../../Tooltip/ConsortiumTooltip'
import utils from '../../../utils'

const DistrictLink = ({ service, stateCode, onMouseEnter, onMouseLeave }) => {
  const outOfStateCode = stateCode !== service.recipient_district_state_code ? `(${service.recipient_district_state_code})` : ''
  return (
    <Link
      className='NearbyServicesTable__link--district-name'
      to={districtDashboard.generatePath({ entity_number: service.recipient_district_ben })}>
      <div
        id={service.recipient_district_ben}
        className={`NearbyServicesTable__cell--${service.recipient_district_ben}`}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}>
        {`${utils.titleize(service.recipient_district_name)} ${outOfStateCode}`}
      </div>
    </Link>
  )
}

const DistrictNameTableCell = ({ service, stateCode, onMouseEnter, onMouseLeave }) => {
  if (service.recommended) {
    return (
      <RecommendedServiceTooltip id='RecommendedService__tooltip'>
        <DistrictLink
          service={service}
          stateCode={stateCode}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </RecommendedServiceTooltip>
    )
  }
  return <DistrictLink service={service} stateCode={stateCode} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
}

function TableRow ({ service, district, onTableMouseEnter, onTableMouseLeave }) {
  const onMouseEnter = (event) => onTableMouseEnter(event.target.getAttribute('id'))
  const onMouseLeave = () => onTableMouseLeave(null)
  return (
    <tr className='NearbyServicesTable__row'>
      <TableCell key={`${service.line_item}-name`} classNamePrefix='NearbyServicesTable__cell' name='district-name'>
        <DistrictNameTableCell
          service={service}
          stateCode={district.state_code}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </TableCell>
      <TableCell key={`${service.line_item}-provider`} classNamePrefix='NearbyServicesTable__cell' name='internet-provider'>
        {utils.titleize(service.service_provider_name)}
      </TableCell>
      <TableCell key={`${service.line_item}-circuit`} classNamePrefix='NearbyServicesTable__cell' name='circuit-size'>
        {utils.formatBandwidth(service.download_speed_mbps)}
      </TableCell>
      <TableCell key={`${service.line_item}-cost`} classNamePrefix='NearbyServicesTable__cell' name='monthly-cost'>
        {utils.formatCost(service.average_monthly_cost, '0,0')}
        <ConsortiumTooltip enabled={service.applicant_type === 'Consortium'} id={service.line_item} />
      </TableCell>
      <Tooltip mobileOnly placement='bottom' overlay={tooltipContent('distance', 'NearbyServicesTable__header__tooltip--distance', district)}>
        <TableCell key={`${service.line_item}-distance`} classNamePrefix='NearbyServicesTable__cell' name='proximity'>
          {utils.formatDistance(service.distance)}
        </TableCell>
      </Tooltip>
      <Tooltip mobileOnly placement='bottom' overlay={tooltipContent('purpose')}>
        <TableCell key={`${service.line_item}-purpose`} classNamePrefix='NearbyServicesTable__cell' name='purpose'>
          {utils.formatPurpose(service.purpose)}
        </TableCell>
      </Tooltip>
      <TableCell key={`${service.line_item}-students`} classNamePrefix='NearbyServicesTable__cell' name='num-students'>
        {utils.formatNumber(service.recipient_district_num_students)}
      </TableCell>
    </tr>
  )
}

export default TableRow
