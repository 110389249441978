import { useCallback, useEffect, useRef, useState } from 'react'
import lodashDebounce from 'lodash.debounce'

const createCallback = (debounce, handleOnScroll) => {
  if (debounce) {
    return lodashDebounce(handleOnScroll, debounce, { trailing: true })
  } else {
    return handleOnScroll
  }
}

const setScrollPosition = (containerRef, offset, onBottom, offBottom, bottom, setBottom) => {
  if (!onBottom || !offBottom || !containerRef.current) return null
  const scrollNode = containerRef.current
  const scrollHeight = scrollNode.clientHeight
  const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + scrollHeight)
  const scrollPosition = Math.round(scrollNode.scrollHeight - offset)

  if (!bottom && scrollPosition <= scrollContainerBottomPosition) {
    onBottom()
    setBottom(true)
  } else if (bottom && (scrollPosition > scrollContainerBottomPosition)) {
    setBottom(false)
    offBottom()
  }
}

/**
 *  A react hook that invokes a callback when user scrolls to the bottom
 *
 * @param onBottom Required callback that will be invoked when scrolled to bottom
 * @param offBottom Required callback that will be invoked when scrolled away from bottom
 * @param offset Offset from bottom of page in pixels. E.g. 300 will trigger onBottom 300px from the bottom of the page
 * @param debounce Optional debounce in milliseconds, defaults to 200ms
 * @return React.MutableRefObject Optionally you can use this to pass to a element to use that as the scroll container
 */
export function useBottomScrollListener (onBottom, offBottom, offset = 0, debounce = 60) {
  const [bottom, setBottom] = useState(false)
  const containerRef = useRef(null)
  const handleOnScroll = useCallback(() => {
    setScrollPosition(containerRef, offset, onBottom, offBottom, bottom, setBottom)
  }, [offset, onBottom, offBottom, bottom, setBottom])

  useEffect(() => {
    const callback = createCallback(debounce, handleOnScroll)
    const ref = containerRef.current

    if (ref != null) {
      ref.addEventListener('scroll', callback)
    } else {
      window.addEventListener('scroll', callback)
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener('scroll', callback)
      } else {
        window.removeEventListener('scroll', callback)
      }
    }
  }, [handleOnScroll, debounce])

  return containerRef
}
