export default {
  AL: {
    stateName: 'Alabama',
    committed: true,
    govQuote: "Having access to high-speed internet is an essential tool to prepare our students to compete in today's workforce. Basic connectivity is no longer enough. Incredible innovations are happening in our 21st century classrooms, and we must ensure that school bandwidth grows alongside those advancements. In Alabama, we are fully embracing the goal of having 1 megabit per second per student.",
    govName: 'Kay Ivey'
  },
  AK: {
    stateName: 'Alaska',
    committed: true,
    govQuote: 'Internet access opens up learning opportunities for our students -- especially in remote areas of our state. It is essential that Alaskan students have equitable access to broadband in order to extend the benefit of great teachers and take advantage of media-rich educational tools. We are eager to support initiatives such as the School BAG grant and the Alaska K-12 Broadband Initiative that expand learning opportunities for our students in every classroom, every day.',
    govName: 'Mike Dunleavy'
  },
  AZ: {
    stateName: 'Arizona',
    committed: true,
    govQuote: 'Yesterday’s broadband speeds cannot support the rapid innovation that is shaping K-12 education. Too many students, specifically in our rural areas, and in our tribal nations are missing out. This is why Arizona is working to increase access to reliable, affordable broadband at the level of 1 Mbps per student to harness digital learning in the classroom and gain the skills necessary to succeed in today’s technology-driven economy.',
    govName: 'Doug Ducey'
  },
  AR: {
    stateName: 'Arkansas',
    committed: true,
    govQuote: 'In Arkansas, our students are developing 21st century skills in the classroom to succeed in tomorrow’s workforce. This requires high-speed internet access in every school - which is why we upgraded the Arkansas Public School Computer Network and can now provide 1 megabit per second per student to 98% of the school districts in Arkansas. We must continue to meet school broadband demands as our teachers and students adopt more and more classroom technology. The fast-paced learning environment must not be slowed by insufficient broadband.',
    govName: 'Asa Hutchinson'
  },
  CA: {
    stateName: 'California',
    committed: true,
    govQuote: 'Education is our most valuable tool, and giving kids and teachers the technology and resources they need to bridge the digital divide is an investment we are making to ensure kids today have the best opportunities to prepare for the future. This year, we committed $7.5 million to create scalable fiber solutions particularly in our hardest-to-reach schools to ensure that every classroom, every day in California has access to the broadband they need for research, projects, and overall knowledge of how the world works.',
    govName: 'Gavin Newsom'
  },
  CO: {
    stateName: 'Colorado',
    committed: true,
    govQuote: 'Ensuring every school in Colorado has access to high-speed broadband and fiber is critical for ensuring every Coloradan can take advantage of 21st century learning opportunities. I look forward to working with stakeholders across our state to close connectivity gaps and help Colorado schools meet the school bandwidth standard of at least 1 megabit per second per student.',
    govName: 'Jared Polis'
  },
  CT: {
    stateName: 'Connecticut',
    committed: true,
    govQuote: 'In Connecticut, we have taken on digital equity as the Brown v. Board of Education of our time. Whether it is connecting students at home through our Everybody Learns initiative or providing virtually limitless broadband to schools through the flagship Connecticut Education Network, we are working to ensure all students have an equal opportunity for digital learning, in every home and classroom, every day.',
    govName: 'Ned Lamont',
    stateQuote: 'Connecticut Education Network (CEN) provides Internet access for all K-12 schools across Connecticut. CEN ensures high performance, reliability/up-time, content filtering, and mitigation against security threats such as Distributed Denial of Service attacks. CEN also has ability to locally cache frequently accessed content and facilitate direct peering with national content delivery network exchanges, making commodity Internet access more efficient for schools throughout the state. Importantly, CEN can monitor bandwidth utilization and increase capacity when an established threshold has been surpassed, adheres to Net Neutrality principals, does not cap or throttle bandwidth, and allows every district to burst as needed. This ensures that that each district has the bandwidth they need at any given time without expending resources on unused capacity. Connect K-12 recognizes and celebrates the work CEN has done to equitably deliver robust connectivity at scale to schools throughout the state since 2000.'
  },
  DE: {
    stateName: 'Delaware',
    committed: true,
    govQuote: 'Expanding broadband access to all Delawareans — including our students — is necessary to further their education, acquire new skills, and compete in an economy that is evolving every day. That’s why we are working with partners in the private sector to eliminate broadband deserts and ensure that every Delaware citizen and business has access to high-speed broadband service by the end of 2020, while continuing our efforts to increase internet speeds for all students toward the FCC’s long-term goal.',
    govName: 'John Carney'
  },
  FL: {
    stateName: 'Florida',
    committed: false,
    govQuote: 'State leaders established a Digital Classroom Plan Initiative to bring increased connectivity and blended learning to more classrooms.',
    govName: 'Ron DeSantis'
  },
  GA: {
    stateName: 'Georgia',
    committed: true,
    govQuote: 'In keeping with my promise as Governor to increase rural broadband capabilities across the state, Georgia has made significant investments in improving K-12 broadband over the past year. We will continue working to equip Georgia students with high-speed internet in every classroom, every day, so that they are prepared for their futures.',
    govName: 'Brian Kemp'
  },
  HI: {
    stateName: 'Hawaii',
    committed: true,
    govQuote: 'Connection, communication, and collaboration are critical to both innovation and to student success. Hawaii has long been a leader in broadband access for students, with 100% of our schools having fiber access for many years. I am committed to maximizing our students’ learning opportunities and support the goal of every student having access to 1 megabit per second of bandwidth.',
    govName: 'David Ige'
  },
  ID: {
    stateName: 'Idaho',
    committed: true,
    govQuote: "Access to high-speed internet is essential to preparing our students to compete in today's workforce, and basic connectivity is no longer enough. We must ensure school bandwidth grows alongside the incredible innovations happening in our 21st century classrooms, and we embrace the future goal of 1 megabit per second per student as Idaho’s vision for broadband.",
    govName: 'Brad Little'
  },
  IL: {
    stateName: 'Illinois',
    committed: true,
    govQuote: 'Illinois is committed to making sure every student has access to broadband inside the classroom and out. Through the state’s Connect Illinois investment, we are connecting every classroom, every day with the broadband service needed for 21st century learning – and life.',
    govName: 'J.B. Pritzker'
  },
  IN: {
    stateName: 'Indiana',
    committed: true,
    govQuote: "High-speed internet access is just as essential to Indiana's prosperity today as highways were a century ago. We have made the most substantial single investment in broadband in our state's history and incredible progress connecting schools across the state. We will continue to work to ensure Hoosiers are able to take full advantage of the opportunities created by access to quality digital learning experiences in every classroom, every day.",
    govName: 'Eric Holcomb'
  },
  IA: {
    stateName: 'Iowa',
    committed: true,
    govQuote: "Connectivity to broadband internet is no longer a luxury, but an essential component to any child’s education. We are focused on bringing every student’s classroom into the 21st century so that they can take full advantage of the digital learning happening right now. While we have made progress, we know that there’s more to do to reach the FCC's long-term goal of 1 megabit per second per student.",
    govName: 'Kim Reynolds'
  },
  KS: {
    stateName: 'Kansas',
    committed: true,
    govQuote: 'To ensure all Kansas children can excel in our modern and changing economy, we must continue to expand internet access across our state to meet the FCC’s 1 megabit per second per student target. High-quality broadband is essential in creating innovative educational opportunities in every Kansas school — no matter the zip code.',
    govName: 'Laura Kelly'
  },
  KY: {
    stateName: 'Kentucky',
    committed: true,
    govQuote: 'Every student in the commonwealth should have the same opportunities to succeed and earn a quality education regardless of where they live. To prepare students, we need to ensure that all classrooms across Kentucky, both urban and rural, are equipped with 21st-century technology including pushing toward the FCC’s long-term goal of 1 Mbps to deliver world-class connectivity to our schools.',
    govName: 'Andy Beshear',
    stateQuote: 'Since 1992, the Office of Education Technology (OET) within the Kentucky Department of Education has provided the Kentucky Education Technology Systems (KETS) network, which delivers enterprise level Internet access for all 172 public K-12 school districts, the Kentucky School for the Blind, and the Kentucky School for the Deaf. KETS provides a highly reliable and scalable network (99.99% uptime during school hours), equity in terms of bandwidth provided, and multiple layers of security to mitigate against various threats. Because of the reliability and scalability of the KETS network, OET is able to provide several statewide cloud-based services including a student information system, content filtering, and a single financial management system. Importantly, OET monitors district bandwidth utilization and increases capacity when an established threshold or need is surpassed. This ensures that each district has the bandwidth they need at any given time without expending resources on unused capacity. Connect K-12 recognizes and celebrates the efficient and reliable services provided by the Kentucky Department of Education to equitably deliver robust connectivity to districts throughout the state.'
  },
  LA: {
    stateName: 'Louisiana',
    committed: true,
    govQuote: 'If we expect our children to compete for 21st century jobs, then we must provide every child with a 21st century education that includes access to digital learning opportunities. High-speed internet is a crucial component in this effort. Louisiana must continue its incredible progress connecting our students to next-generation broadband so they have access to online educational resources and tools in every classroom, every day.',
    govName: 'John Bel Edwards'
  },
  ME: {
    stateName: 'Maine',
    committed: true,
    govQuote: 'High-speed internet is critical to the success of Maine schools and our students. I am committed to ensuring that every Maine student, in every classroom, every day, has the support and tools, including high-speed broadband, to reach their full potential and become the innovators, entrepreneurs, and leaders that our state needs to succeed.',
    govName: 'Janet Mills'
  },
  MD: {
    stateName: 'Maryland',
    committed: true,
    govQuote: 'Every child in Maryland deserves access to a world-class education regardless of the neighborhood they happen to grow up in. As we work hard to provide our students with the skills they need to compete for 21st century careers, our administration will continue to lead the way on faster, more reliable, and affordable broadband across the state that can deliver the FCC goal of 1 Mbps per student.',
    govName: 'Larry Hogan'
  },
  MA: {
    stateName: 'Massachusetts',
    committed: true,
    govQuote: 'Our administration remains committed to expanding access to high-speed internet in every classroom, every day, all across the Commonwealth. This will ensure that all students are fully prepared to enter the pipeline of success here in Massachusetts, and are equipped with the necessary resources to thrive in the workplace and beyond.',
    govName: 'Charlie Baker'
  },
  MI: {
    stateName: 'Michigan',
    committed: true,
    govQuote: "Michigan teachers are hard at work preparing our students with 21st century skills for tomorrow's jobs. Ensuring high-speed internet access is in every school is critical to achieving the FCC's long-term goal of 1 megabit per second per student. That's why Michigan is focused on eliminating broadband deserts and making internet access more affordable. I look forward to working with stakeholders to increase connectivity speeds in order to expand every student's learning experience and prepare a next-generation workforce that's ready to excel in the future economy.",
    govName: 'Gretchen Whitmer'
  },
  MN: {
    stateName: 'Minnesota',
    committed: true,
    govQuote: 'In order to make Minnesota the Education State, we need to ensure that every student has equitable access to high-speed, affordable internet both at school and at home. Our school districts and service providers — along with the state — have made critical broadband investments that are making a real difference for students across Minnesota. We need to keep working to ensure that every student has access to 1 megabit per second in the classroom and that every student has equitable access to the digital resources they need to succeed.',
    govName: 'Tim Walz'
  },
  MS: {
    stateName: 'Mississippi',
    committed: true,
    govQuote: 'Mississippi is making incredible progress towards meeting the FCC’s recommended bandwidth goal of 1 megabit per second per student, and numbers prove this. Our state has seen a 327% increase in schools meeting this goal, and we’re now in the top 10 of most improved states - proof positive of our unwavering commitment to ensuring our students have the digital tools they need to succeed.',
    govName: 'Tate Reeves'
  },
  MO: {
    stateName: 'Missouri',
    committed: true,
    govQuote: '“Expanding broadband internet has always been a focus of my administration, and now more than ever, it is critical to a resilient economy,” said Governor Mike Parson. “Broadband has especially become an essential service needed to educate Missouri students – the skilled workforce of tomorrow. During the pandemic, approximately 20 percent of students in our state could not take part in online learning opportunities because of internet access and/or affordability challenges. We must address the digital divide now to ensure all Missouri students receive the high-quality education they deserve, no matter the circumstances, in every classroom, every day.”',
    govName: 'Mike Parson'
  },
  MT: {
    stateName: 'Montana',
    committed: true,
    govQuote: 'For too long, Montana has lagged other states in access to broadband, and it’s impacted our ability to attract job creators, increase access to telemedicine, and deliver more high-quality education opportunities. Expanding access to broadband will bring more opportunities into greater reach for Montanans, and we’re committed to making targeted investments alongside industry partners to expand broadband access across the state and close the digital divide. With more cable and fiber in the ground, Montana students will have access to the best education possible in every class room, every day, to prepare them for the future.',
    govName: 'Greg Gianforte'
  },
  NE: {
    stateName: 'Nebraska',
    committed: false,
    govQuote: 'Nebraska has been making rapid strides to upgrade broadband connectivity in our school classrooms. Since 2015, we’ve nearly doubled the median bandwidth per student in our schools.  We’ll continue to invest in broadband so that Nebraska’s students have the opportunity to sharpen their minds through digital learning.',
    govName: 'Pete Ricketts',
    stateQuote: 'Network Nebraska (NN) provides Internet access for K-12 schools across Nebraska. NN provides high reliability, intelligent routing, and mitigation against security and DDoS threats. NN also facilitates direct peering relationships with content delivery networks such as Google, Facebook, Akamai, and Internet2, making commodity Internet access more efficient for schools throughout the state. Importantly, NN has the ability to monitor bandwidth utilization and direct internet traffic through high capacity connections. This ensures that each district has the bandwidth they need at any given time without expending resources on unused capacity. Connect K-12 recognizes and celebrates the work Network Nebraska has done to equitably deliver robust connectivity to schools throughout Nebraska, while reducing the unit cost of Internet to one of the lowest in the country.'
  },
  NV: {
    stateName: 'Nevada',
    committed: false,
    govQuote: 'The success of our students and teachers is a top priority for me, and digital learning in the classroom is a critical part of improving educational outcomes and preparing students for the future. That’s why I’m committed to supporting the goal of every student in Nevada, especially those in our low-income and rural communities, having access to high-speed internet, providing students with the knowledge and skills necessary to be successful in our growing digital economy.',
    govName: 'Steve Sisolak'
  },
  NH: {
    stateName: 'New Hampshire',
    committed: true,
    govQuote: 'To succeed in today’s workforce, computer science and digital skills are an essential part of our student’s curriculum. We must ensure every school has internet speeds that can support the rapid innovation and growing technology demands in K-12 education. The New Hampshire School Connectivity Initiative has made incredible progress increasing high-speed broadband access across the state, and we are committed to ensuring every student in every corner of the state can access at least 1 megabit per second of connectivity.',
    govName: 'Chris Sununu'
  },
  NJ: {
    stateName: 'New Jersey',
    committed: true,
    govQuote: "New Jersey has some of the brightest students, teachers, and educators working to make this state a hub of innovation. We have made significant progress in setting up our classrooms with the high-speed internet our children need to succeed in today's technology-driven world, but there is still work to do. We are committed to the FCC's 1 megabit per second per student bandwidth goal; creating unprecedented access to STEM, Computer Science, and personalized learning opportunities.",
    govName: 'Phil Murphy'
  },
  NM: {
    stateName: 'New Mexico',
    committed: true,
    govQuote: "The future of New Mexico depends on providing our children with the 21st century skills needed to succeed in today's technology-driven economy. That means ensuring students in every corner of our state, especially in underserved rural areas, have access to high-speed broadband that enables them to take full advantage of digital learning in the classroom. Currently, 100% of public schools are fiber connected and we're working to achieve the same rate of schools meeting the FCC's 1 megabit per second per student bandwidth goal. Achieving that will assure that every child is prepared with the right technical and problem-solving skills to reach their full potential and help drive our state forward to a better future.",
    govName: 'Michelle Lujan Grisham'
  },
  NY: {
    stateName: 'New York',
    committed: false,
    govQuote: "New York is a leader in digital learning, and the $2 billion Smart Schools Bond Act supports our schools to access the high-speed internet required to give every child a world-class education. As technology continues to change the face of the world as we know it, New York State is leading by working towards the FCC's long-term bandwidth long-term goal of 1 megabit per second per student to equip students and teachers with the tools they need to succeed in the 21st century learning environment.",
    govName: 'Andrew Cuomo'
  },
  NC: {
    stateName: 'North Carolina',
    committed: true,
    govQuote: 'High-speed internet access is essential for our kids to learn and our economy to grow, and last year North Carolina became the first state to get all public schools connected. The national goal of 1 megabit per second per student emphasizes the importance of faster connections, and we’ll continue to push for greater internet speeds in schools and homes so that North Carolinians can take full advantage of digital learning.',
    govName: 'Roy Cooper',
    stateQuote: null
  },
  ND: {
    stateName: 'North Dakota',
    committed: true,
    govQuote: 'As the first state in the nation to have a 100G network providing a minimum of 1 gigabit per second connectivity to every public school district, North Dakota is proud to be on the leading edge of implementing 21st century infrastructure that benefits all citizens and grows our No. 1 resource – our children. We have invested heavily in technology and are delivering world-class connectivity that powers digital learning and enables innovative educational practices for every student, in every school, every day.',
    govName: 'Doug Burgum'
  },
  OH: {
    stateName: 'Ohio',
    committed: false,
    govQuote: 'Students, like all Ohioans, need access to reliable high-speed internet access. My administration is committed to making sure broadband is a part of our overall infrastructure strategy. We recently issued a statewide broadband access report, which expressed the need to develop plans to improve and expand broadband throughout our communities.',
    govName: 'Mike DeWine'
  },
  OK: {
    stateName: 'Oklahoma',
    committed: true,
    govQuote: 'High speed internet allows us to expand personalized learning opportunities for our children. Our schools have made incredible progress improving connectivity so that students and teachers can take full advantage of digital learning in the classroom. Our goal is to ensure all schools have the bandwidth to support the use of technology in every classroom, every day and businesses in every corner of the state have a minimum of 500 megabits per second per student. I look forward to continuing to work with my administration to build upon this progress and improve broadband for all Oklahomans.',
    govName: 'Kevin Stitt'
  },
  OR: {
    stateName: 'Oregon',
    committed: true,
    govQuote: 'In Oregon, we are making a historic investment in our schools so that all of our kids can graduate high school with a plan for the future and the tools to compete in a global economy. To achieve that, our schools need to provide real-world digital learning opportunities. Through the recent creation of the Oregon Broadband Office, we are committed to ensuring every student — especially those in rural and underserved communities — has access to at least 1 megabit per second of connectivity.',
    govName: 'Kate Brown'
  },
  PA: {
    stateName: 'Pennsylvania',
    committed: true,
    govQuote: 'Access to high-speed internet is no longer considered a luxury, but a basic necessity for 21st century learning. Reaching a bandwidth goal of 1 megabit per second per student would help ensure every child in Pennsylvania has access to a high-quality education that will equip them with the tools to compete in a high-tech economy.',
    govName: 'Tom Wolf'
  },
  RI: {
    stateName: 'Rhode Island',
    committed: false,
    govQuote: 'Affordable internet access is vital to ensure that Rhode Island students can compete for the jobs of tomorrow. We have made incredible progress in providing basic connectivity for our students but must ensure every student has access to 1 megabit per second in the classroom.  Alongside educational leaders, we will work to improve digital equity and ensure insufficient broadband does not impede the provision of a world-class education.',
    govName: 'Gina Raimondo'
  },
  SC: {
    stateName: 'South Carolina',
    committed: true,
    govQuote: "Much has been accomplished to transition K-12 education in South Carolina into the digitally connected era, yet much remains to be done. Having access to broadband that can support digital learning in every classroom, every day will increase exponentially as more schools implement 1-to-1 computing, as more teachers infuse technology into their classroom instruction, and as technology transforms and supports personalized learning. We embrace the FCC's bandwidth goal of 1 Megabit per second (1 Mbps) per student and are proud to make this investment on behalf of our people and for the future of our workforce.",
    govName: 'Henry McMaster'
  },
  SD: {
    stateName: 'South Dakota',
    committed: true,
    govQuote: 'South Dakota is one of the nation’s leaders in providing reliable, high-speed internet to our students. Connectivity in the every classroom, every day, arms our students with the tools they need to prepare for digital careers and to advance South Dakota’s economy.',
    govName: 'Kristi Noem'
  },
  TN: {
    stateName: 'Tennessee',
    committed: true,
    govQuote: 'Infrastructure creates arteries of prosperity in our state and we believe that broadband is core to our modern infrastructure. My administration is proud to invest in getting more Tennesseans connected so everyone from educators to entrepreneurs can reap the benefits of quality broadband access — for our schools, this means quality broadband access in every classroom, every day.',
    govName: 'Bill Lee'
  },
  TX: {
    stateName: 'Texas',
    committed: true,
    govQuote: 'High-speed internet is no longer a luxury but a necessity for students and teachers across Texas—in every classroom, every day. The Classroom Connectivity Initiative helps ensure that Texas students have access to the technology and connectivity they need to learn and advance in their education. Every child deserves a quality education, and Texas will continue to build on this initiative to increase high-speed broadband across our communities and bring our schools into the 21st century.',
    govName: 'Greg Abbott'
  },
  UT: {
    stateName: 'Utah',
    committed: true,
    govQuote: 'For nearly three decades, Utah has prioritized investments in school connectivity.  Today, the Utah Education and Telehealth Network (UETN) is a national model for the delivery of cost-effective, robust connectivity to schools in urban and rural areas alike.  My administration remains committed to supporting their work to ensure that schools in every Utah county are equipped with high-speed, scalable connections that enable digital teaching and learning opportunities in every classroom, every day.',
    govName: 'Spencer Cox',
    stateQuote: 'The Utah Education and Telehealth Network (UETN) provides Internet access for K-12 schools across Utah. UETN ensures high reliability/up-time, content filtering, and mitigation against security threats. UETN also has ability to locally cache frequently accessed content and facilitate direct peering with content delivery networks, making commodity Internet access more efficient for schools throughout the state. Importantly, UETN has the ability to monitor bandwidth utilization and increase capacity when an established threshold has been surpassed. This ensures that that each district has the bandwidth they need at any given time without expending resources on unused capacity. Connect K-12 recognizes and celebrates the work UETN has done to equitably deliver robust connectivity to schools throughout the state.'
  },
  VT: {
    stateName: 'Vermont',
    committed: false,
    govQuote: "The growing role technology plays in today's workforce is undeniable. Getting our schools the bandwidth needed to facilitate digital learning for our students will help them compete, strengthen our workforce, and ultimately grow our economy.",
    govName: 'Phil Scott'
  },
  VA: {
    stateName: 'Virginia',
    committed: false,
    govQuote: ' ',
    govName: ' '
  },
  WA: {
    stateName: 'Washington',
    committed: true,
    govQuote: "Access to high-speed internet is essential to preparing our students to compete in today's workforce — and basic connectivity is no longer enough. We must ensure that school bandwidth grows alongside the incredible innovations happening in our 21st century classrooms, which is why we have embraced the future goal of 1 megabit per student as Washington’s vision for broadband",
    govName: 'Jay Inslee'
  },
  WV: {
    stateName: 'West Virginia',
    committed: true,
    govQuote: "I've said for a long time that we need to make education the centerpiece of everything we do in West Virginia, and in today’s world that means making sure every single one of our students has access to the high-speed internet connectivity they need to take full advantage of digital learning in the classroom. My administration and I have been working hard to bring computer science education into every school and to bring broadband to every corner of our state, especially in some of our more rural areas. But we still have a ways to go, and that starts with achieving the FCC's 1 Mbps per student bandwidth goal. If we can meet this goal, I truly believe that our wonderful, bright students can make West Virginia the next great tech hub in the country.",
    govName: 'Jim Justice',
    stateQuote: 'WV districts purchase bandwidth based on local statistical data that reflects utilization, ensuring that schools remain good stewards of the E-rate program and USF funds. As demands and utilization increase with the influx of devices, this amount will grow. The WV K-12 Network provides Internet access for K-12 schools across West Virginia. Our service provider ensures high reliability/up-time and mitigation against security threats, while the WV Department of Education Office of Network Operations provides content filtering statewide. The service provider also has ability to locally cache frequently accessed content and facilitate direct peering with content delivery networks, utilizing Internet 2 connectivity to its fullest and making commodity Internet access more efficient for schools throughout the state. Importantly, the WV K-12 Network has the ability to monitor bandwidth utilization and increase capacity when an established threshold has been surpassed. This ensures that each district has the bandwidth they need at any given time without expending resources on unused capacity. Connect K-12 recognizes and celebrates the work that the WV K-12 Network has done to equitably deliver robust connectivity to schools throughout the state.'
  },
  WI: {
    stateName: 'Wisconsin',
    committed: true,
    govQuote: 'This pandemic has only underscored how critically important it is for our kids to have access to reliable, affordable internet access at home. I’m proud our work to expand broadband has ensured more than 235,000 homes will have access to new or improved service in Wisconsin. I know what’s best for our kids is what’s best for our state, and that’s why we will continue working toward our goal of one megabit per student at every school.',
    govName: 'Tony Evers'
  },
  WY: {
    stateName: 'Wyoming',
    committed: true,
    govQuote: "Over many years, Wyoming's robust educational network has grown an infrastructure that goes beyond federal standards for student connectivity. We welcome the FCC's 1 megabit per second per student bandwidth goal and are committed to ensuring school bandwidth continues to grow to meet the digital learning needs of our students. With access to the very best technologies, our children are equipped to prosper in today's changing economy and to shape their own destiny.",
    govName: 'Mark Gordon'
  }
}
