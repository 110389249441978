import React from 'react'
import PropTypes from 'prop-types'
import states from '../../../data/states'
import './StatePopup.sass'
import StateOverviewLink from '../../links/StateOverviewLink'
import { formatPercent } from '../../../utils/stringHelpers'
import stateOverviewIcon from '../../../assets/icon-24-icon-state-secondary-3.svg'

export default function StatePopup ({ popup }) {
  const { open, location, metric, stateCode } = popup
  if (!open) return null
  return (
    <div className={`StatePopup ${popup.stateCode}`} style={{ left: location.x, top: location.y }}>
      <div className='StatePopup__header'>{states[stateCode].stateName}</div>
      <div className='StatePopup__content'>
        <div className='StatePopup__text'>
          {
            metric === null
              ? 'Data is unavailable'
              : `${formatPercent(metric)} of school districts are at or over 1 Mbps per student.`
          }
        </div>
        <StateOverviewLink classNames={['StatePopup__link']} stateCode={stateCode}>
          <img src={stateOverviewIcon} alt='state menu icon' className='StatePopup__icon' />
          {`View ${stateCode} Overview`}
        </StateOverviewLink>
      </div>
    </div>
  )
}

StatePopup.propTypes = {
  popup: PropTypes.object.isRequired
}
