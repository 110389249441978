import deepmerge from 'deepmerge'
import commonState from '../commonState'

export default deepmerge(commonState, {
  options: {
    min: {
      boundary: 0,
      value: 0
    },
    max: {
      boundary: 10000,
      value: 10000
    }
  }
})
