import React from 'react'
import PropTypes from 'prop-types'
import faqIcon from '../../../assets/icon-24-icon-faq-gray-1.svg'
import faqIconTeal from '../../../assets/icon-24-icon-faq-secondary-3.svg'
import { faqPage, onFAQPage } from '../../../routes'
import MenuItem from './MenuItem'
import { Link } from 'react-router-dom'

export default function Faq ({ pathname, onClick, mobile = false }) {
  const icon = onFAQPage(pathname) ? faqIconTeal : faqIcon
  return (
    <div className='Navbar__link Navbar__link--faq' onClick={onClick}>
      <Link to={faqPage.path}>
        <MenuItem
          icon={icon}
          selected={onFAQPage(pathname)}
          mobile={mobile} >
          FAQ
        </MenuItem>
      </Link>
    </div>
  )
}

Faq.propTypes = {
  mobile: PropTypes.bool
}
