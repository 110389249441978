import { useEffect } from 'react'
import { moveLayerToFront } from '../utils/mapUtils'

/**
 * Explicitly sets the stack order for the top layers of the map on each render.
 *
 * @param map {mapbox.Map} - mapbox map instance
 * @param layerStack {[string]} - array of layer ids from bottom to top
 */
export function useMapLayerOrder (map, layerStack) {
  useEffect(() => {
    if (!map) return

    function sortLayers () {
      if (needsOrdering(map, layerStack)) {
        layerStack.map(layer => moveLayerToFront(map, layer))
      }
    }

    map.on('render', sortLayers)

    return () => {
      map.off('render', sortLayers)
    }
  }, [map, layerStack])
}

function needsOrdering (map, layers) {
  return layersLoaded(map, layers) && !layersOrdered(map, layers)
}

function getTopLayerIds (map, numLayers) {
  const { layers } = map.getStyle()
  return layers
    .slice(Math.max(layers.length - numLayers, 0))
    .map(({ id }) => id)
}

function layersLoaded (map, layers) {
  return layers.every(layer => map.getLayer(layer))
}

function layersOrdered (map, layers) {
  return getTopLayerIds(map, layers.length).every(
    (layerId, idx) => layerId === layers[idx]
  )
}

export default useMapLayerOrder
