import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Line } from 'react-chartjs-2'
import ChartHeader from './ChartHeader'
import colors from '../../../styles/variables.scss'
import options from './areaChartOptions'
import { renderMobile } from '../../../utils'
import * as hooks from '../../../hooks'
import StateErateLink from '../../links/StateErateLink'

import './ErateAppliedFunding.sass'

export default function ErateAppliedFunding ({ stateCode, chartData, onChartRendered, expandWidth }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const [chartRendered, setChartRendered] = useState(false)
  const chartRef = React.createRef()
  const { title, subtitle, labels, stateData } = chartData

  const data = (canvas) => {
    const ctx = canvas.getContext('2d')
    ctx.restore()
    const gradient = ctx.createLinearGradient(0, 0, 0, 500)
    gradient.addColorStop(0, colors.white)
    gradient.addColorStop(1, colors.colorChartColor3)

    return {
      labels,
      datasets: [
        {
          backgroundColor: gradient,
          borderColor: colors.colorChartColor3,
          fill: 'origin',
          pointStyle: 'circle',
          pointBorderWidth: 2,
          pointBorderColor: colors.white,
          pointHoverRadius: 8,
          pointHoverBorderWidth: 2,
          pointHoverBorderColor: colors.colorChartColor3,
          pointHoverBackgroundColor: colors.white,
          pointBackgroundColor: colors.colorChartColor3,
          data: stateData
        }
      ]
    }
  }

  const animationOptions = {
    animation: {
      onComplete: (e) => {
        if (!chartRendered) {
          const imgData = chartRef.current.chartInstance.toBase64Image()
          onChartRendered({ name: 'erateAppliedFunding', imgData, stateCode })
          setChartRendered(true)
        }
      }
    }
  }

  return (
    <div className={classnames('ErateAppliedFunding', {
      'ErateAppliedFunding__expand': expandWidth
    })}>
      <ChartHeader
        title={title}
        subtitle={subtitle}
        chartName='erate-applied-funding' />
      <div className='ErateAppliedFunding__content-container'>
        <div className='ErateAppliedFunding__chart'>
          <Line
            id='ErateAppliedFunding'
            ref={chartRef}
            data={data}
            options={{ ...options(renderMobile(width)), ...animationOptions }} />
        </div>
        <div className='ErateAppliedFunding__button'>
          <StateErateLink stateCode={stateCode} classNames={['StateButtonLink']}>
            {`Learn how ${stateCode} schools use E-rate`}
          </StateErateLink>
        </div>
      </div>
    </div>
  )
}

ErateAppliedFunding.propTypes = {
  chartData: PropTypes.object,
  onChartRendered: PropTypes.func
}
