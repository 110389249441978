import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, Image } from '@react-pdf/renderer'
import states from '../../../data/states'
import '../GovernorQuoteBox.sass'
import bundleLoader from '../../../utils/bundle-loader'
import colors from '../../../styles/variables.scss'

const images = bundleLoader.importGovernorImages()

const headerStyle = {
  backgroundColor: colors.colorSecondary310Percent,
  color: colors.black,
  fontSize: '1.75vw',
  padding: '2mm',
  display: 'inline-flex',
  flexDirection: 'row'
}

const quoteStyle = {
  backgroundColor: colors.colorLightGray1,
  color: colors.black,
  fontSize: '1.5vw',
  padding: '2mm'
}

export default function GovernorQuoteBoxPDF ({ stateCode }) {
  if (!states[stateCode].committed) { return <View /> }

  return (
    <View style={{ paddingBottom: '10mm', width: '40%' }}>
      <View>
        <View style={headerStyle} >
          <Image src={images[stateCode + '.jpeg']} style={{ width: '15mm',
            height: '15mm',
            borderRadius: '15mm' }} />
          <Text style={{ width: '70%', marginLeft: '1mm' }}>
          Gov. {states[stateCode].govName} is committed to connecting {states[stateCode].stateName} students
          </Text>
        </View>
        <Text style={quoteStyle}>
        "{states[stateCode].govQuote}"
        </Text>
      </View>
    </View>
  )
}

GovernorQuoteBoxPDF.propTypes = {
  stateCode: PropTypes.string
}
