import actions from '../../../actions/districtServices'
import * as status from '../../status'

const initialState = {
  data: [],
  recipientDistrictBen: null,
  status: status.INITIALIZED
}

function districtServices (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_DISTRICT_SERVICES:
      return {
        data: state.data,
        recipientDistrictBen: payload.recipientDistrictBen,
        status: status.REQUESTED
      }

    case actions.RECEIVE_DISTRICT_SERVICES:
      return {
        data: payload.data,
        recipientDistrictBen: payload.recipientDistrictBen,
        status: status.LOADED
      }

    case actions.RECEIVE_DISTRICT_SERVICES_ERROR:
      return {
        data: state.data,
        recipientDistrictBen: state.recipientDistrictBen,
        error: payload.error.message,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default districtServices
