import React from 'react'
import ContactInformation from './ContactInformation'
import './index.sass'

export default function PrivacyPolicy () {
  return (
    <div className='PrivacyPolicyContainer'>
      <div className='PrivacyPolicy'>
        <h1 className='PrivacyPolicy__title'>Connect K-12 Privacy Policy</h1>
        <p>Effective date: August 5, 2020</p>
        <h2 className='PrivacyPolicy__section-title'>Data Usage Policy</h2>
        <p>Connected Nation, Inc. (hereinafter referred to as "CN") gathers and reports data on school and library network capacity and costs for the Connect K-12 initiative (hereinafter referred to as "Initiative"). It is one part of our overall strategy to analyze price, speed, and market availability data so that we can help ensure that schools and libraries are able to access the right amount of bandwidth at the right price.</p>
        <p>CN takes seriously our responsibility to prevent any misuse of data. We believe that part of this responsibility is to communicate the following specific policies for use of the data we collect:</p>
        <ul>
          <li>CN may collect your name, birth date, email address, address, phone number, state, zip code and any communications with CN.</li>
          <li>CN will use detailed data to develop analyses, reports, presentations, and policy papers available to the general public. Data will be aggregated by geography (e.g., regional, state) as well as other characteristics (e.g., socioeconomic composition) and presented with grouped statistics. Where individual district data points are used to illustrate a specific point, they will always be presented in an anonymized fashion unless a district has specifically opted-in to allow us to use its name.</li>
          <li>We will share detailed data with governmental organizations such as the <strong>Federal Communications Commission (FCC), U.S. Department of Education (DOE), and state education agencies and their intermediate units</strong> upon request and within their respective jurisdictions.</li>
          <li><strong>School districts and libraries</strong> will have access to aggregate national and regional data and anonymized data within their geographic areas. They will not be given identifying data for specific users without the explicit permission of participating users.</li>
          <li><strong>Research partners</strong> may have access to specific non-public parts of our collected data for the purposes of pursuing a specific research goal that advances the Initiative. Any data shared with a non-governmental agency will be governed by an agreement limiting its use to the specified research project.</li>
          <li><strong>Commercial entities</strong> will not have access to any data beyond what is release pursuant to the Connect K-12 Initiative to the general public, other than when we partner with them for research purposes as described above. We will not sell data to any entity.</li>
        </ul>
        <p>Any data collected through this Initiative will empower the FCC, DOE, state agencies, districts, and libraries with the information they need to increase the availability of service and lower the price of broadband for schools and libraries.</p>

        <h2 className='PrivacyPolicy__section-title'>Privacy Policy</h2>
        <p>This Privacy Policy is incorporated by reference into the Connect K-12 End User Agreement. The term "CN," "we," "our," and "us" includes and our affiliates and subsidiaries. The Privacy Policy explains how CN may:</p>
        <ul>
          <li>collect,</li>
          <li>use, and</li>
          <li>disclose</li>
        </ul>
        <p>information we obtain through CN products and services.</p>
        <p><strong>"CN Product and Services"</strong> includes connectk12.org, and any related products, mobile applications or Internet services under CN's control, whether partial or otherwise, in connection with providing such services. The connectk12.org provides reporting based on E-rate form 471 data.</p>
        <p><strong>"PAI"</strong> is short for Personal and/or Applicant Information and means information that alone or in combination with other information may be used to readily identify, contact, or locate you or other members of your organization, such as: name, address, birth date, email address, or phone number. We do not consider PAI to include information that has been aggregated or anonymized so that it does not allow a third party to easily identify a specific individual or applicant.</p>

        <h2 className='PrivacyPolicy__section-title'>OUR PRODUCTS AND SERVICES COLLECT YOUR INFORMATION</h2>
        <p>We may collect personal and/or applicant information when:</p>
        <ul>
          <li>you provide it to us; or</li>
          <li>we obtain it from public sources.</li>
        </ul>
        <p>We also collect user information, such as anonymous information about your usage statistics, when you use CN products and services.</p>
        <p><strong>Registration and Account Information.</strong> You must register to use CN products and services. To register, you may need to provide PAI, such as your name, email address, and password. You may also need to provide information about your school district and E-rate participation which are not PAI and are covered separately by our Data Usage Policy.</p>
        <p><strong>Using CN Products and Services.</strong> We collect information, including PAI, that you provide to us when you use CN products and services or submit an inquiry to us. We may also collect PAI about school officials from public sources, such as from the U.S. Department of Education. This PAI may include your log-in information, name, school address, and school contact information.</p>
        <p><strong>Cookies, Automatic Data Collection, and Related Technologies.</strong> CN products and services collect and store information that is generated automatically as you use it, including your preferences and anonymous usage statistics.</p>
        <p>When you visit web sites and services provided by CN, we and our third-party service providers may receive and record information in our server logs from your browser, including your IP address, cookies and similar technology. Cookies are small text files placed in visitors’ computer browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, CN products and services may not work properly.</p>
        <p>By using CN products and services, you are authorizing us to gather, parse, and retain data related to the provision of CN products and services.</p>

        <h2 className='PrivacyPolicy__section-title'>HOW WE USE YOUR INFORMATION</h2>
        <p>We use your information to communicate with you.</p>
        <p>We do not rent, sell, or share PAI with nonaffiliated companies or vendors for direct marketing purposes, unless you have given us explicit permission.</p>
        <p>We may use aggregated information that does not identify you specifically for any purpose, including for reporting and public information purposes.</p>
        <p><strong>Internal and Service-Related Usage.</strong> We use information, including PAI, for internal and service-related purposes only and may provide it to authorized third parties to allow us to facilitate CN products and services. We may use and retain any data we collect to provide and improve our services.</p>
        <p><strong>Communications.</strong> We may contact you using the information you provided to us or that we have obtained from public sources for informational, communication, and operational purposes.</p>
        <p><strong>Marketing.</strong> We do not rent, sell, or share PAI about you with other people or nonaffiliated companies for direct marketing purposes unless you have given us explicit permission. We may communicate with you to provide you with information we think may be of interest to you related to our nonprofit mission.</p>
        <p><strong>Aggregate Data.</strong> As described in this Data Policy, we may anonymize and aggregate data collected through CN products and services and use it for any purpose. For example, we may use and aggregate anonymous data to build reports that we distribute to school districts, state departments of education, and other similar organizations. Such reports could include aggregated or anonymized prices paid for E-rate supported services and products.</p>

        <h2 className='PrivacyPolicy__section-title'>WE MAY DISCLOSE YOUR INFORMATION</h2>
        <p>We may share your information:</p>
        <ul>
          <li>with our third-party service providers,</li>
          <li>to comply with legal obligations, and</li>
          <li>with your permission.</li>
        </ul>
        <p><strong>As Required by Law and Similar Disclosures.</strong> We may access, preserve, and disclose your PAI, other account information, and content if we believe doing so is required by law or if those actions are reasonably necessary to:</p>
        <ul>
          <li>comply with legal process, such as a court order or subpoena;</li>
          <li>enforce this Privacy Policy;</li>
          <li>respond to claims that any content violates the rights of third parties;</li>
          <li>respond to your requests for customer service;</li>
          <li>respond to law enforcement;</li>
          <li>investigate and prevent unauthorized transactions or other illegal activities; or</li>
          <li>protect our or others’ rights, property, or personal safety.</li>
        </ul>
        <p>We may also disclose your PAI with your permission.</p>

        <h2 className='PrivacyPolicy__section-title'>SECURITY OF YOUR INFORMATION</h2>
        <p>We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. CN takes reasonable steps to protect PAI collected via the Site from loss, misuse and unauthorized access, disclosure, alteration and destruction.</p>
        <p>Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us. We do not accept liability for theft, destruction or inadvertent disclosure of your PAI. In addition, other Internet sites or services that may be accessible through the K-12 website have separate data and privacy practices independent of CN and CN disclaims any responsibility or liability for their policies or actions.</p>

        <h2 className='PrivacyPolicy__section-title'>UPDATE YOUR INFORMATION OR POSE A QUESTION OR SUGGESTION</h2>
        <p>If you would like to update or correct any information that you have provided to us through your use of any CN products or services, or if you have suggestions for improving this Privacy Policy, please send an email to <a href='mailto:support@connectk12.org'>support@connectk12.org</a></p>

        <h2 className='PrivacyPolicy__section-title'>UPDATE YOUR INFORMATION OR POSE A QUESTION OR SUGGESTION</h2>
        <p>We may revise this Privacy Policy, so we advise you to review it periodically.</p>
        <p>Before we broaden our use of PAI beyond what is stated in this Privacy Policy, we will make reasonable efforts to provide notice and obtain consent as required by law.</p>
        <p><strong>Posting of Revised Privacy Policy.</strong> We will post any adjustments to the Privacy Policy on this web page, and the revised version will be effective when it is posted. If you are concerned about how your information is used, bookmark this page and read this Privacy Policy periodically.</p>
        <p><strong>New Uses of PAI.</strong> We may desire to use PAI for uses not previously disclosed in our Privacy Policy. If our practices change regarding previously collected PAI in a way that would be materially less restrictive than stated in the version of this Privacy Policy in effect at the time we collected the information, we will make reasonable efforts to provide notice and obtain consent to any such uses as may be required by law.</p>

        <h2 className='PrivacyPolicy__section-title'>INDIVIDUAL CONSENT</h2>
        <p>BY VISITING THE CONNECT K-12 WEB SITES, INDIVIDUALS ARE ACCEPTING THE POLICIES THAT ARE DESCRIBED IN THIS PRIVACY POLICY AND CONSENTING TO THE COLLECTION AND USE OF INFORMATION BY CN. CN WILL POST ANY CHANGES TO THIS PRIVACY POLICY IT MAY MAKE FROM TIME TO TIME ON ITS SITES. IF INDIVIDUALS DO NOT AGREE TO THIS PRIVACY POLICY, OR TO ANY CHANGES CN MAY SUBSEQUENTLY MAKE, IMMEDIATELY STOP ACCESSING THE SITES.</p>

        <ContactInformation />
      </div>
    </div>
  )
}
