import actions from '../../../actions/historicalNationMetrics'
import * as status from '../../status'
import { formatStateMetric } from '../../shared/utils'
import { sortObjects } from '../../../utils'

export const initialState = {
  data: {},
  status: status.INITIALIZED
}

function historicalNationMetrics (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_HISTORICAL_NATION_METRICS:
      return {
        status: status.REQUESTED
      }

    case actions.RECEIVE_HISTORICAL_NATION_METRICS:
      let nationMetrics = {
        medianCostPerMbps: [],
        percentDistrictsMeetingGoal: [],
        percentStudentsMeetingGoal: []
      }

      sortObjects(payload.data, 'funding_year').reduce((nationMetrics, metric) => {
        nationMetrics['medianCostPerMbps'].push(formatStateMetric('median_cost_per_mbps')(metric))
        nationMetrics['percentDistrictsMeetingGoal'].push(formatStateMetric('percent_districts_meeting_goal')(metric))
        nationMetrics['percentStudentsMeetingGoal'].push(formatStateMetric('percent_students_meeting_goal')(metric))
        return nationMetrics
      }, nationMetrics)

      return {
        data: nationMetrics,
        status: status.LOADED
      }

    case actions.RECEIVE_HISTORICAL_NATION_METRICS_ERROR:
      return {
        status: status.ERROR
      }

    default:
      return state
  }
}

export default historicalNationMetrics
