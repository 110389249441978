import React from 'react'
import './index.sass'
import chartDocument from '../../../assets/TrendsChart.png'

function externalLink (href, imgAlt, imgSrc) {
  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      <img className='TrendsReport--logo'
        alt={imgAlt}
        src={imgSrc} />
    </a>
  )
}

export default function TrendsReport () {
  return (
    <div className='TrendsReport'>
      <div className='TrendsReport__title'>
      The E-Rate program is working for applicants!
      </div>
      <div className='TrendsReport__text'>
        {externalLink('https://www.fundsforlearning.com/e-rate-data/trendsreport/', '2023 E-rate Trends Report', chartDocument)}
      </div>
      <div className='NationalMetrics__source--demand'>
        Source:&nbsp;
        <a href='https://www.fundsforlearning.com/e-rate-data/trendsreport/'
          target='_blank' rel='noopener noreferrer'>
          2023 E-rate Trends Report
        </a>
      </div>
    </div>
  )
}
