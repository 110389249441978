import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

export default function Dropdown ({ title, eventKey, activeKey, setActiveKey, children }) {
  const togglePlusSign = () => {
    if (eventKey === activeKey) {
      setActiveKey(null)
    } else {
      setActiveKey(eventKey)
    }
  }

  return (
    <Card>
      <Accordion.Toggle className='Faq__question' as={Card.Header} eventKey={eventKey}
        onClick={togglePlusSign}>
        <div className='Faq__plus'>{activeKey === eventKey ? '-' : '+' }</div> {title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className='Faq__text'>
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>)
}

Dropdown.propTypes = {
  title: PropTypes.string,
  eventKey: PropTypes.string,
  activeKey: PropTypes.number,
  setActiveKey: PropTypes.func
}
