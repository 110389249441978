export const UNITS = {
  kbps: 'kbps',
  Gbps: 'Gbps',
  Mbps: 'Mbps',
  none: 'NONE'
}

export const clamp = (min, max) => value =>
  Math.min(Math.max(min, value), max)

export function getUnits (bandwidth) {
  if (bandwidth === 0) {
    return UNITS.none
  } else if (bandwidth < 1) {
    return UNITS.kbps
  } else if (bandwidth >= 1000) {
    return UNITS.Gbps
  } else {
    return UNITS.Mbps
  }
}

export function getUnitConverter (units) {
  switch (units) {
    case UNITS.none:
      return bw => bw
    case UNITS.kbps:
      return bw => (bw * 1000).toPrecision(3)
    case UNITS.Gbps:
      return bw => (bw / 1000)
    case UNITS.Mbps:
      return bw => bw
    default:
      throw new Error(
        `Do not know how to convert to "${units}".
        Expected one of: ${Object.values(UNITS)}`
      )
  }
}

const clampPositive = clamp(0, Number.MAX_SAFE_INTEGER)

export function getUnitInverter (units) {
  switch (units) {
    case UNITS.none:
      return bw => bw
    case UNITS.Gbps:
      return bw => clampPositive(bw * 1000)
    case UNITS.kbps:
      return bw => clampPositive(bw / 1000)
    case UNITS.Mbps:
      return bw => clampPositive(bw)
    default:
      throw new Error(
        `Do not know how to invert from "${units}".
        Expected one of: ${Object.values(UNITS)}`
      )
  }
}
