import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import closeIcon from '../../../assets/icon-24-icon-close-black.svg'
import './PopupHeader.sass'

const PopupHeader = forwardRef(({ title, subtitle, onClose }, ref) => {
  return (
    <header ref={ref} className='PopupHeader'>
      <img className='PopupHeader__close-icon'
        src={closeIcon}
        role='button'
        alt='close popup icon'
        onClick={() => onClose()} />
      <div className='PopupHeader__container'>
        <h1 className='PopupHeader__title'>{title}</h1>
        { subtitle && <div className='PopupHeader__subtitle'>{subtitle}</div> }
      </div>
    </header>
  )
})

export default PopupHeader

PopupHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string
}
