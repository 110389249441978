export const withinRange = (property, { min, max, boundary }) => ({ [property]: value }) => {
  return (min <= value && max >= value) || (max === boundary && value >= max)
}

/**
 * Creates a filter function that matches an object property against a list of
 * accepted values.
 *
 * @param {string} property - The name of the property on service to inspect
 * @param {Array<*>} options - Accepted values for the given property
 * @returns {function(*): boolean}
 */
export const acceptedValues = (property, options) => ({ [property]: value }) => {
  if (!Array.isArray(options) || options.length === 0) {
    return true
  }
  return options.includes(value)
}

/**
 * Creates a filter function that returns true if the object property is an array
 * whose intersection with the given options returns a non empty array.
 *
 * @param {string} property - The name of the property on service to inspect
 * @param {Array<*>} options - Accepted values for the given property
 * @returns {function(*): boolean}
 */
export const intersects = (property, options) => ({ [property]: values }) => {
  if (!Array.isArray(options) || options.length === 0) {
    return true
  }
  if (!Array.isArray(values)) { return false }
  const intersection = options.filter(opt => values.includes(opt))
  return intersection.length > 0
}

export function extractSelected (options) {
  return Object
    .entries(options)
    .filter(([_option, selected]) => selected)
    .map(([option]) => option)
}

export const contractExpiring = (property, { toggled }) => ({ [property]: value }) => {
  if (!toggled) return true
  return toggled && value === true
}
