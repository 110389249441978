import React from 'react'
import Option from './Option'

export default function ({ options, onChange }) {
  const groupId = 'PurposeFilter-options-Purpose'
  const filterOptions = Object.entries(options)

  const onOptionChange = option => checked =>
    onChange({ ...options, ...{ [option]: checked } })

  return (
    <fieldset className='PurposeFilter'>
      <span className='PurposeFilter__aria-label' id={groupId}>Purpose</span>
      {
        filterOptions.map(([option, checked], idx) =>
          <Option
            filter={'Purpose'}
            option={option}
            checked={checked}
            key={`${idx}-${checked}`}
            groupId={groupId}
            onChange={onOptionChange(option)}
          />
        )
      }
    </fieldset>
  )
}
