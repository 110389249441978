import React from 'react'
import classnames from 'classnames'
import './index.sass'

export default function Skeleton ({ count, width, height, circle, className }) {
  const elements = []
  const skeletonCount = count || 1

  for (let i = 0; i < skeletonCount; i++) {
    let style = {}

    if (width !== null) {
      style.width = width
    }

    if (height !== null) {
      style.height = height
    }

    if (width !== null && height !== null && circle) {
      style.borderRadius = '50%'
    }

    elements.push(
      <div className='Skeleton' style={style} key={i} />
    )
  }
  if (className) {
    return (
      <div className={classnames(className)}>
        {elements}
      </div>
    )
  }

  return (
    <>
      {elements}
    </>
  )
}
