import React from 'react'
import './Handle.sass'

function Handle ({ handle: { id, percent }, getHandleProps }) {
  return (
    <div
      className='SliderHandle'
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    >
      <div className='SliderHandle__icon' />
    </div>
  )
}

export default Handle
