import React from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import DownloadDataButton from '../../DownloadData/DownloadDataButton'
import StateOverviewDashboardPDF from './StateOverviewDashboardPDF'
import './StateOverviewPDFButton.sass'
import loadingSpinner from '../../../assets/loading-spinner.svg'

const COMPARISON_CHARTS = ['percentMeetingGoal', 'costOverTime']

function pdfImagesLoaded (pdfImages, comparisonStateCode, selectedStateCode) {
  return Object.keys(pdfImages).every(chartName => {
    if (COMPARISON_CHARTS.includes(chartName) && comparisonStateCode) {
      return !!pdfImages[chartName][comparisonStateCode]
    } else {
      return !!pdfImages[chartName][selectedStateCode]
    }
  })
}

// NOTE: PDFDownloadLink should only generate when we click the button, not on page visit!
// Otherwise there are race conditions and we end up having errors on the page!
// Plus its awful for performance and the vast majority of visitors don't want a PDF anyway
// https://github.com/diegomura/react-pdf/issues/310#issuecomment-621492654
// https://github.com/diegomura/react-pdf/issues/736
export default function StateOverviewPDFButton ({ stateMetrics, stateOverviewCharts, selectedStateCode }) {
  const {
    chartData,
    pdfImages,
    chartData: { comparisonStateCode }
  } = stateOverviewCharts

  // Handler to load PDF data
  const pdfFileName = selectedStateCode + '.pdf'
  const pdfPrepareAndDownload = async () => {
    let button = document.getElementById('pdf-download-button')
    button.classList.add('StateOverviewPDFButton__loading')
    button.classList.remove('StateOverviewPDFButton__active')

    const blob = await pdf((
      <StateOverviewDashboardPDF
        stateMetrics={stateMetrics}
        comparisonStateCode={comparisonStateCode}
        selectedStateCode={selectedStateCode}
        chartData={chartData}
        pdfImages={pdfImages}
      />
    )).toBlob()

    button.classList.remove('StateOverviewPDFButton__loading')
    button.classList.add('StateOverviewPDFButton__active')

    saveAs(blob, pdfFileName)
  }

  const loading = !pdfImagesLoaded(stateOverviewCharts.pdfImages, comparisonStateCode, selectedStateCode)

  if (loading) {
    return (
      <div className='StateOverviewPDFButtonContainer'>
        <div className='StateOverviewPDFButton StateOverviewPDFButton__loading'>
          <div className='DownloadData__text'>
            <img className='StateOverviewPDFButton__loading-spinner' src={loadingSpinner} aria-hidden alt='loading spinner' />
            Download PDF
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='StateOverviewPDFButtonContainer' onClick={pdfPrepareAndDownload}>
      <div id='pdf-download-button' className='StateOverviewPDFButton StateOverviewPDFButton__active'>
        <DownloadDataButton text='Download PDF' />
      </div>
    </div>
  )
}

StateOverviewPDFButton.propTypes = {
  stateOverviewCharts: PropTypes.shape({
    chartData: PropTypes.object,
    pdfImages: PropTypes.shape({
      contractsExpiring: PropTypes.object,
      costOverTime: PropTypes.object,
      percentMeetingGoal: PropTypes.object,
      erateAppliedFunding: PropTypes.object
    })
  }).isRequired
}
