import { LOCATION_CHANGE } from 'connected-react-router'
import { REQUEST_GLOBAL_SEARCH } from '../../../../actions/globalSearch'
import statesData from '../../../../data/states'
export const STATE_OVERVIEW = 'Overview'
export const STATE_ERATE = 'E-rate Details'
export const STATE_BANDWIDTH = 'Bandwidth Details'

export function formatStateOption (page, stateCode) {
  if (statesData[stateCode]) {
    return {
      id: stateCode,
      type: 'states',
      page: page,
      stateCode: stateCode,
      stateName: `${statesData[stateCode].stateName}`,
      label: `${statesData[stateCode].stateName}  •  ${page}`
    }
  } else {
    return null
  }
}

const formatStatesOptions = () => {
  return Object.keys(statesData).flatMap(stateCode => {
    return [
      formatStateOption(STATE_BANDWIDTH, stateCode),
      formatStateOption(STATE_OVERVIEW, stateCode),
      formatStateOption(STATE_ERATE, stateCode)
    ]
  })
}

const initialState = formatStatesOptions()

export default function states (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_GLOBAL_SEARCH:
      const searchTerm = payload.searchTerm
      if (searchTerm.length < 2) { return [] }
      return initialState.filter((state) => {
        if (searchTerm.length === 2) {
          return searchTerm.toLowerCase() === state.id.toLowerCase()
        } else {
          return (state.stateName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          state.label.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
      })
    case LOCATION_CHANGE:
      return []
    default:
      return state
  }
}
