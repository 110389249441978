import { acceptedValues, extractSelected, withinRange
} from '../../../../shared/filters/comparisons'
import buildFilterFunction from '../../../../shared/filters/buildFilterFunction'

export const bandwidth = (options, applied) =>
  withinRange('download_speed_mbps', {
    ...applied,
    boundary: options.max.boundary
  })

export const cost = (options, applied) =>
  withinRange('average_monthly_cost', {
    ...applied,
    boundary: options.max.boundary
  })

export const provider = (_options, applied) =>
  acceptedValues('service_provider_name', applied)

export const purpose = options =>
  acceptedValues('purpose', extractSelected(options))

export const size = (options, applied) =>
  withinRange('recipient_district_num_students', {
    ...applied,
    boundary: options.max.boundary
  })

export const filterFactories = {
  bandwidth,
  cost,
  purpose,
  size
}

export const standardFilters = buildFilterFunction(filterFactories)
export const providerFilters = buildFilterFunction({ provider })
