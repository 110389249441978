import React from 'react'
import './legend.sass'

export default function MapLegend () {
  return (
    <div className='MapLegend__container'>
      <div className='MapLegend__key'>
        <div className='MapLegend--first-quartile-color' />
        <span>0-20%</span>
      </div>
      <div className='MapLegend__key'>
        <div className='MapLegend--second-quartile-color' />
        <span>21-40%</span>
      </div>
      <div className='MapLegend__key'>
        <div className='MapLegend--third-quartile-color' />
        <span>41-60%</span>
      </div>
      <div className='MapLegend__key'>
        <div className='MapLegend--fourth-quartile-color' />
        <span>61-80%</span>
      </div>
      <div className='MapLegend__key'>
        <div className='MapLegend--last-quartile-color' />
        <span>81-100%</span>
      </div>
      <div className='MapLegend__key'>
        <div className='MapLegend--no-data-color' />
        <span>No data</span>
      </div>
    </div>
  )
}
