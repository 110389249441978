import React from 'react'
import classNames from 'classnames'
import './ViewSlider.sass'

export default function ViewSlider ({ view, onChange }) {
  const labelClass = (labelName) => {
    return `ViewSlider__label--${view === labelName ? 'on' : 'off'}`
  }

  return (
    <div className={classNames('ViewSlider', `ViewSlider--${view}`)}>
      <div className='ViewSlider__labels'>
        <div className='ViewSlider__label' role='button' onClick={() => onChange('districts')}>
          <span className={labelClass('districts')}>% school districts</span>
        </div>
        <div className='ViewSlider__label' role='button' onClick={() => onChange('students')}>
          <span className={labelClass('students')}>% students</span>
        </div>
      </div>
      <label className='ViewSlider__slider'>
        <span className='ViewSlider__indicator' />
      </label>
    </div>
  )
}
