import nearbyActions from '../../../actions/districtsNearby'
import { sortObjects } from '../../../utils'
import * as statuses from '../../status'
import { getNeighbouring } from './utils'
import { MAX_NEIGHBOURING_RADIUS } from '../../../middleware/utils'
import { ASC } from '../../../utils/sortHelpers'

const initialState = {
  status: statuses.INITIALIZED,
  data: [],
  radius: MAX_NEIGHBOURING_RADIUS
}

function nearby (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case nearbyActions.REQUEST_DISTRICTS_NEARBY:
      return { ...state, status: statuses.REQUESTED }

    case nearbyActions.RECEIVE_DISTRICTS_NEARBY:
      let districtsMeetingGoal = payload.filter((dist) => {
        return dist.meeting_goal && dist.distance !== 0
      })
      const neighbouring = getNeighbouring(districtsMeetingGoal)
      return {
        data: sortObjects(neighbouring.districts, 'distance', { direction: ASC }),
        status: statuses.LOADED,
        radius: neighbouring.radius
      }

    case nearbyActions.RECEIVE_DISTRICTS_NEARBY_ERROR:
      return { ...initialState, status: statuses.ERROR }
    default:
      return state
  }
}

export default nearby
