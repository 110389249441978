import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-mapbox-gl'
import classnames from 'classnames'
import './index.sass'

const DEFAULT_OFFSET = -14
const SELECTED_OFFSET = -35

export default function MapTooltip ({ onOpen, coordinates, classNamePrefix, enabled, children, selected }) {
  const ref = useCallback(node => {
    if (node && onOpen) {
      onOpen()
    }
  }, [onOpen])

  if (!enabled) return null

  return (
    <Popup
      coordinates={coordinates}
      offset={{ 'bottom': [0, selected ? SELECTED_OFFSET : DEFAULT_OFFSET], 'right': [-15, -15], 'left': [15, -15] }}
      tabIndex='-1'
      className={classnames('MapTooltip', { [`${classNamePrefix}__tooltip`]: classNamePrefix })}
      style={{ zIndex: 8 }}>
      <div ref={ref} className='MapTooltip__content'>
        {children}
      </div>
    </Popup>
  )
}

MapTooltip.propTypes = {
  coordinates: PropTypes.array,
  classNamePrefix: PropTypes.string,
  enabled: PropTypes.bool,
  children: PropTypes.any,
  onOpen: PropTypes.func
}

MapTooltip.defaultProps = {
  onOpen: () => {}
}
