import React from 'react'
import PropTypes from 'prop-types'
import { View, Image } from '@react-pdf/renderer'
import ChartHeaderPDF from './ChartHeaderPDF'
import ChartLegendPDF from './ChartLegendPDF'
import states from '../../../data/states'

export default function PercentMeetingGoalPDF ({ chartData, chartImage, stateCode, comparisonStateCode, hasStateQuote }) {
  const width = states[stateCode].committed ? '60%' : '100%'
  const imageWidth = hasStateQuote ? '80%' : '90%'
  const imageMarginLeft = hasStateQuote ? '20mm' : '5mm'
  const legendMarginLeft = hasStateQuote ? '30mm' : '10mm'

  return (
    <View style={{ paddingBottom: '5mm', width, height: '100%' }}>
      <ChartHeaderPDF
        title={chartData.title}
        subtitle={chartData.subtitle}
        hasStateQuote={hasStateQuote} />
      <Image src={chartImage} style={{ width: imageWidth, marginLeft: imageMarginLeft }} />
      <View style={{ marginLeft: legendMarginLeft }}>
        <ChartLegendPDF stateCode={stateCode} comparisonStateCode={comparisonStateCode} />
      </View>
    </View>
  )
}

PercentMeetingGoalPDF.propTypes = {
  chartData: PropTypes.object,
  chartImage: PropTypes.string,
  stateCode: PropTypes.string,
  comparisonStateCode: PropTypes.string
}
