import React from 'react'
import Base from './Base'
import Column from './Column'
import { formatBandwidth } from '../../../utils/stringHelpers'
import PopupHeader from '../shared/PopupHeader'
import { types } from '../../../reducers/shared/districts/data/map/pins'

const Header = ({ title, onClose }) => {
  return <PopupHeader
    title={title}
    subtitle='Current internet bandwidth is unclear'
    onClose={onClose} />
}

const Body = ({ data }) => {
  return (
    <div className={`DistrictPopup__body DistrictPopup__body--${types.MISSING_DATA}`}>
      <Column
        title='current'
        classId='current--missing-data'
        metric='? Mbps'
        subtitle='? Mbps/student'
      />
      <Column
        title='recommended'
        classId='recommended'
        metric={`${formatBandwidth(data.recommended_bw)}`}
        subtitle='1 Mbps/student' />
      <div className='PopupColumn--contact-us'>
        Contact us to clarify this school district's internet connectivity
      </div>
    </div>
  )
}

const MissingData = ({ data, onClose }) => {
  return <Base
    data={data}
    header={<Header title={data.entity_name} onClose={onClose} />}
    body={<Body data={data} />}
  />
}

export default MissingData
