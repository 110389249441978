import React from 'react'
import PropTypes from 'prop-types'
import ChartHeaderPDF from './ChartHeaderPDF'
import { View, Image, StyleSheet } from '@react-pdf/renderer'

export default function ErateAppliedFundingPDF ({ chartData, chartImage, hasStateQuote }) {
  const chartContainerStyle = StyleSheet.create({
    width: '60%',
    paddingLeft: '3mm',
    paddingBottom: hasStateQuote ? '2mm' : '5mm'
  })

  const imageWidth = hasStateQuote ? '70%' : '80%'
  const imageMarginLeft = hasStateQuote ? '20mm' : '15mm'

  return (
    <View style={chartContainerStyle}>
      <ChartHeaderPDF
        title={chartData.title}
        subtitle={chartData.subtitle}
        hasStateQuote={hasStateQuote} />
      <Image src={chartImage} style={{ width: imageWidth, marginLeft: imageMarginLeft }} />
    </View>
  )
}

ErateAppliedFundingPDF.propTypes = {
  chartData: PropTypes.object,
  chartImage: PropTypes.string
}
