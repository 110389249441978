import React from 'react'
import Handle from './Handle'
import Track from './Track'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { snapUpTo } from '../../../utils/numberHelpers'
import './RangeFilter.sass'

function formatValues (step, values) {
  return values.map((v) => {
    return snapUpTo(step)(v).toFixed(2)
  })
}

// https://sghall.github.io/react-compound-slider/#/getting-started/
export default function RangeSlider ({ values, step, domain, onChange }) {
  return (
    <Slider
      className='RangeFilter__Slider'
      mode={2}
      step={step}
      values={formatValues(step, values)}
      domain={domain}
      onUpdate={onChange}
      onChange={onChange}>
      <Rail>
        {({ getRailProps }) => (
          <div className='RangeFilter__Rail' {...getRailProps()} />
        )}
      </Rail>

      <Tracks right={false} left={false}>
        {({ tracks, getTrackProps }) => (
          <div className='RangeFilter__Tracks'>
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>

      <Handles>
        {({ handles, getHandleProps }) => (
          <div className='RangeFilter__Handles'>
            {handles.map(handle => (
              <Handle
                key={handle.id}
                handle={handle}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
    </Slider>
  )
}
