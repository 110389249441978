import React from 'react'
import classnames from 'classnames'

export default function TableCell ({ name, classNamePrefix, children, ...props }) {
  const classNames = classnames(
    classNamePrefix,
    `${classNamePrefix}--${name}`
  )
  return <td className={classNames} {...props}>{children}</td>
}
