import { SHOW_FOOTER } from '../../actions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { onStateBandwidthPage, onDistrictPage } from '../../routes'

function footer (state = true, action = {}) {
  const { type, payload } = action

  switch (type) {
    case SHOW_FOOTER:
      return payload
    case LOCATION_CHANGE:
      const { location: { pathname } } = payload
      const onMapPage = onStateBandwidthPage(pathname) || onDistrictPage(pathname)
      return !onMapPage
    default:
      return state
  }
}

export default footer
