import React from 'react'
import TypeaheadFilter from './TypeaheadFilter'

function Provider ({ provider, onChange, ...props }) {
  const { open, applied, options, update } = provider

  return (
    <TypeaheadFilter
      label='Provider'
      open={open}
      enabled={provider.enabled}
      applied={applied}
      selected={update && update.selected}
      options={options}
      onChange={onChange}
      {...props} />
  )
}

export default Provider
