import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MapSkeleton from './MapSkeleton'
import { MapContext, Layer, ZoomControl } from 'react-mapbox-gl'
import Popup from './Popup'
import {
  Map,
  mapProps,
  getFeature,
  shouldOpenTooltip,
  addLayerSource
} from '../../../utils/mapUtils'
import { useMapboxContainer, useMapLayerOrder } from '../../../hooks'
import './index.sass'
import useMapPositioning from '../../../hooks/useMapPositioning'
import MapTooltip from '../../MapTooltip'
import TooltipContent from './TooltipContent'
import ExpandMapButton from './ExpandMapButton'

import {
  SELECTED_ID,
  RECOMMENDED_ID,
  PINS_ID,
  PINS_LABEL_ID,
  NEARBY_SERVICES_SOURCE_ID,
  addLabelImage,
  textLayoutOptions,
  circlePaintOptions,
  sharedLayoutOptions
} from '../../Map/layerStyles'

import useMapUtils from './utils'

export default function NearbyServicesMap ({
  tableStatus,
  bannerVisible,
  map,
  onPinClick,
  onClosePopup,
  onOpenPopup,
  onMouseEnter,
  onMouseLeave,
  toggleDistrictBanner
}) {
  const { nearby, selected, canvas, popup, pins, tooltipId } = map
  const { fitBounds, center, offsetY, resetZoom } = canvas
  const [mapboxMap, setMapboxMap] = useState(null)
  const feature = getFeature(tooltipId, pins.data.features)
  const { id: featureId, properties: featureProps, geometry } = { ...feature }
  const { coordinates } = { ...geometry }
  const {
    eventHandlers,
    displayLabels,
    containerHeight,
    setContainerHeight
  } = useMapUtils({
    mapboxMap,
    nearby,
    selected,
    coordinates,
    onPinClick,
    onMouseLeave,
    onMouseEnter,
    onClosePopup,
    toggleDistrictBanner
  })
  const container = useMapboxContainer(mapboxMap, containerHeight, setContainerHeight)
  const selectedDistrictId = Number(Object.keys(selected)[0])

  useMapPositioning({ map: mapboxMap, fitBounds, center, offsetY, resetZoom })

  useMapLayerOrder(mapboxMap, [
    RECOMMENDED_ID,
    SELECTED_ID,
    PINS_ID
  ])

  useEffect(() => {
    if (mapboxMap) {
      mapboxMap.getSource(NEARBY_SERVICES_SOURCE_ID).setData(pins.data)
    }
  }, [pins.data, mapboxMap])

  const sharedLayerProps = {
    onMouseEnter: eventHandlers.handleMouseEnter,
    onMouseLeave: eventHandlers.handleMouseLeave,
    onClick: eventHandlers.handleClick,
    sourceId: NEARBY_SERVICES_SOURCE_ID
  }

  return (
    <div className='NearbyServicesMap__container'>
      <MapSkeleton map={mapboxMap} status={tableStatus} />
      <div className='NearbyServicesMap' ref={container}>
        {
          mapboxMap &&
          <ExpandMapButton
            bannerVisible={bannerVisible}
            onClick={eventHandlers.handleMapButtonClick}
          />
        }
        <Map
          onStyleLoad={setMapboxMap}
          onSourceData={eventHandlers.handleDisplayLabels}
          onResize={eventHandlers.handleDisplayLabels}
          {...mapProps()}>
          <MapContext.Consumer>
            {
              (map) => {
                addLayerSource(map, NEARBY_SERVICES_SOURCE_ID, pins.data)
                addLabelImage(map)

                return (
                  <>
                    <Layer
                      id={PINS_LABEL_ID}
                      type='symbol'
                      sourceId={NEARBY_SERVICES_SOURCE_ID}
                      layout={textLayoutOptions(displayLabels)}
                      filter={['!=', ['get', 'label'], null]}
                      symbolSortKey={[['get', 'recommended'], 1, 0]}
                    />
                    <Layer
                      id={PINS_ID}
                      type='circle'
                      sourceId={NEARBY_SERVICES_SOURCE_ID}
                      paint={circlePaintOptions(tooltipId, popup.entity_number)}
                      filter={[
                        'all',
                        ['!=', ['get', 'recommended'], true],
                        ['!=', ['get', 'selected'], true]
                      ]}
                      {...sharedLayerProps}
                    />
                    <Layer
                      id={SELECTED_ID}
                      type='symbol'
                      filter={['get', 'selected']}
                      layout={{
                        ...sharedLayoutOptions(SELECTED_ID),
                        'icon-offset': [0, -10]
                      }}
                      {...sharedLayerProps}
                    />
                    <Layer
                      id={RECOMMENDED_ID}
                      type='symbol'
                      filter={['get', 'recommended']}
                      layout={sharedLayoutOptions(RECOMMENDED_ID)}
                      {...sharedLayerProps}
                    />
                    <Popup
                      data={popup}
                      onOpen={onOpenPopup}
                      onClose={eventHandlers.handleClosePopup}
                      map={map}
                    />
                    <MapTooltip
                      onOpen={eventHandlers.handleTooltipOpen}
                      coordinates={coordinates}
                      selected={selectedDistrictId === tooltipId}
                      enabled={shouldOpenTooltip(tooltipId, featureId)}
                      {...featureProps} >
                      <TooltipContent {...featureProps} />
                    </MapTooltip>
                    <ZoomControl
                      className='map__zoom-control'
                      zoomDiff={1}
                      style={{ top: '50px', right: '50px' }} />
                  </>
                )
              }
            }
          </MapContext.Consumer>
        </Map>
      </div>
    </div>
  )
}

NearbyServicesMap.propTypes = {
  tableStatus: PropTypes.string,
  map: PropTypes.shape({
    nearby: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
    popup: PropTypes.object.isRequired,
    canvas: PropTypes.object.isRequired
  }),
  onPinClick: PropTypes.func,
  onClosePopup: PropTypes.func,
  onOpenPopup: PropTypes.func,
  bannerVisible: PropTypes.bool,
  setBannerVisible: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}
