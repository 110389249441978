import React from 'react'
import PropTypes from 'prop-types'
import './ThankYou.sass'

export default function ThankYou ({ contactFormSubmitted }) {
  if (!contactFormSubmitted) { return null }

  return (
    <div className='ThankYou'>
      <h1 className='ThankYou__title'>
        Thank You
      </h1>
      <h3 className='ThankYou__subtitle'>
        Your feedback is important, thanks for contacting us! We'll be in touch soon.
      </h3>
    </div>
  )
}

ThankYou.propTypes = {
  contactFormSubmitted: PropTypes.bool.isRequired
}
