import { connect } from 'react-redux'
import StateOverviewDashboard from '../../components/StateOverviewDashboard'
import {
  chartRendered,
  stateComparisonClicked,
  stateComparisonCleared
} from '../../actions/stateOverviewDashboard'
import { updatePercentMeetingGoalView } from '../../actions/historicalStateMetrics'
import { safeDup } from '../../utils/'

const mapStateToProps = (state) => {
  // Create a copy of the metrics to protect the data in the store
  // from manipulation by Chart.js
  return {
    stateMetrics: safeDup(state.historicalStateMetrics),
    nationMetrics: safeDup(state.historicalNationMetrics),
    stateOverviewCharts: safeDup(state.stateOverviewCharts)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChartRendered: (name, imgData) => {
      dispatch(chartRendered(name, imgData))
    },
    onStateComparisonClicked: (state) => {
      dispatch(stateComparisonClicked(state))
    },
    onStateComparisonCleared: () => {
      dispatch(stateComparisonCleared())
    },
    onPercentMeetingGoalViewUpdated: (view) => {
      dispatch(updatePercentMeetingGoalView(view))
    }
  }
}

const StateOverviewDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StateOverviewDashboard)

export default StateOverviewDashboardContainer
