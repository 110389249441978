import * as router from 'connected-react-router'
import { getStateMetrics } from '../selectors/dataSelectors'
import { DISTRICT_SELECTED, STATE_SELECTED } from '../actions/globalSearch'
import {
  districtDashboard,
  stateBandwidthDashboard,
  stateErateDashboard,
  stateOverviewDashboard
} from '../routes'
import {
  STATE_OVERVIEW,
  STATE_BANDWIDTH,
  STATE_ERATE
} from '../reducers/shared/globalSearch/data/states'

import { buildFilterQueryString, shouldAddBandwidthQuery } from '../utils/urlHelpers'

const globalSearchSelected = store => next => action => {
  const { type, payload } = action

  next(action)

  if (type === DISTRICT_SELECTED) {
    const path = districtDashboard.generatePath({
      entity_number: payload.district.entity_number
    })
    store.dispatch(router.push(path))
  }

  if (type === STATE_SELECTED) {
    const stateOption = payload.state
    let path
    switch (stateOption.page) {
      case STATE_OVERVIEW:
        path = stateOverviewDashboard.generatePath({
          state_code: stateOption.stateCode
        })
        break
      case STATE_BANDWIDTH:
        const stateMetrics = getStateMetrics(store.getState(), stateOption.stateCode)
        const filterForBandwidth = shouldAddBandwidthQuery(stateMetrics.num_districts_not_meeting_goal)
        path = stateBandwidthDashboard.generatePath({
          state_code: stateOption.stateCode
        }) + (filterForBandwidth ? buildFilterQueryString(['bandwidth']) : '')

        break
      case STATE_ERATE:
        path = stateErateDashboard.generatePath({
          state_code: stateOption.stateCode
        })
        break
      default:
        break
    }
    if (path) store.dispatch(router.push(path))
  }
}

export default globalSearchSelected
