import actions from '../../../../../actions/districts'
import {
  DISTRICTS_FILTERS_APPLIED,
  DISTRICTS_DATA_FILTERED,
  DISTRICTS_FILTER_CLEARED,
  DISTRICTS_FILTERS_CLEARED } from '../../../../../actions/districtsFilters'
import * as statuses from '../../../../status'

export default function (state = statuses.INITIALIZED, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_DISTRICTS:
      if (payload.isFirstRequest) return statuses.LOADING
      return state
    case DISTRICTS_FILTERS_APPLIED:
    case DISTRICTS_FILTER_CLEARED:
    case DISTRICTS_FILTERS_CLEARED:
      return statuses.LOADING
    case actions.SORT_DISTRICTS_TABLE:
    case actions.DISTRICTS_TABLE_SORT_APPLIED:
      return statuses.SORTING
    case actions.DISTRICTS_TABLE_SORTED:
    case DISTRICTS_DATA_FILTERED:
      return statuses.LOADED
    default:
      return state
  }
}
