import { combineReducers } from 'redux'
import { INITIALIZED } from '../../status'
import data from './data'
import status from './status'

const initialState = {
  data: {},
  status: INITIALIZED
}

export default combineReducers({ initialState, data, status })
