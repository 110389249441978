import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import nationalIcon from '../../../assets/icon-24-icon-national-2-gray-1.svg'
import nationalSelected from '../../../assets/icon-24-icon-national-2-secondary-3.svg'
import { nationalDashboard, onNationalPage } from '../../../routes'
import MenuItem from './MenuItem'

export default function NationalLink ({ pathname, mobile = false, classNames, onClick }) {
  const icon = onNationalPage(pathname) ? nationalSelected : nationalIcon
  return (
    <div className='Navbar__link Navbar__link--national' onClick={onClick}>
      <Link to={nationalDashboard.path}>
        <MenuItem
          icon={icon}
          selected={onNationalPage(pathname)}
          mobile={mobile} >
          National
        </MenuItem>
      </Link>
    </div>
  )
}

NationalLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  classNames: PropTypes.array,
  onClick: PropTypes.func
}
