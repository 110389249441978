import React from 'react'
import './index.sass'
import { Link } from 'react-router-dom'
import { nationalDashboard } from '../../routes'

export default function NotFoundPage () {
  return (
    <div className='NotFoundPage'>
      <h1 className='NotFoundPage__title'>404</h1>
      <h2 className='NotFoundPage__subtitle'>Uh oh, page not found, that’s almost as sad as students without internet access.</h2>
      <Link to={nationalDashboard.path}>
        <button className='NotFoundPage__button'>Return to home</button>
      </Link>
    </div>
  )
}
