import actions from '../../../actions/nation'
import * as status from '../../status'

const initialState = {
  data: {},
  status: status.INITIALIZED
}

function nation (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_NATION:
      return {
        data: state.data,
        status: status.REQUESTED
      }

    case actions.RECEIVE_NATION:
      return {
        data: payload,
        status: status.LOADED
      }

    case actions.RECEIVE_NATION_ERROR:
      return {
        data: {},
        error: payload.error.message || `Error requesting nation ${payload.entityNumber}`,
        status: status.ERROR
      }

    default:
      return state
  }
}

export default nation
