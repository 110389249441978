import {
  formatBandwidth,
  formatCost,
  formatISODate,
  formatNumber,
  formatPurpose,
  titleize
} from '../../../utils/stringHelpers'

const formatCostBlankNan = (cost) => formatCost(cost, '0,0[.]00', { nan: '' })

export default [
  {
    header: 'Internet Provider',
    column: 'service_provider_name',
    formatFn: titleize
  },
  {
    header: 'Circuit Size',
    column: 'download_speed_mbps',
    formatFn: formatBandwidth
  },
  {
    header: 'Connections',
    column: 'district_num_lines',
    formatFn: formatNumber
  },
  {
    header: 'Monthly Cost/Connection',
    column: 'average_monthly_cost',
    formatFn: formatCostBlankNan
  },
  {
    header: 'Purpose',
    column: 'purpose',
    formatFn: formatPurpose
  },
  {
    header: 'Contract End Date',
    column: 'contract_expiration_date',
    formatFn: formatISODate
  }
]
