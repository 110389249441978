import React from 'react'
import Filter, {
  Label,
  Options as OptionsContainer,
  Toggle
} from '../../Filters/Filter'
import fundingYears from '../../../data/fundingYears'
import './Contract.sass'

const Header = () => {
  return (
    <div className='Filter__options--header ContractFilter__header'>
      <strong>Contract Expiring</strong>
      <p>Show only school districts whose internet contracts expire before 7/1/{fundingYears.current + 1}</p>
    </div>
  )
}

export default function Contract ({ contract, applied, onChange, onClick, onClear, onApply }) {
  const { open, options } = contract
  const label = 'Contract Expiring'

  const handleChange = () => {
    onChange({ toggled: !options.toggled })
  }

  return (
    <Filter label={label} open={open} enabled={contract.enabled} applied={applied}>
      <Label open={open} onClick={onClick} name={label} applied={!!applied} appliedLabel={label} />
      <OptionsContainer
        visible={open}
        applied={!!applied}
        onApply={onApply}
        onClear={onClear}>
        <div className='ContractFilter'>
          <Header />
          <Toggle
            className='ContractFilter__toggle'
            toggled={options.toggled}
            onChange={() => handleChange()} />
        </div>
      </OptionsContainer>
    </Filter>
  )
}
