// Redux
import { applyMiddleware, createStore } from 'redux'

// Routing Support
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

// Reducers
import reducers from './reducers/index'

// Middleware
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import * as middleware from './middleware'

// Load browser history
export const history = createBrowserHistory()

// Shared middleware
const shared = [
  thunk,
  ...Object.values(middleware),
  routerMiddleware(history)
]

export function envMiddleware (env) {
  if (env === 'test') {
    return applyMiddleware(...shared)
  }
  return composeWithDevTools(applyMiddleware(...[...shared, logger]))
}

// Build store
export const store =
  createStore(reducers(history), {}, envMiddleware(process.env.NODE_ENV))
