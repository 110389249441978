import { getFilters } from '../selectors/filterSelectors'
import { getPathname } from '../selectors/locationSelectors'
import { getData, getDistrictsMap } from '../selectors/dataSelectors'
import { onStateEratePage } from '../routes'

export const DISTRICTS_FILTER_UPDATED = 'DISTRICTS_FILTER_UPDATED'
export function districtsFilterUpdated (name, update) {
  return {
    type: DISTRICTS_FILTER_UPDATED,
    payload: {
      name,
      update
    }
  }
}

export const DISTRICTS_FILTERS_APPLIED = 'DISTRICTS_FILTERS_APPLIED'
export function districtsFiltersApplied (filters) {
  return {
    type: DISTRICTS_FILTERS_APPLIED,
    payload: {
      filters
    }
  }
}

export const DISTRICTS_FILTER_MENU_TOGGLED = 'DISTRICTS_FILTER_MENU_TOGGLED'
export function districtsFilterMenuToggled (name, open) {
  return {
    type: DISTRICTS_FILTER_MENU_TOGGLED,
    payload: {
      name,
      open
    }
  }
}

export const DISTRICTS_FILTER_CLEARED = 'DISTRICTS_FILTER_CLEARED'
export function districtsFilterCleared (name) {
  return {
    type: DISTRICTS_FILTER_CLEARED,
    payload: {
      name
    }
  }
}

export const DISTRICTS_FILTERS_CLEARED = 'DISTRICTS_FILTERS_CLEARED'
export function districtsFiltersCleared () {
  return {
    type: DISTRICTS_FILTERS_CLEARED
  }
}

export const DISTRICTS_DATA_FILTERED = 'DISTRICTS_DATA_FILTERED'
export function districtsDataFiltered ({ filtered, fitBounds } = { filtered: [], fitBounds: [] }) {
  return {
    type: DISTRICTS_DATA_FILTERED,
    payload: { filtered, fitBounds }
  }
}

export const DISTRICTS_FILTERS_MODAL_TOGGLED = 'DISTRICTS_FILTERS_MODAL_TOGGLED'
export function districtsFiltersModalToggled (open = false) {
  return {
    type: DISTRICTS_FILTERS_MODAL_TOGGLED,
    payload: {
      open
    }
  }
}

export const FILTER_DISTRICTS = 'FILTER_DISTRICTS'
export function filterDistricts (filters) {
  return {
    type: FILTER_DISTRICTS,
    payload: filters
  }
}

export const FILTER_DISTRICTS_BY_PROVIDER = 'FILTER_DISTRICTS_BY_PROVIDER'
export function filterDistrictsByProvider (filters, districts) {
  return {
    type: FILTER_DISTRICTS_BY_PROVIDER,
    payload: { filters, districts }
  }
}

export const applyFilters = async (dispatch, getState) => {
  if (!onStateEratePage(getPathname(getState()))) {
    const filters = getFilters(getState())
    await dispatch(filterDistricts(filters))
    await applyProviderFilter(dispatch, getState)
    return notifyDataFiltered(dispatch, getState)
  }
}

const applyProviderFilter = async (dispatch, getState) => {
  const { districts: { data: { districts }, filters } } = getState()
  return dispatch(filterDistrictsByProvider(filters, districts))
}

const notifyDataFiltered = (dispatch, getState) => {
  const state = getState()
  const filtered = getData(state).districts
  const fitBounds = getDistrictsMap(state).fitBounds
  return dispatch(
    districtsDataFiltered({ filtered, fitBounds })
  )
}
