import actions from '../../../../actions/nearbyServices'
import {
  NEARBY_SERVICES_FILTERS_APPLIED,
  NEARBY_SERVICES_DATA_FILTERED,
  NEARBY_SERVICES_FILTER_CLEARED,
  NEARBY_SERVICES_FILTERS_CLEARED } from '../../../../actions/nearbyServicesFilters'
import * as statuses from '../../../status'

export default function (state = statuses.INITIALIZED, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_NEARBY_SERVICES:
      if (payload.isFirstRequest) return statuses.LOADING
      return state
    case NEARBY_SERVICES_FILTER_CLEARED:
    case NEARBY_SERVICES_FILTERS_CLEARED:
    case NEARBY_SERVICES_FILTERS_APPLIED:
      return statuses.LOADING
    case actions.SORT_NEARBY_SERVICES:
    case actions.NEARBY_SERVICES_SORT_APPLIED:
      return statuses.SORTING
    case actions.NEARBY_SERVICES_SORTED:
    case NEARBY_SERVICES_DATA_FILTERED:
      return statuses.LOADED
    default:
      return state
  }
}
