import * as nearbyServices from '../../../actions/nearbyServicesFilters'
import * as districts from '../../../actions/districtsFilters'
import { DOCUMENT_CLICKED } from '../../../actions'
import { updateFilter } from './transformations/updateFilter'
import { applyFilters } from './transformations/applyFilters'
import { clearFilter, clearAllFilters } from './transformations/clearFilters'
import { toggleMenus, toggleAllMenus, closeAllMenus } from './transformations/menu'
import { enableFilters } from './transformations/enableFilters'

const NEARBY_SERVICES = [
  nearbyServices.NEARBY_SERVICES_FILTER_UPDATED,
  nearbyServices.NEARBY_SERVICES_FILTERS_MODAL_TOGGLED,
  nearbyServices.NEARBY_SERVICES_FILTERS_APPLIED,
  nearbyServices.NEARBY_SERVICES_FILTER_CLEARED,
  nearbyServices.NEARBY_SERVICES_FILTER_MENU_TOGGLED,
  nearbyServices.NEARBY_SERVICES_DATA_FILTERED,
  nearbyServices.NEARBY_SERVICES_FILTERS_CLEARED
]

const DISTRICTS = [
  districts.DISTRICTS_FILTER_UPDATED,
  districts.DISTRICTS_FILTERS_MODAL_TOGGLED,
  districts.DISTRICTS_FILTERS_APPLIED,
  districts.DISTRICTS_FILTER_CLEARED,
  districts.DISTRICTS_FILTER_MENU_TOGGLED,
  districts.DISTRICTS_FILTERS_CLEARED,
  districts.DISTRICTS_DATA_FILTERED
]

function reducerApplies (actionType, filterId) {
  const filters = { DISTRICTS, NEARBY_SERVICES }
  return filters[filterId].includes(actionType) || actionType === DOCUMENT_CLICKED
}

function filters (initialState, filterId) {
  return (state = initialState, action = {}) => {
    const { type, payload } = action

    if (!reducerApplies(type, filterId)) return state

    switch (type) {
      case nearbyServices.NEARBY_SERVICES_FILTER_UPDATED:
      case districts.DISTRICTS_FILTER_UPDATED:
        return {
          ...state,
          ...updateFilter(state[payload.name], payload.name, payload.update)
        }

      case nearbyServices.NEARBY_SERVICES_FILTERS_APPLIED:
      case districts.DISTRICTS_FILTERS_APPLIED:
        return {
          ...state,
          ...applyFilters(state, payload)
        }

      case nearbyServices.NEARBY_SERVICES_FILTER_CLEARED:
      case districts.DISTRICTS_FILTER_CLEARED:
        return {
          ...state,
          ...clearFilter(state, initialState, payload.name)
        }

      case nearbyServices.NEARBY_SERVICES_FILTER_MENU_TOGGLED:
      case districts.DISTRICTS_FILTER_MENU_TOGGLED:
        return toggleMenus({ ...state }, payload.name)

      case nearbyServices.NEARBY_SERVICES_FILTERS_CLEARED:
      case districts.DISTRICTS_FILTERS_CLEARED:
        return clearAllFilters({ ...state }, initialState)

      case nearbyServices.NEARBY_SERVICES_FILTERS_MODAL_TOGGLED:
      case districts.DISTRICTS_FILTERS_MODAL_TOGGLED:
        return toggleAllMenus({ ...state }, payload.open)

      case districts.DISTRICTS_DATA_FILTERED:
      case nearbyServices.NEARBY_SERVICES_DATA_FILTERED:
        return enableFilters(state)

      case DOCUMENT_CLICKED:
        return closeAllMenus({ ...state })

      default:
        return state
    }
  }
}

export default filters
