import React from 'react'
import { InputWithUnits, Input } from '.'
import classNames from 'classnames'

export function Cost ({ name, value, step, className, onChange, onApply, maxBoundary }) {
  return (
    <InputWithUnits
      className={classNames('CostFilter__input', className)}
      name={name}
      value={value}
      input={props => <Input step={step} name={name} maxBoundary={maxBoundary} {...props} />}
      units={props => <div {...props}>$</div>}
      onChange={val => onChange(val)}
      onApply={onApply}
    />
  )
}
