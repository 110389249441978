import { fetchRawErateStateMetrics } from '../data'

export const REQUEST_STATE_METRICS = 'REQUEST_STATE_METRICS'
export function requestRawErateStateMetrics (stateCode) {
  return {
    type: REQUEST_STATE_METRICS,
    payload: { stateCode }
  }
}

export const RECEIVE_STATE_METRICS = 'RECEIVE_STATE_METRICS'
export function receiveRawErateStateMetrics (stateCode, data) {
  return {
    type: RECEIVE_STATE_METRICS,
    payload: { stateCode, data }
  }
}

export const RECEIVE_STATE_METRICS_ERROR = 'RECEIVE_STATE_METRICS_ERROR'
export function receiveRawErateStateMetricsError (stateCode, error) {
  return {
    type: RECEIVE_STATE_METRICS_ERROR,
    payload: {
      data: {
        state_code: stateCode
      },
      error: error.message,
      status: error.status
    }
  }
}

export function getRawErateStateMetrics (stateCode, retries = 2) {
  return function (dispatch) {
    dispatch(requestRawErateStateMetrics(stateCode))

    return fetchRawErateStateMetrics(stateCode)
      .then(data => { return dispatch(receiveRawErateStateMetrics(stateCode, data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveRawErateStateMetricsError(stateCode, error))
        }
        return dispatch(getRawErateStateMetrics(stateCode, retries - 1))
      })
  }
}

export default {
  REQUEST_STATE_METRICS,
  RECEIVE_STATE_METRICS,
  RECEIVE_STATE_METRICS_ERROR
}
