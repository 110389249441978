import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ScrollToTop from '../ScrollToTop'
import Navbar from '../../containers/Navbar'
import Homepage from '../../containers/Homepage'
import StateBandwidthDashboardContainer from '../../containers/StateBandwidthDashboard'
import DistrictDashboardContainer from '../../containers/DistrictDashboardContainer'
import StateOverviewDashboardContainer from '../../containers/StateOverviewDashboard'
import NationalDashboardContainer from '../../containers/NationalDashboard'
import StateErateDashboardContainer from '../../containers/StateErateDashboard'
import Footer from '../Footer'
import WindowDimensionsProvider from './WindowDimensionsProvider'
import AboutUs from '../AboutUs'
import Faq from '../Faq'
import PrivacyPolicy from '../policies/PrivacyPolicy'
import TermsOfUse from '../policies/TermsOfUse'
import ContactUsContainer from '../../containers/ContactUs'
import ErrorPage from '../ErrorPage'
import './App.sass'
import { history } from '../../store.js'
import routes from '../../routes'

function App ({ pathname, statusCode, showFooter, onDocumentClick, shouldDispatchDocumentClick }) {
  const showErrorPage = statusCode >= 400
  const classNames = classnames('App', {
    'App--map': (routes.onDistrictPage(pathname) || routes.onStateBandwidthPage(pathname)) && !showErrorPage
  })

  const eventListener = () => {
    onDocumentClick(shouldDispatchDocumentClick)
  }

  return (
    <ConnectedRouter history={history}>
      <WindowDimensionsProvider>
        <ScrollToTop />
        <div className={classNames} id='app' onClick={eventListener}>
          <Navbar />
          {
            showErrorPage
              ? <ErrorPage status={statusCode} />
              : <Switch>
                <Route exact
                  path={routes.homepage.path}
                  component={Homepage}
                />
                <Route exact
                  path={routes.nationalDashboard.path}
                  component={NationalDashboardContainer}
                />
                <Route exact
                  path={routes.stateBandwidthDashboard.path}
                  component={StateBandwidthDashboardContainer}
                />
                <Route exact
                  path={routes.districtDashboard.path}
                  component={DistrictDashboardContainer}
                />
                <Route exact
                  path={routes.stateOverviewDashboard.path}
                  component={StateOverviewDashboardContainer}
                />
                <Route exact
                  path={routes.stateErateDashboard.path}
                  component={StateErateDashboardContainer}
                />
                <Route exact
                  path={routes.aboutUsPage.path}
                  component={AboutUs}
                />
                <Route exact
                  path={routes.faqPage.path}
                  component={Faq}
                />
                <Route exact
                  path={routes.privacyPolicyPage.path}
                  component={PrivacyPolicy}
                />
                <Route exact
                  path={routes.termsOfUsePage.path}
                  component={TermsOfUse}
                />
                <Route exact
                  path={routes.contactUsPage.path}
                  component={ContactUsContainer}
                />
                <Route component={() => <ErrorPage status={404} />} />
              </Switch>
          }

          {(showFooter || showErrorPage) && <Footer />}
        </div>
      </WindowDimensionsProvider>
    </ConnectedRouter>
  )
}

App.propTypes = {
  pathname: PropTypes.string,
  showFooter: PropTypes.bool,
  onDocumentClick: PropTypes.func,
  shouldDispatchDocumentClick: PropTypes.bool
}

export default App
