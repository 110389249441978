import actions from '../../../actions/districts'
import * as statuses from '../../status'
import { DISTRICTS_DATA_FILTERED } from '../../../actions/districtsFilters'

export default function (state = statuses.INITIALIZED, action = {}) {
  const { type } = action

  switch (type) {
    case actions.REQUEST_DISTRICTS:
      return statuses.REQUESTED
    case actions.RECEIVE_DISTRICTS:
      return statuses.LOADED
    case actions.RECEIVE_DISTRICTS_ERROR:
      return statuses.ERROR
    case DISTRICTS_DATA_FILTERED:
      return statuses.PROCESSED
    default:
      return state
  }
}
