import combine from '../combine'
import { SHOW_NAVBAR_TYPEAHEAD } from '../../actions/navbar'

function showNavbarTypeahead (state = false, action = {}) {
  if (action.type === SHOW_NAVBAR_TYPEAHEAD) {
    return action.payload
  }
  return state
}

export default combine({
  showNavbarTypeahead
})
