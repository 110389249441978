import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import dropdownArrow from '../../assets/icon-24-icon-arrow-d-black.svg'
import { renderMobile } from '../../utils'
import * as hooks from '../../hooks'
import './index.sass'
import { ASC, DESC } from '../../utils/sortHelpers'

function getValueFromLabel (label) {
  return label.replace('Sort by ', '')
}

const SingleValue = (width, icon) => ({ children, ...props }) => {
  const placeholder = renderMobile(width) ? props.data.mobile : children
  return (
    <components.SingleValue {...props}>
      <img src={icon} alt='Sort menu icon' style={{ minWidth: '24px' }} />
      <span>{placeholder}</span>
    </components.SingleValue>
  )
}

const DropdownIndicator = (width, isMenuOpen) => (props) => {
  const className = isMenuOpen ? 'SortDropdown__arrow--up' : ''
  return (
    !renderMobile(width) &&
    <components.DropdownIndicator {...props}>
      <img className={`SortDropdown__arrow ${className}`} src={dropdownArrow} alt='Sort button dropdown arrow' />
    </components.DropdownIndicator>
  )
}

export default function SortDropdown ({ options, value, onChange, icon }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const className = 'SortDropdown__sort-menu'
  const getOptionValue = ({ label }) => getValueFromLabel(label)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  // There's an open issue here, when we pass these callbacks it prevents the
  // menu from opening on mobile: https://github.com/JedWatson/react-select/issues/3973
  const props = renderMobile(width) ? {} : {
    onMenuOpen: () => setIsMenuOpen(true),
    onMenuClose: () => setIsMenuOpen(false)
  }
  const components = {
    DropdownIndicator: DropdownIndicator(width, isMenuOpen),
    SingleValue: SingleValue(width, icon),
    IndicatorSeparator: null
  }

  return (
    <Select
      components={components}
      aria-label='Sort table menu button'
      className={className}
      classNamePrefix={className}
      getOptionValue={getOptionValue}
      onChange={(option) => onChange(option)}
      options={options}
      isSearchable={false}
      tabindex='-1'
      value={value}
      {...props}
    />
  )
}

SortDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    direction: PropTypes.oneOf([ASC, DESC]),
    secondary: PropTypes.object
  })),
  onChange: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    direction: PropTypes.oneOf([ASC, DESC]),
    secondary: PropTypes.object
  })
}
