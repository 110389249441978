import React from 'react'
import PropTypes from 'prop-types'
import * as statuses from '../../reducers/status'
import NearbyServicesMap from '../../containers/NearbyServices/NearbyServicesMap'
import NearbyServicesTable from '../../containers/NearbyServices/NearbyServicesTable'
import NearbyServicesFilters from '../../containers/NearbyServices/NearbyServicesFilters'
import { renderTabletMd } from '../../utils'
import * as hooks from '../../hooks'
import './index.sass'

export default function NearbyServices ({ status, error }) {
  const { width } = hooks.useWindowDimensionsCtx()
  const mobileOrTablet = renderTabletMd(width)

  if (status === statuses.ERROR) {
    return (
      <div>{`Error loading services: ${error}`}</div>
    )
  }

  return (
    <div className='NearbyServices'>
      <NearbyServicesFilters />
      <div className='NearbyServicesContainer'>
        <NearbyServicesTable />
        {!mobileOrTablet && <NearbyServicesMap />}
      </div>
    </div>
  )
}

NearbyServices.propTypes = {
  nearbyServices: PropTypes.shape({
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    map: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired
  }),
  district: PropTypes.object,
  onPinClick: PropTypes.func,
  onClosePopup: PropTypes.func,
  onOpenPopup: PropTypes.func,
  onSort: PropTypes.func,
  onTableMouseEnter: PropTypes.func,
  onTableMouseLeave: PropTypes.func,
  bannerVisible: PropTypes.bool,
  setBannerVisible: PropTypes.func
}
