import { fetchHistoricalNationMetrics } from '../data'

export const REQUEST_HISTORICAL_NATION_METRICS = 'REQUEST_HISTORICAL_NATION_METRICS'
export function requestHistoricalNationMetrics () {
  return {
    type: REQUEST_HISTORICAL_NATION_METRICS
  }
}

export const RECEIVE_HISTORICAL_NATION_METRICS = 'RECEIVE_HISTORICAL_NATION_METRICS'
export function receiveHistoricalNationMetrics (data) {
  return {
    type: RECEIVE_HISTORICAL_NATION_METRICS,
    payload: { data }
  }
}

export const RECEIVE_HISTORICAL_NATION_METRICS_ERROR = 'RECEIVE_HISTORICAL_NATION_METRICS_ERROR'
export function receiveHistoricalNationMetricsError (error) {
  return {
    type: RECEIVE_HISTORICAL_NATION_METRICS_ERROR,
    payload: {
      error: error.message,
      status: error.status
    }
  }
}

export const CALCULATE_NATION_CHART_DATA = 'CALCULATE_NATION_CHART_DATA'
export function calculateNationChartData (data) {
  return {
    type: CALCULATE_NATION_CHART_DATA,
    payload: data.historicalNationMetrics
  }
}

export function loadHistoricalNationMetricsData () {
  return async function (dispatch, getState) {
    await getHistoricalNationMetrics()(dispatch)
    await dispatch(calculateNationChartData(getState()))
  }
}

export function getHistoricalNationMetrics (retries = 2) {
  return function (dispatch) {
    dispatch(requestHistoricalNationMetrics())

    return fetchHistoricalNationMetrics()
      .then(data => { return dispatch(receiveHistoricalNationMetrics(data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveHistoricalNationMetricsError(error))
        }
        return dispatch(getHistoricalNationMetrics(retries - 1))
      })
  }
}

export default {
  REQUEST_HISTORICAL_NATION_METRICS,
  RECEIVE_HISTORICAL_NATION_METRICS,
  RECEIVE_HISTORICAL_NATION_METRICS_ERROR
}
