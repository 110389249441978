export { getDistrict } from './district'
export { getDistricts } from './districts'
export { getDistrictServices } from './districtServices'
export { getDistrictsNearby } from './districtsNearby'
export { getNation } from './nation'
export { getStateMetrics } from './stateMetrics'
export { getStatesMetrics } from './statesMetrics.js'
export { getHistoricalStateMetrics } from './historicalStateMetrics'
export { getRawErateHistoricalStateMetrics } from './rawErateHistoricalStateMetrics'
export { getHistoricalNationMetrics } from './historicalNationMetrics'
export { getNearbyServices } from './nearbyServices'
export { searchDistricts } from './globalSearch'
export { applyFiltersOnClickAway } from './filters'

export const DOCUMENT_CLICKED = 'DOCUMENT_CLICKED'
export function documentClicked () {
  return {
    type: DOCUMENT_CLICKED
  }
}

export const SHOW_FOOTER = 'SHOW_FOOTER'
export function showFooter (showFooter) {
  return {
    type: SHOW_FOOTER,
    payload: showFooter
  }
}

export const TOGGLE_DISTRICT_BANNER = 'TOGGLE_DISTRICT_BANNER'
export function toggleDistrictBanner () {
  return {
    type: TOGGLE_DISTRICT_BANNER
  }
}

export const HTTP_ERROR_RESPONSE = 'HTTP_ERROR_RESPONSE'
export function httpErrorResponse (response) {
  return {
    type: HTTP_ERROR_RESPONSE,
    payload: {
      error: response.error,
      status: response.status
    }
  }
}
