import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../../Skeleton'
import TableTitle from '../../Table/Title'
import pluralize from 'pluralize'
import { formatNumber, NBSP } from '../../../utils/stringHelpers'
import { anyFilterApplied } from '../../../reducers/shared/filters/buildFilterFunction'
import * as statuses from '../../../reducers/status'
import './index.sass'

function below1MbpsFilterApplied (bandwidth) {
  return bandwidth.applied && bandwidth.options.max.value <= 0.9999
}

function contractFilterApplied (contract) {
  return contract.applied && contract.options.toggled
}

function anyOtherFilterApplied (filters) {
  delete filters.bandwidth
  delete filters.contract
  return anyFilterApplied(filters, ...Object.keys(filters))
}

function title (filters, filtered, numDistricts, stateCode) {
  const { bandwidth, contract } = filters
  const formattedFiltered = formatNumber(filtered)
  const formattedTotal = formatNumber(numDistricts)

  pluralize.addIrregularRule('has', 'have')

  if (below1MbpsFilterApplied(bandwidth) && contractFilterApplied(contract) && !anyOtherFilterApplied({ ...filters })) {
    return `${formattedFiltered} of ${formattedTotal} ${stateCode} school districts ${pluralize('is', filtered)} below 1 Mbps/student and ${pluralize('has', filtered)} expiring${NBSP}contracts`
  } else if (below1MbpsFilterApplied(bandwidth) && !anyOtherFilterApplied({ ...filters })) {
    return `${formattedFiltered} of ${formattedTotal} ${stateCode} school districts ${pluralize('is', filtered)} below 1 Mbps/student`
  } else if (anyFilterApplied(filters, ...Object.keys(filters))) {
    return `${formattedFiltered} of ${formattedTotal} school districts ${filtered === 1 ? 'meets' : 'meet'} the filter criteria`
  } else {
    return `Showing all ${formattedTotal} school districts in ${stateCode}`
  }
}

export default function DistrictsTableTitle ({ status, className, filtered, filters, tableStatus, stateMetrics }) {
  const { num_districts: numDistricts, state_code: stateCode } = stateMetrics.data

  if (status !== statuses.PROCESSED && tableStatus === statuses.LOADING) {
    return (
      <div className='DistrictsTableTitle'>
        <div className={`DistrictsTableTitle__${className}__skeleton`}>
          <Skeleton width={'30vw'} height={35} />
        </div>
      </div>
    )
  }

  const props = className === 'mobile' && {
    subtitle: 'Click on a district name to learn more.',
    subtitleClassName: 'DistrictsTableTitle__subtitle'
  }

  return (
    <div className='DistrictsTableTitle'>
      <TableTitle
        className={`DistrictsTableTitle__${className}`}
        title={title(filters, filtered, numDistricts, stateCode)}
        {...props}
      />
    </div>
  )
}

DistrictsTableTitle.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  filtered: PropTypes.number,
  filters: PropTypes.shape({
    bandwidth: PropTypes.object.isRequired,
    contract: PropTypes.object.isRequired,
    cost: PropTypes.object.isRequired,
    size: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired
  }),
  tableStatus: PropTypes.string,
  stateMetrics: PropTypes.shape({
    data: PropTypes.object.isRequired,
    status: PropTypes.string
  })
}
