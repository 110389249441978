import React from 'react'
import PropTypes from 'prop-types'
import states from '../../../data/states'
import variables from '../../../styles/variables.scss'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

export default function ChartLegendPDF ({ stateCode, comparisonStateCode }) {
  const boxStyle = StyleSheet.create({
    borderRadius: '0.7vw',
    width: '1.75vw'
  })

  const legendStyle = StyleSheet.create({
    display: 'inline-flex',
    flexDirection: 'row'
  })

  const labelStyle = StyleSheet.create({
    fontSize: '10pt',
    padding: '0 5mm 0 2mm'
  })

  return (
    <View style={legendStyle}>
      <Text style={[boxStyle, { backgroundColor: variables.colorChartColor1 }]} />
      <Text style={labelStyle}>
      National
      </Text>
      <Text style={[boxStyle, { backgroundColor: variables.colorChartColor3 }]} />
      <Text style={labelStyle}>
        {states[stateCode].stateName}
      </Text>
      {
        comparisonStateCode &&
        <>
          <Text style={[boxStyle, { backgroundColor: variables.colorChartColor2 }]} />
          <Text style={labelStyle}>
            {states[comparisonStateCode].stateName}
          </Text>
        </>
      }
    </View>
  )
}

ChartLegendPDF.propTypes = {
  stateCode: PropTypes.string.isRequired,
  comparisonStateCode: PropTypes.string
}
