import actions from '../../../actions/rawErateDistricts'

const initialState = null

export default function (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_DISTRICTS:
    case actions.RECEIVE_DISTRICTS:
    case actions.RECEIVE_DISTRICTS_ERROR:
      return payload.stateCode
    default:
      return state
  }
}
