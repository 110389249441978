import { searchDistrictsIndex } from '../data'

export const REQUEST_GLOBAL_SEARCH = 'REQUEST_GLOBAL_SEARCH'
export function requestGlobalSearch (searchTerm) {
  return {
    type: REQUEST_GLOBAL_SEARCH,
    payload: { searchTerm }
  }
}

export const RECEIVE_DISTRICTS_SEARCH = 'RECEIVE_DISTRICTS_SEARCH'
export function receiveDistrictsSearch (searchTerm, data) {
  return {
    type: RECEIVE_DISTRICTS_SEARCH,
    payload: { searchTerm, data }
  }
}

export const RECEIVE_DISTRICTS_SEARCH_ERROR = 'RECEIVE_DISTRICTS_SEARCH_ERROR'
export function receiveDistrictsSearchError (searchTerm, error) {
  return {
    type: RECEIVE_DISTRICTS_SEARCH_ERROR,
    payload: { searchTerm, error }
  }
}

export const DISTRICT_SELECTED = 'DISTRICT_SELECTED'
export function districtSelected (district) {
  return {
    type: DISTRICT_SELECTED,
    payload: { district }
  }
}

export const STATE_SELECTED = 'STATE_SELECTED'
export function stateSelected (state) {
  return {
    type: STATE_SELECTED,
    payload: { state }
  }
}

export const SYNC_RECENT_SEARCHES = 'SYNC_RECENT_SEARCHES'
export function syncRecentSearches (recentSearches) {
  return {
    type: SYNC_RECENT_SEARCHES,
    payload: { recentSearches }
  }
}

export function searchDistricts (searchTerm, retries = 2) {
  return function (dispatch) {
    const dispatchSuccess = data => {
      let action = receiveDistrictsSearch(searchTerm, data)
      return dispatch(action)
    }

    const dispatchError = error => {
      console.error(error.message)
      dispatch(receiveDistrictsSearchError(searchTerm, error))
      if (retries === 0) return
      return dispatch(searchDistricts(searchTerm, retries - 1))
    }

    return searchDistrictsIndex(searchTerm)
      .then(dispatchSuccess, dispatchError)
  }
}
