import React from 'react'
import { formatLargeCost } from '../../../utils/stringHelpers'
import ErateFundingChart from './ErateFundingChart'
import ErateFundingChartLegend from './ErateFundingChartLegend'
import fundingYears from '../../../data/fundingYears'
import './index.sass'

const ErateStateFundingMetrics = ({ stateMetrics }) => {
  const { data } = stateMetrics

  if (!data) return

  const {
    total_funding_requested: totalFunding,
    total_c1_funding_requested: totalC1Funding,
    total_c2_funding_requested: totalC2Funding
  } = data

  return (
    <div className='ErateStateFundingMetrics'>
      <h2 className='ErateStateFundingMetrics__title'>
        {formatLargeCost(totalFunding)} in FCC E-rate funding requested for K-12 school districts in {fundingYears.current}
      </h2>
      <div className='ErateStateFundingMetrics__metrics-container'>
        <ErateFundingChart
          totalFunding={totalFunding}
          totalC1Funding={totalC1Funding}
          totalC2Funding={totalC2Funding}
        />
        <div className='ErateFundingChartLegend__container'>
          <ErateFundingChartLegend
            category={1}
            amount={totalC1Funding}
            text='Services that provide the basic conduit access to the internet.'
          />
          <ErateFundingChartLegend
            category={2}
            amount={totalC2Funding}
            text='Internal connections services needed to enable high-speed broadband connectivity and broadband internal connections components.'
          />
        </div>
      </div>
    </div>
  )
}

export default ErateStateFundingMetrics
