export const ASC = 'ASC'
export const DESC = 'DESC'

const MOVE_UP = -1
const NO_CHANGE = 0
const MOVE_DOWN = 1
export const FIRST = -1
export const LAST = 1

const defaultSortOptions = {
  direction: ASC,
  nullPosition: LAST,
  secondary: null
}

const flip = shift => {
  switch (shift) {
    case MOVE_UP: return MOVE_DOWN
    case MOVE_DOWN: return MOVE_UP
    default: return NO_CHANGE
  }
}

const leftShift = (left, right, direction = ASC) => {
  if (left > right) { return direction === ASC ? MOVE_DOWN : MOVE_UP }
  if (left === right) { return NO_CHANGE }
  if (left < right) { return direction === ASC ? MOVE_UP : MOVE_DOWN }
}

const nullComparison = (left, right, nullPosition) => {
  if (left === null) { return right === null ? NO_CHANGE : nullPosition }
  if (right === null) { return flip(nullPosition) }
}

const compare = (left, right, direction, nullPosition) => {
  if (left === null || right === null) {
    return nullComparison(left, right, nullPosition)
  }
  return leftShift(left, right, direction)
}

const normalizeValue = (value) => {
  if (value === null || typeof value === 'undefined' || typeof value === 'boolean') return value
  if (typeof value === 'string' && isNaN(Number(value))) return value.toLowerCase()
  if (typeof Number(value) === 'number') return parseFloat(value)
  return value
}

const attrSort = (properties, nullPosition) => (a, b) => {
  for (const [attr, direction] of properties) {
    const comparison = compare(normalizeValue(a[attr]), normalizeValue(b[attr]), direction, nullPosition)
    if (comparison !== 0) return comparison
    continue
  }
  return 0
}

export default function sortObjects (collection, property, options = {}) {
  const { direction, nullPosition } = { ...defaultSortOptions, ...options }
  const sorted = [...collection]
  const properties = [[property, direction]]
  if (options.secondary) {
    const { value, direction } = options.secondary
    properties.push([value, direction])
  }
  return sorted.sort(attrSort(properties, nullPosition))
}
