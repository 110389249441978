import { connect } from 'react-redux'
import ContactUs from '../../components/ContactUs'
import { contactUsMenuButtonClicked, contactFormSubmitted } from '../../actions/contactUs'

const mapStateToProps = (state) => {
  return {
    selectedContactForm: state.selectedContactForm,
    contactFormSubmitted: state.contactFormSubmitted
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuButtonClick: (button) => {
      dispatch(contactUsMenuButtonClicked(button))
    },
    onContactFormSubmit: (formData) => {
      dispatch(contactFormSubmitted(formData))
    }
  }
}

const ContactUsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs)

export default ContactUsContainer
