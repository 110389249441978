import {
  contractExpiring, intersects, withinRange
} from '../../../filters/comparisons'

import buildFilterFunction from '../../../filters/buildFilterFunction'

export const bandwidth = (options, applied) =>
  withinRange('bandwidth_per_student_mbps', {
    ...applied,
    boundary: options.max.boundary
  })

export const contract = (options, applied) =>
  contractExpiring('contract_expiring', {
    toggled: applied
  })

export const cost = (options, applied) =>
  withinRange('cost_per_mbps', {
    ...applied,
    boundary: options.max.boundary
  })

export const provider = (_options, applied) =>
  intersects('service_providers', applied)

export const size = (options, applied) =>
  withinRange('num_students', {
    ...applied,
    boundary: options.max.boundary
  })

export const filterFactories = {
  bandwidth,
  contract,
  cost,
  size
}

export const standardFilters = buildFilterFunction(filterFactories)
export const providerFilters = buildFilterFunction({ provider })
