import { LOCATION_CHANGE } from 'connected-react-router'
import { loadHistoricalStateMetricsData, loadComparisonHistoricalStateMetricsData } from '../actions/historicalStateMetrics'
import { loadRawErateHistoricalStateMetricsData, loadComparisonRawErateHistoricalStateMetricsData } from '../actions/rawErateHistoricalStateMetrics'
import { loadHistoricalNationMetricsData } from '../actions/historicalNationMetrics'
import { STATE_COMPARISON_CLICKED } from '../actions/stateOverviewDashboard'
import { stateOverviewDashboard } from '../routes'

const stateOverviewDashboardDataLoader = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { location: { pathname } } = payload
    const match = stateOverviewDashboard.matchPath(pathname)

    if (match) {
      store.dispatch(loadHistoricalStateMetricsData(match.params.state_code))
      store.dispatch(loadRawErateHistoricalStateMetricsData(match.params.state_code))
      store.dispatch(loadHistoricalNationMetricsData())
    }
  } else if (type === STATE_COMPARISON_CLICKED) {
    store.dispatch(loadComparisonHistoricalStateMetricsData(payload))
    store.dispatch(loadComparisonRawErateHistoricalStateMetricsData(payload))
  }
}

export default stateOverviewDashboardDataLoader
