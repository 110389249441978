import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './Options.sass'
import Actions from './Actions'

function Options ({ visible, applied, children, onClear, onApply }) {
  const options = React.createRef()
  const [rightOffset, setRightOffset] = useState(0)

  useEffect(() => {
    function resetRightOffset () { setRightOffset(0) }

    if (options.current) {
      const boundingBox = options.current.getBoundingClientRect()
      if (boundingBox.right > window.innerWidth) {
        setRightOffset(boundingBox.right - window.innerWidth)
      }
    }

    window.addEventListener('resize', resetRightOffset)

    return function cleanup () {
      window.removeEventListener('resize', resetRightOffset)
    }
  }, [options, rightOffset])

  if (!visible) {
    return <div />
  }

  return (
    <div className='Filter__options-container' style={{ right: rightOffset + 'px' }} onClick={(e) => { e.stopPropagation() }}>
      <div className='Filter__options Filter__options--open' ref={options}>
        {children}
        <Actions applied={applied} onClear={onClear} onApply={onApply} />
      </div>
    </div>
  )
}

Options.propTypes = {
  visible: PropTypes.bool,
  applied: PropTypes.bool,
  children: PropTypes.any,
  onClear: PropTypes.func,
  onApply: PropTypes.func
}

export default Options
