import Homepage from '../../components/Homepage'
import { connect } from 'react-redux'
import {
  showHomepageTypeahead
} from '../../actions/navbar'

const mapStateToProps = (state) => {
  return {
    showNavbarTypeahead: state.showNavbarTypeahead
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setShowNavbarTypeahead: (showTypeahead) => {
      dispatch(showHomepageTypeahead(showTypeahead))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage)
