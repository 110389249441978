import deepmerge from 'deepmerge'
import { LOCATION_CHANGE } from 'connected-react-router'
import commonState from './commonState'
import { parseQuery } from '../../../../utils/urlHelpers'

export const initialState = deepmerge(commonState, {
  options: { toggled: false }
})

const contractParam = query => {
  const { contract } = query
  return !!contract
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const query = parseQuery(payload.location.search)
    if (!contractParam(query)) {
      return {
        ...state,
        ...{
          applied: false,
          enabled: false,
          options: initialState.options
        }
      }
    }

    return deepmerge(initialState, {
      applied: true,
      options: { toggled: true }
    })
  }

  return state
}
