import NearbyServicesMap from '../../components/NearbyServices/NearbyServicesMap'
import { connect } from 'react-redux'
import {
  getNearbyServicesTableStatus,
  getNearbyServicesMap
} from '../../selectors/dataSelectors'
import {
  servicePinMouseEnter,
  servicePinMouseLeave,
  servicePinClicked,
  servicePopupClosed,
  servicePopupOpened
} from '../../actions/map'
import { toggleDistrictBanner } from '../../actions'

const mapStateToProps = (state) => {
  return {
    status: state.nearbyServices.status,
    map: getNearbyServicesMap(state),
    tableStatus: getNearbyServicesTableStatus(state),
    bannerVisible: state.bannerVisible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDistrictBanner: () => {
      dispatch(toggleDistrictBanner())
    },
    onMouseEnter: (tooltipId) => {
      dispatch(servicePinMouseEnter(tooltipId && Number(tooltipId)))
    },
    onMouseLeave: () => {
      dispatch(servicePinMouseLeave())
    },
    onPinClick: (services) => {
      dispatch(servicePinClicked(services))
    },
    onClosePopup: () => {
      dispatch(servicePopupClosed())
    },
    onOpenPopup: (node, map, lngLat) => {
      dispatch(servicePopupOpened(node, map, lngLat))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NearbyServicesMap)
