import React, { useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import PopupBody from './PopupBody'
import PopupHeader from '../shared/PopupHeader'
import PopupFooter from '../shared/PopupFooter'
import { getPopupBodyHeight } from '../../../utils/styleBuilderUtils'
import pluralize from 'pluralize'
import './index.sass'

function subtitleText (numServices, totalServices) {
  const verb = totalServices === 1 ? 'matches' : 'match'
  return `${numServices} of ${totalServices} internet ${pluralize('service', totalServices)} ${verb} the filter criteria`
}

function subtitle ({ selected, services, totalServices }) {
  if (selected) { return null }
  return subtitleText(services.length, totalServices)
}

const ServicePopup = forwardRef(({ map, data, onClose }, ref) => {
  const headerRef = useRef()
  const footerRef = useRef()
  const bodyHeight = map && getPopupBodyHeight({
    selected: data.selected,
    mapHeight: map._container.offsetHeight,
    headerHeight: headerRef.current && headerRef.current.clientHeight,
    footerHeight: footerRef.current && footerRef.current.clientHeight,
    numServices: data.services.length
  })
  return (
    <div className='ServicePopup__popup' ref={ref}>
      <PopupHeader
        ref={headerRef}
        title={data.entity_name}
        subtitle={subtitle(data)}
        onClose={onClose} />
      <PopupBody
        selected={data.selected}
        style={bodyHeight}
        services={data.services}
      />
      <PopupFooter ref={footerRef} enabled={!data.selected} entityNumber={data.entity_number} />
    </div>
  )
})

export default ServicePopup

ServicePopup.propTypes = {
  map: PropTypes.object,
  data: PropTypes.object,
  onClose: PropTypes.func
}
