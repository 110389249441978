import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Tooltip from '../../Tooltip'
import Skeleton from '../../Skeleton'
import NoResults from '../../NoResults'
import Table from '../../Table'
import Header from '../../Table/Header'
import TableCell from '../../Table/Cell'
import InfoTooltip from '../../Tooltip/InfoTooltip'
import DirtyDistrictTooltip from '../../Tooltip/DirtyDistrictTooltip'
import { districtDashboard } from '../../../routes'
import columnHeaders from './columnHeaders.json'
import { tooltipContent } from './tooltipContent'
import * as statuses from '../../../reducers/status'
import utils from '../../../utils'
import { useToggleFooterOnScroll } from '../../../hooks'

function TableRow ({ district, isMobile, onTableMouseEnter, onTableMouseLeave }) {
  const onMouseEnter = (event) => onTableMouseEnter(event.target.getAttribute('id'))
  const onMouseLeave = () => onTableMouseLeave(null)

  return (
    <tr className='DistrictsTable__row'>
      <TableCell classNamePrefix='DistrictsTable__cell' name='name'>
        <Link to={districtDashboard.generatePath({ entity_number: district.entity_number })} className='DistrictsTable__cell--district-name'>
          <div
            id={district.entity_number}
            className={`DistrictsTable__cell--district-name--${district.entity_number}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {utils.titleize(district.entity_name)}
          </div>
        </Link>
        <DirtyDistrictTooltip unfitType={district.unfit_type} />
      </TableCell>
      <Tooltip mobileOnly placement='auto' overlay={tooltipContent('cost_per_mbps')}>
        <TableCell classNamePrefix='DistrictsTable__cell' name='cost-per-mbps'>
          {utils.formatCost(district.cost_per_mbps)}
        </TableCell>
      </Tooltip>
      <Tooltip mobileOnly placement='auto' overlay={tooltipContent('bandwidth_per_student_mbps')}>
        <TableCell classNamePrefix='DistrictsTable__cell' name='bandwidth-per-student-mbps'>
          {utils.formatBandwidth(district.bandwidth_per_student_mbps)}
        </TableCell>
      </Tooltip>
      <TableCell classNamePrefix='DistrictsTable__cell' name='service-providers'>
        {utils.formatProviders(district.service_providers)}
      </TableCell>
      <TableCell classNamePrefix='DistrictsTable__cell' name='num-students'>
        {utils.formatNumber(district.num_students)}
      </TableCell>
    </tr>
  )
}

export default function DistrictsTableData ({ districts, status, isMobile, tableSortOption, tableStatus, onTableMouseEnter, onTableMouseLeave }) {
  const ref = useToggleFooterOnScroll()
  if (districts.length === 0 && status === statuses.PROCESSED) { return <NoResults /> }
  return (
    <Table classNames={['DistrictsTable__table']} ref={ref}>
      <thead>
        <tr className='DistrictsTable__header-row'>
          {
            columnHeaders.map((header) => {
              return (
                <Header
                  key={header.column}
                  header={header.header}
                  column={header.column}
                  classNames={[utils.sortedHeaderClass(header.column, tableSortOption.value)]}>
                  <InfoTooltip enabled={!!header.tooltip} content={tooltipContent(header.column)} />
                </Header>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        { tableStatus !== statuses.LOADED
          ? [...Array(20)].map((_, idx) => {
            return (
              <tr key={`Skeleton__row-${idx}`} className='DistrictsTable__row'>
                {[...Array(5)].map((_, idx) => {
                  return (
                    <TableCell key={`Skeleton__cell-${idx}`} classNamePrefix='DistrictsTable__cell' name={'i' + idx}>
                      <Skeleton />
                    </TableCell>
                  )
                })}
              </tr>
            )
          })
          : districts.map((district, idx) => {
            return (
              <TableRow
                key={`TableRow-${idx}`}
                district={district}
                isMobile={isMobile}
                onTableMouseEnter={onTableMouseEnter}
                onTableMouseLeave={onTableMouseLeave}
              />
            )
          })}
      </tbody>
    </Table>
  )
}

DistrictsTableData.propTypes = {
  districts: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  tableSortOption: PropTypes.shape({
    value: PropTypes.string
  }),
  tableStatus: PropTypes.string,
  onTableMouseEnter: PropTypes.func,
  onTableMouseLeave: PropTypes.func
}
