import React from 'react'
import { CSVLink } from 'react-csv'
import DownloadDataButton from './DownloadDataButton'
import './index.sass'

export default function DownloadCSVData ({ data, headers, filename }) {
  return (
    <div className='DownloadData'>
      <CSVLink className='DownloadData__link' data={data} headers={headers} filename={filename}>
        <DownloadDataButton />
      </CSVLink>
    </div>
  )
}
