import actions from '../../../actions/nearbyServices'
const initialState = null

export default function (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case actions.REQUEST_NEARBY_SERVICES:
    case actions.RECEIVE_NEARBY_SERVICES:
    case actions.RECEIVE_NEARBY_SERVICES_ERROR:
      return payload.recipientDistrictBen || state
    default:
      return state
  }
}
