import React from 'react'
import { InputWithUnits, Input } from '.'
import { UNITS } from '../../../utils/bandwidthHelpers'
import classNames from 'classnames'

export function BandwidthPerStudent ({ maxBoundary, name, value, className, onChange, onApply }) {
  const Units = () =>
    <div className={classNames('BandwidthPerStudent__units', className)}>
      <div className='BandwidthPerStudent__unit-container'>
        <div className='BandwidthPerStudent__unit'>
          {UNITS.Mbps}
        </div>
      </div>
    </div>

  const BandwidthInput = ({ value, ...args }) => {
    return (<Input name={name} maxBoundary={maxBoundary} value={value} {...args} />)
  }

  return (
    <InputWithUnits
      className={classNames('BandwidthPerStudent__input', className)}
      name={name}
      value={value}
      input={BandwidthInput}
      units={Units}
      unitPosition='right'
      onChange={val => onChange(val)}
      onApply={onApply}
    />
  )
}
