import { fetchDistricts } from '../data'
import { applyFilters } from './districtsFilters'
import { tableSortDispatcher, firstRequest } from './helpers'
import { getData } from '../selectors/dataSelectors'

export const REQUEST_DISTRICTS = 'REQUEST_DISTRICTS'
export function requestDistricts (stateCode, isFirstRequest) {
  return {
    type: REQUEST_DISTRICTS,
    payload: { stateCode, isFirstRequest }
  }
}

export const RECEIVE_DISTRICTS = 'RECEIVE_DISTRICTS'
export function receiveDistricts ({ stateCode, data, isFirstRequest }) {
  return {
    type: RECEIVE_DISTRICTS,
    payload: { stateCode, data, isFirstRequest }
  }
}

export const RECEIVE_DISTRICTS_ERROR = 'RECEIVE_DISTRICTS_ERROR'
export function receiveDistrictsError (stateCode, error) {
  return {
    type: RECEIVE_DISTRICTS_ERROR,
    payload: {
      stateCode,
      error: error.message,
      status: error.status
    }
  }
}

export const SORT_DISTRICTS_TABLE = 'SORT_DISTRICTS_TABLE'
export function sortDistrictsTable (options) {
  return {
    type: SORT_DISTRICTS_TABLE,
    payload: { ...options }
  }
}

export const DISTRICTS_TABLE_SORTED = 'DISTRICTS_TABLE_SORTED'
export function districtsTableSorted () {
  return {
    type: DISTRICTS_TABLE_SORTED
  }
}

export const DISTRICTS_TABLE_SORT_APPLIED = 'DISTRICTS_TABLE_SORT_APPLIED'
export function districtsTableSortApplied (option) {
  return {
    type: DISTRICTS_TABLE_SORT_APPLIED,
    payload: { ...option }
  }
}

export const applySort = async (dispatch, getState) => {
  await tableSortDispatcher(sortDistrictsTable)(dispatch, getState)
  await dispatch(districtsTableSorted())
}

export function getDistricts (stateCode, retries = 2) {
  const requestedStateCode = stateCode
  return function (dispatch, getState) {
    const state = getState()
    const { districts: currentData } = getData(state)
    const currentStateCode = state.districts && state.districts.stateCode
    const isFirstRequest = firstRequest(currentData, currentStateCode, requestedStateCode)

    dispatch(requestDistricts(stateCode, isFirstRequest))

    const dispatchSuccess = data => {
      let action = receiveDistricts({ stateCode, data, isFirstRequest })
      return dispatch(action)
    }

    const dispatchError = error => {
      console.error(error.message)
      if (retries === 0) {
        return dispatch(receiveDistrictsError(stateCode, error))
      }
      return dispatch(getDistricts(stateCode, retries - 1))
    }

    return fetchDistricts(stateCode)
      .then(dispatchSuccess, dispatchError)
  }
}

export const loadDistrictsData = stateCode => {
  return async function (dispatch, getState) {
    await getDistricts(stateCode)(dispatch, getState)
    await applyFilters(dispatch, getState)
    return applySort(dispatch, getState)
  }
}

export default {
  REQUEST_DISTRICTS,
  RECEIVE_DISTRICTS,
  RECEIVE_DISTRICTS_ERROR,
  SORT_DISTRICTS_TABLE,
  DISTRICTS_TABLE_SORTED,
  DISTRICTS_TABLE_SORT_APPLIED
}
