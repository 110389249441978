import NearbyServicesTable from '../../components/NearbyServices/NearbyServicesTable'
import { connect } from 'react-redux'
import { nearbyServicesSortApplied } from '../../actions/nearbyServices'
import {
  districtNameMouseEnter,
  districtNameMouseLeave
} from '../../actions/map'
import {
  getNearbyServicesTable,
  getNearbyServicesData,
  getNearbyServicesFilters
} from '../../selectors/dataSelectors'

const mapStateToProps = (state) => {
  return {
    services: getNearbyServicesData(state).nearby,
    district: state.district.data,
    table: getNearbyServicesTable(state),
    filters: getNearbyServicesFilters(state),
    status: state.nearbyServices.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSort: (option) => {
      dispatch(nearbyServicesSortApplied(option))
    },
    onTableMouseEnter: (tooltipId) => {
      dispatch(districtNameMouseEnter(tooltipId && Number(tooltipId)))
    },
    onTableMouseLeave: () => {
      dispatch(districtNameMouseLeave())
    }
  }
}

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(NearbyServicesTable)

Connected.whyDidYouRender = true

export default Connected
