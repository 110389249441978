export const MOBILE_MENU_BUTTON_CLICKED = 'MOBILE_MENU_BUTTON_CLICKED'
export const mobileMenuButtonClicked = () => {
  return {
    type: MOBILE_MENU_BUTTON_CLICKED
  }
}

export const MOBILE_MENU_CLOSED = 'MOBILE_MENU_CLOSED'
export const mobileMenuClosed = () => {
  return {
    type: MOBILE_MENU_CLOSED
  }
}

export const NAV_PROMPT_OPENED = 'NAV_PROMPT_OPENED'
export const navPromptOpened = ({ type, prompt, leftOffset }) => {
  return {
    type: NAV_PROMPT_OPENED,
    payload: { type, prompt, leftOffset }
  }
}

export const NAV_PROMPT_CLOSED = 'NAV_PROMPT_CLOSED'
export const navPromptClosed = () => {
  return {
    type: NAV_PROMPT_CLOSED
  }
}

export const SHOW_NAVBAR_TYPEAHEAD = 'SHOW_NAVBAR_TYPEAHEAD'
export const showHomepageTypeahead = (showTypeahead) => {
  return {
    type: SHOW_NAVBAR_TYPEAHEAD,
    payload: showTypeahead
  }
}

export default {
  MOBILE_MENU_BUTTON_CLICKED,
  MOBILE_MENU_CLOSED,
  NAV_PROMPT_OPENED,
  NAV_PROMPT_CLOSED,
  SHOW_NAVBAR_TYPEAHEAD
}
