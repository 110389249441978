import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FilterPane from '../../FilterPane'
import Size from '../../Filters/Size'
import Cost from '../../Filters/Cost'
import Contract from './Contract'
import Provider from '../../Filters/Provider'
import FCCErateSource from '../../FCCErateSource'
import BandwidthPerStudent from './BandwidthPerStudent'
import { formatCost, formatRangeWith } from '../../../utils/stringHelpers'
import './index.sass'

export default function Filters ({
  filters,
  onToggle,
  onApply,
  onClear,
  onClearAll,
  onModalToggle,
  onUpdate
}) {
  const { bandwidth, size, cost, contract, provider } = filters

  const updateFilter = name => options => {
    onUpdate(name, {
      open: filters[name].open,
      options
    })
  }

  const clearFilter = name => () => {
    onClear(name)
  }

  const toggleFilterVisibility = name => () => {
    if (filters.modal.open) { return }
    onToggle(name, filters[name].open)
  }

  const updateTypeahead = name => selected => {
    onUpdate(name, {
      open: filters[name].open,
      selected
    })
  }

  const applyAll = () => {
    onApply('bandwidth', 'size', 'cost', 'contract', 'provider')
  }

  const CostHeader = () => {
    return (
      <div className='Filter__options--header CostMbps__header'>
        <strong>Cost/Mbps</strong>
      </div>
    )
  }

  return (
    <div className={classnames('StateBandwidthDashboard__filters-container', {
      'StateBandwidthDashboard__filters-container--open': filters.modal.open
    })}>
      <FilterPane modal={filters.modal} onModalToggle={onModalToggle}
        onApply={applyAll} onClearAll={onClearAll}>

        <BandwidthPerStudent
          bandwidth={bandwidth}
          onChange={updateFilter('bandwidth')}
          onClick={toggleFilterVisibility('bandwidth')}
          onClear={clearFilter('bandwidth')}
          onApply={() => onApply('bandwidth')}
        />

        <Cost
          header={CostHeader}
          cost={cost}
          step={0.1}
          label='Cost/Mbps'
          onChange={updateFilter('cost')}
          onClick={toggleFilterVisibility('cost')}
          onClear={clearFilter('cost')}
          onApply={() => onApply('cost')}
          appliedLabel={formatRangeWith(formatCost)(cost) + '/Mbps'}
        />

        <Size
          size={size}
          onChange={updateFilter('size')}
          onClick={toggleFilterVisibility('size')}
          onClear={clearFilter('size')}
          onApply={() => onApply('size')}
        />

        <Contract
          contract={contract}
          applied={contract.applied}
          onChange={updateFilter('contract')}
          onClick={toggleFilterVisibility('contract')}
          onClear={clearFilter('contract')}
          onApply={() => onApply('contract')}
        />

        <Provider
          provider={provider}
          applied={provider.applied}
          onChange={updateTypeahead('provider')}
          onClick={toggleFilterVisibility('provider')}
          onClear={clearFilter('provider')}
          onApply={() => onApply('provider')}
        />

      </FilterPane>
      <FCCErateSource className='StateBandwidthDashboard__source-text' />
    </div>
  )
}

Filters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onToggle: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  onClearAll: PropTypes.func,
  onModalToggle: PropTypes.func
}
