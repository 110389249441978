import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import arrowDown from '../../../assets/icon-16-icon-arrow-d-black.svg'
import stateOverviewIcon from '../../../assets/icon-24-icon-state-gray-1.svg'
import { onStatePage } from '../../../routes'
import StateMenuDropdown from './StateMenuDropdown'
import MenuItem from './MenuItem'
import { useMobileLeftOffset, calculateDesktopLeftOffset } from './useMobileLeftOffset'
import './State.sass'

export const PROMPT = 'Select a state from the search to view its connectivity details'

export default function State ({ stateMetrics, stateCode, onClick, pathname, mobile = false }) {
  const ref = createRef()
  const mobileLeftOffset = useMobileLeftOffset()

  const toggleDropdownOnMobile = () => {
    if (mobile) ref.current.classList.toggle('toggleDropdown')
  }

  const handleClick = stateRef => e => {
    e && e.stopPropagation()
    if (stateCode) {
      toggleDropdownOnMobile()
    } else {
      const menuItem = stateRef.current.getBoundingClientRect()
      const offset = !mobile
        ? calculateDesktopLeftOffset(menuItem)
        : mobileLeftOffset
      onClick({ type: 'STATE', prompt: PROMPT, leftOffset: offset })
    }
  }

  return (
    <div ref={ref} className='Navbar__link Navbar__link--state' onClick={handleClick(ref)}>
      <MenuItem selected={!mobile && onStatePage(pathname)} mobile={mobile} icon={false}>
        {mobile && <img src={stateOverviewIcon} alt='state menu icon' className='MenuItem__icon' />}
        State
        {stateCode && <img src={arrowDown} className='MenuItem__arrow' role='button' alt='state menu dropdown arrow' />}
        <StateMenuDropdown stateMetrics={stateMetrics} pathname={pathname} stateCode={stateCode} onClick={onClick} mobile={mobile} />
      </MenuItem>
    </div>
  )
}

State.propTypes = {
  stateMetrics: PropTypes.object,
  stateCode: PropTypes.string,
  pathname: PropTypes.string,
  onClick: PropTypes.func,
  mobile: PropTypes.bool
}
