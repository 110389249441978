import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import variables from '../../../styles/variables.scss'

export default function ChartHeaderPDF ({ title, subtitle, hasStateQuote }) {
  const titleStyle = StyleSheet.create({
    fontSize: '2vw'
  })

  const subtitleStyle = StyleSheet.create({
    color: variables.colorGray1,
    fontSize: '1.5vw',
    padding: hasStateQuote ? '1mm 0' : '2mm 0',
    marginBottom: hasStateQuote ? '0mm' : '5mm'
  })

  return (
    <View>
      <Text style={titleStyle}>
        {title}
      </Text>
      <Text style={subtitleStyle}>
        {subtitle}
      </Text>
    </View>
  )
}

ChartHeaderPDF.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}
