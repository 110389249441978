import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import states from '../../data/states'
import dropdownArrow from '../../assets/icon-24-icon-arrow-d-black.svg'
import './index.sass'

function setButtonTitle (buttonTitleContent) {
  return (
    <div>
      {buttonTitleContent}
      <img className='StateDropdown__arrow' src={dropdownArrow} alt='State comparison dropdown arrow' />
    </div>
  )
}

export default function StateComparisonDropdown ({ stateCode, onStateComparisonClicked, onStateComparisonCleared }) {
  const [buttonTitleContent, setButtonTitleContent] = useState('Choose a State')
  const [defaultItemContent, setDefaultItemContent] = useState('Choose a State')

  const onStateComparisonClearedClicked = () => {
    setDefaultItemContent('Choose a State')
    return onStateComparisonCleared()
  }
  const onStateItemClicked = (stateCode, state) => {
    setDefaultItemContent('Clear State')
    return (stateCode === state) ? onStateComparisonClearedClicked() : onStateComparisonClicked(state)
  }

  return (
    <DropdownButton
      id='state-dropdown-button'
      title={setButtonTitle(buttonTitleContent)}
      onSelect={(e) => setButtonTitleContent(e)}>
      <Dropdown.Item
        eventKey={'Choose a State'}
        onClick={() => {
          onStateComparisonClearedClicked()
        }}>
        {defaultItemContent}
      </Dropdown.Item>
      <Dropdown.Divider />
      {Object.keys(states).map((state, i) => {
        return (
          <Dropdown.Item
            key={i}
            id={state}
            eventKey={states[state].stateName}
            onClick={() => onStateItemClicked(stateCode, state)}>
            {states[state].stateName}
          </Dropdown.Item>)
      })}
    </DropdownButton>
  )
}

StateComparisonDropdown.propTypes = {
  stateCode: PropTypes.string,
  onStateComparisonClicked: PropTypes.func,
  onStateComparisonCleared: PropTypes.func
}
