import { connectRouter } from 'connected-react-router'
import combine from './combine'
import globalSearch from './shared/globalSearch'
import navbar from './shared/navbar'
import footer from './shared/footer'
import states from './shared/states'
import shouldDispatchDocumentClick from './shared/shouldDispatchDocumentClick'
import requestErrorStatusCode from './shared/requestErrorStatusCode'
import { routeReducer } from './routeReducer'

export default history => {
  const router = connectRouter(history)
  const commonReducers = combine({
    states,
    globalSearch,
    requestErrorStatusCode,
    shouldDispatchDocumentClick,
    navbar,
    footer,
    router
  })

  const passThrough = state => state
  let reducer

  return (state = {}, action = {}) => {
    reducer = routeReducer(state, action) || reducer || passThrough

    return {
      ...reducer(state, action),
      ...commonReducers(state, action)
    }
  }
}
