import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import Dropdown from './Dropdown'

export default function Section ({ sectionTitle, faqs, questionNumber }) {
  let [activeKey, setActiveKey] = useState(null)

  return (
    <div>
      <h2 className='Faq__section-title'>{sectionTitle}</h2>
      <Accordion>
        {faqs.map((faq, index) => {
          questionNumber = questionNumber + 1
          return (
            <Dropdown
              key={'faq-' + sectionTitle + '-connectk12-' + index}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              title={questionNumber + '. ' + faq.question}
              eventKey={`${index}`}
            >
              {faq.answer}
            </Dropdown>)
        })}
      </Accordion>
    </div>)
}
