export function validMinMaxRange ({ min, max }) {
  return min && max ? max >= min : true
}

export function setRangeOrMaxBoundary ({ min, max }, defaultOptions) {
  const maxBoundary = defaultOptions.max.boundary
  return {
    min: (min && min > maxBoundary) ? maxBoundary : (min || defaultOptions.min.boundary),
    max: (max && max > maxBoundary) ? maxBoundary : (max || maxBoundary)
  }
}
