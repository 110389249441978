/**
 * Simplified version of combineReducers
 *
 * @param reducers {Object}
 * @returns {function(*, *=): {}}
 */
export default function (reducers = {}) {
  return combinedFunction(
    reducers.initialState || {},
    prepareReducers(reducers)
  )
}

/**
 * Removes "initialState" key
 *
 * @param reducers {Object}
 * @returns {[string, function][]} - Array of [name, function] tuples
 */
function prepareReducers (reducers) {
  const _reducers = { ...reducers }
  delete _reducers.initialState
  return Object.entries(_reducers)
}

/**
 * Combine the given reducers into a single function
 *
 * @param initialState {Object}
 * @param reducers {[string, function][]} - Array of [name, function] tuples
 * @returns {function(*=, *=): *}
 */
function combinedFunction (initialState, reducers) {
  return (state = initialState, action = {}) =>
    reducers.reduce((nextState, [name, fn]) => {
      nextState[name] = fn(state[name], action)
      return nextState
    }, {})
}
