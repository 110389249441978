import { LOCATION_CHANGE } from 'connected-react-router'
import { getStatesMetrics } from '../actions/statesMetrics'

const globalSearchDataLoader = store => next => action => {
  next(action)

  if (action.type === LOCATION_CHANGE) {
    store.dispatch(getStatesMetrics())
  }
}

export default globalSearchDataLoader
