import { LOCATION_CHANGE } from 'connected-react-router'
import { CALCULATE_STATE_BANDWIDTH_CHART_DATA, RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS } from '../../../../actions/historicalStateMetrics'
import { STATE_COMPARISON_CLEARED } from '../../../../actions/stateOverviewDashboard'
import { formatPercent } from '../../../../utils/stringHelpers'
import sortObjects from '../../../../utils/sortHelpers'
import { formatStateMetric } from '../../../shared/utils'

export const initialState = {
  stateComparisonData: null,
  subtitle: null,
  title: null,
  stateData: []
}

export default function costOverTime (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case CALCULATE_STATE_BANDWIDTH_CHART_DATA:
      const stateData = sortObjects(payload.data.medianCostPerMbps, 'fundingYear').filter(excludeZeroYears()).slice(Math.max(payload.data.medianCostPerMbps.length - 5, 1))
      const stateCode = payload.stateCode
      const firstYear = stateData[0]
      const firstYearCost = firstYear.data
      const firstYearFundingYear = firstYear.fundingYear
      const currentCost = stateData[stateData.length - 1].data

      return {
        ...state,
        title: title(firstYearFundingYear, firstYearCost, currentCost, stateCode),
        subtitle,
        stateData
      }

    case RECEIVE_COMPARISON_HISTORICAL_STATE_METRICS:
      return {
        ...state,
        stateComparisonData: [...payload.data].map(formatStateMetric('median_cost_per_mbps'))
      }

    case STATE_COMPARISON_CLEARED:
    case LOCATION_CHANGE:
      return {
        ...state,
        stateComparisonData: null
      }
    default:
      return state
  }
}

const excludeZeroYears = () => (data) => {
  return (data.data > 0)
}

function calculateChange (currentCost, firstYearCost) {
  let costTrend, difference
  if (currentCost === firstYearCost) {
    costTrend = ''
    difference = 0
  } else if (currentCost < firstYearCost) {
    costTrend = 'decreased'
    difference = firstYearCost - currentCost
  } else {
    costTrend = 'increased'
    difference = currentCost - firstYearCost
  }
  return { costTrend, percentChange: difference / firstYearCost }
}

function title (firstYear, firstYearCost, currentCost, stateCode) {
  const { costTrend, percentChange } = calculateChange(currentCost, firstYearCost)
  const trendVerb = costTrend === '' ? 'remained consistent' : `${costTrend} by ${formatPercent(percentChange)}`

  return `Since ${firstYear}, the cost of broadband in ${stateCode} ${trendVerb}`
}

const subtitle = 'Median Cost per Mbps'
