import React, { useEffect } from 'react'
import './index.sass'
import NationalHeatMap from './NationalHeatMap'
import MedianCostDecrease from './metrics/MedianCostDecrease'
// import DigitalLearning from './metrics/DigitalLearning'
import ExecutiveSummaryReport from './metrics/ExecutiveSummaryReport'
import TrendsReport from './metrics/TrendsReport'
import StateRankings from './StateRankings'
import Partners from './Partners'
import { cleanupChartTooltips } from '../ChartTooltip'

export default function NationalDashboard ({ nation, states }) {
  useEffect(() => {
    return cleanupChartTooltips
  })

  if (!nation || !states) {
    return null
  }

  const { status } = nation

  return (
    <div className='NationalDashboard'>
      <h1 className='NationalDashboard__title'>
        Digital learning in every classroom, every day requires high-speed internet
      </h1>
      <div className='NationalDashboard__container'>
        <NationalHeatMap states={states} />
        <MedianCostDecrease
          status={status} />
        {/* <DigitalLearning /> */}
        <ExecutiveSummaryReport />
        <TrendsReport />
        <StateRankings states={states} />
        <Partners />
      </div>
    </div>
  )
}

NationalDashboard.propTypes = {}
