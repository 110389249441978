import { RECEIVE_DISTRICTS } from '../../../../../actions/districts'
import { calculateFitBounds } from '../../../../../utils/mapUtils'

const initialState = [[0, 0], [0, 0]]

export default function (state = initialState, action = {}) {
  const { type, payload } = action

  if (type === RECEIVE_DISTRICTS) {
    const { data, isFirstRequest } = payload
    return (isFirstRequest && calculateFitBounds(data)) || state
  }

  return state
}
