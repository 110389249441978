import { LOCATION_CHANGE } from 'connected-react-router'
import { DISTRICTS_FILTER_MENU_TOGGLED } from '../../actions/districtsFilters'
import { NEARBY_SERVICES_FILTER_MENU_TOGGLED } from '../../actions/nearbyServicesFilters'
import { NAV_PROMPT_OPENED } from '../../actions/navbar'
import { DOCUMENT_CLICKED } from '../../actions'

function shouldDispatchDocumentClick (state = false, action = {}) {
  const { type, payload } = action
  switch (type) {
    case DISTRICTS_FILTER_MENU_TOGGLED:
    case NEARBY_SERVICES_FILTER_MENU_TOGGLED:
    case NAV_PROMPT_OPENED:
      return !payload.open
    case LOCATION_CHANGE:
    case DOCUMENT_CLICKED:
      return false
    default:
      return state
  }
}

export default shouldDispatchDocumentClick
