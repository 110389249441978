import { fetchDistrictServices } from '../data'

export const REQUEST_DISTRICT_SERVICES = 'REQUEST_DISTRICT_SERVICES'
export function requestDistrictServices (recipientDistrictBen) {
  return {
    type: REQUEST_DISTRICT_SERVICES,
    payload: { recipientDistrictBen }
  }
}

export const RECEIVE_DISTRICT_SERVICES = 'RECEIVE_DISTRICT_SERVICES'
export function receiveDistrictServices (recipientDistrictBen, data) {
  return {
    type: RECEIVE_DISTRICT_SERVICES,
    payload: { recipientDistrictBen, data }
  }
}

export const RECEIVE_DISTRICT_SERVICES_ERROR = 'RECEIVE_DISTRICT_SERVICES_ERROR'
export function receiveDistrictServicesError (recipientDistrictBen, error) {
  return {
    type: RECEIVE_DISTRICT_SERVICES_ERROR,
    payload: {
      recipientDistrictBen,
      error: error.message,
      status: error.status
    }
  }
}

export function getDistrictServices (recipientDistrictBen, retries = 2) {
  return function (dispatch) {
    dispatch(requestDistrictServices(recipientDistrictBen))

    return fetchDistrictServices(recipientDistrictBen)
      .then(data => dispatch(receiveDistrictServices(recipientDistrictBen, data)))
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveDistrictServicesError(recipientDistrictBen, error))
        }
        return dispatch(getDistrictServices(recipientDistrictBen, retries - 1))
      })
  }
}

export default {
  REQUEST_DISTRICT_SERVICES,
  RECEIVE_DISTRICT_SERVICES,
  RECEIVE_DISTRICT_SERVICES_ERROR
}
