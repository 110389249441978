import { LOCATION_CHANGE } from 'connected-react-router'
import { ASC } from '../../../../../utils/sortHelpers'
import { NEARBY_SERVICES_SORT_APPLIED, RECEIVE_NEARBY_SERVICES } from '../../../../../actions/nearbyServices'
import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../../actions/nearbyServicesFilters'
import { recommendedServices } from '../../../../../components/NearbyServices/helpers'
import { parseQuery } from '../../../../../utils/urlHelpers'
import { recommendedSortOption } from './sortOptions'
import { initialState as defaultSortOptions } from './options'

export const initialState = {
  label: 'Proximity (Closest to Farthest)',
  mobile: 'Sort by proximity',
  value: 'distance',
  direction: ASC,
  secondary: null
}

const VALID_SORT_OPTIONS = [...defaultSortOptions, recommendedSortOption]

export function formatLabel (option) {
  return {
    ...option,
    label: option.label.includes('Sort by') ? option.label : `Sort by ${option.label}`
  }
}

export default function selected (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case LOCATION_CHANGE:
      const query = parseQuery(payload.location.search)
      const params = query['sort']

      const validURLParams = (params) => ({ value, direction }) => {
        if (!params) return false
        return value === params.value && direction === params.direction
      }

      const valid = VALID_SORT_OPTIONS.some(validURLParams(params))

      if (!valid) return formatLabel(state)

      return formatLabel(VALID_SORT_OPTIONS.find(validURLParams(params)))
    case RECEIVE_NEARBY_SERVICES:
      const { data, isFirstRequest } = payload
      if (!isFirstRequest) return formatLabel(state)
      if (recommendedServices(data)) {
        return formatLabel(recommendedSortOption)
      }
      return formatLabel(state)
    case NEARBY_SERVICES_SORT_APPLIED:
      return formatLabel(payload)
    case NEARBY_SERVICES_DATA_FILTERED:
      const { defaultSortOption } = payload
      return formatLabel(defaultSortOption)
    default:
      return formatLabel(state)
  }
}
