import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Table from '../../Table'
import COLUMNS from './columns'
import { ServiceHeaders, ServiceRows, UnfitMessage } from './ModalRows'
import './Modal.sass'
import closeButtonIcon from '../../../assets/icon-24-icon-close-black.svg'
import { formatCost, titleize } from '../../../utils/stringHelpers'

function BackboneCostFooter ({ enabled, cost }) {
  if (!cost || !enabled) return null

  return (
    <tfoot className='DistrictServicesModal__footer'>
      <tr>
        <td colSpan={COLUMNS.length}>
          This district also receives backbone (shared infrastructure) in the
          amount of {formatCost(cost, '0,0')} per month.
        </td>
      </tr>
    </tfoot>
  )
}

function CloseButton ({ onClick }) {
  return <img
    src={closeButtonIcon}
    role='button'
    alt='close icon'
    className='DistrictServicesModal__close-button'
    onClick={onClick}
  />
}

function DistrictServices ({ enabled, services, district }) {
  if (!enabled) return null
  return (
    <Table classNames={['DistrictServicesModal__table']}>
      <ServiceHeaders />
      <ServiceRows services={services} />
      <BackboneCostFooter enabled={enabled} cost={district.total_monthly_backbone_cost} />
    </Table>
  )
}

export default function Modal ({ district, services, open, onClose }) {
  const dirtyDistrict = district.hasOwnProperty('fit_for_ia') && !district.fit_for_ia
  const hasServices = services.data.length > 0
  return (
    <div className={classnames('DistrictServicesModal', { 'DistrictServicesModal--open': !!open })}>
      <div className='DistrictServicesModal__backdrop' onClick={onClose} />
      <div className='DistrictServicesModal__content'>
        <div className='DistrictServicesModal__header'>
          <h1 className='DistrictServicesModal__title'>
            {titleize(`${district.entity_name}'s Services`)}
          </h1>
          <CloseButton onClick={onClose} />
        </div>
        <UnfitMessage enabled={dirtyDistrict} services={services.data} />
        <DistrictServices enabled={hasServices} services={services.data} district={district} />
      </div>
    </div>
  )
}

Modal.propTypes = {
  district: PropTypes.shape({
    entity_name: PropTypes.string,
    total_monthly_backbone_cost: PropTypes.number
  }),
  services: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      average_monthly_cost: PropTypes.number,
      contract_expiration_date: PropTypes.string,
      download_speed_mbps: PropTypes.number,
      purpose: PropTypes.string,
      service_provider_name: PropTypes.string,
      shared: PropTypes.bool
    }))
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
}
