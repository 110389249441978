import deepmerge from 'deepmerge'
import { LOCATION_CHANGE } from 'connected-react-router'
import commonState from './commonState'
import providerLocationChangeHandler from '../../filters/providerLocationChangeHandler'
import { formatProviderOptions } from '../../utils'
import { RECEIVE_DISTRICTS } from '../../../../actions/districts'
import { FILTER_DISTRICTS_BY_PROVIDER } from '../../../../actions/districtsFilters'

export const initialState = deepmerge(commonState, { options: [] })

const locationChangeHandler = providerLocationChangeHandler(initialState)
const updateProviderOptions = (state, data) => {
  if (!data || (data.length === 0)) return state

  const providerNames = data
    .flatMap((district) => district.service_providers)
    .filter(options => !!options)

  const options = formatProviderOptions(providerNames, state.applied)

  return { ...state, ...{ options } }
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case LOCATION_CHANGE:
      return locationChangeHandler(state, action)
    case RECEIVE_DISTRICTS:
      return updateProviderOptions(state, payload.data)
    case FILTER_DISTRICTS_BY_PROVIDER:
      return updateProviderOptions(state, payload.districts)
    default:
      return state
  }
}
