import React from 'react'
import SortDropdown from '../../SortDropdown'
import DownloadNearbyServices from './../DownloadNearbyServices'
import { ASC } from '../../../utils'
import ascIcon from '../../../assets/iconSortAsc24.svg'
import descIcon from '../../../assets/iconSortDesc24.svg'
import recommendedIcon from '../../../assets/icon-24-icon-recommended.svg'

function sortIcon ({ direction, value }) {
  if (value === 'recommended') return recommendedIcon
  return direction === ASC ? ascIcon : descIcon
}

export default function TableOptions ({ tableSort, services, district, onSort }) {
  const { options, selected } = tableSort

  return (
    <div className='TableOptions__container'>
      <SortDropdown
        options={options}
        value={selected}
        icon={sortIcon(selected)}
        onChange={onSort} />
      {district &&
        <DownloadNearbyServices
          data={services}
          districtName={district.entity_name} />}
    </div>
  )
}
