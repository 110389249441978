import React from 'react'
import PropTypes from 'prop-types'
import stateData from './usa-states-data.json'
import USAState from './USAState'

export default function USAMap ({ width, height, defaultFill, stateOptions }) {
  const fillColor = (stateKey) => {
    if (stateOptions[stateKey] && stateOptions[stateKey].fill) {
      return stateOptions[stateKey].fill
    }
    return defaultFill
  }

  const handleMouseOver = (stateKey) => {
    if (stateOptions[stateKey] && stateOptions[stateKey].onMouseOver) {
      return stateOptions[stateKey].onMouseOver
    }
    return () => {}
  }

  const buildPaths = () => {
    let paths = []
    for (let stateKey in stateData) {
      const path = <USAState
        key={stateKey}
        state={stateKey}
        stateData={stateData[stateKey]}
        fill={fillColor(stateKey)}
        onMouseOver={handleMouseOver(stateKey)} />
      paths.push(path)
    }
    return paths
  }

  return (
    <svg className='us-state-map' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 959 593'>
      <g className='outlines'>
        {buildPaths()}
      </g>
    </svg>
  )
}

USAMap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stateOptions: PropTypes.object.isRequired
}

USAMap.defaultProps = {
  width: 959,
  height: 593,
  defaultFill: '#D3D3D3'
}
