import { SORT_DISTRICTS_TABLE, DISTRICTS_TABLE_SORT_APPLIED } from '../../../../../actions/districts'
import { ASC } from '../../../../../utils/sortHelpers'

export const initialState = {
  label: 'Sort by Bandwidth/Student (Low-High)',
  mobile: 'Sort by Bandwidth/Student',
  value: 'bandwidth_per_student_mbps',
  direction: ASC
}

export default function sort (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SORT_DISTRICTS_TABLE: {
      const { label } = payload
      return {
        ...payload,
        label: `Sort by ${label}`
      }
    }
    case DISTRICTS_TABLE_SORT_APPLIED: {
      const { label } = payload
      return {
        ...payload,
        label: label.includes('Sort by') ? label : `Sort by ${label}`
      }
    }
    default:
      return state
  }
}
