import { parseQuery } from '../../../../../utils/urlHelpers'
import deepmerge from 'deepmerge'
import { initialState } from './index'

const VALID_OPTIONS = ['internet', 'upstream', 'isp']

const extractParams = (purpose, { keys }) => {
  return keys.reduce((params, key) => {
    params[key] = purpose[key]
    return params
  }, {})
}

const filterKeys = purpose => fn => Object.keys(purpose).filter(fn)

const printIgnoredParamsWarning = (purpose, keys) => {
  const params = extractParams(purpose, { keys })
  const paramStrings = Object.entries(params).map(([key, value]) => `"purpose[${key}]=${value}"`)
  console.warn(`Ignored invalid filters: ${paramStrings.join(', ')}`)
}

const purposeParams = ({ purpose }) => {
  if (!purpose) { return }

  const keyFilter = filterKeys(purpose)
  const validKeys = keyFilter(key => VALID_OPTIONS.includes(key))
  const invalidKeys = keyFilter(key => !VALID_OPTIONS.includes(key))

  if (invalidKeys.length > 0) { printIgnoredParamsWarning(purpose, invalidKeys) }
  return extractParams(purpose, { keys: validKeys })
}

export default (state, payload) => {
  const params = purposeParams(parseQuery(payload.location.search))
  if (!params) {
    return {
      ...state,
      ...{
        applied: false,
        options: initialState.options
      }
    }
  }

  const applied = deepmerge(initialState.options, params)
  return deepmerge(state, { applied, options: applied })
}
