import React from 'react'
import Popover from 'react-bootstrap/Popover'

export function tooltipContent (header, id, selectedDistrict = {}) {
  const contentMap = {
    purpose: (
      <Popover id={id} content='true'>
        <p><b>Internet:</b>&nbsp;
          The service provider includes both the internet bandwidth and the physical transport circuit in the price of the service.
        </p>
        <p>
          <b>ISP (no circuit):</b>&nbsp;
          Internet bandwidth only with no physical transport circuit included.
        </p>
        <p>
          <b>Transport (to ISP):</b>&nbsp;
          Physical transport circuit to ISP.
        </p>
      </Popover>
    ),
    distance: (
      <Popover id={id} content='true'>
        <p><b>Proximity:</b>&nbsp;
          The approximate distance in miles from <strong>{selectedDistrict.entity_name}</strong>
        </p>
      </Popover>
    )
  }

  return (contentMap[header])
}
