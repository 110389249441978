import React from 'react'
import PropTypes from 'prop-types'
import DownloadData from '../../DownloadData'
import { formatDistrictURL } from '../../../utils/stringHelpers'
import utils from '../../../utils'

export default function DownloadDistricts ({ data, stateCode }) {
  const csvData = data.map((district) => {
    return {
      ...district,
      entity_name: district.entity_name ? district.entity_name.replace(/"/g, '""') : '',
      total_funding_requested: utils.formatCost(district.total_funding_requested, '0,0.00'),
      c1_discount_rate: utils.formatPercent(district.c1_discount_rate / 100),
      c2_discount_rate: utils.formatPercent(district.c2_discount_rate / 100),
      num_students: utils.formatNumber(district.num_students),
      csv_link_to_district_dashboard_page: formatDistrictURL(district)
    }
  })

  const csvHeaders = [
    { label: 'Schoool District name', key: 'entity_name' },
    { label: 'E-rate $ portion', key: 'total_funding_requested' },
    { label: 'Cat 1 Discount %', key: 'c1_discount_rate' },
    { label: 'Cat 2 Discount %', key: 'c2_discount_rate' },
    { label: 'Number of students', key: 'num_students' },
    { label: 'BEN(s)', key: 'entity_number' },
    { label: 'Link to district dashboard page', key: 'csv_link_to_district_dashboard_page' }
  ]

  return (
    <DownloadData data={csvData} headers={csvHeaders} filename={'districts-erate-funding-' + stateCode + '.csv'} />
  )
}

DownloadDistricts.propTypes = {
  data: PropTypes.array.isRequired,
  stateCode: PropTypes.string.isRequired
}
