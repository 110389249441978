import React from 'react'
import Skeleton from '../../Skeleton'
import DistrictAndSchoolBens from '../../Tooltip/DistrictBensTooltip'
import districtIcon from '../../../assets/icon-24-icon-district-gray-1.svg'
import { titleize, formatNumber } from '../../../utils/stringHelpers'

export default function DistrictDemographics ({ placeholder, district }) {
  if (placeholder) return <Skeleton className='DistrictDemographics__title--skeleton' />

  return (
    <div className='DistrictDemographics'>
      <h1 className='DistrictDemographics__title'>
        <img src={districtIcon} alt='school district icon' className='DistrictDemographics__title__icon' />
        {district && titleize(district.entity_name)}
      </h1>
      <div className='DistrictDemographics__num-students'>
        {formatNumber(district.num_students)} Students
      </div>
      <DistrictAndSchoolBens districtBen={district.entity_number} schoolBens={district.child_entity_numbers} />
    </div>
  )
}
