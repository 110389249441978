import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './MenuItem.sass'

export default function MenuItem ({ onClick = null, icon = null, selected = false, mobile = false, children }) {
  const menuItemClasses = classNames(
    !mobile && ['MenuItem', selected && 'MenuItem--selected'],
    mobile && ['MobileMenuItem', selected && 'MobileMenuItem--selected']
  )

  return (
    <div onClick={onClick} className={menuItemClasses}>
      {mobile && icon && <img src={icon} alt={`page icon`} className='MenuItem__icon' />}
      {children}
    </div>
  )
}

MenuItem.propTypes = {
  icon: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  mobile: PropTypes.bool,
  children: PropTypes.any
}
