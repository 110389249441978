import { LOCATION_CHANGE } from 'connected-react-router'
import { getRawErateStateMetrics } from '../actions/rawErateStateMetrics'
import { loadRawErateDistrictsData } from '../actions/rawErateDistricts'
import { stateErateDashboard } from '../routes'

const stateErateDashboardDataLoader = store => next => action => {
  next(action)

  const { type, payload } = action

  if (type === LOCATION_CHANGE) {
    const { location: { pathname } } = payload
    const match = stateErateDashboard.matchPath(pathname)

    if (match) {
      store.dispatch(loadRawErateDistrictsData(match.params.state_code))
      store.dispatch(getRawErateStateMetrics(match.params.state_code))
    }
  }
}

export default stateErateDashboardDataLoader
