import React from 'react'
import { Link } from 'react-router-dom'
import closeButtonIcon from '../../../assets/icon-24-icon-close-black.svg'
import logo from '../../../assets/connect-k12-logo.svg'
import { stopPropagation } from '../../../utils/actionHelpers'
import { homepage } from '../../../routes'
import PropTypes from 'prop-types'

export default function Header ({ onClick }) {
  return (
    <div className='MobileMenu__header' onClick={onClick}>
      <Link className='Navbar__logo' to={homepage.path}>
        <img className='MobileMenu__logo' src={logo} alt='ConnectK12 logo link' />
      </Link>
      <img src={closeButtonIcon} role='button' alt='close menu button'
        className='MobileMenu__close' onClick={stopPropagation(onClick)} />
    </div>
  )
}

Header.propTypes = {
  onClick: PropTypes.func.isRequired
}
