import { RECEIVE_NEARBY_SERVICES_ERROR } from '../../../actions/nearbyServices'

const initialState = null

export default function error (state = initialState, action = {}) {
  const { type, payload } = action
  if (type === RECEIVE_NEARBY_SERVICES_ERROR) {
    const defaultMessage = `Error requesting nearby district services for recipient ben: ${payload.recipientDistrictBen}`
    return payload.error.message || defaultMessage
  }
  return state
}
