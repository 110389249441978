import { stringify } from 'query-string'
import fundingYears from './fundingYears'

export async function requestData (url) {
  const response = await fetch(url)
  if (response.status > 299) {
    const error = new Error(response.statusText)
    error.status = response.status
    throw error
  }
  return response.json()
}

export async function fetchDistrict (entityNumber) {
  const url = `${process.env.REACT_APP_BODHI_URL}districts/${entityNumber}?funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchDistrictServices (recipientDistrictBen) {
  const url = `${process.env.REACT_APP_BODHI_URL}districts_services?recipient_district_ben=${recipientDistrictBen}&funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchDistricts (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}districts?state_code=${stateCode}&funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchDistrictsNearby ({ latitude, longitude, radius }) {
  const query = stringify({ latitude, longitude, radius })
  const url = `${process.env.REACT_APP_BODHI_URL}districts/nearby?funding_year=${fundingYears.current}&${query}`
  return requestData(url)
}

export async function fetchHistoricalNationMetrics () {
  const url = `${process.env.REACT_APP_BODHI_URL}nation/`
  return requestData(url)
}

export async function fetchHistoricalStateMetrics (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}states/${stateCode}`
  return requestData(url)
}

export async function fetchNationMetrics () {
  const url = `${process.env.REACT_APP_BODHI_URL}nation/${fundingYears.current}`
  return requestData(url)
}

export async function fetchNearbyServices (district) {
  const url = `${process.env.REACT_APP_BODHI_URL}districts_services/nearby?entity_number=${district.entity_number}&funding_year=${fundingYears.current}&latitude=${district.latitude}&longitude=${district.longitude}&radius=160000`
  return requestData(url)
}

export async function fetchStateMetrics (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}states/${stateCode}?funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchStatesMetrics () {
  const url = `${process.env.REACT_APP_BODHI_URL}states?funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function searchDistrictsIndex (name) {
  const url = `${process.env.REACT_APP_BODHI_URL}districts/search?entity_name=${name}&funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchRawErateDistricts (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}raw_erate_districts?state_code=${stateCode}&funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchRawErateStateMetrics (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}raw_erate_states/${stateCode}?funding_year=${fundingYears.current}`
  return requestData(url)
}

export async function fetchRawErateHistoricalStateMetrics (stateCode) {
  const url = `${process.env.REACT_APP_BODHI_URL}raw_erate_states/${stateCode}`
  return requestData(url)
}
