import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { stateErateDashboard } from '../../../routes'
import '../shared.sass'

export default function StateErateLink ({ classNames = [''], stateCode, children }) {
  if (!stateCode) { return <div /> }

  return (
    <Link className={classNames.join(' ')} to={stateErateDashboard.generatePath({ state_code: stateCode })}>
      {children}
    </Link>
  )
}

StateErateLink.propTypes = {
  stateCode: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any
}
