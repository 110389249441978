import React from 'react'
import Skeleton from '../../Skeleton'
import InfoTooltip from '../../Tooltip/InfoTooltip'
import tooltipContent from './tooltipContent'
import { formatBandwidth } from '../../../utils/stringHelpers'

const placeholderElements = (
  [
    ['1 Mbps', 'per student'],
    [<Skeleton width={'6vw'} />, 'total bandwidth']
  ]
)

const fccMetricsContent = (placeholder, recommendedBw) => {
  if (placeholder) return placeholderElements
  const bandwidth = recommendedBw && [formatBandwidth(recommendedBw), 'total bandwidth']

  return (
    [
      ['1 Mbps', 'per student'],
      bandwidth
    ]
  )
}
export default function FCCRecommendations ({ placeholder, district = {} }) {
  const recommendedBw = district.meeting_goal
    ? Math.min(district.total_bandwidth_mbps, district.recommended_bw)
    : district.recommended_bw

  return (
    <div className='DistrictDetails__metrics--fcc'>
      <div className='DistrictDetails__metrics--title'>
        FCC BANDWIDTH GOAL
        <InfoTooltip content={tooltipContent('fcc')} />
      </div>
      <div className='DistrictDetails__metric-container'>
        {
          fccMetricsContent(placeholder, recommendedBw).map((metric, key) => {
            return metric &&
              <div key={`FCCMetrics-${metric[1]}-${key}`} className='DistrictDetails__metric'>
                <div className='DistrictDetails__metric--value'>{metric[0]}</div>
                <div className='DistrictDetails__metric--description'>{metric[1]}</div>
              </div>
          })
        }
      </div>
    </div>
  )
}
