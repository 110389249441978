import React from 'react'
import downloadIcon from '../../assets/iconDownloadData.svg'

export default function DownloadDataButton ({ text = 'Download Data' }) {
  return (
    <div className='DownloadData__text'>
      <img alt='download icon' src={downloadIcon} className='DownloadData__icon--download-arrow' />
      {text}
    </div>
  )
}
