import { combineReducers } from 'redux'
import canvas from './canvas'
import nearby from './nearby'
import totalServices from './totalServices'
import selected from './selected'
import popup from './popup'
import pins from './pins'
import tooltipId from './tooltipId'

const initialState = {
  canvas: {},
  nearby: {},
  totalServices: {},
  selected: {},
  popup: {},
  pins: {},
  tooltipId: null
}

export default combineReducers({
  initialState,
  canvas,
  nearby,
  totalServices,
  selected,
  popup,
  pins,
  tooltipId
})
