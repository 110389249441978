
export function stopPropagation (onClick) {
  return (event) => {
    event.stopPropagation()
    onClick(event)
  }
}

export function clickOffComponent (action, modalReference) {
  return (e) => {
    if (!modalReference.current.contains(e.target)) { return action() }
  }
}
