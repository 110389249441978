import { connect } from 'react-redux'
import { documentClicked } from '../../actions'
import { applyFiltersOnClickAway } from '../../actions/filters'
import { getPathname } from '../../selectors/locationSelectors'
import App from '../../components/App'

const mapStateToProps = (state) => {
  return {
    shouldDispatchDocumentClick: state.shouldDispatchDocumentClick,
    pathname: getPathname(state),
    showFooter: state.footer,
    statusCode: state.requestErrorStatusCode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDocumentClick: (shouldDispatchDocumentClick) => {
      if (shouldDispatchDocumentClick) {
        dispatch(documentClicked())
        dispatch(applyFiltersOnClickAway())
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
