import React from 'react'
import iconRecommended from '../../../assets/icon-24-icon-recommended.svg'

function RecommendedTableRow ({ style }) {
  return (
    <tr className='RecommendedTableRow'>
      <th colSpan={7} style={{ ...style, borderBottom: 0 }}>
        <div className='RecommendedTableRow__container'>
          <img
            src={iconRecommended}
            alt='Recommended Service Icon'
            className='RecommendedTableRow__icon'
          />
          <div className='RecommendedTableRow__text-container'>
            <strong style={{ marginRight: '5px' }}>Recommended:</strong>
            <div className='RecommendedTableRow__text'>Competitive prices from providers with internet connections closest to your school district.</div>
          </div>
        </div>
      </th>
    </tr>
  )
}

export default RecommendedTableRow
