import colors from '../../../styles/variables.scss'
import { formatLargeCost } from '../../../utils/stringHelpers'
import 'chartjs-plugin-style'

function _setDynamicOptions (renderMobile) {
  return {
    point: {
      radius: renderMobile ? 8 : 5
    },
    xAxes: {
      fontSize: renderMobile ? 12 : 16
    },
    yAxes: {
      fontSize: renderMobile ? 12 : 14
    }
  }
}

export default function (renderMobile) {
  const dynamicOptions = _setDynamicOptions(renderMobile)
  return (
    {
      elements: {
        point: {
          radius: dynamicOptions.point.radius,
          hitRadius: 10
        }
      },
      hover: {
        animationDuration: 0
      },
      tooltips: {
        enabled: true,
        bodyAlign: 'center',
        backgroundColor: colors.white,
        cornerRadius: 8,
        displayColors: false,
        bodyFontColor: colors.black,
        bodyFontSize: 14,
        caretSize: 0,
        xPadding: 10,
        yPadding: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        shadowBlur: 5,
        shadowColor: colors.colorBlack12percent,
        callbacks: {
          title: () => undefined,
          label: tooltipItem => formatLargeCost(tooltipItem.value)
        }

      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: dynamicOptions.xAxes.fontSize,
              fontColor: colors.black,
              padding: 10
            },
            gridLines: false,
            position: 'center'
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontSize: dynamicOptions.yAxes.fontSize,
              fontColor: colors.colorGray1,
              maxTicksLimit: 3,
              padding: 10,
              callback: label => formatLargeCost(label)
            },
            position: 'right',
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              lineWidth: 1,
              zeroLineColor: colors.colorLightGray1,
              zeroLineWidth: 0.5,
              color: colors.colorLightGray1
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      maintainAspectRatio: false
    }
  )
}
