import { fetchRawErateHistoricalStateMetrics } from '../data'
import status from '../reducers/status'

export const REQUEST_RAW_ERATE_HISTORICAL_STATE_METRICS = 'REQUEST_RAW_ERATE_HISTORICAL_STATE_METRICS'
export function requestRawErateHistoricalStateMetrics (stateCode) {
  return {
    type: REQUEST_RAW_ERATE_HISTORICAL_STATE_METRICS,
    payload: { stateCode }
  }
}

export const REQUEST_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS = 'REQUEST_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS'
export function requestComparisonRawErateHistoricalStateMetrics (stateCode) {
  return {
    type: REQUEST_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS,
    payload: { stateCode }
  }
}

export const RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS = 'RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS'
export function receiveRawErateHistoricalStateMetrics (stateCode, data) {
  return {
    type: RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS,
    payload: { stateCode, data }
  }
}

export const RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS = 'RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS'
export function receiveComparisonRawErateHistoricalStateMetrics (stateCode, data) {
  return {
    type: RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS,
    payload: { stateCode, data }
  }
}

export const RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS_ERROR = 'RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS_ERROR'
export function receiveRawErateHistoricalStateMetricsError (stateCode, error) {
  return {
    type: RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS_ERROR,
    payload: {
      data: {
        state_code: stateCode
      },
      error: error.message,
      status: error.status
    }
  }
}

export const RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR = 'RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR'
export function receiveComparisonRawErateHistoricalStateMetricsError (stateCode, error) {
  return {
    type: RECEIVE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS_ERROR,
    payload: {
      data: {
        state_code: stateCode
      },
      error: error.message,
      status: error.status
    }
  }
}

export const CALCULATE_RAW_ERATE_STATE_BANDWIDTH_CHART_DATA = 'CALCULATE_RAW_ERATE_STATE_BANDWIDTH_CHART_DATA'
export function calculateRawErateStateBandwidthChartData (data) {
  return {
    type: CALCULATE_RAW_ERATE_STATE_BANDWIDTH_CHART_DATA,
    payload: data.rawErateHistoricalStateMetrics
  }
}

export const CALCULATE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS = 'CALCULATE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS'
export function calculateComparisonRawErateHistoricalStateMetrics () {
  return {
    type: CALCULATE_RAW_ERATE_COMPARISON_HISTORICAL_STATE_METRICS
  }
}

export const PERCENT_RAW_ERATE_MEETING_GOAL_VIEW_UPDATED = 'PERCENT_RAW_ERATE_MEETING_GOAL_VIEW_UPDATED'
export function percentMeetingGoalViewUpdated (view) {
  return {
    type: PERCENT_RAW_ERATE_MEETING_GOAL_VIEW_UPDATED,
    payload: {
      view
    }
  }
}

export const generateRawErateStateMetricCharts = async (dispatch, getState) => {
  const state = getState()
  if (state.rawErateHistoricalStateMetrics.status !== status.ERROR) {
    return dispatch(calculateRawErateStateBandwidthChartData(state))
  }
}

export function loadRawErateHistoricalStateMetricsData (stateCode) {
  return async function (dispatch, getState) {
    await getRawErateHistoricalStateMetrics(stateCode)(dispatch)
    await generateRawErateStateMetricCharts(dispatch, getState)
  }
}

export function getRawErateHistoricalStateMetrics (stateCode, retries = 2) {
  return function (dispatch) {
    dispatch(requestRawErateHistoricalStateMetrics(stateCode))

    return fetchRawErateHistoricalStateMetrics(stateCode)
      .then(data => { return dispatch(receiveRawErateHistoricalStateMetrics(stateCode, data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveRawErateHistoricalStateMetricsError(stateCode, error))
        }
        return dispatch(getRawErateHistoricalStateMetrics(stateCode, retries - 1))
      })
  }
}

export function loadComparisonRawErateHistoricalStateMetricsData (stateCode) {
  return async function (dispatch) {
    await getComparisonRawErateHistoricalStateMetrics(stateCode)(dispatch)
    await dispatch(calculateComparisonRawErateHistoricalStateMetrics())
  }
}

export function getComparisonRawErateHistoricalStateMetrics (stateCode, retries = 2) {
  return function (dispatch) {
    dispatch(requestComparisonRawErateHistoricalStateMetrics(stateCode))

    return fetchRawErateHistoricalStateMetrics(stateCode)
      .then(data => { return dispatch(receiveComparisonRawErateHistoricalStateMetrics(stateCode, data)) })
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveComparisonRawErateHistoricalStateMetricsError(stateCode, error))
        }
        return dispatch(getComparisonRawErateHistoricalStateMetrics(stateCode, retries - 1))
      })
  }
}

export default {
  REQUEST_RAW_ERATE_HISTORICAL_STATE_METRICS,
  RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS,
  RECEIVE_RAW_ERATE_HISTORICAL_STATE_METRICS_ERROR
}
