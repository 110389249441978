import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Button.sass'
import filterIcon from '../../assets/filters.svg'

function Button ({ numApplied, onClick }) {
  const buttonClass = classnames('FilterModal__Button', {
    'FilterModal__Button--applied': !!numApplied
  })
  return (
    <div className={buttonClass} onClick={onClick} role='button'>
      <img className='FilterModal__ButtonIcon' src={filterIcon} aria-hidden alt='filter icon' />
      Filters
      {!!numApplied && <div className='FilterModal__Button--count'>{numApplied}</div>}
    </div>
  )
}

Button.propTypes = {
  numApplied: PropTypes.number,
  onClick: PropTypes.func
}

export default Button
