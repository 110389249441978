import React from 'react'
import PropTypes from 'prop-types'
import Filter, { Label, Options as OptionsContainer } from '../Filter'
import RangeSlider from './RangeSlider'
import RangeInputGroup from './RangeInputGroup'
import './RangeFilter.sass'

function RangeFilter ({
  appliedFormatter,
  header: Header,
  label,
  maxInput: MaxInput,
  minInput: MinInput,
  onApply,
  appliedLabel,
  onChange,
  onClear,
  onClick,
  range,
  step
}) {
  const { open, applied, enabled, options: { min, max } } = range

  const values = [min.value, max.value]

  const domain = [min.boundary, max.boundary]

  const handleChange = ([min, max]) => {
    if (values[0] === min && values[1] === max) return null
    if (step < 1) {
      min = parseFloat(min.toFixed(2), 10)
      max = parseFloat(max.toFixed(2), 10)
    }
    onChange({ min: { value: min }, max: { value: max } })
  }

  return (
    <Filter {...{ label, open, enabled, applied }}>
      <Label open={open} onClick={onClick} name={label} applied={!!applied} appliedLabel={appliedLabel} />

      <OptionsContainer
        visible={open}
        applied={!!applied}
        onApply={onApply}
        onClear={onClear} >
        <Header />
        <RangeSlider
          values={values}
          step={step}
          domain={domain}
          onChange={handleChange} />
        <RangeInputGroup
          values={values}
          onChange={handleChange}
          onApply={onApply}
          maxBoundary={max.boundary}
          minInput={MinInput}
          maxInput={MaxInput} />
      </OptionsContainer>
    </Filter>
  )
}

RangeFilter.defaultProps = {
  header: 'span',
  onChange: () => true
}

RangeFilter.propTypes = {
  appliedLabel: PropTypes.string,
  appliedFormatter: PropTypes.func,
  header: PropTypes.any,
  label: PropTypes.string,
  maxInput: PropTypes.func,
  minInput: PropTypes.func,
  onApply: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  range: PropTypes.object,
  step: PropTypes.number
}

export default RangeFilter
