import React from 'react'
import { Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead'
import districtIcon from '../../assets/icon-24-icon-district-gray-1.svg'
import stateOverviewIcon from '../../assets/icon-24-icon-state-gray-1.svg'
import stateBandwidthIcon from '../../assets/icon-24-icon-state-details-gray-1.svg'
import stateErateIcon from '../../assets/icon-24-icon-state-erate-gray-1.svg'
import { STATE_BANDWIDTH, STATE_OVERVIEW, STATE_ERATE } from '../../reducers/shared/globalSearch/data/states'
import recentIcon from '../../assets/recentIcon.svg'

const DistrictMenuItem = ({ result, index, text }) => {
  return (
    <MenuItem key={result.id} option={result} position={index} className='GlobalSearchTypeahead__option'>
      <img src={districtIcon} alt='search-icon' className='GlobalSearchTypeahead__icon--option' />
      <span>
        <Highlighter search={text}>
          {result.label}
        </Highlighter>
      </span>
    </MenuItem>
  )
}

const StateMenuItem = ({ result, index, text }) => {
  const icons = {
    [STATE_BANDWIDTH]: stateBandwidthIcon,
    [STATE_OVERVIEW]: stateOverviewIcon,
    [STATE_ERATE]: stateErateIcon
  }
  const icon = icons[result.page]
  return (
    <MenuItem key={`${result.id}-${result.page}`} option={result} position={index} className='GlobalSearchTypeahead__option'>
      <img src={icon} alt='search-icon' className='GlobalSearchTypeahead__icon--option' />
      <span>
        <Highlighter search={text}>
          {result.label}
        </Highlighter>
      </span>
    </MenuItem>
  )
}

export const Item = ({ result, index, text }) => {
  return result.type === 'districts'
    ? <DistrictMenuItem result={result} index={index} text={text} />
    : <StateMenuItem result={result} index={index} text={text} />
}

export function groupMenuItems (items) {
  const grouped = {
    states: {
      items: [],
      header: 'States'
    },
    districts: {
      items: [],
      header: 'School Districts'
    },
    recent: {
      items: [],
      header: 'Recent Searches'
    }
  }

  items.forEach((item) => {
    if (!item.type) return
    const key = item.recent ? 'recent' : item.type
    grouped[key].items.push(item)
  })
  return grouped
}

function MenuHeader ({ show, header, icon }) {
  if (!show) return null
  const id = `GlobalSearchMenu__header--${header.replace(' ', '')}`
  return (
    <Menu.Header id={id}>
      {icon && <img src={icon} className='GlobalSearchTypeahead__icon--header' alt='typeahead menu header icon' />}
      {header}
    </Menu.Header>
  )
}

export function renderMenuItems (menuItems, props) {
  let idx = 0
  const items = Object.keys(menuItems).map((type) => {
    const icon = type === 'recent' && recentIcon
    const empty = menuItems[type].items.length === 0
    return [
      <MenuHeader key={menuItems[type].header} header={menuItems[type].header} show={!empty} icon={icon} />,
      menuItems[type].items.map(item => {
        const resultItem = <Item key={`${item.id}-${idx}`} result={item} index={idx} text={props.text} />
        idx++
        return resultItem
      })
    ]
  })
  return items
}

export default function GlobalSearchMenu (results, menuProps, props) {
  if (results.length === 0) return null
  return (
    <Menu {...menuProps}>
      {renderMenuItems(groupMenuItems(results), props)}
    </Menu>
  )
}
