import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Skeleton from '../../Skeleton'
import { formatBandwidth } from '../../../utils/stringHelpers'
import colors from '../../../styles/variables.scss'
import checkmarkCircle from '../../../assets/icon-24-icon-meeting-goal.svg'
import progressBarGraphic from '../../../assets/progress-bar-graphic.svg'
import './index.sass'

function bwBarStyle (bandwidth, meetingGoal) {
  const color = meetingGoal ? colors.colorSecondary3 : colors.colorSecondary340Percent
  return {
    position: 'absolute',
    backgroundColor: color,
    width: `${bwBarPercentage(bandwidth)}%`,
    content: '',
    top: 0,
    bottom: 0,
    left: 0
  }
}

function bwBarPercentage (bandwidth) {
  const percentage = (bandwidth / BANDWIDTH_GOAL) * 100
  if (percentage < 1) return 1
  if (percentage > 95) return 95
  else return percentage
}

const BANDWIDTH_GOAL = 1

const InsufficientDataMessage = () => (
  <div className='BandwidthStatus__text'>
    We have insufficient broadband data for this school district. Please contact us to clarify.
  </div>
)

const ProgressBar = ({ bandwidth }) => {
  const meetingGoal = bandwidth >= BANDWIDTH_GOAL
  const goalLabelBw = meetingGoal ? bandwidth : BANDWIDTH_GOAL

  return (
    <div className='BandwidthStatus__bar-section'>
      <div className='BandwidthStatus__bar--labels'>
        <div className='BandwidthStatus__bar--label'>
          {!meetingGoal && `${formatBandwidth(bandwidth)}/student`}
        </div>
        <div className='BandwidthStatus__bar--label'>
          {meetingGoal && <img className='BandwidthStatus__icon' src={checkmarkCircle} alt='meeting goal icon' />}
          {formatBandwidth(goalLabelBw)}/student
        </div>
      </div>
      <div className={classNames('BandwidthStatus__bar', { 'BandwidthStatus__bar--meeting-goal': meetingGoal })} >
        <div style={bwBarStyle(bandwidth, meetingGoal)} />
        <div
          style={{ 'marginLeft': `${bwBarPercentage(bandwidth)}%` }}
          className={classNames('BandwidthStatus__bar--arrow', { 'BandwidthStatus__bar--arrow--meeting-goal': meetingGoal })} />
      </div>
    </div>
  )
}

export default function BandwidthStatus ({ placeholder, bandwidth }) {
  if (placeholder) {
    return (
      <div className='BandwidthStatus BandwidthStatus__skeleton'>
        <Skeleton className='BandwidthStatus__graphic--skeleton' height={'100%'} width={'100%'} />
        <Skeleton className='BandwidthStatus__title--skeleton' count={3} />
        <Skeleton className='BandwidthStatus__bar-section--skeleton' />
      </div>
    )
  }

  return (
    <div className='BandwidthStatus'>
      <div className='BandwidthStatus__graphic'>
        <img className='BandwidthStatus__graphic--image' src={progressBarGraphic} alt='Bandwidth status bar graphic' />
      </div>
      <div className='BandwidthStatus__title'>
        To enable digital learning in every classroom every day, the FCC recommends <strong>1 Mbps per&nbsp;student.</strong>
      </div>
      {
        bandwidth ? <ProgressBar bandwidth={bandwidth} /> : <InsufficientDataMessage />
      }
    </div>
  )
}

BandwidthStatus.propTypes = {
  placeholder: PropTypes.bool,
  bandwidth: PropTypes.number
}
