import { connect } from 'react-redux'
import StateBandwidthDashboard from '../../components/StateBandwidthDashboard'
import {
  districtPinClicked,
  districtPopupClosed,
  districtPopupOpened,
  districtNameMouseEnter,
  districtNameMouseLeave
} from '../../actions/map'
import { districtsTableSortApplied } from '../../actions/districts'

const mapStateToProps = (state) => {
  return {
    districts: state.districts,
    stateMetrics: state.stateMetrics
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSort: (option) => {
      dispatch(districtsTableSortApplied(option))
    },
    onPinClick: (feature) => {
      const { properties, geometry } = feature
      const { goalStatus, district } = properties
      const { coordinates } = geometry
      dispatch(districtPinClicked(goalStatus, district, coordinates))
    },
    onClosePopup: () => {
      dispatch(districtPopupClosed())
    },
    onOpenPopup: (node, map) => {
      dispatch(districtPopupOpened(node, map))
    },
    onTableMouseEnter: (tooltipId) => {
      dispatch(districtNameMouseEnter(tooltipId && Number(tooltipId)))
    },
    onTableMouseLeave: () => {
      dispatch(districtNameMouseLeave())
    }
  }
}

const StateBandwidthDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StateBandwidthDashboard)

export default StateBandwidthDashboardContainer
