import { connect } from 'react-redux'
import DistrictDashboard from '../../components/DistrictDashboard'

const mapStateToProps = (state) => {
  return {
    district: state.district,
    districtServices: state.districtServices,
    stateMetrics: state.stateMetrics,
    meetingGoalDistricts: state.meetingGoalDistricts,
    bannerVisible: state.bannerVisible
  }
}

const DistrictDashboardContainer = connect(
  mapStateToProps,
  () => ({})
)(DistrictDashboard)

export default DistrictDashboardContainer
