import React from 'react'
import Bandwidth from '../../Filters/Bandwidth'
import * as Input from '../../Filters/Inputs'
import { formatBandwidth, formatRangeWith } from '../../../utils/stringHelpers'

function CircuitSize ({ bandwidth, onChange, onApply, ...props }) {
  const { min, max } = bandwidth.options

  const step = (min.value > 1000 && max.value > 1000) ? 1000 : 100

  return (
    <Bandwidth
      label='Circuit Size'
      step={step}
      bandwidth={bandwidth}
      onChange={onChange}
      onApply={onApply}
      minInput={props => <Input.CircuitSize name='min' {...props} />}
      maxInput={props => <Input.CircuitSize maxBoundary={max.boundary} name='max' {...props} />}
      appliedLabel={formatRangeWith(formatBandwidth)(bandwidth)}
      {...props} />
  )
}

export default CircuitSize
