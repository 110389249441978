import { fetchDistrict } from '../data'

export const REQUEST_DISTRICT = 'REQUEST_DISTRICT'
export function requestDistrict (entityNumber) {
  return {
    type: REQUEST_DISTRICT,
    payload: { entityNumber }
  }
}

export const RECEIVE_DISTRICT = 'RECEIVE_DISTRICT'
export function receiveDistrict (entityNumber, data) {
  return {
    type: RECEIVE_DISTRICT,
    payload: { entityNumber, data }
  }
}

export const RECEIVE_DISTRICT_ERROR = 'RECEIVE_DISTRICT_ERROR'
export function receiveDistrictError (entityNumber, error) {
  return {
    type: RECEIVE_DISTRICT_ERROR,
    payload: {
      entityNumber,
      error: error.message,
      status: error.status
    }
  }
}

export function getDistrict (entityNumber, retries = 2) {
  return function (dispatch) {
    dispatch(requestDistrict(entityNumber))

    return fetchDistrict(entityNumber)
      .then(data => dispatch(receiveDistrict(entityNumber, data)))
      .catch(error => {
        console.error(error.message)
        if (retries === 0) {
          return dispatch(receiveDistrictError(entityNumber, error))
        }
        return dispatch(getDistrict(entityNumber, retries - 1))
      })
  }
}

export default {
  REQUEST_DISTRICT,
  RECEIVE_DISTRICT,
  RECEIVE_DISTRICT_ERROR
}
