import React from 'react'
import Tooltip from '.'
import Popover from 'react-bootstrap/Popover'
import exclamationPoint from '../../assets/icon-24-icon-exclamation-secondary-3.svg'
import { NB_HYPHEN } from '../../utils/stringHelpers'

export default function DirtyDistrictTooltip ({ unfitType }) {
  if (!unfitType) return null

  const overlayContent = {
    ia: (
      <Popover id='DistrictsTable__tooltip--dirty' content='true'>
        <p className='DistrictsTable__tooltip--dirty'>We have insufficient broadband data for this school district.</p>
      </Popover>
    ),
    cost: (
      <Popover id='DistrictsTable__tooltip--dirty' content='true'>
        <p className='DistrictsTable__tooltip--dirty'>We have insufficient data about the cost of broadband for this school district. </p>
      </Popover>
    ),
    erate: (
      <Popover id='DistrictsTable__tooltip--dirty' content='true'>
        <p className='DistrictsTable__tooltip--dirty'>We have insufficient data about the E{NB_HYPHEN}rate funding for this school district. </p>
      </Popover>
    )
  }
  return (
    <Tooltip placement='auto' overlay={overlayContent[unfitType]}>
      <img tabIndex={0} className='DirtyDistrictTooltip__icon' src={exclamationPoint} aria-hidden alt='dirty district tooltip' />
    </Tooltip>
  )
}
