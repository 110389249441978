import React from 'react'
import PropTypes from 'prop-types'

import './FilterPane.sass'
import FilterModal from '../FilterModal/FilterModal'

export default function FilterPane ({ modal, children, onModalToggle, onApply, onClearAll }) {
  return (
    <div className='FilterPane'>
      <FilterModal numApplied={modal.numApplied} open={modal.open} onToggle={onModalToggle} onApply={onApply} onClearAll={onClearAll}>
        {children}
      </FilterModal>
      <div className='FilterPane__filters'>
        {children}
      </div>
      <div className='FilterPane__clear-all' onClick={onClearAll}>
        Clear all filters
      </div>
    </div>
  )
}

FilterPane.propTypes = {
  modal: PropTypes.object,
  children: PropTypes.any,
  onModalToggle: PropTypes.func,
  onApply: PropTypes.func,
  onClearAll: PropTypes.func
}
