import React from 'react'
import './index.sass'
import ExecutiveSummaryPDF from '../../../assets/documents/Connect_K12_Connectivity_Report_2023_FINAL.pdf'

export default function ExecutiveSummaryReport () {
  return (
    <div className='ExecutiveSummaryReport'>
      <div className='NationalMetrics__title'>
        2023 Report on School Connectivity
      </div>
      <div className='ExecutiveSummaryReport__text'>
        Read more about the current state of classroom connectivity across the United States.
      </div>
      <div className='ExecutiveSummaryReport__text--2'>
        <a href={ExecutiveSummaryPDF} className='ExecutiveSummaryReport__download' target='_blank' rel='noopener noreferrer'>Download the Report</a>
      </div>
      {/* <div className='NationalMetrics__source--educators'>
        Source:&nbsp;
        <a href='http://www.newschools.org/wp-content/uploads/2019/09/Gallup-Ed-Tech-Use-in-Schools-2.pdf'
          target='_blank' rel='noopener noreferrer'>
           NewSchools/Gallup Survey
        </a>
      </div> */}
    </div>
  )
}
