import qs from 'qs'
import * as router from 'connected-react-router'
import { NEARBY_SERVICES_SORT_APPLIED } from '../actions/nearbyServices'
import { DISTRICTS_TABLE_SORT_APPLIED } from '../actions/districts'
import {
  NEARBY_SERVICES_FILTERS_APPLIED,
  NEARBY_SERVICES_FILTER_CLEARED,
  NEARBY_SERVICES_FILTERS_CLEARED
} from '../actions/nearbyServicesFilters'
import {
  DISTRICTS_FILTERS_APPLIED,
  DISTRICTS_FILTER_CLEARED,
  DISTRICTS_FILTERS_CLEARED
} from '../actions/districtsFilters'
import { getLocation } from '../selectors/locationSelectors'
import { getAppliedFilterParams } from '../selectors/filterSelectors'
import { getSelectedSortOption } from '../selectors/dataSelectors'

const actions = [
  DISTRICTS_FILTERS_APPLIED,
  DISTRICTS_FILTER_CLEARED,
  DISTRICTS_FILTERS_CLEARED,
  DISTRICTS_TABLE_SORT_APPLIED,
  NEARBY_SERVICES_FILTERS_APPLIED,
  NEARBY_SERVICES_FILTER_CLEARED,
  NEARBY_SERVICES_FILTERS_CLEARED,
  NEARBY_SERVICES_SORT_APPLIED
]

const urlQueryParams = store => next => action => {
  const { type, meta } = action

  next(action)

  if (actions.includes(type)) {
    const state = store.getState()
    const location = getLocation(state)
    const filters = getAppliedFilterParams(state)
    const { value, direction } = getSelectedSortOption(state)
    const params = { ...filters, sort: { value, direction } }
    const search = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })
    const method = meta && meta.method
    const routerAction = method ? router[method] : router.push

    store.dispatch(routerAction({ ...location, search }))
  }
}

export default urlQueryParams
