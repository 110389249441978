import { LOCATION_CHANGE } from 'connected-react-router'
import deepmerge from 'deepmerge'
import commonState from './commonState'
import { RECEIVE_NEARBY_SERVICES } from '../../../../actions/nearbyServices'
import { enableMenu } from '../../../shared/filters/transformations/menu'
import applyRangeParams from '../../../shared/filters/transformations/applyRangeParams'

export const initialState = deepmerge(commonState, {
  options: {
    min: {
      boundary: 0,
      value: 0
    },
    max: {
      boundary: 30000,
      value: 30000
    }
  }
})

const applyURLParams = applyRangeParams('size')(initialState)

export default (state = initialState, action = {}) => {
  const { type } = action

  switch (type) {
    case LOCATION_CHANGE:
      return applyURLParams(state, action)
    case RECEIVE_NEARBY_SERVICES:
      return enableMenu(state)
    default:
      return state
  }
}
