import React from 'react'
import { Bar } from 'react-chartjs-2'
import colors from '../../styles/variables.scss'
import generateChartTooltip from '../ChartTooltip'

const tooltipType = 'PercentMeetingGoal__tooltip'

export default function ChartBar () {
  const chartRef = React.createRef()

  const tooltipHeader = (title, className) => {
    return (
      <tr>
        <th className={`${className}__header ChartTooltip__header`}>
          {title}
        </th>
      </tr>
    )
  }

  const tooltipBody = ({ tooltip, className }) => {
    return (
      <tr>
        <td>
          <div className={`${className}__value ChartTooltip__dollar`}>{(tooltip.labelColors[0].backgroundColor === '#fd676e' ? '$' : '') + parseFloat(tooltip.body[0].lines).toFixed(2)}</div>
        </td>
      </tr>
    )
  }

  const data = {
    labels: ['2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023'],
    datasets: [
      {
        backgroundColor: [colors.colorChartColor3, colors.colorChartColor1, colors.colorChartColor3, colors.colorChartColor1, colors.colorChartColor3, colors.colorChartColor1, colors.colorChartColor3, colors.colorChartColor1, colors.colorChartColor3, colors.colorChartColor1, colors.colorChartColor3, colors.colorChartColor1],
        data: [0.93, 1.85, 1.25, 1.35, 1.47, 1.20, 1.80, 1.01],
        barPercentage: 0.85,
        categoryPercentage: 1,
        datalabels: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold'
          }
        }
      }
    ]
  }

  const options = {
    legend: {
      display: false
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      axis: 'x',
      custom: generateChartTooltip({ tooltipType, tooltipHeader, tooltipBody, data })
    },
    borderRadius: 10,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: colors.black,
            padding: 10
          },
          gridLines: false
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: colors.colorGray1,
            min: 0,
            max: 2.00,
            stepSize: 0.5,
            padding: 10,
            callback: (label) => {
              return parseFloat(label).toFixed(2)
            }
          },
          position: 'right',
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            color: colors.colorLightGray1
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'center',
        formatter: function (value) {
          return ((parseFloat(value) === 1.85 || parseFloat(value) === 1.35 || parseFloat(value) === 1.20 || parseFloat(value) === 1.01) ? '$' : '') + parseFloat(value).toFixed(2)
        }
      }
    },
    maintainAspectRatio: false
  }
  return (
    <div>
      <Bar
        ref={chartRef}
        data={data}
        options={options}
      />
    </div>
  )
}
