import { sortObjects } from '../../utils'
import { DESC } from '../../utils/sortHelpers'

export const formatStateMetric = (value) => (metric) => (
  { fundingYear: metric.funding_year, data: metric[value] }
)

export const getProviderTotalServices = (providerNames) => {
  return providerNames.reduce((providers, name) => {
    let serviceByProvider = providers.find((s) => s.name === name)
    if (serviceByProvider) {
      serviceByProvider.totalServices += 1
    } else {
      serviceByProvider = { name, totalServices: 0 }
      serviceByProvider.totalServices += 1
      providers.push(serviceByProvider)
    }
    return providers
  }, [])
}

export const formatProviderOptions = (providerNames, applied = false) => {
  if (applied && applied.length > 0) {
    providerNames = providerNames.filter((name) => !applied.includes(name))
  }

  let sorted = sortObjects(
    getProviderTotalServices(providerNames),
    'totalServices',
    { direction: DESC }
  )
  return [...sorted].map((option) => {
    option.label = `${option.name} (${option.totalServices})`
    return option
  })
}
