import { isProvider } from './index'
import { closeAllMenus } from './menu'

export function clearFilter (state, initialState, name) {
  const clearedState = {
    applied: false,
    update: undefined
  }

  if (!isProvider(name)) {
    clearedState.options = initialState[name].options
  } else {
    clearedState.selected = []
  }

  return {
    [name]: {
      ...state[name],
      ...clearedState
    }
  }
}

export function clearAllFilters (state, initialState) {
  const nextState = {}

  Object.keys(state).forEach((name) => {
    nextState[name] = clearFilter(state, initialState, name)[name]
  })

  if (state.modal.open) return nextState

  return closeAllMenus(nextState)
}
