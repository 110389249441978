import { RECEIVE_NEARBY_SERVICES } from '../../../../../actions/nearbyServices'
import { NEARBY_SERVICES_DATA_FILTERED } from '../../../../../actions/nearbyServicesFilters'
import sortOptions, { recommendedSortOption } from './sortOptions'
import columnHeaders from '../../../../../components/NearbyServices/NearbyServicesTable/columnHeaders.json'
import { recommendedServices } from '../../../../../components/NearbyServices/helpers'

export const initialState = sortOptions(columnHeaders)

export default function options (state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case RECEIVE_NEARBY_SERVICES:
      const { data, isFirstRequest } = payload
      if (isFirstRequest && recommendedServices(data)) {
        return [...new Set([
          ...state,
          recommendedSortOption
        ])]
      }
      return state
    case NEARBY_SERVICES_DATA_FILTERED:
      const { nearby } = payload
      if (recommendedServices(nearby)) {
        return [...new Set([
          ...state,
          recommendedSortOption
        ])]
      }
      return [...initialState]
    default:
      return state
  }
}
