import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.css'

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import * as serviceWorker from './serviceWorker'
import './index.css'
import App from './containers/App'
import { store } from './store.js'
import './polyfill.js'

// FontAwesome React v5
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faGraduationCap,
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'

library.add(faGraduationCap, faCheckCircle, faExclamationCircle)

function enableAxe () {
  return String(process.env.ENABLE_AXE).toLowerCase() === 'true'
}

if (enableAxe()) {
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}

// Get these values from GTM Admin -> Environments -> Actions -> Get Snippet
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
  dataLayer: {}
}

TagManager.initialize(tagManagerArgs)

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  disabled: process.env.REACT_APP_DISABLE_HONEYBADGER === 'true'
})

ReactDOM.render(
  <ErrorBoundary honeybadger={Honeybadger}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
