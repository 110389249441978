import { connect } from 'react-redux'
import StateErateDashboard from '../../components/StateErateDashboard'
import { rawErateDistrictsTableSortApplied } from '../../actions/rawErateDistricts'

const mapStateToProps = (state) => {
  return {
    stateMetrics: state.rawErateStateMetrics,
    districts: state.rawErateDistricts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSort: (option) => {
      dispatch(rawErateDistrictsTableSortApplied(option))
    }
  }
}

const StateErateDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StateErateDashboard)

export default StateErateDashboardContainer
