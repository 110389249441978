import { initialState as bandwidth } from './bandwidth'
import { initialState as contract } from './contract'
import { initialState as cost } from './cost'
import { initialState as provider } from './provider'
import { initialState as size } from './size'
import { initialState as modal } from './modal'

export const filterId = 'DISTRICTS'

export default {
  bandwidth,
  contract,
  cost,
  provider,
  size,
  modal
}
