import React from 'react'
import PropTypes from 'prop-types'
import CurrentMetrics from './CurrentMetrics'
import FCCRecommendations from './FCCRecommendations'
import DistrictDemographics from './DistrictDemographics'
import './index.sass'

export default function DistrictDetails ({ placeholder, district, services }) {
  return (
    <div className='DistrictDetails'>
      <DistrictDemographics placeholder={placeholder} district={district} />
      <div className='DistrictDetails__metrics'>
        <CurrentMetrics placeholder={placeholder} district={district} services={services} />
        <FCCRecommendations placeholder={placeholder} district={district} />
      </div>
    </div>
  )
}

DistrictDetails.propTypes = {
  placeholder: PropTypes.bool.isRequired,
  district: PropTypes.object,
  services: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.string
  })
}
