import { POPUP_MARGIN } from '../components/Popup'

export function getPopupBodyHeight ({ selected, mapHeight, footerHeight = 0, headerHeight, numServices }) {
  const pinHeight = selected ? 35 : 30
  const calculatedHeight = mapHeight - headerHeight - footerHeight - pinHeight - POPUP_MARGIN.y
  const maxHeight = numServices > 1 ? Math.min(mapHeight - POPUP_MARGIN.y, 300) : 180
  if (calculatedHeight > maxHeight) {
    return { maxHeight: `${maxHeight}px` }
  } else {
    return { height: `${calculatedHeight}px` }
  }
}
