import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { isIE11 } from '../../../../utils'

const eventHandler = (handleEvent) => {
  return isIE11
    ? { onClick: handleEvent, onChange: () => {} }
    : { onChange: handleEvent }
}

export default function Checkbox ({ name, labelText, className, register }) {
  const [ checked, setChecked ] = useState(false)
  const icon = checked ? 'check-square' : 'square'
  const iconClassModifier = checked ? 'checked' : 'unchecked'
  const iconClass = `ContactForm__checkbox-icon--${iconClassModifier}`
  const labelClass = `ContactForm__checkbox--label ContactForm__checkbox--label-${name}`
  const handleEvent = () => setChecked(!checked)

  return (
    <div>
      <div className={className}>
        <label htmlFor={name}>
          <span>
            <input
              id={name}
              name={name}
              ref={register}
              className='ContactForm__checkbox--input'
              type='checkbox'
              checked={checked}
              {...eventHandler(handleEvent)}
            />
            <Icon icon={icon} className={iconClass} />
          </span>
          <span className={labelClass}>{labelText}</span>
        </label>
      </div>
      {
        name === 'other' && checked &&
        <input
          className='ContactForm__other-reason'
          name='otherReason'
          placeholder='Share what you are using this tool for'
          ref={register}
        />
      }
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string,
  register: PropTypes.func.isRequired
}
